import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
// import Checkbox from "../../component/Checkbox/Checkbox";
import Checkbox from "component/Checkbox/Checkbox";
import { Fragment } from "react";
import LinearWithValueLabel from "../progressBar/LinerBar";
import { Tooltip } from "@material-ui/core";
import CustomInput from "component/customInput/CustomInput";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";

export class CreateScreeningProtocol extends Component {
  render() {
    const {
      show,
      onHide,
      state,
      onChange,
      toggleCb,
      onFileChange,
      addNewScreeningProtocol,
      deleteEvent,
      createScreeningProtocolApi,
      isLoading,
    } = this.props;

    // console.log(
    //   "URL.createObjectURL(state.selectedVideo)",
    //   state.selectedVideo ? URL.createObjectURL(state.selectedVideo) : null
    // );

    return (
      <div>
        <Modal
          show={show}
          onHide={() => false}
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                Create Screening Protocol
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Name"
                  name="protocolName"
                  value={state.protocolName}
                  onChange={(e) => onChange(e)}
                  error={state.protocolNameError}
                />
              </div>

              <div className="d-flex align-item-center">
                <div className="form-group mr-2">
                  <CustomInput
                    type="text"
                    placeholder="Test Name"
                    name="nameOfTest"
                    value={state.nameOfTest}
                    onChange={(e) => onChange(e)}
                    error={state.nameOfTestError}
                  />
                </div>
                <div className="form-group ml-2">
                  <CustomInput
                    type="text"
                    placeholder="Description"
                    name="descriptionOfTest"
                    value={state.descriptionOfTest}
                    onChange={(e) => onChange(e)}
                    error={state.descriptionOfTestError}
                  />
                </div>
              </div>
              <div>
                <input
                  type="file"
                  onChange={(e) => onFileChange(e)}
                  style={{ display: "none" }}
                  accept="video/mp4"
                  ref={(ref) => (this.fileInput = ref)}
                  onClick={(e) => (e.target.value = null)}
                />
                <button
                  className="screening_protocol_comment_btn"
                  onClick={() => this.fileInput.click()}
                  style={
                    state.createVideoUploadProgress !== null &&
                    state.createVideoUploadProgress !== undefined &&
                    state.createVideoUploadProgress.length !== 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  disabled={
                    state.createVideoUploadProgress !== null &&
                    state.createVideoUploadProgress !== undefined &&
                    state.createVideoUploadProgress.length !== 0 &&
                    true
                  }
                >
                  <CloudUploadOutlinedIcon
                    style={{ color: "var(--safetyOrange)", marginRight: "7px" }}
                  />
                  Upload video
                </button>
                {state.createVideoUploadProgress !== null &&
                  state.createVideoUploadProgress !== undefined &&
                  state.createVideoUploadProgress.length !== 0 && (
                    <LinearWithValueLabel
                      progress={state.createVideoUploadProgress}
                    />
                  )}
                <p className="react_validation">{state.selectedVideoError}</p>
              </div>
              {/* <iframe
                width="100%"
                height="315"
                // src={activityDetails?.youtube_link}
                src={`https://www.youtube.com/embed/0by6K3wMlRc`}
                title=" video"
                frameborder="0"
                //   allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe> */}
              {state.showCreateProtocolViedio && (
                <div>
                  {state.selectedVideo ? (
                    <video
                      width="220"
                      height="120"
                      controls
                      style={{ outline: 0, width: "100%", height: "230px" }}
                      src={URL.createObjectURL(state.selectedVideo)}
                    ></video>
                  ) : null}
                </div>
              )}
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  <Checkbox
                    style={{ marginTop: "7px", cursor: "pointer" }}
                    checked={state.comments}
                    toggleCb={() => toggleCb()}
                  />
                  <span>Add Comment</span>
                </div>
                <div>
                  <Tooltip arrow title="Add new test">
                    <AddCircleIcon
                      onClick={() => addNewScreeningProtocol()}
                      style={{
                        width: "45px",
                        height: "50px",
                        color: "#F75F03",
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              {state.inputDisplay.length !== 0 &&
                state.inputDisplay.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="d-flex align-item-center">
                        <CustomInput
                          className={"mr-2"}
                          placeholder=""
                          value={data.name}
                          readOnly={true}
                        />
                        <CustomInput
                          className={"ml-2"}
                          placeholder=""
                          value={data.description}
                          readOnly={true}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className="d-flex align-items-center"
                          style={{ fontSize: "14px", color: "white" }}
                        >
                          <Checkbox
                            style={{ marginLeft: "5px" }}
                            checked={data.comment_allowed}
                            readOnly
                          />
                          <span>Add Comment</span>
                        </div>
                        <div>
                          <Tooltip arrow title="Delete test">
                            <div onClick={() => deleteEvent(index)}>
                              <SvgIcon
                                name="trash"
                                style={{
                                  width: "25px",
                                  height: "50px",
                                }}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <div>
                        <video
                          width="250"
                          height="140"
                          controls
                          style={{ outline: 0, width: "100%", height: "230px" }}
                        >
                          <source src={data.video} type="video/mp4" />
                        </video>
                      </div>
                    </React.Fragment>
                  );
                })}

              <div className="d-flex">
                <CustomButton
                  flex={1}
                  dataDismiss="modal"
                  onClick={() => createScreeningProtocolApi()}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CreateScreeningProtocol;
