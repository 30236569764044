import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import ShareIcon from "@material-ui/icons/Share";
import { Divider, IconButton } from "@material-ui/core";

import { Fragment } from "react";
import moment from "moment";
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#2E84AF",
    color: "#fff",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function SpacificReportTable({ reportWeekDayDetails }) {
  const classes = useStyles();


  return (
    <div>
      <TableContainer component={Paper}>
        {reportWeekDayDetails?.map((item) => {
          return (
            <Table
              className={classes.table}
              aria-label="customized table"
              key={item.id}
              style={{ backgroundColor: "var(--blackRussian)" }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    colSpan={6}
                    color="var(--defaultWhite)"
                    style={{
                      backgroundColor: "var(--blackRussian)",
                      border: "none",
                      fontWeight: 500,
                      fontSize: "13px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {`   Day ${item?.day_number
                      } - Session completed on : ${moment(
                        item?.updated_at
                      ).format("DD/MM/YYYY hh:mm a")}`}
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <Fragment>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      color="var(--defaultWhite)"
                      style={{
                        backgroundColor: "var(--valhalla)",
                        border: "none",
                        fontWeight: 500,
                        fontSize: "13px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Exercise
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      color="var(--defaultWhite)"
                      style={{
                        backgroundColor: "var(--valhalla)",
                        border: "none",
                        fontWeight: 500,
                        fontSize: "13px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Set Number
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      color="var(--defaultWhite)"
                      style={{
                        backgroundColor: "var(--valhalla)",
                        border: "none",
                        fontWeight: 500,
                        fontSize: "13px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Reps Completed
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      color="var(--defaultWhite)"
                      style={{
                        backgroundColor: "var(--valhalla)",
                        border: "none",
                        fontWeight: 500,
                        fontSize: "13px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Load Lifted
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {item?.completed_exercises.map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <TableBody>
                        <StyledTableRow
                          style={{
                            backgroundColor:
                              index === 0 || index % 2 === 0
                                ? "var(--blackRussian)"
                                : "var(--jaguar)",
                            paddingLeft: 20,
                            borderRadius: "13px",
                            border: "1px solid var(--blackRussian)",
                          }}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              color: "var(--defaultWhite)",
                              marginLeft: "88px",
                              border: "none",
                              padding: "16px",
                              fontSize: "12px",
                            }}
                          >
                            {data?.exercise?.exercise}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "var(--defaultWhite)",
                              border: "none",
                              padding: "16px",
                              fontSize: "12px",
                            }}
                          >
                            {data?.annual_training_program_exercise_set_number}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "var(--defaultWhite)",
                              border: "none",
                              padding: "16px",
                              fontSize: "12px",
                            }}
                          >
                            {data?.annual_training_program_reps_completed}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "var(--defaultWhite)",
                              border: "none",
                              padding: "16px",
                              fontSize: "12px",
                            }}
                          >
                            {data.annual_training_program_load_completed}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Fragment>
                  );
                })}
              </Fragment>
            </Table>
          );
        })}
      </TableContainer>
    </div>
  );
}
