import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./videoModal.css";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
export class VideoModal extends Component {
  render() {
    const { videoModalDetails } = this.props;
    const url = this.props?.videoModalDetails?.workout_exercise_video;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          // contentClassName="videoModal"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5
                className="modal-title"
                id="videopopupTitle"
                style={{ color: "#fff" }}
              >
                {videoModalDetails?.workout_exercise_name}
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div className="modal-body">
              {this.props?.videoModalDetails?.workout_exercise_video && (
                <div>
                  <iframe
                    // onLoad={() => setLoading(false)}
                    // placeholder="abcccc"
                    width="100%"
                    title="video"
                    height="345"
                    src={
                      this.props?.videoModalDetails
                        ?.workout_exercise_type_media === "video" ||
                      this.props?.videoModalDetails
                        ?.workout_exercise_type_media === "pdf"
                        ? this.props?.videoModalDetails?.workout_exercise_video
                        : `https://www.youtube.com/embed/${urlId}`
                    }
                    // src={`https://www.youtube.com/embed/${urlId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              {videoModalDetails.workout_exercise_description && (
                <>
                  <p style={{ color: "white", fontSize: 18, fontWeight: 500 }}>
                    Description
                  </p>
                  <p style={{ color: "white", fontSize: 10 }}>
                    {videoModalDetails.workout_exercise_description}
                  </p>
                </>
              )}
            </div>
          </Modal.Body>
          {/* <Modal.Footer
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              type="button"
              className="Model_btn "
              data-dismiss="modal"
              onClick={() => this.props.onHide()}
              style={{ padding: '5px ​36p' }}
            >
              Close
            </button>
          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

export default VideoModal;
