import React from "react";
import StartWorkoutModal from "../SelfScreen/StartWorkoutModal";
import ViewWeekModal from "../SelfScreen/ViewWorkoutModal";
import moment from "moment";
import AthleteTranningSessionRepostModal from "../../component/SelfScreen/tranningSessionSpacificReport/AthleteTranningSessionRepostModal";
import EventIcon from "@material-ui/icons/Event";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import ViewAthleteWorkoutModal from "container/Events/athleteEvent/modal/ViewAthleteWorkoutModal";
import { standardPostApi } from "container/API/ApiWrapper";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ViewSpacificReportAthlete from "./ViewSpacificReportAthlete";
import { ThreeSixtySharp } from "@material-ui/icons";
import CustomButton from "component/customButton/CustomButton";
import CardWrapper from "component/card/cardWrapper/CardWrapper";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CustomModal from "component/customModal/CustomModal";
import ActionButton from "component/actionButton/ActionButton";
import LoaderWrapper from "container/Loader/LoaderWrapper";
import ExportWokoutPdf from "./ExportWorkoutPdf";
import ExportWorkoutPdf from "./ExportWorkoutPdf";
import SvgIcon from "component/SvgIcon";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DoneAllIcon from "@material-ui/icons/DoneAll";

export default class AssignedWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startWorkoutModal: false,
      viewWorkoutModal: false,
      // weekArray: this.props.viewWorkoutModalArray,
      visibleGreenTick: false,
      annualTrainingProgram: this.props.startWorkoutArray,
      daysDetail: {},
      weekDetail: {},
      viewWorkoutData: {},
      AthleteRepostModal: false,
      viewWorkoutLoader: false,
      workoutDetails: [],
      startWorkoutClickEvent: "",
      spacificReportModal: false,
      spacificDaysId: "",
      spacificDayDetails: {},
      startDate: "",
      endDate: "",
      pdfUrl: "",
      downloadLoading: false,
      modalName: "",
    };
  }

  showStartWorkoutModal = async (days, data) => {
    // console.log("After Click On Start Workout DAY OBJECT", days);
    // console.log("AFTER CLICK ON START WORKOUT WEEK OBJECT ", data);
    await this.setState({
      startWorkoutModal: !this.state.startWorkoutModal,
      // daysDetail: days,
      // weekDetail: data,
    });
  };
  handelStartDate = (startDate) => {
    this.setState({ startDate: startDate });
  };
  handelEndDate = (endDate) => {
    this.setState({ endDate: endDate });
  };
  hideStartWorkoutModal = async () => {
    await this.setState({ startWorkoutModal: false });
  };

  showViewWorkoutModal = async (days, message, data) => {
    // console.log("View Days ---->", days);
    // localStorage.setItem(
    //   "modalName",
    //   `${this.state.daysDetail.day_number}ViewWorkout`
    // );
    await this.setState({
      viewWorkoutModal: !this.state.viewWorkoutModal,
      viewWorkoutData: days,
      startWorkoutClickEvent: message,
      daysDetail: days,
      weekDetail: data,
    });
    this.assignDayWorkoutCalander(days?.id);
  };

  hideViewWorkoutModal = async () => {
    await this.setState({ viewWorkoutModal: false });
  };

  AthleteRepostModalToggle = async () => {
    const { AthleteRepostModal } = this.state;
    await this.setState({ AthleteRepostModal: !AthleteRepostModal });
  };

  assignDayWorkoutCalander = async (daysId) => {
    const Atp = this.props.viewWorkoutModalArray;

    this.setState({ viewWorkoutLoader: true });
    try {
      const res = await standardPostApi(
        "assigned_day_workout_calender",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: Atp?.annual_training_program_id,
          annual_training_program_week_id: Atp?.id,
          annual_training_program_day_id: daysId,
        },
        true
      );

      // console.log("res", res.data.code);

      if (res.data.code === 200) {
        // console.log("Response of assign day workout calender", res.data.data);
        this.setState({ workoutDetails: res?.data?.data });
      }
    } catch (error) {
      console.error("assign DayWise Calender Error", error);
    } finally {
      this.setState({ viewWorkoutLoader: false });
    }
  };

  switchStartWorkout = () => {
    // localStorage.setItem(
    //   "modalName",
    //   `${this.state.daysDetail.day_number}WellBeing`
    // );
    this.setState({
      viewWorkoutModal: false,
      startWorkoutModal: true,
    });
  };

  toggleSpacificReportModal = (days) => {
    this.setState({
      spacificReportModal: !this.state.spacificReportModal,
      spacificDaysId: days?.id,
      spacificDayDetails: days,
    });
  };
  onModalName = (text) => {
    this.setState({ modalName: text });
    if (this.state.modalName === text) {
      return true;
    }
  };

  render() {
    const { AthleteRepostModal } = this.state;
    const {
      trainingSessionSpecificReportApi,
      trainingSessionReport,
      nextWeek,
      prevWeek,
    } = this.props;
    return (
      <div className="week_section mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h6 className="mb-3">Workout</h6>
              <div className="row mb-3">
                <div className="col-lg-5 col-md-5 col-sm-5">
                  <InputDateTimePicker
                    style={{ width: "100%" }}
                    value={this.state.startDate}
                    placeholder="Start Date"
                    onChange={this.handelStartDate}
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5">
                  <InputDateTimePicker
                    style={{ width: "100%" }}
                    value={this.state.endDate}
                    placeholder="End Date"
                    onChange={this.handelEndDate}
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <CustomModal
                    actionElement={<CustomButton>Export Workout</CustomButton>}
                    headingTitle="Export Workout"
                  >
                    <ExportWorkoutPdf
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      handleClose
                    />
                  </CustomModal>
                </div>
              </div>
            </div>
            <div className="heading athlete_week_date_main_div ">
              <div className="d-flex">
                <h5 className="athlete_week_date_main_h3 mr-3">
                  {this.props.viewWorkoutModalArray?.week_number}
                  <span className="athlete_week_date_main_dateFormate">
                    (&nbsp;
                    {moment(
                      this.props.viewWorkoutModalArray?.week_start
                    ).format("MMMM Do")}
                    )
                  </span>
                </h5>
                {/* {!this.props.hidePrev && ( */}
                <IconButton
                  style={{
                    // border: "2px dashed #fff",
                    backgroundColor: "var(--valhalla)",
                    height: "30px",
                    width: "30px",
                    marginRight: "5px",
                  }}
                  onClick={() => prevWeek()}
                >
                  <ArrowBackIosIcon
                    style={{ color: "#fff", fontSize: "12px" }}
                  />
                </IconButton>
                {/* )} */}
                {/* {!this.props?.hideNext && ( */}
                <IconButton
                  style={{
                    // border: "2px dashed #fff",
                    backgroundColor: "var(--valhalla)",
                    height: "30px",
                    width: "30px",
                  }}
                  onClick={() => nextWeek()}
                >
                  <ArrowForwardIosIcon
                    style={{ color: "#fff", fontSize: "12px" }}
                  />
                </IconButton>
                {/* )} */}
              </div>

              <Link to="/athleteEvent">
                <SvgIcon
                  name={"calender"}
                  style={{ color: "var(--safetyOrange)" }}
                />
              </Link>
            </div>
            <div className="row mt-4">
              {this.props.viewWorkoutModalArray?.days &&
                this.props.viewWorkoutModalArray?.days.map((days) => {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-6 " key={days?.id}>
                      <CardWrapper height={110}>
                        <div className="d-flex justify-content-between">
                          <h5>{days.day_number}</h5>
                          {days?.day_workout_complete && (
                            <DoneAllIcon
                              style={{
                                color: "green",
                                fontSize: "30px",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>

                        <div className="d-flex">
                          <CustomButton
                            className={"mt-2 mr-2"}
                            style={{ height: "33px", fontSize: "12px" }}
                            onClick={() => {
                              // localStorage.getItem("modalName") ===
                              // "ViewWorkout"
                              //   ?
                              this.showViewWorkoutModal(
                                days,
                                "startWorkout",
                                this.props.viewWorkoutModalArray
                              );
                              // :
                              //  this.setState({ startWorkoutModal: true });
                            }}
                          >
                            Start workout
                          </CustomButton>
                          <CustomButton
                            className={"mt-2"}
                            style={{ height: "33px", fontSize: "12px" }}
                            onClick={() => {
                              this.showViewWorkoutModal(
                                days,
                                "",
                                this.props.viewWorkoutModalArray
                              );
                            }}
                          >
                            View workout
                          </CustomButton>
                        </div>
                      </CardWrapper>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <StartWorkoutModal
          show={this.state.startWorkoutModal}
          onHide={this.hideStartWorkoutModal}
          startWorkoutArray={this.props.startWorkoutArray}
          daysDetail={this.state.daysDetail}
          weekDetail={this.state.weekDetail}
          onModalName={this.onModalName}
          modalName={this.state.modalName}
        />

        {/* <ViewWeekModal
          show={this.state.viewWorkoutModal}
          onHide={this.hideViewWorkoutModal}
          viewWorkoutModalArray={this.props.viewWorkoutModalArray}
          annualTrainingProgram={this.state.annualTrainingProgram}
          viewWorkoutData={this.state.viewWorkoutData}
        /> */}

        <ViewAthleteWorkoutModal
          show={this.state.viewWorkoutModal}
          onHide={this.hideViewWorkoutModal}
          workoutDetails={this.state?.workoutDetails}
          daysDetail={this.state.daysDetail}
          viewWorkoutModalArray={this.props.viewWorkoutModalArray}
          weekDetail={this.state.weekDetail}
          viewWorkoutLoader={this.state.viewWorkoutLoader}
          startWorkoutClickEvent={this.state.startWorkoutClickEvent}
          switchStartWorkout={this.switchStartWorkout}
          coachViewWorkout={false}
        />

        <AthleteTranningSessionRepostModal
          show={AthleteRepostModal}
          onHide={this.AthleteRepostModalToggle}
          trainingSessionReport={trainingSessionReport}
        />

        {this.state.spacificDaysId && (
          <ViewSpacificReportAthlete
            show={this.state.spacificReportModal}
            onHide={this.toggleSpacificReportModal}
            viewWorkoutModalArray={this.props.viewWorkoutModalArray}
            spacificDaysId={this.state.spacificDaysId}
            userId={this.props.userId}
            spacificDayDetails={this.state.spacificDayDetails}
          />
        )}
      </div>
    );
  }
}
