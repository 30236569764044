import React, { Component } from "react";
import { Form, Modal, ModalFooter } from "react-bootstrap";
// import Checkbox from "component/Checkbox/Checkbox";
import sportImg from "Custom/images/body-transformation-img.png";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../smallChackBox/Checkbox/Checkbox";
import Image from "component/ImageComponent/Image";
import CustomButton from "component/customButton/CustomButton";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
class AssignTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // confermatonMessage: false,
    };
  }

  render() {
    const {
      show,
      onHide,
      coachInfo,
      teamList,
      assignCoachToTeam,
      toggleChackbox,
      loadingAssinCoachModal,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="modal_backdrop"
        contentClassName="modal_container"
      >
        <Modal.Body>
          <div className="modal-header">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flex: "1",
              }}
            >
              <h5 className="modal-title" id="videopopupTitle">
                Assign team to {coachInfo.first_name} {coachInfo.last_name}
              </h5>
              <button type="button" className="modal_close" onClick={onHide}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="modal-body" style={{ color: "white" }}>
            {loadingAssinCoachModal ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa fa-spinner fa-spin fa-3x fa-fw"
                  style={{
                    color: "var(--safetyOrange)",
                    fontSize: "40px",
                    margin: "20px 0",
                  }}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="mb-3">Assign Award To Player</div>
                {teamList &&
                  teamList.length !== 0 &&
                  teamList.map((item, index) => {
                    return (
                      <FormControlLabel
                        style={{ margin: 0 }}
                        value={item?.id}
                        control={<GreenRadio checked={item?.is_checked} />}
                        label={item.team_name}
                        onClick={() => toggleChackbox(item, index)}
                        // onChange={() => toggleChackbox(item, index)}
                      />
                    );
                  })}
              </div>
            )}

            <div className="flex">
              <CustomButton flex={1} onClick={() => assignCoachToTeam()}>
                Assign
              </CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default AssignTeamModal;
const GreenRadio = withStyles({
  root: {
    color: "var(--safetyOrange)",
    "&$checked": {
      color: "var(--safetyOrange)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
