import SvgIcon from "component/SvgIcon";
import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "./CustomCalender.scss";
function CustomCalender({ onChange, value = new Date(), eventDate }) {
  // const [value, onChange] = useState(new Date());
  return (
    <Calendar
      onChange={onChange}
      tileContent={({ view, date }) =>
        view === "month" &&
        eventDate?.find(
          (x) =>
            moment(x.date, "YYYY-MM-DD").toDate().getTime() === date.getTime()
        ) ? (
          <p
            style={{
              margin: "0",
              borderRadius: "50px",
              width: "5px",
              height: "5px",
              backgroundColor: "var(--safetyOrange)",
            }}
          ></p>
        ) : null
      }
      value={value}
      className="custom_calender"
      nextLabel={
        <SvgIcon name="arrow_filled" style={{ transform: "rotate(180deg)" }} />
      }
      prevLabel={<SvgIcon name="arrow_filled" />}
      next2Label={null}
      prev2Label={null}
      onClickMonth={onChange}
      onActiveStartDateChange={(e) => onChange(e?.activeStartDate)}
    />
  );
}

export default CustomCalender;
