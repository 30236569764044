import React, { Component, useState } from "react";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
import TestingGraph from "../../component/Charts/TestingGraph";
import {
  DropdownButton,
  InputGroup,
  Modal,
  Dropdown,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { standardPostApi } from "../API/ApiWrapper";
import Checkbox from "../../component/Checkbox/Checkbox";
import { toast } from "react-toastify";
import Loader from "../../container/Loader/LoaderWrapper";
import DeleteTestingProtocolModal from "./DeleteTestingProtocolModal";
import { errorToast, successToast } from "utils/toastMessage";
import CreatePageSetModal from "./CreatePageSetModal";
import EditTestingProtocolModal from "./modal/EditTestingProtocolModal";
import ConfirmDeleteModal from "./modal/ConfirmDeleteModal";
import Buttons from "container/Coach After Login/Buttons";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import ActionButton from "component/actionButton/ActionButton";
import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SvgIcon from "component/SvgIcon";
import userImg from "../../Custom/images/user_placeholder.png";
import NoDataFound from "component/lottiLoader/LottiLoader";

class PlayerTesting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loding: true,
      show: false,
      showEdit: false,
      teamId: this.props.match.params.id,
      ShowAllTestingProtocol: [],
      name: "",
      testingProtocol: "",
      testingProtocolDetails: [],
      testing_protocol_id: "",
      resultCount: 0,
      currentPage: 1,
      teamTestingShow: false,
      inputList: [],
      exerciseName: "",
      smaller_better: "0",
      isChecked: false,
      units: "",
      nameError: "",
      // isChecked: false,
      graphData: "",
      editName: "",
      testingProtocolSelectPicker: [],
      testing_protocol_exercise_id: "",
      list_user_testing_protocol_result: [],
      user_testing_protocol_result: [],
      newTestingResult: "",
      exercise_id: "",
      user_id: "",
      user_exercise: [],
      testing_protocol_exercise_selectpicker_exercise_id: "",
      athlete_exercise: [],
      exercise_object: {},
      testingProtocolExercises: [],
      graphLabel: [],
      graph_Result: [],
      graph_color: [],
      graph_colorbg: [],
      list_user_testing_Exercise: "",
      athlete_result_for_modal: "",
      resultModalError: "",
      editTestingName: "",
      editTestingExercise: "",
      editTestingUnits: "",
      updateInputList: [],
      updateEditExerciseList: [],
      exerciseList: true,
      newExerciseList: false,
      itemForUpdateSaveButton: "",
      editNewTestingExercise: "",
      editNewTestingUnits: "",
      editInputList: [],
      editNewTestingExerciseError: "",
      editNewTestingUnitsError: "",
      finalArray: [],
      // testingProtocolDetailsForDeleteEdit: {},
      editTestingProtocolDeleteEvent: [],
      editTestingProtocolDeleteEventIndex: "",
      testingProtocoValue: "",
      testingProtocolName: "",
      tableHead: null,
      createTestingProtocolCheckbox: 0,
      deleteTestingProtocolModal: false,
      testingProtocolPreName: "",
      exerciseNameParagraphError: "",
      UnitsParagraphError: "",
      editTestingProtocolCheckbox: 0,
      buttonPreVisible: false,
      buttonNextVisible: false,
      toggleCreateModal: false,
      preEnable: false,
      nextEnable: false,
      selectPik: false,
      editNewModal: false,
      editNewDetailsName: "",
      editNewDetailsObj: [],
      editExerciseNew: "",
      editUnitNew: "",
      confirmModal: false,
      deleteExeIndex: "",
      deleteExerciseLoader: false,
      onHandelWait: true,
      editLoader: false,
      noProtocolAvalabe: "",
      createLoading: false,
      save_user_testing_protocolLoading: false,
      deleteLoading: false,
      createUpdateTestingProtocolResultsetLoading: false,
    };
    this.onChange = this.onChange.bind(this);
    // this.onChangeCreate = this.onChangeCreate.bind(this);
  }

  componentDidMount() {
    this.fetchShowAllTestingProtocol();
  }

  fetchShowAllTestingProtocol = async (message, updateProtocolId, proName) => {
    try {
      const res = await standardPostApi(
        "show_all_testing_protocol",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team_id: this.state.teamId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log(
        //   'This is Res of Show All Testing Protocol-> ',
        //   res.data.data.pickerArray.length
        // );

        const SelectedPicker = res.data.data.pickerArray[0];

        const totalIndex = res.data.data.pickerArray.length - 1;

        if (res.data.data.pickerArray.length === 0) {
          this.setState({
            testingProtocoValue: "",
            ShowAllTestingProtocol: [],
            loding: false,
            testingProtocolPreName: "",
            noProtocolAvalabe: "No testing protocol available",
          });
        } else {
          this.setState(
            message == "eventMessage"
              ? {
                  testingProtocoValue: updateProtocolId
                    ? updateProtocolId
                    : res.data.data.pickerArray[totalIndex].id,
                  ShowAllTestingProtocol: res.data.data.pickerArray,
                  loding: false,
                  testingProtocolPreName: proName
                    ? proName
                    : res.data.data.pickerArray[totalIndex].label,
                  noProtocolAvalabe: "",
                }
              : this.setState({
                  testingProtocoValue: SelectedPicker.id,
                  ShowAllTestingProtocol: res.data.data.pickerArray,
                  loding: false,
                  testingProtocolPreName: SelectedPicker.label,
                  noProtocolAvalabe: "",
                })
          );
        }

        res.data.data.pickerArray.length !== 0 &&
          this.testingProtocolResultCount(this.state.testingProtocoValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  testingProtocolResultCount = async (protocolId) => {
    try {
      const res = await standardPostApi(
        "testing_protocol_resultset_count",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_id: protocolId,
          team_id: this.state.teamId,
        },
        true
      );

      if (res.data.code === 200) {
        // console.log("RESPONSE OF RESULT COUNT", res.data.data.resultset_count);

        if (res.data.data.resultset_count === 0) {
          this.createUpdateTestingProtocolResultset(protocolId, "callMessage");
        } else {
          this.setState({ resultCount: res.data?.data?.resultset_count });
        }
        this.disableButton();
        this.TestingProtocolDetails(protocolId, this.state.currentPage);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  createUpdateTestingProtocolResultset = async (protocolId, message) => {
    this.setState({ createUpdateTestingProtocolResultsetLoading: true });
    try {
      const res = await standardPostApi(
        "create_update_testing_protocol_resultset",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_id: protocolId,
          team_id: this.state.teamId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log('Response Of Create Result set', res.data.data);

        this.setState({
          resultCount: res.data?.data?.resultset_count,
          currentPage: res.data?.data?.resultset_count,
          toggleCreateModal: false,
        });
        this.disableButton();
        this.TestingProtocolDetails(protocolId, this.state.currentPage);
        if (message === "callMessage") {
        } else {
          successToast(res.data.message);
        }
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      this.setState({ createUpdateTestingProtocolResultsetLoading: false });
    }
  };

  TestingProtocolDetails = async (id, count) => {
    try {
      const res = await standardPostApi(
        "testing_protocol_details",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_id: id,
          team_id: this.state.teamId,
          resultset_no: count,
        },
        true
      );

      if (res.data.code === 200) {
        // console.log(
        //   "This is res of Testing Protocol Details *>",
        //   res.data.data
        // );
        res.data.data.testing_protocol_exercises.map((item) => {
          this.setState({ tableHead: item.exercise });
        });

        // console.log(
        //   " res.data.data.testing_protocol_exercise_selectpicker.pickerArray",
        //   res.data.data.testing_protocol_exercise_selectpicker.pickerArray
        // );

        const GraphSelectPicker = res.data.data
          .testing_protocol_exercise_selectpicker.pickerArray[0]
          ? res.data.data.testing_protocol_exercise_selectpicker.pickerArray[0]
          : [];

        // console.log("This is graphSelectPicker-->", GraphSelectPicker.id);
        // console.log(
        //   "Previous--->",
        //   res.data.data.testing_protocol_exercise_selectpicker
        // );

        this.setState({
          testingProtocolDetails: res.data.data,
          testing_protocol_id: id,
          testingProtocolSelectPicker:
            res.data.data.testing_protocol_exercise_selectpicker.pickerArray,
          user_testing_protocol_result:
            res.data.data.user_testing_protocol_result,
          testing_protocol_exercise_selectpicker_exercise_id:
            res.data.data.testing_protocol_exercise_selectpicker.pickerObject,
          testing_protocol_exercise_id: GraphSelectPicker.id,
          onHandelWait: true,
        });

        GraphSelectPicker.id &&
          (await this.list_user_testing_protocol_result(count));
      }
    } catch (error) {
      this.setState({ onHandelWait: false });
      console.error(error);
    }
  };

  deleteTestingProtocol = async () => {
    const testingProtocolId = this.state.testing_protocol_id;
    // console.log('testing_protocol_id', testingProtocolId);
    this.setState({ deleteLoading: true });
    try {
      const res = await standardPostApi(
        "delete_testing_protocol",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_id: testingProtocolId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("this is res of delete protocol", res);
        await this.setState({
          deleteTestingProtocolModal: false,
          testingProtocolName: this.state.testingProtocolPreName,
          // currentPage: 1,
          currentPage: this.state.currentPage,
        });
        toast.success(res.data.message);
        await this.fetchShowAllTestingProtocol();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ deleteLoading: false });
    }
    // }
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log("onChange", this.state);
  }

  onHandel = (e) => {
    // console.log("target", e.target.value);

    this.setState({
      testingProtocoValue: e.target.value,
      currentPage: 1,
      onHandelWait: false,
    });

    this.state.ShowAllTestingProtocol.map((item) => {
      if (e.target.value == item.id) {
        this.setState({
          testingProtocolPreName: item.label,
        });
      }
    });
    // this.setState({
    //   onHandelWait: false,
    // });

    this.testingProtocolResultCount(e.target.value);
    this.TestingProtocolDetails(e.target.value, this.state.currentPage);

    // console.log(
    //   "Current Page--",
    //   this.state.currentPage,
    //   "/Result Count",
    //   this.state.resultCount,
    //   "Protocol id",
    //   e.target.value
    // );
  };

  toggleCb = async () => {
    await this.setState({
      createTestingProtocolCheckbox: !this.state.createTestingProtocolCheckbox,
    });

    if (this.state.createTestingProtocolCheckbox === true) {
      this.setState({ createTestingProtocolCheckbox: 1 });
    } else if (this.state.createTestingProtocolCheckbox === false) {
      this.setState({ createTestingProtocolCheckbox: 0 });
    }
  };

  addToTestingProtocolExercises = async () => {
    const isValid = this.addToTestingProtocolExercisesValidation();
    if (isValid) {
      const {
        inputList,
        exerciseName,
        units,
        // smaller_better,
        createTestingProtocolCheckbox,
      } = this.state;

      inputList.push({
        exercise: exerciseName,
        units: units,
        smaller_better: createTestingProtocolCheckbox,
      });
      await this.setState({
        inputList,
        exerciseName: "",
        units: "",
        createTestingProtocolCheckbox: 0,
        exerciseNameParagraphError: "",
        UnitsParagraphError: "",
      });
    }
  };

  addToTestingProtocolExercisesValidation = () => {
    let exerciseNameParagraphError = "";
    let UnitsParagraphError = "";

    if (!this.state.exerciseName) {
      exerciseNameParagraphError = "Exercise field is required";
    }
    if (!this.state.units) {
      UnitsParagraphError = "Units field is required";
    }

    // if(this)

    if (exerciseNameParagraphError || UnitsParagraphError) {
      this.setState({
        exerciseNameParagraphError,
        UnitsParagraphError,
      });
      return false;
    } else {
      return true;
    }
  };

  deleteEvent = (index) => {
    const copyPostArray = Object.assign([], this.state.inputList);
    copyPostArray.splice(index, 1);
    this.setState({
      inputList: copyPostArray,
    });
  };

  createTestingProtocol = async () => {
    // console.log("check state of exercise", this.state.name);

    const { inputList } = this.state;
    const inputArray = inputList.map((data) => {
      return {
        exercise: data.exercise,
        units: data.units,
        smaller_better: data.smaller_better,
      };
    });

    const isValid = this.createTestingProtocolValidation();
    if (isValid) {
      this.setState({ createLoading: true });
      try {
        const res = await standardPostApi(
          "create_testing_protocol",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            testing_protocol: this.state.name,
            team_id: this.props.match.params.id,
            testing_protocol_exercise: JSON.stringify(inputArray),
          },
          true
        );
        if (res.data.code === 200) {
          // console.log(
          //   'This is res og Create Testing Protocol ->',
          //   res.data.data
          // );
          toast.success(res.data.message);
          await this.setState({
            show: false,
            name: "",
            inputList: [],
            currentPage: 1,

            // currentPage: this.state.resultCount,
          });
          await this.fetchShowAllTestingProtocol("eventMessage");
          // this.disableButton();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ createLoading: false });
      }
    }
  };

  createTestingProtocolValidation = () => {
    if (!this.state.name) {
      errorToast("Name field is required.");
      return false;
    } else if (this.state.inputList.length === 0) {
      errorToast(
        "Please add atleast one exercise and Unit to create testing protocol."
      );
      return false;
    } else {
      return true;
    }
  };

  toggleEditCb = async () => {
    await this.setState({
      editTestingProtocolCheckbox: !this.state.editTestingProtocolCheckbox,
    });

    if (this.state.editTestingProtocolCheckbox === true) {
      this.setState({ editTestingProtocolCheckbox: 1 });
    } else if (this.state.editTestingProtocolCheckbox === false) {
      this.setState({ editTestingProtocolCheckbox: 0 });
    }
    // console.log(
    //   'THis si Check Box Respone->',
    //   this.state.editTestingProtocolCheckbox
    // );
  };

  list_user_testing_protocol_result = async (count) => {
    try {
      const res = await standardPostApi(
        "list_user_testing_protocol_result",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_exercise_id: this.state.testing_protocol_exercise_id,
          team_id: this.state.teamId,
          resultset_no: count,
        },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          list_user_testing_protocol_result: res.data.data.UserResult.user_data,
          list_user_testing_Exercise: res.data.data.UserResult.exercise,
        });
        // console.log({res});
        // console.log("===> ", this.state.list_user_testing_protocol_result);
        let ARR = [];
        let ArrResult = [];
        let ArrColour = [];
        let ArrBgColor = [];

        this.state.list_user_testing_protocol_result.forEach((item) => {
          ARR.push(item.name);
        });

        this.state.list_user_testing_protocol_result.forEach((item) => {
          ArrResult.push(item.result);
        });

        this.state.list_user_testing_protocol_result.forEach((item) => {
          ArrColour.push(item.color);
        });

        this.state.list_user_testing_protocol_result.forEach((item) => {
          ArrBgColor.push(item.color_bg);
        });
        // console.log("The array is ", ARR);
        // console.log("The array is ", ArrResult);
        this.setState({
          graphLabel: ARR,
          graph_Result: ArrResult,
          graph_color: ArrColour,
          graph_colorbg: ArrBgColor,
        });
        // this.setState({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  save_user_testing_protocol_validation = () => {
    let resultModalError = "";

    if (!this.state.newTestingResult) {
      resultModalError = "Please Enter New Testing Result";
    } else if (this.state.newTestingResult == 0) {
      resultModalError = "This value can not be empty or negative.";
    }

    if (resultModalError) {
      this.setState({ resultModalError });
      return false;
    } else {
      return true;
    }
  };

  save_user_testing_protocol = async () => {
    const { currentPage } = this.state;
    const testingProtocolId = this.state.testing_protocol_id;

    const user_id = this.state.user_id;

    // console.log("user_id", user_id);
    // console.log("testingProtocolId", testingProtocolId);
    this.setState({ save_user_testing_protocolLoading: true });
    const exercise_Id_result = this.state.athlete_exercise.map((data) => {
      return {
        id: data.id,
        result: data.result,
      };
    });
    // console.log("exercise....id...result", exercise_Id_result);
    const testValue = this.state.newTestingResult;
    const _exercises = exercise_Id_result;
    const _exerciseResult = this.state.exercise_object;
    // console.log("check rajat sir", _exercisesArray);

    let _exercisesArray = [];
    _exercises.forEach((item) => {
      _exercisesArray.push({
        testing_protocol_exercise_id: item.id.toString(),
        testing_protocol_result: item.result.toString(),
      });
    });

    _exercisesArray.forEach((i) => {
      if (i.testing_protocol_exercise_id === _exerciseResult.id.toString()) {
        i.testing_protocol_result = testValue;
      }
    });
    await this.setState({ testingProtocolExercises: [..._exercisesArray] });

    const isValid = this.save_user_testing_protocol_validation();
    if (isValid) {
      try {
        const res = await standardPostApi(
          "save_user_testing_protocol",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            testing_protocol_id: testingProtocolId,
            user_id: user_id,
            testing_protocol_exercise: JSON.stringify(
              this.state.testingProtocolExercises
            ),
            team_id: this.state.teamId,
            resultset_no: currentPage,
          },
          true
        );
        // console.log('This is res of save_user_testing_protocol ->', res);
        successToast(res.data.message);
        if (res.data.code === 200) {
          await this.setState({ teamTestingShow: false });
          await this.TestingProtocolDetails(
            this.state.testingProtocoValue,
            currentPage
          );
          
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ save_user_testing_protocolLoading: false });
        this.setState({testing_protocol_exercise_id:this.state.exercise_object?.id})
      }
    }
  };

  showModal = async (e) => {
    await this.setState({
      show: !this.state.show,
    });
  };

  hideModal = async (item) => {
    await this.setState({ show: false });
  };

  showEditModal = async (e) => {
    await this.setState({
      showEdit: !this.state.showEdit,
    });
  };

  hideEditModal = async (item) => {
    await this.setState({ showEdit: false });
  };

  showTeamTestingModal = async (item, data) => {
    // console.log('data', data);
    await this.setState({
      teamTestingShow: !this.state.teamTestingShow,
      exercise_id: item.id,
      user_id: data.id,
      user_exercise: data.exercise,
      athlete_exercise: data.exercise,
      exercise_object: item,
      // athlete_result_for_modal: item.result,
      newTestingResult: item.result,
      resultModalError: "",
    });
  };

  hideTeamTestingModal = async (item) => {
    await this.setState({ teamTestingShow: false, resultModalError: "" });
  };

  graphData = async (e) => {
    await this.setState({ testing_protocol_exercise_id: e.target.value });

    await this.list_user_testing_protocol_result(this.state.currentPage);
  };

  showDeleteTestingProtocolModal = async () => {
    await this.setState({
      deleteTestingProtocolModal: !this.state.deleteTestingProtocolModal,
    });
  };

  hideDeleteTestingProtocolModal = async () => {
    await this.setState({ deleteTestingProtocolModal: false });
  };

  // previousDetails = async () => {
  //   const {
  //     testing_protocol_exercise_id,
  //     testingProtocolSelectPicker,
  //   } = this.state;
  //   let id = testingProtocolSelectPicker[0]?.id;
  //   if (testing_protocol_exercise_id !== id) {
  //     let temp = testingProtocolSelectPicker.find(
  //       (x) => x.id === testing_protocol_exercise_id - 1
  //     );
  //     this.setState({ testing_protocol_exercise_id: temp?.id });
  //     this.list_user_testing_protocol_result();
  //   }
  // };

  // nextDetails = async () => {
  //   const {
  //     testing_protocol_exercise_id,
  //     testingProtocolSelectPicker,
  //   } = this.state;
  //   let id =
  //     testingProtocolSelectPicker[testingProtocolSelectPicker.length - 1]?.id;
  //   if (testing_protocol_exercise_id !== id) {
  //     let temp = testingProtocolSelectPicker.find(
  //       (x) => x.id === testing_protocol_exercise_id + 1
  //     );
  //     this.setState({ testing_protocol_exercise_id: temp?.id });
  //     this.list_user_testing_protocol_result();
  //   }
  // };

  disableButton = async () => {
    this.setState({ buttonPreVisible: false, buttonNextVisible: false });
    console.log(this.state.currentPage == 1 && this.state.resultCount > 1);
    console.log(this.state.currentPage == 1 && this.state.resultCount == 1);
    if (this.state.resultCount > 1) {
      this.setState({ buttonPreVisible: true, buttonNextVisible: true });

      // // when we are on first page of all the pages
      if (this.state.currentPage == 1 && this.state.resultCount > 1) {
        this.setState({
          buttonPreVisible: false,
          preEnable: false,
          buttonNextVisible: true,
          nextEnable: true,
        });
      } else if (this.state.currentPage == 1 && this.state.resultCount == 1) {
        this.setState({
          buttonPreVisible: false,
          preEnable: false,
          buttonNextVisible: false,
          nextEnable: false,
        });
      } else if (this.state.currentPage == this.state.resultCount) {
        this.setState({
          buttonPreVisible: true,
          preEnable: true,
          buttonNextVisible: false,
          nextEnable: false,
        });
      } else if (
        this.state.currentPage > 1 &&
        this.state.currentPage < this.state.resultCount
      ) {
        this.setState({
          buttonPreVisible: true,
          buttonNextVisible: true,
          preEnable: true,
          nextEnable: true,
        });
      } else {
        this.setState({
          buttonPreVisible: false,
          preEnable: false,
          buttonNextVisible: false,
          nextEnable: false,
        });
      }
      //when we are on last page of all the pages

      // when current page is in between various pages
    }
  };

  previousButton = async () => {
    // console.log("Previous BTN", (this.state.currentPage -= 1));
    this.setState({ currentPage: (this.state.currentPage -= 1) });

    this.disableButton();
    this.TestingProtocolDetails(
      this.state.testingProtocoValue,
      this.state.currentPage
    );
  };

  nextButton = async () => {
    console.log("dfdkfkdas");
    // console.log("Next BTN", (this.state.currentPage += 1));
    this.setState({ currentPage: (this.state.currentPage += 1) });
    this.disableButton();
    this.TestingProtocolDetails(
      this.state.testingProtocoValue,
      this.state.currentPage
    );
  };

  toggleCreatePageModal = async () => {
    this.setState({
      toggleCreateModal: !this.state.toggleCreateModal,
    });
  };

  toggleSelectPicker = async () => {
    await this.setState({ selectPik: !this.state.selectPik });
  };

  isInputNumber = (event) => {
    var char = String.fromCharCode(event.which);

    // if (!/[0-9]/.test(char)) {
    //   event.preventDefault();
    // }
    if (!/^\d*(\.\d{0,2})?$/.test(char)) {
      event.preventDefault();
    }
  };

  toggleNewEditModal = () => {
    let temp = this.state.testingProtocolDetails;

    this.setState({
      editNewModal: !this.state.editNewModal,
      editNewDetailsName: temp?.testing_protocol,
      editNewDetailsObj: temp?.testing_protocol_exercises,
    });
  };

  handelUpdateTestingProtocol = async (index, field, event) => {
    const { editNewDetailsObj } = this.state;
    let temp1 = [...editNewDetailsObj];
    temp1[index][field] = event.target.value;
    // console.log('TEMP INP', temp1);
    this.setState({ editNewDetailsObj: temp1 });
  };

  handelUpdateTestingProtocolCheckbox = async (item, index) => {
    const { editNewDetailsObj } = this.state;
    let temp1 = [...editNewDetailsObj];
    temp1[index].smaller_better = item.smaller_better === "1" ? "0" : "1";
    // console.log('TEMP CHECK', temp1);
    this.setState({ editNewDetailsObj: temp1 });
  };

  addToUpdateTestingProtocolExerciseNew = async () => {
    const isValid = this.addToUpdateTestingProtocolExerciseValidationNew();
    if (isValid) {
      const {
        editNewDetailsObj,
        editExerciseNew,
        editUnitNew,
        editTestingProtocolCheckbox,
      } = this.state;

      editNewDetailsObj.push({
        exercise: editExerciseNew,
        units: editUnitNew,
        smaller_better: JSON.stringify(editTestingProtocolCheckbox),
      });
      await this.setState({
        editNewDetailsObj,
        editExerciseNew: "",
        editUnitNew: "",
        editTestingProtocolCheckbox: 0,
        editNewTestingExerciseError: "",
        editNewTestingUnitsError: "",
      });
    }
  };

  addToUpdateTestingProtocolExerciseValidationNew = () => {
    let editNewTestingExerciseError = "";
    let editNewTestingUnitsError = "";

    if (!this.state.editExerciseNew) {
      // errorToast("Exercise field must not be empty");
      editNewTestingExerciseError = "Exercise field must not be empty";
    }

    if (!this.state.editUnitNew) {
      // editNewTestingUnitsError = toast.error("Units field must not be empty");
      editNewTestingUnitsError = "Units field must not be empty";
      // errorToast("Units field must not be empty");
    }

    if (editNewTestingExerciseError || editNewTestingUnitsError) {
      this.setState({ editNewTestingExerciseError, editNewTestingUnitsError });
      return false;
    } else {
      return true;
    }
  };

  updateTestingProtocolApi = async () => {
    const { editNewdefName, editNewDetailsName, editNewDetailsObj } =
      this.state;
    const testingProtocolId = this.state.testing_protocol_id;
    this.setState({ editLoader: true });
    let finalArray = [];

    editNewDetailsObj.map((item) => {
      const arr = {
        exercise: item.exercise,
        units: item.units,
        smaller_better: item.smaller_better,
      };
      finalArray.push(arr);
    });

    let proName = "";

    this.state.ShowAllTestingProtocol.map((item) => {
      if (item.id == testingProtocolId) {
        proName = item.label;
      }
    });

    try {
      const res = await standardPostApi(
        "update_testing_protocol",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_id: testingProtocolId,
          testing_protocol_name: editNewdefName
            ? editNewdefName
            : editNewDetailsName,
          testing_protocol_exercise: JSON.stringify(finalArray),
        },
        true
      );
      if (res.data.code === 200) {
        // console.log('Response of Update ', res.data.data);
        this.setState({
          currentPage: this.state.currentPage,
          editNewDetailsObj: res.data.data.testing_protocol_exercises,
          testingProtocolName: this.state.testingProtocolPreName,
          editLoader: false,
        });
        await this.fetchShowAllTestingProtocol(
          "eventMessage",
          testingProtocolId,
          proName
        );
        successToast(res.data.message);
        this.toggleNewEditModal();
      }
    } catch (error) {
      console.error(error);
      this.setState({ editLoader: false });
    } finally {
      this.setState({ editLoader: false });
    }
  };

  toggleConfirmModal = async (index) => {
    await this.setState({
      confirmModal: !this.state.confirmModal,
      deleteExeIndex: index,
    });
  };

  deleteExerciseEvent = async (index) => {
    const { editNewdefName, editNewDetailsName, editNewDetailsObj } =
      this.state;

    const testingProtocolId = this.state.testing_protocol_id;

    let temp = [...editNewDetailsObj];

    temp.splice(index, 1);

    let finalArray = [];

    temp.map((item) => {
      const arr = {
        exercise: item.exercise,
        units: item.units,
        smaller_better: item.smaller_better,
      };
      finalArray.push(arr);
    });

    // console.log('Final Array', finalArray);

    this.setState({ confirmModal: false, deleteExerciseLoader: true });

    try {
      const res = await standardPostApi(
        "update_testing_protocol",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          testing_protocol_id: testingProtocolId,
          testing_protocol_name: editNewdefName
            ? editNewdefName
            : editNewDetailsName,
          testing_protocol_exercise: JSON.stringify(finalArray),
        },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          currentPage: this.state.currentPage,
          editNewDetailsObj: res.data.data.testing_protocol_exercises,
          testingProtocolName: this.state.testingProtocolPreName,
          deleteExerciseLoader: false,
        });
        successToast("Testing Protocol deleted successfully.");
        this.TestingProtocolDetails(
          this.state.testingProtocoValue,
          this.state.currentPage
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ deleteExerciseLoader: false });
    } finally {
      this.setState({ deleteExerciseLoader: false });
    }
  };

  render() {
    const {
      ShowAllTestingProtocol,
      name,
      exerciseName,
      units,
      // smaller_better,
      // testingProtocol,
      // testingProtocolDetails,
      newTestingResult,
      // editTestingName,
      // editTestingExercise,
      // editTestingUnits,
      // editNewTestingExercise,
      // editNewTestingUnits,
      currentPage,
      graph_color,
      graph_colorbg,
      onHandelWait,
      noProtocolAvalabe,
      resultCount,
    } = this.state;

    const editExercise =
      this.state.testingProtocolDetails.testing_protocol_exercises;

    const user_testing_protocol_result =
    this.state.user_testing_protocol_result.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    })

    const coachRoles = localStorage.getItem("access_role");
   

    return (
      <div className="loader_sec">
        <CoachHeader />

        <div className="dashboard-wrapper">
          <section className="myteams_wrapper">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 side-buttons left-side-section">
                <Buttons
                  coachRoles={coachRoles}
                  props={this.props}
                  isActive="testing"
                />
              </div>

              <div className="col-lg-10 col-md-10 col-sm-10 right-side-section">
                <CustomBreadcrumb
                  heading="My Player"
                  className={"mb-4"}
                  navList={[
                    {
                      name: "My Team",
                      link: `/myteamwrapper`,
                    },
                    {
                      name: this.props.match.params.teamname,
                      link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                    },
                    {
                      name: "Reports",
                    },
                  ]}
                />
                {this.state.loding ? (
                  <Loader />
                ) : (
                  <>
                    <div className="d-flex align-items-center mt-5">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        {noProtocolAvalabe.length ? (
                          <select
                            className="select_class"
                            name="screeningProtocolValue"
                          >
                            <option className="dropdown-item dropdown-menu react_select_menu">
                              {noProtocolAvalabe}
                            </option>
                          </select>
                        ) : (
                          <select
                            className="select_class"
                            name="testingProtocol"
                            value={this.state.testingProtocoValue}
                            onChange={this.onHandel}
                          >
                            {/* <option value={1}>Testing Protocol Select</option> */}
                            {ShowAllTestingProtocol &&
                              ShowAllTestingProtocol.map((data) => {
                                return (
                                  <option
                                    className="dropdown-item dropdown-menu react_select_menu"
                                    value={data.id}
                                    key={data.id}
                                  >
                                    {data.label}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-3 col-md-3 col-sm-12 " />
                      <div className="col-lg-6 col-md-6 col-sm-12 row">
                        {coachRoles ===
                        "Assistant Coach" ? null : coachRoles ===
                          "S&C Coach" ? null : (
                          <>
                            <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center mb-2">
                              <ActionButton
                                onClick={
                                  onHandelWait === false
                                    ? null
                                    : (e) => {
                                        this.showModal();
                                      }
                                }
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center mb-2">
                              {noProtocolAvalabe.length ? (
                                <ActionButton type="edit" />
                              ) : (
                                <ActionButton
                                  type="edit"
                                  onClick={
                                    onHandelWait === false
                                      ? null
                                      : () => this.toggleNewEditModal()
                                  }
                                />
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center mb-2 ">
                              {noProtocolAvalabe.length ? (
                                <ActionButton type="delete" />
                              ) : (
                                <ActionButton
                                  type="delete"
                                  onClick={
                                    onHandelWait === false
                                      ? null
                                      : () =>
                                          this.showDeleteTestingProtocolModal()
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 " />
                    </div>
                    <div className="testresult_wrapper mt-4">
                      {noProtocolAvalabe.length ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <NoDataFound
                            height={250}
                            width={250}
                            text={noProtocolAvalabe}
                          />
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col col-lg-6 col-md-6 col-sm-6">
                            {this.state.selectPik === false ? (
                              <span>
                                Testing Results Page {currentPage} of{" "}
                                {this.state.resultCount}
                              </span>
                            ) : (
                              <select
                                className="select_class"
                                style={{ padding: "2px" }}
                              >
                                <option className="dropdown-item dropdown-menu react_select_menu">
                                  Testing Results Page 2 of 8
                                </option>
                                <option className="dropdown-item dropdown-menu react_select_menu">
                                  Testing Results Page 2 of 8
                                </option>
                                <option className="dropdown-item dropdown-menu react_select_menu">
                                  Testing Results Page 2 of 8
                                </option>
                              </select>
                            )}
                          </div>
                          <div className="col col-lg-6 col-md-6 col-sm-6 d-flex justify-content-end">
                            {/* {this.state.buttonPreVisible && ( */}
                            <CustomButton
                              className={"mr-2"}
                              onClick={
                                onHandelWait === false
                                  ? null
                                  : () => this.previousButton()
                              }
                              disabled={
                                this.state.preEnable === true ? false : true
                              }
                              style={{
                                backgroundColor: "#514E6C",
                                color: "white",
                                opacity:
                                  this.state.preEnable === true ? 1 : 0.3,
                                fontSize: "12px",
                              }}
                            >
                              Prev
                            </CustomButton>
                            {/* )}
                          {this.state.buttonNextVisible && ( */}
                            <CustomButton
                              className={"ml-2"}
                              onClick={
                                onHandelWait === false
                                  ? null
                                  : () => this.nextButton()
                              }
                              // disabled={!this.state.nextEnable}
                              disabled={
                                parseInt(this.state.currentPage) >=
                                parseInt(this.state.resultCount)
                              }
                              style={{
                                backgroundColor: "#514E6C",
                                color: "white",
                                fontSize: "12px",
                                opacity:
                                  parseInt(this.state.currentPage) >=
                                  parseInt(this.state.resultCount)
                                    ? 0.3
                                    : 1,
                              }}
                            >
                              Next
                            </CustomButton>
                            {/* )} */}
                          </div>
                        </div>
                      )}

                      <div className="d-flex align-items-center pt-4">
                        <div className="w-100 mr-3">
                          {noProtocolAvalabe.length ? null : (
                            <select
                              id=""
                              className="select_class"
                              value={this.state.testing_protocol_exercise_id}
                              onChange={this.graphData}
                            >
                              {this.state.testingProtocolSelectPicker.length ===
                                0 && (
                                <option value=""> Select an item....</option>
                              )}

                              {this.state.testingProtocolSelectPicker &&
                                this.state.testingProtocolSelectPicker.map(
                                  (item) => {
                                    return (
                                      <option
                                        className="dropdown-item dropdown-menu react_select_menu"
                                        value={item.id}
                                        key={item.id}
                                        // onClick={() => this.graphData(item)}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          )}
                        </div>
                        {noProtocolAvalabe.length ? null : (
                          <>
                            {coachRoles === "Assistant Coach" ? null : (
                              <CustomButton
                                onClick={
                                  onHandelWait === false
                                    ? null
                                    : () => this.toggleCreatePageModal()
                                }
                                style={{ fontSize: "15px", fontWeight: 500 }}
                              >
                                Create
                              </CustomButton>
                            )}
                          </>
                        )}
                      </div>
                      {this.state.testingProtocolSelectPicker.length !== 0 &&
                        this.state.graph_Result?.length > 0 && (
                          <TestingGraph
                            graphLabels={this.state.graphLabel}
                            graph_Result={this.state.graph_Result}
                            graph_color={graph_color}
                            graph_colorbg={graph_colorbg}
                            exercise={this.state.list_user_testing_Exercise}
                          />
                        )}

                      <div className="table-responsive mt-5 table_react_cursor">
                        <table className="table ">
                          <thead>
                            <tr
                              className="react_Testing_Table"
                              style={{ backgroundColor: "var(--blackRussian)" }}
                            >
                              {this.state.tableHead && <th>Player Name</th>}
                              {editExercise &&
                                editExercise.map((data) => {
                                  if (
                                    data?.id ==
                                    this.state.testing_protocol_exercise_id
                                  )
                                    return (
                                      <th key={data.id}>
                                        {data.exercise}{" "}
                                        {`${
                                          data.units ? `( ${data.units} )` : ""
                                        }`}
                                      </th>
                                    );
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {user_testing_protocol_result.map((data) => {
                              return (
                                <tr
                                  key={data.id}
                                  className="react_Testing_Table_1"
                                >
                                  <td>
                                    <img
                                      src={userImg}
                                      alt="No Img..."
                                      className="table-usr-img"
                                    />
                                    {data.name} ({data.email})
                                  </td>
                                  {data.exercise.map((item) => {
                                    if (
                                      item?.id ==
                                      this.state.testing_protocol_exercise_id
                                    )
                                      return (
                                        <td key={item.id}>
                                          <button
                                            className="team_testing_result_button"
                                            onClick={(e) => {
                                              coachRoles === "Assistant Coach"
                                                ? errorToast(
                                                    "You are not authorized to perform this operation."
                                                  )
                                                : this.showTeamTestingModal(
                                                    item,
                                                    data
                                                  );
                                            }}
                                          >
                                            {item.result > 0 ? (
                                              <span
                                                className="exercise_table_btn"
                                                style={
                                                  item.color === "#1e8bc3"
                                                    ? {
                                                        color: "#fff",
                                                        fontSize: "12px",
                                                      }
                                                    : {
                                                        color: item.color,
                                                        fontSize: "12px",
                                                      }
                                                }
                                              >
                                                {item.result}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  color: "var(--safetyOrange)",
                                                }}
                                              >
                                                {" "}
                                                Add New Result{" "}
                                              </span>
                                            )}
                                          </button>
                                        </td>
                                      );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>

        <Footer />
        {/* Adding Create  button model */}

        <Modal
          show={this.state.show}
          onHide={this.hideModal}
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                Create Testing Protocol
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.hideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={(e) => this.onChange(e)}
                  error={this.state.nameError}
                />
              </div>
              <div className="d-flex align-item-center">
                <div className="form-group mr-2">
                  <CustomInput
                    type="text"
                    placeholder="Exercise"
                    name="exerciseName"
                    value={exerciseName}
                    onChange={(e) => this.onChange(e)}
                    error={this.state.exerciseNameParagraphError}
                  />
                </div>
                <div className="form-group ml-2">
                  <CustomInput
                    type="text"
                    placeholder="Unit"
                    name="units"
                    value={units}
                    onChange={(e) => this.onChange(e)}
                    error={this.state.UnitsParagraphError}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  <Checkbox
                    style={{ marginTop: "7px" }}
                    checked={this.state.createTestingProtocolCheckbox}
                    toggleCb={() => this.toggleCb()}
                  />
                  <span>Smaller is Better?</span>
                </div>
                <div>
                  <AddCircleIcon
                    onClick={() => this.addToTestingProtocolExercises()}
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#F75F03",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              {this.state.inputList.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="d-flex align-item-center">
                      <CustomInput
                        className={"mr-2"}
                        type="text"
                        value={data.exercise}
                        readOnly={true}
                      />

                      <CustomInput
                        className={"ml-2"}
                        type="text"
                        value={data.units}
                        readOnly={true}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: "14px", color: "white" }}
                      >
                        <Checkbox
                          style={{ marginTop: "7px", cursor: "pointer" }}
                          name="smaller_better"
                          checked={data.smaller_better}
                          readOnly
                        />
                        <span>Smaller is Better?</span>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => this.deleteEvent(index)}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <SvgIcon
                            name="trash"
                            style={{
                              width: "25px",
                              height: "50px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}

              <div className="d-flex">
                <CustomButton
                  flex={1}
                  dataDismiss="modal"
                  onClick={() => {
                    this.createTestingProtocol();
                  }}
                  isLoading={this.state.createLoading}
                  disabled={this.state.createLoading}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* this is a team testing result model */}
        <Modal
          show={this.state.teamTestingShow}
          onHide={this.hideTeamTestingModal}
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="edit-protocol">
                Add New Testing Result
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.hideTeamTestingModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {/* <form action="" method=""> */}
              <div className="form-group">
                <input
                  style={{
                    height: "50px",
                    borderRadius: "25px",
                    backgroundColor: "var(--valhalla)",
                    border: "none",
                    color: "white",
                  }}
                  type="number"
                  className="form-control"
                  placeholder="0"
                  name="newTestingResult"
                  step="0.01"
                  min="0"
                  max="1000000000"
                  maxLength="10"
                  defaultValue={newTestingResult == 0 ? "" : newTestingResult}
                  onChange={(e) => this.onChange(e)}
                  onKeyPress={(event) => {
                    // console.log("event.key", event.key);
                    if (event.key === "Enter") {
                      this.save_user_testing_protocol();
                    } else {
                      this.isInputNumber(event);
                    }
                  }}
                  autoComplete="off"
                  // maxLength="10"
                />
                <p className="react_validation">
                  {this.state.resultModalError}
                </p>
              </div>
              {/* </form> */}
              <div className="d-flex">
                <CustomButton
                  dataDismiss="modal"
                  onClick={() => this.save_user_testing_protocol()}
                  isLoading={this.state.save_user_testing_protocolLoading}
                  disabled={this.state.save_user_testing_protocolLoading}
                  flex={1}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <DeleteTestingProtocolModal
          show={this.state.deleteTestingProtocolModal}
          onHide={this.hideDeleteTestingProtocolModal}
          testingProtocolName={this.state.testingProtocolName}
          deleteTestingProtocol={() => this.deleteTestingProtocol()}
          isLoading={this.state.deleteLoading}
        />

        <CreatePageSetModal
          show={this.state.toggleCreateModal}
          onHide={this.toggleCreatePageModal}
          testing_protocol_id={this.state.testing_protocol_id}
          createUpdateTestingProtocolResultset={
            this.createUpdateTestingProtocolResultset
          }
          isLoading={this.state.createUpdateTestingProtocolResultsetLoading}
        />
        <EditTestingProtocolModal
          show={this.state.editNewModal}
          onHide={this.toggleNewEditModal}
          editExercise={editExercise}
          state={this.state}
          onChange={this.onChange}
          handelUpdateTestingProtocol={this.handelUpdateTestingProtocol}
          handelUpdateTestingProtocolCheckbox={
            this.handelUpdateTestingProtocolCheckbox
          }
          toggleEditCb={this.toggleEditCb}
          addToUpdateTestingProtocolExerciseNew={
            this.addToUpdateTestingProtocolExerciseNew
          }
          updateTestingProtocolApi={this.updateTestingProtocolApi}
          toggleConfirmModal={this.toggleConfirmModal}
          editNewDetailsObj={this.state.editNewDetailsObj}
          isLoading={this.state.editLoader}
        />

        <ConfirmDeleteModal
          onHide={this.toggleConfirmModal}
          show={this.state.confirmModal}
          deleteExerciseEvent={this.deleteExerciseEvent}
          deleteExeIndex={this.state.deleteExeIndex}
        />
      </div>
    );
  }
}

export default PlayerTesting;
