import React from "react";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { standardPostApi } from "../../container/API/ApiWrapper";
import "../Coach After Login/MyTeamWrapper.css";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
// react toast
import SportCard from "component/card/sportCard/SportCard";
import CustomButton from "component/customButton/CustomButton";
import NoDataFound from "component/lottiLoader/LottiLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderWrapper from "../Loader/LoaderWrapper";
import AddNewTeamModal from "./modal/AddNewTeamModal";

class MyTeamWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      show: false,
      login: true,
      preCreateNewTeame: [],
      teamName: "",
      sportList: "",
      teamNameError: "",
      showAllTeam: [],
      selectSportError: "",
      createTeam: {},
      addTeamLoading: false,
      // teamDetail: {},
    };
  }

  checkLoginUrl = async () => {
    const token = await localStorage.getItem("access_token");
    const role = await localStorage.getItem("access_role");

    if (token == null && role == null) {
      this.setState({ login: false });
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    // console.log("onCHange", this.state);
  }

  componentDidMount() {
    this.fetchPreCreateNewTeam();
    this.fetchShowAllTeams();
    this.checkLoginUrl();
  }

  fetchPreCreateNewTeam = async () => {
    try {
      const res = await standardPostApi(
        "pre_create_new_team",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        this.setState({ preCreateNewTeame: res.data.data.Sports.pickerArray });
        // console.log("This is res of Pre Create New Team =>", res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  createNewTeaam = async () => {
    this.setState({ addTeamLoading: true });
    const isValid = this.validation();
    if (isValid) {
      try {
        const res = await standardPostApi(
          "create_new_team",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            name: this.state.teamName,
            sport: this.state.sportList,
          },
          true
        );
        if (res.data.code === 200) {
          await this.setState({
            showAllTeam: [...this.state.showAllTeam, res.data?.data],
          });
          // console.log("this is res of create new team", res.data.data);
          await this.hideModal();
          toast.success(res.data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ addTeamLoading: false });
      }
    }
  };

  validation = () => {
    let teamNameError = "";
    let selectSportError = "";

    if (!this.state.teamName) {
      teamNameError = "This field is required";
    }

    if (!this.state.sportList) {
      selectSportError = "Choose Sport Field";
    }

    if (teamNameError || selectSportError) {
      this.setState({ teamNameError, selectSportError });
      return false;
    } else {
      return true;
    }
  };

  fetchShowAllTeams = async () => {
    try {
      const res = await standardPostApi(
        "show_all_teams",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        // console.log("This is res of Show All Team =>", res.data.data);
        this.setState({ showAllTeam: res.data.data, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  showModal = async () => {
    await this.setState({
      show: !this.state.show,
    });
  };

  hideModal = async () => {
    await this.setState({ show: false });
  };

  // isInputAlphabet = (event) => {
  //   var char = String.fromCharCode(event.which);
  //   if (!/^[a-zA-Z ]+$/.test(char)) {
  //     event.preventDefault();
  //   }
  // };

  render() {
    if (this.state.login === false) {
      return <Redirect to="/" />;
    }

    const ShowTeam = this.state.showAllTeam;

    const coachRole = localStorage.getItem("access_role");
    // console.log("createTeam====>>>", ShowTeam);

    return (
      <div className="loader_sec background_color">
        <CoachHeader />
        {this.state.loading ? (
          <LoaderWrapper />
        ) : (
          <div className="pt-4">
            <section className="myteams_wrapper pb-0">
              <div className="container-fluid">
                <div className="my_team_container">
                  <div className="d-flex justify-content-between align-items-center py-4">
                    <div className="heading flex">My Team</div>
                    <AddNewTeamModal
                      showModal={this.state.show}
                      onHidePress={this.hideModal}
                      sports={this.state.preCreateNewTeame}
                      onChange={(e) => this.onChange(e)}
                      value={(this.state.teamName, this.state.sportList)}
                      addNewTeam={() => this.createNewTeaam()}
                      teamNameError={this.state.teamNameError}
                      selectSportError={this.state.selectSportError}
                      isInputAlphabet={this.isInputAlphabet}
                      isLoading={this.state.addTeamLoading}
                    />
                    {coachRole === "S&C Coach" ? null : coachRole ===
                      "Assistant Coach" ? null : (
                      <CustomButton onClick={this.showModal}>
                        Add New Team
                      </CustomButton>
                      // <div className="text-right">
                      //   <button
                      //     className="Model_Btn_term"
                      //     onClick={(e) => {
                      //       this.showModal();
                      //     }}
                      //   >
                      //     Add New Team{" "}
                      //     <i className="fa fa-plus" aria-hidden="true"></i>
                      //   </button>
                      // </div>
                    )}
                  </div>

                  <div
                    className="row mt-5 "
                    style={
                      ShowTeam.length === 0
                        ? { display: "flex", justifyContent: "center" }
                        : {}
                    }
                  >
                    {ShowTeam?.length === 0 ? (
                      <div>
                        {/* <img
                          src={noTeamAssign}
                          alt=""
                          style={{
                            width: "249px",
                            height: "249px",
                          }}
                        />
                        <p
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          No team avalable yet
                        </p> */}
                        <NoDataFound
                          height={250}
                          width={250}
                          text="No team available yet."
                        />
                      </div>
                    ) : (
                      <>
                        {ShowTeam &&
                          ShowTeam.map((team) => {
                            return (
                              <div
                                className="col-lg-4 col-12 col-md-6 text-center team_list_react"
                                key={team.id}
                              >
                                <Link
                                  to={`/myplayers/${team.id}/${team.team_name}`}
                                >
                                  <SportCard item={team} />
                                </Link>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default MyTeamWrapper;
