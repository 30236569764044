import React, { Component } from "react";
import "../Coach After Login/MyPlayers.css";
import { Link, Redirect } from "react-router-dom";
import maleIcon from "../../Custom/images/male-icon.png";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
import { Modal, Form, Alert } from "react-bootstrap";
import { standardPostApi } from "../API/ApiWrapper";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../Custom/images/celender.jpg";
// moment and timestamp in react js
import moment from "moment";
import { toast } from "react-toastify";
import LoaderWrapper from "../Loader/LoaderWrapper";
import show from "../../Custom/images/show.png";
import hide from "../../Custom/images/hide.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import noTeamAssign from "Custom/images/noTeamAvalable.png";
import NoDataFound from "component/lottiLoader/LottiLoader";
import { LinearProgress } from "@material-ui/core";
import AnimationsLoading from "utils/Skeleton";
import MyPlayerSkeleton from "skeletons/myPlayerSkeleton";
import MyPlayerSquarSkeleton from "skeletons/MyPlayerSquar";
import { errorToast } from "utils/toastMessage";
import { emailRegex } from "utils/Constants";
import CustomLinkButton from "component/customLinkButton/CustomLinkButton";
import SvgIcon from "component/SvgIcon";
import CoachCard from "component/coachCard/CoachCard";
import PlayerCard from "component/card/playerCard/PlayerCard";
import CustomInput from "component/customInput/CustomInput";
import CustomButton from "component/customButton/CustomButton";
import Buttons from "./Buttons";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import { element } from "prop-types";
// import AddPlayer from './AddPlayer/AddPlayer';
// import AnimationsLoading from "utils/Skeleton";

class MyPlayers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CratedByMe: false,
      showTeamLoader: false,
      show: false,
      addCoach: false,
      date: null,
      hasSetDate: false,
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      surname: "",
      SportPosition: "",
      teamId: this.props.match.params.id,
      preSportPosition: [],
      emailError: "",
      emailRequiredError: "",
      passwordError: "",
      passwordRequiredError: "",
      confirmPasswordError: "",
      firstNameError: "",
      surnameError: "",
      dateOfBirthError: "",
      ageLimitError: "",
      SportPositionError: "",
      coachEmail: "",
      coachPassword: "",
      coachConfirmPassword: "",
      coachFirstName: "",
      coachSurname: "",
      coachSpecialisation: "",
      coachRole: "",
      preCoachSpecialisation: [],
      coachEmailError: "",
      CoachEmailRequiredError: "",
      coachPasswordError: "",
      coachPasswordRequiredError: "",
      coachConfirmPasswordError: "",
      coachFirstNameError: "",
      coachSurnameError: "",
      coachDateOfBirthError: "",
      coachAgeLimitError: "",
      coachSpecialisationError: "",
      coachRoleError: "",
      listTeamPlayer: [],
      listTeamCoach: [],
      deleteTeamPage: false,
      deleteTeamModal: false,
      addCoachData: {},
      visibleUpdatePlayerData: false,
      loading: true,
      playerPasswordToggle: false,
      playerConfirmPasswordToggle: false,
      coachPasswordToggle: false,
      coachConfirmPasswordToggle: false,
      showAddPlayers: false,
      showAddCoach: false,
      isLoadingAddPlayer: false,
      isLoadingAddCoach: false,
      coachCount: 0,
    };
  }

  // refreshPage = async () => {
  //   await window.location.reload(false);
  // };

  onHandle(e) {
    this.setState({ [e.target.name]: e.target.value });
    // console.log("this is update state", this.state);
    this.setState({ [`${e.target.name}Error`]: " " });
  }

  addPlayerInTeam = async () => {
    const DOB = moment(this.toTimestamp(this.state.date) * 1000)
      .format("YYYY-MM-DD")
      .toString();
    const dateOfBirth = DOB;

    // console.log("THIS IS SPORT POSTION ISSUE -->", this.state.SportPosition);

    const isValid = this.validation();
    if (isValid) {
      this.setState({ isLoadingAddPlayer: true });
      try {
        const res = await standardPostApi(
          "add_coach_player_in_team",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            first_name: this.state.firstName,
            last_name: this.state.surname,
            email: this.state.email,
            password: this.state.password,
            dob: dateOfBirth,
            team: this.state.teamId,
            sport_position: this.state.SportPosition,
            role: "2",
          },
          true
        );
        if (res.data.code === 200) {
          await this.setState({
            addCoachData: res.data.data,
            visibleUpdatePlayerData: true,
          });
          await this.fetchListTeamPlayer();
          await this.hideModal();
          // toast.success(res.data.message);
          toast.success("Player successfully Created!");
          this.setState({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            surname: "",
            SportPosition: "",
            date: null,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ isLoadingAddPlayer: false });
      }
    }
  };

  onHandleCoach(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ [`${e.target.name}Error`]: " " });
    // console.log("this is update state", this.state);
  }

  addCoachInTeam = async () => {
    const DOB = moment(this.toTimestamp(this.state.date) * 1000)
      .format("YYYY-MM-DD")
      .toString();
    const dateOfBirth = DOB;

    const isValid = this.addCoachValidation();

    if (isValid) {
      this.setState({ isLoadingAddCoach: true });
      try {
        const res = await standardPostApi(
          "add_coach_player_in_team",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            first_name: this.state.coachFirstName,
            last_name: this.state.coachSurname,
            email: this.state.coachEmail,
            password: this.state.coachPassword,
            dob: dateOfBirth,
            specialization: this.state.coachSpecialisation,
            team: this.state.teamId,
            role: this.state.coachRole,
          },
          true
        );
        if (res.data.code === 200) {
          // console.log("This is res of Register Coach", res.data.data);
          await this.fetchListTeamPlayer();
          await this.hideCoachModal();
          // toast.success(res.data.message);
          const message =
            this.state.coachRole === "3"
              ? "Coach successfully Created!"
              : this.state.coachRole === "5"
              ? "Assistent Coach Created Sucessfully"
              : this.state.coachRole === "4"
              ? "S&C Coach Created Sucessfully"
              : "";

          toast.success(message);
          this.setState({
            coachEmail: "",
            coachPassword: "",
            coachConfirmPassword: "",
            coachFirstName: "",
            coachSurname: "",
            coachSpecialisation: "",
            coachRole: "",
            date: null,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ isLoadingAddCoach: false });
      }
    }
  };

  deleteTeamWithModal = async () => {
    try {
      const res = await standardPostApi(
        "delete_team",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: this.state.teamId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("This is response of delete Team ", res);
        toast.success(res.data.message);
        // alert(res.data.message);
        this.setState({ deleteTeamPage: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  validation = () => {
    let emailError = "";
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let emailRequiredError = "";
    let passwordError = "";
    let passReg =
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;
    let confirmPasswordError = "";
    let firstNameError = "";
    let surnameError = "";
    let dateOfBirthError = "";
    let ageLimitError = "";
    const dob = new Date(this.state.date);
    const today = new Date();
    let SportPositionError = "";
    let passwordRequiredError = "";

    if (!this.state.email) {
      emailRequiredError = "The Email field is required.";
    } else if (!this.state.email.match(emailReg)) {
      emailError = "The Email field is not a valid e-mail address.";
    }

    if (!this.state.password) {
      passwordRequiredError = "The Password field is required.";
    } else if (!this.state.password.match(passReg)) {
      passwordError =
        "Password must have minimum 8 characters, at least one number, at least one upper case, at least one lower case, at least one special character";
    }

    if (!this.state.confirmPassword.match(this.state.password)) {
      confirmPasswordError =
        "The password and confirmation password do not match.";
    }
    if (!this.state.firstName) {
      firstNameError = "The First Name field is required.";
    }
    if (!this.state.surname) {
      surnameError = "The Last Name field is required.";
    }

    if (!this.state.date) {
      dateOfBirthError = "Please select a Date of Birth.";
    } else if (today.getFullYear() - dob.getFullYear() < 16) {
      ageLimitError =
        "You must be at least 16 year old, in order to add new player.";
    }

    // if (!this.state.SportPosition) {
    //   SportPositionError = "Choose Sport Position Field";
    // }

    if (
      emailError ||
      emailRequiredError ||
      passwordError ||
      passwordRequiredError ||
      confirmPasswordError ||
      firstNameError ||
      surnameError ||
      dateOfBirthError ||
      ageLimitError
      // SportPositionError
    ) {
      this.setState({
        emailError,
        emailRequiredError,
        passwordError,
        passwordRequiredError,
        confirmPasswordError,
        firstNameError,
        surnameError,
        dateOfBirthError,
        ageLimitError,
        // SportPositionError,
      });
      return false;
    } else {
      return true;
    }
  };

  addCoachValidation = () => {
    let coachEmailError = "";
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let CoachEmailRequiredError = "";
    let coachPasswordError = "";
    let passReg =
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;
    let coachPasswordRequiredError = "";
    let coachConfirmPasswordError = "";
    let coachFirstNameError = "";
    let coachSurnameError = "";
    let coachDateOfBirthError = "";
    let coachAgeLimitError = "";
    const dob = new Date(this.state.date);
    const today = new Date();
    let coachSpecialisationError = "";
    let coachRoleError = "";

    if (!this.state.coachEmail) {
      coachEmailError = "The Email field is required.";
    } else if (!this.state.coachEmail.match(emailReg)) {
      CoachEmailRequiredError =
        "The Email field is not a valid e-mail address.";
    }

    if (!this.state.coachPassword) {
      coachPasswordError = "The Password field is required.";
    } else if (!this.state.coachPassword.match(passReg)) {
      coachPasswordRequiredError =
        "Password must have minimum 8 characters, at least one number, at least one upper case, at least one lower case, at least one special character";
    }

    if (!this.state.coachConfirmPassword.match(this.state.coachPassword)) {
      coachConfirmPasswordError =
        "The password and confirmation password do not match.";
    }

    if (!this.state.coachFirstName) {
      coachFirstNameError = "The First Name field is required.";
    }

    if (!this.state.coachSurname) {
      coachSurnameError = "The Last Name field is required.";
    }

    if (!this.state.date) {
      coachDateOfBirthError = "Please select a Date of Birth.";
    } else if (today.getFullYear() - dob.getFullYear() < 16) {
      coachAgeLimitError =
        "You must be at least 16 year old, in order to add new coach .";
    }

    if (!this.state.coachSpecialisation) {
      coachSpecialisationError = "Choose Coach Specialisation Field";
    }

    if (!this.state.coachRole) {
      coachRoleError = "Choose Role Field";
    }

    if (
      coachEmailError ||
      CoachEmailRequiredError ||
      coachPasswordError ||
      coachPasswordRequiredError ||
      coachConfirmPasswordError ||
      coachFirstNameError ||
      coachSurnameError ||
      coachDateOfBirthError ||
      coachAgeLimitError ||
      coachSpecialisationError ||
      coachRoleError
    ) {
      this.setState({
        coachEmailError,
        CoachEmailRequiredError,
        coachPasswordError,
        coachPasswordRequiredError,
        coachConfirmPasswordError,
        coachFirstNameError,
        coachSurnameError,
        coachDateOfBirthError,
        coachAgeLimitError,
        coachSpecialisationError,
        coachRoleError,
      });
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    this.fetchShowAllTeams();
    this.fetchPlayerSportPosition();
    this.fetchPreCoachPlayerInTeamSpecialisation();
    this.fetchListTeamPlayer();
    // console.log("--->",this.props?.location?.state);
    if (this.props?.location?.state?.addPlayer) {
      this.onShowAddPlayer();
    }

    if (this.props?.location?.state?.addCoach) {
      this.onShowAddCoach();
    }
  }

  fetchPlayerSportPosition = async () => {
    try {
      const res = await standardPostApi(
        "pre_add_coach_player_in_team",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: this.state.teamId,
        },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          preSportPosition: res.data.data.SportPositions.pickerArray,
        });
        // console.log(
        //   "This is res of Player Sport Position =>",
        //   res.data.data.SportPositions.pickerArray
        // );
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchPreCoachPlayerInTeamSpecialisation = async () => {
    try {
      const res = await standardPostApi(
        "pre_add_coach_player_in_team",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: this.state.teamId,
        },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          preCoachSpecialisation: res.data.data.CoachSpecialization.pickerArray,
        });
        // console.log(
        //   "This is res of  Coach Specialisation ",
        //   res.data.data.CoachSpecialization.pickerArray
        // );
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchListTeamPlayer = async () => {
    try {
      const res = await standardPostApi(
        "list_team_players",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: this.state.teamId,
        },
        true
      );
      if (res.data.code === 200) {
        let scCoach = [];
        let headCoach = [];
        let astCoach = [];

        res.data.data.Coach.map((element) => {
          if (element.role == "4") {
            scCoach.push(element);
          }
          if (element.role == "5") {
            astCoach.push(element);
          }
          if (element.role == "3") {
            headCoach.push(element);
          }
        });

        let tempCoaches = [
          {
            title: "Coach",
            coaches: headCoach,
          },
          {
            title: "S&C Coach",
            coaches: scCoach,
          },
          {
            title: "Assistant Coach",
            coaches: astCoach,
          },
        ];
        this.setState({
          listTeamCoach: tempCoaches,

          listTeamPlayer: res.data.data.Athelete,
          loading: false,
          coachCount: res.data.data.Coach.length,
        });
        // console.log('This is res of List Team Player =>', res.data.data);
        // console.log("listTeamPlayer in Function", this.state.listTeamPlayer);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ date: date, hasSetDate: true });
    this.setState({ dateOfBirthError: " " });
    // console.log("The date selected is ", this.state.date);
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  showModal = async (item) => {
    await this.setState({
      show: !this.state.show,
    });
  };

  hideModal = async (item) => {
    await this.setState({ show: false });
  };

  ShowCoachModal = async (item) => {
    await this.setState({
      addCoach: !this.state.addCoach,
    });
  };

  hideCoachModal = async (item) => {
    await this.setState({ addCoach: false });
  };

  showDeleteTeamModal = async () => {
    await this.setState({ deleteTeamModal: !this.state.deleteTeamModal });
  };

  hideDeleteTeamModal = async () => {
    await this.setState({ deleteTeamModal: false });
  };

  togglePassword = async () => {
    await this.setState({
      playerPasswordToggle: !this.state.playerPasswordToggle,
    });
  };

  togglePlayerConfirmPass = async () => {
    await this.setState({
      playerConfirmPasswordToggle: !this.state.playerConfirmPasswordToggle,
    });
  };

  toggleCoachPass = async () => {
    const { coachPasswordToggle } = this.state;
    await this.setState({ coachPasswordToggle: !coachPasswordToggle });
  };

  toggleCoachConfirmPass = async () => {
    const { coachConfirmPasswordToggle } = this.state;
    await this.setState({
      coachConfirmPasswordToggle: !coachConfirmPasswordToggle,
    });
  };

  fetchShowAllTeams = async () => {
    this.setState({ showTeamLoader: true });
    try {
      const res = await standardPostApi(
        "show_all_teams",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        // console.log('This is res of Show All Team =>', res.data.data);
        // console.log('this.props.match.params.id', this.props.match.params.id);
        // res.data.data.map((item) => {
        //   if (item.id === this.props.match.params.id) {
        //     temp = item;
        //   }
        // });

        const temp = res.data.data.find(
          (x) => x.id == this.props.match.params.id
        );

        // console.log('TEam ye hai ', temp);

        this.setState({ CratedByMe: temp?.created_by_me });

        // this.setState({ showAllTeam: res.data.data });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ showTeamLoader: false });
    }
  };
  onShowAddPlayer = () => {
    this.setState({ showAddPlayers: true, showAddCoach: false });
  };
  onShowAddCoach = () => {
    this.setState({ showAddCoach: true, showAddPlayers: false });
  };
  ShowPlayer = () => {
    this.setState({ showAddCoach: false, showAddPlayers: false });
  };
  render() {
    if (this.state.deleteTeamPage) {
      return <Redirect to="/myteamwrapper" />;
    }
    const {
      email,
      password,
      confirmPassword,
      firstName,
      surname,
      SportPosition,
      coachEmail,
      coachPassword,
      coachConfirmPassword,
      coachFirstName,
      coachSurname,
      coachSpecialisation,
      coachRole,
      playerConfirmPasswordToggle,
      coachPasswordToggle,
      coachConfirmPasswordToggle,
      showAddPlayers,
      showAddCoach,
    } = this.state;

    const sports = this.state.preSportPosition;
    const Specialisation = this.state.preCoachSpecialisation;

    const { listTeamPlayer } = this.state;

    const coachRoles = localStorage.getItem("access_role");

    return (
      <div className="loader_sec">
        <CoachHeader />
        <div className="dashboard-wrapper">
          <section className="myteams_wrapper">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 side-buttons left-side-section">
                <Buttons
                  coachRoles={coachRoles}
                  props={this.props}
                  onShowAddCoach={this.onShowAddCoach}
                  onShowAddPlayer={this.onShowAddPlayer}
                  isActive={
                    showAddPlayers
                      ? "addPlayers"
                      : showAddCoach
                      ? "addCoach"
                      : null
                  }
                  showDeleteModal={this.showDeleteTeamModal}
                />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 right-side-section">
                <CustomBreadcrumb
                  heading={"My Players"}
                  className={"mb-4"}
                  navList={[
                    {
                      name: "My Team",
                      link: `/myteamwrapper`,
                    },
                    {
                      name: this.props.match.params.teamname,
                    },

                    showAddPlayers || showAddCoach
                      ? {
                          name: showAddPlayers
                            ? "Add New Player"
                            : showAddCoach
                            ? "Add New Coach"
                            : null,
                        }
                      : null,
                  ]}
                />

                {showAddPlayers && (
                  <div className="row" style={{ margin: "35px 0" }}>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => this.onHandle(e)}
                        error={this.state.firstNameError}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Last Name"
                        type="text"
                        name="surname"
                        value={surname}
                        onChange={(e) => this.onHandle(e)}
                        error={this.state.surnameError}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Email Address"
                        type="text"
                        name="email"
                        value={email}
                        onChange={(e) => this.onHandle(e)}
                        error={
                          this.state.emailError + this.state.emailRequiredError
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <InputDateTimePicker
                        value={this.state.date}
                        onChange={this.handleDateChange}
                        name="DateOfBirth"
                        placeholder="Date Of Birth"
                        error={`${this.state.dateOfBirthError}
                        ${this.state.ageLimitError}`}
                        maxDate={new Date()}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Password"
                        type={
                          this.state.playerPasswordToggle === false
                            ? "password"
                            : "text"
                        }
                        name="password"
                        value={password}
                        onChange={(e) => this.onHandle(e)}
                        error={
                          this.state.passwordError +
                          this.state.passwordRequiredError
                        }
                      />
                      {this.state.playerPasswordToggle === false ? (
                        <VisibilityIcon
                          className="addPlayer-pass-hide"
                          color="white"
                          onClick={() => this.togglePassword()}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="addPlayer-pass-hide"
                          color="white"
                          onClick={() => this.togglePassword()}
                        />
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Confirm Password"
                        type={
                          playerConfirmPasswordToggle === false
                            ? "password"
                            : "text"
                        }
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => this.onHandle(e)}
                        error={this.state.confirmPasswordError}
                      />
                      {playerConfirmPasswordToggle === false ? (
                        <VisibilityIcon
                          className="addPlayer-pass-hide"
                          onClick={() => this.togglePlayerConfirmPass()}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="addPlayer-pass-hide"
                          onClick={() => this.togglePlayerConfirmPass()}
                        />
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <select
                        disabled={sports.length !== 0 ? false : true}
                        className="select_class"
                        name="SportPosition"
                        value={SportPosition}
                        onChange={(e) => this.onHandle(e)}
                      >
                        <option value="">
                          {sports.length !== 0
                            ? "Select Sport Position"
                            : "No Sport Position Avalable"}
                        </option>
                        {/* {console.log("this is SPORT POSITION ISSUE", sports)} */}
                        {sports &&
                          sports.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>
                      <p className="react_validation">
                        {this.state.SportPositionError}
                      </p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="d-flex">
                        <CustomButton
                          isLoading={this.state.isLoadingAddPlayer}
                          disabled={this.state.isLoadingAddPlayer}
                          style={{ margin: "4px 0" }}
                          flex={1}
                          onClick={() => {
                            this.addPlayerInTeam();
                          }}
                        >
                          Save
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                )}
                {showAddCoach && (
                  <div className="row" style={{ margin: "35px 0" }}>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="First Name"
                        type="text"
                        name="coachFirstName"
                        value={coachFirstName}
                        onChange={(e) => this.onHandleCoach(e)}
                        error={this.state.coachFirstNameError}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Last Name"
                        type="text"
                        name="coachSurname"
                        value={coachSurname}
                        onChange={(e) => this.onHandleCoach(e)}
                        error={this.state.coachSurnameError}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Email Address"
                        type="text"
                        name="coachEmail"
                        value={coachEmail}
                        onChange={(e) => this.onHandleCoach(e)}
                        error={
                          this.state.coachEmailError +
                          this.state.CoachEmailRequiredError
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <InputDateTimePicker
                        style={{ width: "100%" }}
                        value={this.state.date}
                        onChange={this.handleDateChange}
                        name="DateOfBirth"
                        maxDate={new Date()}
                        placeholder="Date Of Birth"
                        error={`${this.state.coachDateOfBirthError}
                        ${this.state.coachAgeLimitError}`}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Password"
                        type={
                          coachPasswordToggle === false ? "password" : "text"
                        }
                        name="coachPassword"
                        value={coachPassword}
                        onChange={(e) => this.onHandleCoach(e)}
                        error={
                          this.state.coachPasswordError +
                          this.state.coachPasswordRequiredError
                        }
                      />
                      {coachPasswordToggle === false ? (
                        <VisibilityIcon
                          className="addPlayer-pass-hide"
                          color="white"
                          onClick={() => this.toggleCoachPass()}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="addPlayer-pass-hide"
                          color="white"
                          onClick={() => this.toggleCoachPass()}
                        />
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <CustomInput
                        placeholder="Confirm Password"
                        type={
                          coachConfirmPasswordToggle === false
                            ? "password"
                            : "text"
                        }
                        name="coachConfirmPassword"
                        value={coachConfirmPassword}
                        onChange={(e) => this.onHandleCoach(e)}
                        error={this.state.coachConfirmPasswordError}
                      />
                      {coachConfirmPasswordToggle === false ? (
                        <VisibilityIcon
                          className="addPlayer-pass-hide"
                          color="white"
                          onClick={() => this.toggleCoachConfirmPass()}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="addPlayer-pass-hide"
                          color="white"
                          onClick={() => this.toggleCoachConfirmPass()}
                        />
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <select
                        className="select_class"
                        name="coachSpecialisation"
                        value={coachSpecialisation}
                        onChange={(e) => this.onHandleCoach(e)}
                      >
                        <option value="">Select Coach Specialisation</option>
                        {Specialisation &&
                          Specialisation.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>
                      <p className="react_validation">
                        {this.state.coachSpecialisationError}
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                      <select
                        className="select_class"
                        name="coachRole"
                        value={coachRole}
                        onChange={(e) => this.onHandleCoach(e)}
                      >
                        <option value="">Select Role</option>
                        {/* <option value="">Admin</option> */}
                        {this.state.CratedByMe && (
                          <option value="3">Coach</option>
                        )}
                        <option value="4">S&C Coach</option>
                        <option value="5">Assistant Coach</option>
                      </select>
                      <p className="react_validation">
                        {this.state.coachRoleError}
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="d-flex">
                        <CustomButton
                          isLoading={this.state.isLoadingAddCoach}
                          disabled={this.state.isLoadingAddCoach}
                          flex={1}
                          onClick={() => {
                            this.addCoachInTeam();
                          }}
                        >
                          Save
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.loading ? (
                  <LoaderWrapper />
                ) : (
                  <>
                    {listTeamPlayer?.length === 0 ||
                    listTeamPlayer === undefined ? (
                      <>
                        {!showAddCoach && !showAddPlayers && (
                          <NoDataFound
                            height={250}
                            width={250}
                            text="   No player available yet"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {listTeamPlayer &&
                          listTeamPlayer.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {!showAddPlayers && !showAddCoach && (
                                  <div
                                    className="player-position-card"
                                    key={item.position}
                                  >
                                    <h2>{item.position}</h2>
                                    <div className="row">
                                      {item.players.map((player) => {
                                        return (
                                          <div
                                            className="col-lg-3 col-md-4 col-sm-6 mb-3"
                                            key={player.id}
                                          >
                                            <Link
                                              to={{
                                                pathname: `/coachplayerinner/${this.props.match.params.id}/${player.id}/${this.props.match.params.teamname}`,
                                                state: { player },
                                              }}
                                              className="player_page w-100"
                                            >
                                              <PlayerCard item={player} />
                                            </Link>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        {!showAddPlayers &&
                          !showAddCoach &&
                          this.state.coachCount > 0 && (
                            <p className="heading-p">My Coaches</p>
                          )}
                        {this.state.listTeamCoach?.map((element, index) => {
                          return (
                            <React.Fragment key={element?.title}>
                              {!showAddPlayers &&
                                !showAddCoach &&
                                element.coaches?.length > 0 && (
                                  <div className="player-position-card">
                                    <h2>{element?.title}</h2>
                                    <div className="row">
                                      {element?.coaches.map((item) => {
                                        return (
                                          <div
                                            className="col-lg-3 col-md-4 col-sm-6 mb-3"
                                            key={item.id}
                                          >
                                            <Link
                                              to={{
                                                pathname: `/coachinner/${this.props.match.params?.id}/${item?.id}/${this.props.match.params?.teamname}`,
                                                state: { item },
                                              }}
                                              className="player_page w-100"
                                            >
                                              <PlayerCard item={item} />
                                            </Link>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="container-fluid"></div>
          </section>
        </div>
        <Footer />
        {/* this  is add Player Modal */}
        <Modal show={this.state.show} onHide={this.hideModal}>
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="playeraddTitle">
                Add New Player
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  this.hideModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form action="" className="create_newteam">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => this.onHandle(e)}
                  />
                  <p className="react_validation">
                    {this.state.emailError}
                    {this.state.emailRequiredError}
                  </p>
                </div>

                <div className="form-group" style={{ position: "relative" }}>
                  <label>Password</label>
                  <input
                    // type="password"
                    type={
                      this.state.playerPasswordToggle === false
                        ? "password"
                        : "text"
                    }
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => this.onHandle(e)}
                  />
                  <p className="react_validation">
                    {this.state.passwordError}
                    {this.state.passwordRequiredError}
                  </p>
                  {this.state.playerPasswordToggle === false ? (
                    <img
                      src={show}
                      alt="show"
                      className="addPlayer-pass-hide"
                      onClick={() => this.togglePassword()}
                    />
                  ) : (
                    <img
                      className="addPlayer-pass-hide"
                      onClick={() => this.togglePassword()}
                      src={hide}
                      alt="hide"
                    />
                  )}
                </div>

                <div className="form-group" style={{ position: "relative" }}>
                  <label>Confirm password</label>
                  <input
                    // type="password"
                    type={
                      playerConfirmPasswordToggle === false
                        ? "password"
                        : "text"
                    }
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => this.onHandle(e)}
                  />
                  <p className="react_validation">
                    {this.state.confirmPasswordError}
                  </p>

                  {playerConfirmPasswordToggle === false ? (
                    <img
                      src={show}
                      alt="show"
                      className="addPlayer-pass-hide"
                      onClick={() => this.togglePlayerConfirmPass()}
                    />
                  ) : (
                    <img
                      className="addPlayer-pass-hide"
                      onClick={() => this.togglePlayerConfirmPass()}
                      src={hide}
                      alt="hide"
                    />
                  )}
                </div>

                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => this.onHandle(e)}
                  />
                  <p className="react_validation">
                    {this.state.firstNameError}
                  </p>
                </div>

                <div className="form-group">
                  <label>Surname</label>
                  <input
                    type="text"
                    className="form-control"
                    name="surname"
                    value={surname}
                    onChange={(e) => this.onHandle(e)}
                  />
                  <p className="react_validation">{this.state.surnameError}</p>
                </div>

                <div className="form-group">
                  <label>Date of Birth</label>

                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleDateChange}
                    name="DateOfBirth"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    ref={(ref) => (this.accordionEndContent = ref)}
                  />
                  <img
                    className="celender_img "
                    src={Calander}
                    alt={Calander}
                    onClick={() => this.accordionEndContent.onInputClick()}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="react_validation ">
                    {this.state.dateOfBirthError}
                    {this.state.ageLimitError}
                  </p>
                </div>

                {sports.length !== 0 && (
                  <div className="form-group">
                    <label>Sport Position</label>
                    <select
                      className="form-control"
                      name="SportPosition"
                      value={SportPosition}
                      onChange={(e) => this.onHandle(e)}
                    >
                      <option value="">
                        {sports.length !== 0
                          ? "Select Sport Position"
                          : "No Sport Position Avalable"}
                      </option>
                      {/* {console.log("this is SPORT POSITION ISSUE", sports)} */}
                      {sports &&
                        sports.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.label}
                            </option>
                          );
                        })}
                    </select>
                    <p className="react_validation">
                      {this.state.SportPositionError}
                    </p>
                  </div>
                )}
                <button
                  type="button"
                  className="Model_btn"
                  data-dismiss="modal"
                  onClick={() => {
                    this.addPlayerInTeam();
                  }}
                >
                  Save
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        {/* this is a add coach Modal */}
        <Modal show={this.state.addCoach} onHide={this.hideCoachModal}>
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="playeraddTitle">
                Add New Coach
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.hideCoachModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form action="" className="create_newteam">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="coachEmail"
                    value={coachEmail}
                    onChange={(e) => this.onHandleCoach(e)}
                  />
                  <p className="react_validation">
                    {this.state.coachEmailError}
                    {this.state.CoachEmailRequiredError}
                  </p>
                </div>

                <div className="form-group" style={{ position: "relative" }}>
                  <label>Password</label>
                  <input
                    // type="password"
                    type={coachPasswordToggle === false ? "password" : "type"}
                    className="form-control"
                    name="coachPassword"
                    value={coachPassword}
                    onChange={(e) => this.onHandleCoach(e)}
                  />
                  <p className="react_validation">
                    {this.state.coachPasswordError}
                    {this.state.coachPasswordRequiredError}
                  </p>
                  {coachPasswordToggle === false ? (
                    <img
                      className="addPlayer-pass-hide"
                      onClick={() => this.toggleCoachPass()}
                      src={hide}
                      alt="hide"
                    />
                  ) : (
                    <img
                      src={show}
                      alt="show"
                      className="addPlayer-pass-hide"
                      onClick={() => this.toggleCoachPass()}
                    />
                  )}
                </div>

                <div className="form-group" style={{ position: "relative" }}>
                  <label>Confirm password</label>
                  <input
                    // type="password"
                    type={
                      coachConfirmPasswordToggle === false ? "password" : "text"
                    }
                    className="form-control"
                    name="coachConfirmPassword"
                    value={coachConfirmPassword}
                    onChange={(e) => this.onHandleCoach(e)}
                  />
                  <p className="react_validation">
                    {this.state.coachConfirmPasswordError}
                  </p>
                  {coachConfirmPasswordToggle === false ? (
                    <img
                      className="addPlayer-pass-hide"
                      onClick={() => this.toggleCoachConfirmPass()}
                      src={hide}
                      alt="hide"
                    />
                  ) : (
                    <img
                      src={show}
                      alt="show"
                      className="addPlayer-pass-hide"
                      onClick={() => this.toggleCoachConfirmPass()}
                    />
                  )}
                </div>

                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="coachFirstName"
                    value={coachFirstName}
                    onChange={(e) => this.onHandleCoach(e)}
                  />
                  <p className="react_validation">
                    {this.state.coachFirstNameError}
                  </p>
                </div>

                <div className="form-group">
                  <label>Surname</label>
                  <input
                    type="text"
                    className="form-control"
                    name="coachSurname"
                    value={coachSurname}
                    onChange={(e) => this.onHandleCoach(e)}
                  />
                  <p className="react_validation">
                    {this.state.coachSurnameError}
                  </p>
                </div>

                <div className="form-group">
                  <label>Date of Birth</label>

                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleDateChange}
                    name="DateOfBirth"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    placeholderText="dd/MM/yyyy"
                    dropdownMode="select"
                    ref={(ref) => (this.accordionContent = ref)}
                  />
                  <img
                    className="celender_img "
                    src={Calander}
                    alt={Calander}
                    onClick={() => this.accordionContent.onInputClick()}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="react_validation ">
                    {this.state.coachDateOfBirthError}
                    {this.state.coachAgeLimitError}
                  </p>
                </div>

                <div className="form-group">
                  <label>Coach Specialisation</label>
                  <select
                    className="form-control"
                    name="coachSpecialisation"
                    value={coachSpecialisation}
                    onChange={(e) => this.onHandleCoach(e)}
                  >
                    <option value="">Select Coach Specialisation</option>
                    {Specialisation &&
                      Specialisation.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.label}
                          </option>
                        );
                      })}
                  </select>
                  <p className="react_validation">
                    {this.state.coachSpecialisationError}
                  </p>
                </div>

                <div className="form-group">
                  <label>Role</label>
                  <select
                    className="form-control"
                    name="coachRole"
                    value={coachRole}
                    onChange={(e) => this.onHandleCoach(e)}
                  >
                    <option value="">Select Role</option>
                    {/* <option value="">Admin</option> */}
                    {this.state.CratedByMe && <option value="3">Coach</option>}
                    <option value="4">S&C Coach</option>
                    <option value="5">Assistant Coach</option>
                  </select>
                  <p className="react_validation">
                    {this.state.coachRoleError}
                  </p>
                </div>

                <button
                  type="button"
                  className="Model_btn"
                  data-dismiss="modal"
                  onClick={() => {
                    this.addCoachInTeam();
                  }}
                >
                  Save
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        {/* this is delete team modal */}
        <Modal
          show={this.state.deleteTeamModal}
          onHide={this.hideDeleteTeamModal}
          animation={true}
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="teamcreate">
                Delete Team
              </h5>
              <button
                type="button"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.hideDeleteTeamModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="delete_modal_react">
              Are you sure you want to delete this team, this cannot be undone?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="Model_btn_Cancel "
              data-dismiss="modal"
              onClick={() => {
                this.hideDeleteTeamModal();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="Model_btn_ok "
              data-dismiss="modal"
              // onClick={() => {
              //   this.deleteTeam();
              // }}
              onClick={() => {
                this.deleteTeamWithModal();
              }}
              style={{ padding: "6px 43px" }}
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default MyPlayers;
