import React from "react";
import piotrCoach from "../../Custom/images/piotr-coach.png";
import jamesCoach from "../../Custom/images/james-coach.png";
import joshResizedCoach from "../../Custom/images/josh-resized-coach.png";
import arrowRightBlueImg from "../../Custom/images/arrow-right-blue-img.png";
import { Link } from "react-router-dom";
import CoachCard from "component/coachCard/CoachCard";

class OurCoachWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { firstCoach: false, secondCoach: false, thirdCoach: false };
  }

  firstCoachShow = async () => {
    await this.setState({ firstCoach: !this.state.firstCoach });
  };

  secondCoachShow = async () => {
    await this.setState({ secondCoach: !this.state.secondCoach });
  };

  thirdCoachShow = async () => {
    await this.setState({ thirdCoach: !this.state.thirdCoach });
  };

  render() {
    return (
      <div>
        <section id="our-coaches-wrapper">
          <div className="container-fluid">
            <div className="heading mb-4">
              <h2>Our Coaches</h2>
              <p>
                Created by our leading strength and conditioning coaches, our
                programmes are designed to help you reach your full athletic
                potential. You’ll be assigned an expert coach in your field
                who’ll provide advice and support to help you enhance your
                performance.
              </p>
            </div>
            <div className="row mt-5  justify-content-around">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3">
                <CoachCard 
                data={
                  {
                    img : piotrCoach,
                    profile:"Founder | Strength & Conditioning Coach",
                    name:"Piotr Golaszczyk",
                    description_1 : `Over the last decade, Piotr has worked with some of
                    the best universities, and athletes in the world. He
                    holds an MSc in Sports Therapy, a BSc in Sports
                    Science and Medicine, and is an accredited strength
                    and conditioning coach through the UKSCA. He’s
                    currently employed at Anglia Ruskin University as a
                    lecturer/practitioner in Sport and Exercise Therapy.`,
                    description_2 : `Founder of Prime Coach, Piotr combined the smarts of
                    AI technology with his years of experience in the
                    field and his in-depth knowledge, to create a system
                    that supports athletes and coaches throughout the
                    year.`
                  }
                }
                />
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3">
              <CoachCard 
                data={
                  {
                    img : jamesCoach,
                    profile:"Strength & Conditioning Coach",
                    name:"James Meadows",
                    description_1 : `With a BSc (Hons) in Psychology, and a Premier Diploma
                    in Personal Training, James has a wealth of knowledge.
                    James has over 3 years of experience as a strength and
                    conditioning coach; he’s currently employed at the
                    University of Cambridge, and is pursuing his UKSCA
                    accreditation.`,
                    description_2 : `He’s previously worked on The Bristol School Sports
                    Partnership’s Gifted and Talented Young Athlete’s
                    programme, training some of the most talented youth
                    athletes in the region. He’s worked with athletes from
                    a range of clubs and sports, including American
                    football, cricket, fencing and lightweight rowing. His
                    unrivalled knowledge and experience have been
                    invaluable in the creation of Prime Coach.`
                   }
                }
                />
             
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
              <CoachCard 
                data={
                  {
                    img : joshResizedCoach,
                    profile:"Strength & Conditioning Coach",
                    name:"Josh Gooden",
                    description_1:`Josh graduated with both an MSc in Applied Sport
                    Physiology and a BSc in Sport Coaching from the
                    University of Brighton. During his studies, Josh
                    worked with elite sporting institutes, including
                    Sussex County Cricket, Brighton and Hove Albion
                    Football Club and the Sport and Exercise Science
                    Consultancy Unit at The University of Brighton.`,
                    description_2 : `Currently, Josh works as a Strength & Conditioning
                    Coach at the University of Cambridge, delivering S&C
                    services to both local and university athletes. In
                    this role, Josh coaches several team sports and
                    individual athletes, with many of them currently
                    competing at national or international levels. Aside
                    from S&C, Josh has a Football coaching role at various
                    primary school clubs.`
                   }
                }
                />
              </div>
            </div>
          </div>
   
        </section>
      </div>
    );
  }
}

export default OurCoachWrapper;
