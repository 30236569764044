import React, { Component } from "react";
import "../CoachAddEvent.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@material-ui/core";
import CustomButton from "component/customButton/CustomButton";
import { Modal } from "react-bootstrap";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import moment from "moment";

export class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  render() {
    const {
      startTime,
      onChange,
      state,
      handelStartTime,
      handelEndTime,
      calendarAddEvent,
    } = this.props;
    return (
      <div className="d-flex">
        <CustomButton
          onClick={() => this.handleShow()}
          flex={1}
          style={{ fontWeight: "500" }}
        >
          Add Event
        </CustomButton>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          animation={true}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="teamcreate">
                Add New Event
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <p className="add-event-date-heading">
              {moment(state.date).format("DD MMM YYYY")}
            </p>
            <div className="modal-body">
              <CustomInput
                type="text"
                placeholder="Event Name"
                name="eventName"
                value={state.eventName}
                onChange={(e) => onChange(e)}
                className={"mb-3"}
                error={state.nameError}
              />
              <div className="my-3 d-flex">
                <InputDateTimePicker
                  onChange={(date) => handelStartTime(date)}
                  value={startTime}
                  error={state.startTimeError}
                  placeholder="Start Time"
                  type="time"
                  className={"mr-2"}
                />

                <InputDateTimePicker
                  onChange={(date) => handelEndTime(date)}
                  value={state.endTime}
                  error={state.endTimeError}
                  placeholder="End Time "
                  type="time"
                />
              </div>

              <CustomInput
                rows="3"
                cols="50"
                name="Description"
                value={state.Description}
                onChange={onChange}
                placeholder="Description"
                type="textarea"
                // error={state.descriptionError}
              />

              <div className="row d-flex mt-3">
                <CustomButton
                  onClick={() => calendarAddEvent()}
                  disabled={state.onAddLoader}
                  flex={1}
                  style={{ fontWeight: "bold" }}
                  isLoading={state.onAddLoader}
                >
                  Add
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddEvent;
