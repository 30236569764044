import React from "react";
import "./AnalysisWorkoutCard.scss";

function AnalysisWorkoutCard({ item }) {
  return (
    <div className="analysis_workout_card_container position-relative h-100">
      <div className="analysis_workout_container_back" />
      <div className="analysis_workout_container_front">
        <div className="d-flex align-items-center justify-content-between">
          <h5 style={{ fontSize: "15px" }}>{item?.name}</h5>
          <div className="workout_sets">
            <span style={{ fontSize: "12px" }}>Sets : {item.totalSet}</span>
          </div>
        </div>
        <div className="row">
          {item?.data?.map((data, index) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-6 load_card ml-3 mt-3">
                <p className="m-0" style={{ fontSize: "12px" }}>
                  {data?.load_completed} Kg
                </p>
                <p className="m-0" style={{ fontSize: "12px" }}>
                  {data?.reps_completed} Reps
                </p>
                <p
                  className="text-right m-0"
                  style={{ color: "var(--safetyOrange)", fontSize: "12px" }}
                >
                  {index + 1}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AnalysisWorkoutCard;
