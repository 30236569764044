import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";
import React from "react";

function WeekDays(props) {
  return (
    <div
      className="col-lg-12 col-md-12 col-sm-12 p-3"
      style={{ backgroundColor: "var(--blackRussian)" }}
    >
      <div className="week_heading">Day</div>

      {Array.from(Array(7).keys()).map((item, index) => (
        <button
          style={{ marginBottom: "12px" }}
          className="analyse-workout-day"
          key={index}
          onClick={() => props.annual_training_program_week_days(index)}
        >
          Day {index + 1}
        </button>
      ))}

      {props.dayLoader === true ? (
        <i
          className="fa fa-spinner fa-spin fa-3x fa-fw"
          style={{
            color: "var(--appBlue2)",
            fontSize: "60px",
          }}
        />
      ) : (
        <div className="row">
          {props.dayArray &&
            props.dayArray.map((item, index) => {
              return (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 mt-4"
                  key={item?.id + ""}
                >
                  <ProgramViewCard
                    containerBackCardStyle={{
                      backgroundColor: "var(--jaguar)",
                    }}
                    buttonText="Delete"
                    text={item.day_number}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.deleteDaysModal(
                        item?.day_number?.split(" ")?.pop(),
                        index
                      );
                    }}
                    Icon={"copy"}
                    copyIconClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.clone_annual_training_program_day(item);
                    }}
                    cardClick={() => {
                      props.weekDayButton(item, index);
                    }}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default WeekDays;
