import { Button } from '@material-ui/core';
import CustomButton from 'component/customButton/CustomButton';
import CustomInput from 'component/customInput/CustomInput';
import CustomRadioButton from 'component/customRadioButton/CustomRadioButton';
import React, { Component } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';

export class UpdateCoachAwardModal extends Component {
  render() {
    const {
      updateChange,
      parentState,
      toggleUpdateAssignTo,
      handelUpdateAwards,
    } = this.props;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          centered
          animation={true}
          backdropClassName='modal_backdrop'
          contentClassName='modal_container'
        >
          <Modal.Body>
            <div className='modal-header'>
              <h5 className='modal-title' id='edit-protocol'>
                Update Awards
              </h5>
              <button
                type='button'
                className='modal_close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={this.props.onHide}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='form-group'>
                <CustomInput
                  type='text'
                  name='updateAwardName'
                  value={parentState.updateAwardName}
                  onChange={(e) => updateChange(e)}
                  placeholder='Name of the award'
                  error={parentState.updateAwardNameError}
                />
              </div>

              <div className='form-group'>
                <span className='edit-madical-status-span'>Award type</span>
                <div className='d-flex'>
                  <CustomRadioButton
                    title='Award Team'
                    onClick={() => toggleUpdateAssignTo('team')}
                    isChecked={
                      parentState.updateAssignTo === 'team' ? false : true
                    }
                    className={'mr-3'}
                  />
                  <CustomRadioButton
                    title='Award Individual'
                    onClick={() => toggleUpdateAssignTo('individual')}
                    isChecked={
                      parentState.updateAssignTo === 'individual' ? false : true
                    }
                    className={'ml-3'}
                  />
                  <p className='react_validation' style={{ marginTop: '3px' }}>
                    {parentState.updateAssgnToError}
                  </p>
                </div>
              </div>
              <div className='d-flex'>
                <CustomButton
                  flex={1}
                  onClick={handelUpdateAwards}
                  disabled={parentState.updateLoader}
                  isLoading={parentState.updateLoader}
                  style={{ fontWeight: 'bold' }}
                >
                  Update
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default UpdateCoachAwardModal;
