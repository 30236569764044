import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import Checkbox from "component/Checkbox/Checkbox";
// import CircularProgressWithLabel from "../progressBar/ProgressBar";
import LinearWithValueLabel from "../progressBar/LinerBar";
import { Tooltip } from "@material-ui/core";
import CustomInput from "component/customInput/CustomInput";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SvgIcon from "component/SvgIcon";
import CustomButton from "component/customButton/CustomButton";

class EditScreeningProtocol extends Component {
  constructor(props) {
    super(props);
    this.state = { fileInpIndex: undefined };
  }
  render() {
    const {
      show,
      onHide,
      state,
      onChange,
      updateToggleCb,
      handelUpdateScreeningProtocol,
      onFileUpdateChange,
      addNewScreeningProtocolOnUpdate,
      updateTest,
      updateDescription,
      updateNameField,
      updateToggleInUpdateCb,
      onFileUpdatePreArrayChange,
      updateScreeningProtocolApi,
      editModalDeleteEvent,
      toggleEditDelete,
      isLoading,
    } = this.props;

    return (
      <div>
        <div>
          <Modal
            show={show}
            onHide={() => false}
            centered
            animation={true}
            backdropClassName="modal_backdrop"
            contentClassName="modal_container"
          >
            <Modal.Body>
              <div className="modal-header">
                <h5 className="modal-title" id="protocol">
                  Edit Screening Protocol
                </h5>
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="form-group">
                  <CustomInput
                    type="text"
                    placeholder="Name"
                    name="updateNameField"
                    value={updateNameField}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="d-flex align-item-center">
                  <div className="form-group mr-2">
                    <CustomInput
                      type="text"
                      placeholder="Test Name"
                      name="updateTest"
                      value={updateTest}
                      onChange={(e) => onChange(e)}
                      error={state.updateTestError}
                    />
                  </div>
                  <div className="form-group ml-2">
                    <CustomInput
                      type="text"
                      placeholder="Description"
                      name="updateDescription"
                      value={updateDescription}
                      onChange={(e) => onChange(e)}
                      error={state.updateDescriptionError}
                    />
                  </div>
                </div>
                <div>
                  <input
                    type="file"
                    onChange={(e) => onFileUpdateChange(e)}
                    style={{ display: "none" }}
                    accept="video/mp4"
                    ref={(ref) => (this.fileInput1 = ref)}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <button
                    className="screening_protocol_comment_btn"
                    onClick={() => this.fileInput1.click()}
                    style={
                      state.editNewVideoUploadProgress !== null &&
                      state.editNewVideoUploadProgress !== undefined &&
                      state.editNewVideoUploadProgress.length !== 0
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                    disabled={
                      state.editNewVideoUploadProgress !== null &&
                      state.editNewVideoUploadProgress !== undefined &&
                      state.editNewVideoUploadProgress.length !== 0 &&
                      true
                    }
                  >
                    <CloudUploadOutlinedIcon
                      style={{
                        color: "var(--safetyOrange)",
                        marginRight: "7px",
                      }}
                    />
                    Upload video
                  </button>
                  {state.editNewVideoUploadProgress !== null &&
                    state.editNewVideoUploadProgress !== undefined &&
                    state.editNewVideoUploadProgress.length !== 0 && (
                      <LinearWithValueLabel
                        progress={state.editNewVideoUploadProgress}
                      />
                    )}

                  <p className="react_validation" style={{ marginTop: "2px" }}>
                    {state.selectedVideoUpdateError}
                  </p>
                </div>
                {state.showCreateProtocolViedioUpdate && (
                  <div>
                    {state.selectedVideoUpdate && (
                      <video
                        width="220"
                        height="120"
                        controls
                        style={{ outline: 0, width: "100%", height: "230px" }}
                        src={URL.createObjectURL(state.selectedVideoUpdate)}
                      >
                        {/* <source
                                  src={URL.createObjectURL(
                                    state.selectedVideoUpdate
                                  )}
                                /> */}
                      </video>
                    )}
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "14px", color: "white" }}
                  >
                    <Checkbox
                      style={{ marginTop: "7px", cursor: "pointer" }}
                      checked={state.updateComment}
                      toggleCb={() => updateToggleCb()}
                    />
                    <span>Add Comment</span>
                  </div>
                  <div>
                    <Tooltip arrow title="Add new Test">
                      <AddCircleIcon
                        onClick={() => addNewScreeningProtocolOnUpdate()}
                        style={{
                          width: "40px",
                          height: "40px",
                          color: "#F75F03",
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                {state.editDetails?.length !== 0 &&
                  state.editDetails?.map((item, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <div className="d-flex align-item-center">
                          <CustomInput
                            className={"mr-2"}
                            type="text"
                            placeholder=""
                            value={item?.name}
                            onChange={(text) =>
                              handelUpdateScreeningProtocol(ind, "name", text)
                            }
                          />
                          <CustomInput
                            className={"ml-2"}
                            type="text"
                            placeholder=""
                            value={item?.description}
                            onChange={(text) =>
                              handelUpdateScreeningProtocol(
                                ind,
                                "description",
                                text
                              )
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            className="d-flex align-items-center"
                            style={{ fontSize: "14px", color: "white" }}
                          >
                            <Checkbox
                              style={{ marginLeft: "5px" }}
                              checked={
                                item?.comment_allowed == "1" ? true : false
                              }
                              toggleCb={() => updateToggleInUpdateCb(item, ind)}
                            />
                            <span>Add Comment</span>
                          </div>
                          <div>
                            <Tooltip arrow title={`Delete ${item?.name} test`}>
                              <button
                                type="button"
                                onClick={() => toggleEditDelete(ind)}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                              >
                                <SvgIcon
                                  name="trash"
                                  style={{
                                    width: "25px",
                                    height: "50px",
                                  }}
                                />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                        <div>
                          <video
                            width="250"
                            height="140"
                            controls
                            style={{
                              outline: 0,
                              width: "100%",
                              height: "230px",
                            }}
                            src={item?.full_path}
                          ></video>
                        </div>
                        <div>
                          <input
                            type="file"
                            onChange={(event) => {
                              onFileUpdatePreArrayChange(
                                event,
                                this.state.fileInpIndex
                              );
                            }}
                            style={{ display: "none" }}
                            accept=" video/webm, video/mp4"
                            ref={(ref) => (this.fileInput = ref)}
                            onClick={(e) => (e.target.value = null)}
                          />
                          <button
                            className="screening_protocol_comment_btn "
                            onClick={() => {
                              this.setState({ fileInpIndex: ind });
                              this.fileInput.click();
                            }}
                            style={
                              state.videoUploadProgress[ind] !== null &&
                              state.videoUploadProgress[ind] !== undefined
                                ? {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    cursor: "not-allowed",
                                    marginBottom: "12px",
                                  }
                                : {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    cursor: "pointer",
                                    marginBottom: "12px",
                                  }
                            }
                            disabled={
                              state.videoUploadProgress[ind] !== null &&
                              state.videoUploadProgress[ind] !== undefined &&
                              true
                            }
                          >
                            <span style={{ flexDirection: "none" }}>
                              <CloudUploadOutlinedIcon
                                style={{
                                  color: "var(--safetyOrange)",
                                  marginRight: "7px",
                                }}
                              />
                              Upload video
                            </span>
                          </button>
                          {state.videoUploadProgress[ind] !== null &&
                            state.videoUploadProgress[ind] !== undefined && (
                              <LinearWithValueLabel
                                progress={state.videoUploadProgress[ind]}
                              />
                            )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                <div className="d-flex">
                  <CustomButton
                    flex={1}
                    dataDismiss="modal"
                    onClick={() => updateScreeningProtocolApi()}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Edit
                  </CustomButton>
                </div>
              </div>
            </Modal.Body>
            {/* <ModalFooter>
              <Tooltip
                arrow
                title={`Edit ${updateNameField} screening protocol`}
              >
                <button
                  type="button"
                  className="Model_btn"
                  data-dismiss="modal"
                  onClick={() => updateScreeningProtocolApi()}
                >
                  Edit
                </button>
              </Tooltip>
            </ModalFooter> */}
          </Modal>
        </div>
      </div>
    );
  }
}

export default EditScreeningProtocol;
