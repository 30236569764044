import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export class ConfirmDeleteModal extends Component {
  render() {
    const { deleteExerciseEvent } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        animation={true}
        backdropClassName="modal_backdrop"
        contentClassName="modal_container"
        centered
      >
        <Modal.Body>
          <div className="modal-header" style={{ border: "none" }}>
            <button
              type="button"
              className="modal_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.onHide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="delete_modal_react d-flex align-items-center justify-content-center flex-column">
            <SvgIcon name="modal-delete" />
            <span
              className="mt-3 mb-3"
              style={{ fontSize: 20, fontWeight: "bold", color: "white" }}
            >
              Are you sure?
            </span>
            <span style={{ color: "white", textAlign: "center" }}>
              you want to delete this exercise ? this change cannot be undone.
            </span>
            <div className="d-flex mt-4">
              <CustomButton
                className={"mr-2"}
                data-dismiss="modal"
                onClick={this.props.onHide}
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid white",
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                className={"ml-2"}
                dataDismiss="modal"
                ref={(node) => (this.btn = node)}
                onClick={() => deleteExerciseEvent(this.props.deleteExeIndex)}
                style={{ backgroundColor: "#E81E16" }}
              >
                Delete
              </CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ConfirmDeleteModal;
