import CustomInput from "component/customInput/CustomInput";
import React from "react";
import userImg from "Custom/images/coach-login2.png";
import userAthleteImg from "Custom/images/sign_in_as_athlete.png";
import CustomButton from "component/customButton/CustomButton";
import { useState } from "react";
import { standardPostApi } from "container/API/ApiWrapper";
import { useHistory } from "react-router";
import { errorToast, successToast } from "utils/toastMessage";
function UserDeletion() {
  const [role, setRole] = useState("coach");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { push } = useHistory();
  const validation = (e) => {
    let emailError = "";
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!email) {
      emailError = "The Email field is required.";
    } else if (!email.match(emailReg)) {
      emailError = "This Email Address is not valid. ";
    }

    if (emailError) {
      setEmailError(emailError);
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  const onSendRequest = async (e) => {
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
      setIsLoading(true);
      try {
        const res = await standardPostApi(
          "user/delete-request",
          undefined,
          {
            email: email,
            role: role === "coach" ? 3 : 2,
          },
          true
        );

        if (res.data.code === 200) {
          console.log("response of log in ", res.data?.message);
          successToast(res.data?.message);
          setEmail("");
        }
      } catch (error) {
        errorToast(error?.message);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="loader_sec">
      <div className="login-page-wrapper">
        <section className="login_page">
          <div
            className="container"
            style={{ marginTop: "0", marginBottom: "10%" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {role === "coach" ? (
                  <img src={userImg} alt="no_img" className="img-fluid" />
                ) : (
                  <img
                    src={userAthleteImg}
                    alt="no_img"
                    className="img-fluid"
                  />
                )}
              </div>
              <div className="col-lg-6 m-auto">
                <form action="" className="login_form">
                  <div
                    className="heading text-center"
                    style={{ textTransform: "none", fontWeight: "500" }}
                  >
                    {role === "coach" ? "Delete Coach" : "Delete Athlete"}
                  </div>
                  <div className="form-group">
                    <CustomInput
                      type="text"
                      placeholder="Email Address"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e?.target.value)}
                      error={emailError}
                    ></CustomInput>
                  </div>
                  <div className="d-flex">
                    <CustomButton
                      onClick={onSendRequest}
                      disabled={isLoading}
                      isLoading={isLoading}
                      flex={1}
                    >
                      Send Request
                    </CustomButton>
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      color: "white",
                      paddingTop: "60px",
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setRole(role === "coach" ? "athlete" : "coach")
                    }
                  >
                    {role === "coach" ? "Or Delete Athlete" : "Or Delete Coach"}

                    <div className="border_bottom_Link"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UserDeletion;
