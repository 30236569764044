import React, { Component } from "react";
import CoachHeader from "container/PublicLayout/CoachHeader";
import Footer from "container/PublicLayout/Footer";
import { Link, Redirect } from "react-router-dom";
import "./AnalyseWorkoutCoachSide.css";
import { standardPostApi } from "container/API/ApiWrapper";
import moment from "moment";
import { errorToast } from "utils/toastMessage";
import NoDataFound from "component/lottiLoader/LottiLoader";
import Header from "container/PublicLayout/Header";
import InnerHeader from "container/PublicLayout/InnerHeader";
import LoaderWrapper from "container/Loader/LoaderWrapper";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import AnalysisWorkoutCard from "component/card/analysisWorkoutCard/AnalysisWorkoutCard";

export class AnalyseWorkoutCoachSide extends Component {
  state = {
    isloading: false,
    report: [],
    selectedDay: undefined,
    weekArray: [],
    pickerArray: [],
    selectedWeek: {},
    selectedPickerValue: "",
  };
  componentDidMount() {
    this.onLoadWeekData();
  }

  onLoadWeekData = async () => {
    this.setState({ isloading: true });
    // const PlayerData = navigation.getParam("player");
    const playerId = this.props.match.params.playerId;
    try {
      const res = await standardPostApi(
        "athlete_program_view",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: playerId,
        },
        true,
        false
      );
      if (res.data.code === 301) {
        this.setState({
          isloading: false,
        });
        console.log("Error ", JSON.stringify(res.data, null, 2));
      }
      let temp = [];
      res.data.data.map((x, i) => {
        temp.push({
          label: `${x.week_number} (${moment(
            x?.week_start,
            "YYYY-MM-DD"
          ).format("Do MMM")} - ${moment(x?.week_end, "YYYY-MM-DD").format(
            "Do MMM"
          )})`,
          value: x.id,
        });
      });
      if (res.data.code === 200) {
        this.setState({
          weekArray: res.data.data,
          pickerArray: temp,
          selectedPickerValue: temp[0]?.value,
          selectedWeek: res.data.data[0],
          isloading: false,
        });
        if (res.data.data[0]?.days?.length > 0) {
          this.setState({ selectedDay: res.data.data[0]?.days[0].id });
          this.onLoad(res.data.data[0]?.days[0].id);
        }
        // console.log("list Data ", JSON.stringify(res.data.data, null, 2));
      }
    } catch (error) {
      this.setState({ isloading: false });
      console.log(error);
    }
  };
  onLoad = async (dayId) => {
    // errorToast("HUa ");
    this.setState({ isloading: true });

    // const PlayerData = navigation.getParam("player");
    const playerId = this.props.match.params.playerId;

    const { selectedWeek } = this.state;
    try {
      const res = await standardPostApi(
        // "training_session_day_wise_report",
        "training_session_day_wise_report_v3",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: selectedWeek?.annual_training_program_id,
          annual_training_program_week_id: selectedWeek?.id,
          annual_training_program_week_day_id: dayId,
          access_user_id: playerId,
        },
        true,
        false
      );
      if (res.data.code === 301) {
        this.setState({
          report: res.data.data,
          isloading: false,
        });
        console.log("Error ", JSON.stringify(res.data, null, 2));
      }
      if (res.data.code === 200) {
        // console.log("dattttt", JSON.stringify(res.data.data, null, 2));
        let Temp = res.data.data;
        let Final = [];
        for (var i = 0; Temp.length; i++) {
          let SELECTED = Temp.filter(
            (x) => x.exercise_name === Temp[0]?.exercise_name
          );
          let REMAIN = Temp.filter(
            (x) => x.exercise_name !== Temp[0]?.exercise_name
          );
          Final.push({
            name: SELECTED[0]?.exercise_name,
            totalSet: SELECTED?.length,
            data: SELECTED,
          });
          Temp = REMAIN;
        }
        this.setState({
          report: Final,
          isloading: false,
        });
        // console.log("Final", JSON.stringify(Final, null, 2));
      }
    } catch (error) {
      this.setState({ isloading: false });
      console.log(error);
    }
  };
  render() {
    const { weekArray, pickerArray, report } = this.state;
    // console.log("pickerArray-->", this.state.selectedWeek);
    return (
      <div className="loader_sec">
        {/* <CoachHeader /> */}
        {localStorage.getItem("access_role") === null ? (
          <Header />
        ) : localStorage.getItem("access_role") === "Athlete" ? (
          <InnerHeader />
        ) : (
          <CoachHeader />
        )}

        <div className="dashboard-wrapper">
          {this.state.isloading ? (
            <LoaderWrapper />
          ) : (
            <section className="myteams_wrapper">
              <div className="inner_teamsection_1">
                <div className="row" style={{ marginBottom: "3%" }}>
                  <div className="col-lg-12">
                    <CustomBreadcrumb
                      heading="My Player"
                      className={"mb-4"}
                      navList={[
                        {
                          name: "My Team",
                          link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                        },
                        {
                          name:
                            this.props.location.state.player.first_name +
                            " " +
                            this.props.location.state.player.last_name,
                          link: {
                            pathname: `/coachplayerinner/${this.props.match.params.id}/${this.props.match.params?.playerId}/${this.props.match.params.teamname}`,
                            state: {
                              player: this.props?.location?.state?.player,
                            },
                          },
                        },
                        {
                          name: "Reports",
                          link: {
                            pathname: `/playerreport/${this.props.match.params.id}/${this.props.match.params.playerId}/${this.props.match.params.teamname}`,
                            state: this.props.location.state,
                          },
                        },
                        {
                          name: "Analyse Workout",
                        },
                      ]}
                    />

                    {this.state.pickerArray.length === 0 ||
                    this.state?.selectedWeek?.days?.length === 0 ? null : (
                      <select
                        className="select_border_class"
                        name="weekPicker"
                        value={this.state.selectedPickerValue}
                        onChange={async (e) => {
                          let tempWeek = this.state.weekArray?.find(
                            (x) => x.id === parseInt(e.target.value)
                          );
                          await this.setState({
                            selectedPickerValue: parseInt(e.target.value),
                            selectedWeek: tempWeek,
                          });
                          // console.log("hhhhhhh", tempWeek);
                          if (tempWeek?.days?.length > 0) {
                            this.setState({
                              selectedDay: tempWeek?.days[0].id,
                            });
                            this.onLoad(tempWeek?.days[0].id);
                          }
                          // this.listActivityReport(value);
                        }}
                      >
                        {/* <option value={1}>Testing Protocol Select</option> */}
                        {/* <option className="dropdown-item dropdown-menu react_select_menu">
                                Select Week
                              </option> */}

                        {pickerArray.length !== 0 &&
                          pickerArray.map((item) => {
                            return (
                              <option
                                className="dropdown-item dropdown-menu react_select_menu"
                                value={item.value}
                                key={item.value}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                      </select>
                    )}

                    <div className="mt-4">
                      {this.state?.selectedWeek?.days?.map((data, index) => {
                        return (
                          <button
                            className={
                              this.state.selectedDay === data?.id
                                ? "analyse-workout-day-active"
                                : "analyse-workout-day"
                            }
                            onClick={() => {
                              this.onLoad(data?.id);
                              this.setState({ selectedDay: data?.id });
                            }}
                          >
                            {data.day_number}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {this.state.pickerArray.length === 0 ||
                this.state?.selectedWeek?.days?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <NoDataFound
                      height={250}
                      width={250}
                      text={`No day workout assigned`}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <p>
                        Workout Report :
                        {
                          this.state.selectedWeek?.days?.find(
                            (x) => x.id === this.state.selectedDay
                          )?.day_number
                        }
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="row">
                          {report.length !== 0 &&
                            report?.map((item) => {
                              return (
                                <div
                                  className="col-lg-4 col-md-4 col-sm-6 px-4 mt-4"
                                  key={item?.id}
                                >
                                  <AnalysisWorkoutCard item={item} />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div>
                      {report.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        >
                          <NoDataFound
                            height={250}
                            width={250}
                            text={`You did not complete
                            ${
                              this.state.selectedWeek?.days?.find(
                                (x) => x.id === this.state.selectedDay
                              )?.day_number
                            }
                            workout`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}{" "}
        </div>
        <Footer />
      </div>
    );
  }
}

export default AnalyseWorkoutCoachSide;
