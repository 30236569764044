import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import LinearWithValueLabel from "container/Pages/screeningProtocol/progressBar/LinerBar";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import React, { useRef } from "react";

function AddNewWorkoutExercise({
  ExerciseName,
  ExerciseDescription,
  onChange,
  onSelectUploadType,
  selectUploadType,
  onChangeFile,
  exercise_media,
  ExerciseGroupsSelectpicker,
  VideoUploadProgress,
  ExerciseGroupsId,
  onHandel,
  isLoading,
  addWorkoutExercise,
  youtube_link,
  handleClose,
}) {
  const fileInput = useRef();
  return (
    <React.Fragment>
      <CustomInput
        className={"mb-3"}
        placeholder="Name"
        value={ExerciseName}
        name="ExerciseName"
        onChange={(e) => onChange(e)}
      />
      <CustomInput
        type="textarea"
        placeholder="Description"
        className="mb-2"
        defaultValue={ExerciseDescription}
        name="ExerciseDescription"
        onChange={(e) => onChange(e)}
        // defaultValue={state?.viewModalData?.description}
        // readOnly
      />
      <select
        className="select_class my-2"
        onChange={onSelectUploadType}
        value={selectUploadType}
      >
        <option value="">Select A Upload Type </option>
        {[
          { id: 1, label: "File", value: 1 },
          { id: 2, label: "YouTube Link", value: 2 },
        ].map((data) => {
          return (
            <option key={data.id} value={data.id}>
              {data?.label}
            </option>
          );
        })}
      </select>
      {selectUploadType == 2 && (
        <CustomInput
          className={"mb-3"}
          placeholder="Video link"
          defaultValue={youtube_link}
          name="youtube_link"
          onChange={(e) => onChange(e)}
        />
      )}
      {selectUploadType == 1 && (
        <div>
          <input
            type="file"
            onChange={onChangeFile}
            style={{ display: "none" }}
            accept="video/mp4"
            ref={fileInput}
            onClick={(e) => (e.target.value = null)}
          />
          <button
            className="screening_protocol_comment_btn"
            onClick={() => fileInput.click()}
            style={{ cursor: "pointer", marginBottom: "10px" }}
          >
            <CloudUploadOutlinedIcon
              style={{
                color: "var(--safetyOrange)",
                marginRight: "7px",
              }}
            />
            Upload video
          </button>
        </div>
        // <CustomInput
        //   type="file"
        //   // accept="video/mp4,application/pdf"
        //   className={"mb-3"}
        //   placeholder="Video link"
        //   onChange={this.props?.onChangeFile}
        //   inputStyle={{ paddingTop: "10px" }}
        // />
      )}
      {selectUploadType == 1 && (
        <div>
          {exercise_media ? (
            <video
              width="220"
              height="120"
              controls
              style={{
                outline: 0,
                width: "100%",
                height: "230px",
              }}
              src={URL.createObjectURL(exercise_media)}
            ></video>
          ) : null}
        </div>
      )}
      <select
        className="select_class"
        defaultValue={ExerciseGroupsId}
        onChange={onHandel}
        selected
        readOnly
      >
        {ExerciseGroupsSelectpicker.map((data) => {
          return (
            <option key={data.id} value={data.value}>
              {data.label}
            </option>
          );
        })}
      </select>
      {VideoUploadProgress !== null &&
        VideoUploadProgress !== undefined &&
        VideoUploadProgress.length !== 0 && (
          <LinearWithValueLabel progress={VideoUploadProgress} />
        )}
      <div className="d-flex mt-4">
        <CustomButton
          dataDismiss="modal"
          flex={1}
          style={{ height: "50px" }}
          onClick={() => addWorkoutExercise(handleClose)}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Save
        </CustomButton>
      </div>
    </React.Fragment>
  );
}

export default AddNewWorkoutExercise;
