import CustomButton from 'component/customButton/CustomButton';
import CustomInput from 'component/customInput/CustomInput';
import React, { Component } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';

export class AddAwayTeamModal extends Component {
  render() {
    const { show, onHide, onChange, parentState, handelAddAwayTeam } =
      this.props;
    return (
      <div>
        <Modal
          show={show}
          onHide={() => false}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          backdropClassName='modal_backdrop'
          contentClassName='modal_container'
        >
          <Modal.Body>
            <div className='modal-header'>
              <h5
                className='modal-title'
                id='teamcreate'
                style={{ color: '#fff' }}
              >
                Add Away Team
              </h5>
              <button
                type='button'
                className='modal_close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={onHide}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='form-group'>
                <CustomInput
                  type='text'
                  placeholder=' Away Team Name'
                  name='awayTeamName'
                  value={parentState.awayTeamName}
                  onChange={(e) => onChange(e)}
                  error={parentState.awayTeamError}
                />
              </div>
              <div className='d-flex'>
                <CustomButton
                  flex={1}
                  onClick={() => handelAddAwayTeam()}
                  disabled={parentState.addAwayTeamLoader}
                  isLoading={parentState.addAwayTeamLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddAwayTeamModal;
