import CustomButton from "component/customButton/CustomButton";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import React from "react";
import "./ProgramViewCard.scss";
function ProgramViewCard({
  item,
  onClick,
  text,
  buttonText,
  containerBackCardStyle,
  dayWorkoutComplete,
  Icon,
  copyIconClick,
  cardClick,
  weekRange,
}) {
  return (
    <div className="program_view_card_container position-relative">
      <div
        className="program_view_card_container_back"
        style={containerBackCardStyle}
      />
      <div
        className="program_view_card_container_front"
        onClick={cardClick}
        style={{ cursor: "pointer" }}
      >
        <div className="text-truncate heading flex d-flex align-items-center justify-content-between mb-0 mt-0">
          <p className="d-flex justify-content-center flex-column mt-3">
            <span>{text}</span>
            <span style={{ fontSize: 10 }}>{weekRange ?? ""}</span>
          </p>

          {dayWorkoutComplete === true && (
            <CheckCircleRoundedIcon
              style={{
                color: "var(--safetyOrange)",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          )}
          {Icon === "copy" && (
            <button
              style={{
                color: "#5BB85D",
                padding: 0,
                backgroundColor: "transparent",
                fontSize: "17px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={copyIconClick}
            >
              <i className="fa fa-clone"></i>
            </button>
          )}
          {Icon === "edit" && (
            <button
              style={{
                color: "#EFAD4D",
                padding: 0,
                backgroundColor: "transparent",
                fontSize: "17px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={copyIconClick}
            >
              <i className="fa fa-pencil"></i>
            </button>
          )}
        </div>

        <CustomButton onClick={onClick}>{buttonText}</CustomButton>
      </div>
    </div>
  );
}

export default ProgramViewCard;
