import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";

export class AddResultSetModal extends Component {
  isInputNumber = (event) => {
    var char = String.fromCharCode(event.which);
    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  };

  render() {
    const {
      show,
      onHide,
      homeTeamObj,
      awayTeamObj,
      parentState,
      onChange,
      handelCrateStatisticResult,
      onSelectChange,
    } = this.props;

    // console.log("playerList", parentState.playerList);
    return (
      <div>
        <Modal
          show={show}
          onHide={() => onHide()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="teamcreate">
                Create Criteria Value For Home And Away Team
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                {/* <label htmlFor='' style={{ fontWeight: 'bold', }}>
                  Player Name{' '}
                  {homeTeamObj?.team_name && `(${homeTeamObj?.team_name})`}
                </label> */}
                <select
                  className="select_class"
                  name="playerPicker"
                  value={parentState?.playerPicker}
                  onChange={(e) => onSelectChange(e)}
                >
                  <option value="">Select Player</option>
                  {parentState?.playerList.map((item) => {
                    return (
                      <option value={item.user_id} key={item.id}>
                        {item?.user_first_name} {item?.user_last_name}
                      </option>
                    );
                  })}
                </select>
                {/* <p className="react_validation" style={{ fontWeight: "bold" }}>
                  {parentState.playerPickerError}
                </p> */}
              </div>

              <div className="form-group">
                <span
                  className="edit-madical-status-span"
                  style={{ marginLeft: "20px" }}
                >
                  Home Team
                </span>
                <CustomInput
                  placeholder="Home Team"
                  type="text"
                  name="playerResultValue"
                  value={
                    parentState?.playerResultValue === undefined
                      ? 0
                      : parentState?.playerResultValue
                  }
                  onChange={(e) => onChange(e)}
                  // onKeyPress={this.isInputNumber}
                  maxLength={4}
                />
              </div>
              <div className="form-group">
                <span
                  className="edit-madical-status-span"
                  style={{ marginLeft: "20px" }}
                >
                  Away Team
                </span>
                <CustomInput
                  placeholder={`${awayTeamObj?.name} Value`}
                  type="text"
                  name="awayTeamValue"
                  value={parentState.awayTeamValue}
                  onChange={(e) => onChange(e)}
                  maxLength={4}
                />
              </div>
              <div className="d-flex">
                <CustomButton
                  flex={1}
                  onClick={handelCrateStatisticResult}
                  disabled={parentState.crateStatisticResultLoader}
                  isLoading={parentState.crateStatisticResultLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddResultSetModal;
