import SvgIcon from 'component/SvgIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import './CustomLinkButton.css';

function CustomLinkButton({
  dataToggle,
  dataTarget,
  onClick,
  children,
  style,
  svgName,
  disabled,
}) {
  return (
    <button
      className={!disabled ? 'custom-link-button' : 'custom-link-button-active'}
      data-toggle={dataToggle}
      data-target={dataTarget}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      <SvgIcon
        name={svgName}
        style={{ width: 20, height: 20, marginBottom: 10 }}
      />
      {children}
    </button>
  );
}

export default CustomLinkButton;
