import React from "react";
import "../Coach After Login/PlayerReport.css";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
import { Link } from "react-router-dom";
import PlayerReportGraph from "../../component/Charts/PlayerReportGraph";
import { standardPostApi } from "../API/ApiWrapper";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// moment and timestamp in react js
import moment from "moment";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { BarChartComponent } from "component/chart/Chart";
import CustomButton from "component/customButton/CustomButton";
import AnalysisWorkoutCard from "component/card/analysisWorkoutCard/AnalysisWorkoutCard";
import LoaderWrapper from "container/Loader/LoaderWrapper";
import NoDataFound from "component/lottiLoader/LottiLoader";

class PlayerReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      endDate: "",
      reportSelectPicker: [],
      graphLabels: [],
      graphData: [],
      pickerName: "",
      workoutLocation: "",
      isLoading: false,
    };
  }

  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
    // console.log("this is update state", this.state);
  }

  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ date: date });
    // console.log("The start date selected is ", this.state.date);
  };

  endDateChange = async (endDate) => {
    await this.setState({ endDate: endDate });
    // console.log("The end  date .... is ", this.state.endDate);
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  componentDidMount() {
    this.athlete_workout_activity_report();
  }

  onHandel = async (e) => {
    // this.state.reportSelectPicker.map((item) => {
    //   if (e.target.value == item.value) {
    this.setState({ pickerName: e.target.value });
    //   }
    // });
    this.athlete_workout_activity_report();
  };

  handelChange = () => {
    // console.log("this is Working");

    this.athlete_workout_activity_report();
  };

  athlete_workout_activity_report = async () => {
    this.setState({ isLoading: true });
    const start_date = moment(this.state.date).format("YYYY-MM-DD");

    const end_date = moment(this.state.endDate).format("YYYY-MM-DD");

    try {
      const res = await standardPostApi(
        "athlete_workout_activity_reports",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          report_type_id: this.state.pickerName,
          access_user_id: this.props.match.params.playerId,
          week_start_date: this.state.date && start_date,
          week_end_date: this.state.endDate && end_date,
        },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          reportSelectPicker: [
            ...res.data?.data?.ReportTypeSelect?.pickerArray,
            ...res.data?.data?.ReportTypeSelect?.other_activity_pickerArray?.flat(),
          ],
        });
        let temp = res.data.data.WeekDetails?.[0];
        let graphLabels = Object.keys(temp ?? {}) ?? [];
        let graphData = [];
        graphLabels.forEach((item) => {
          graphData.push(temp?.[item]?.final_result);
        });
        await this.setState({
          graphLabels: graphLabels,
          graphData: graphData,
        });
        // console.log(
        //   "This is RESPONSE OF athlete_workout_activity_report->",
        //   res.data.data
        // );
        // let temp = res.data.data.WeekDetails?.filter(Boolean);
        // temp.forEach((item) => {
        //   console.log("workoutLocation", item.annual_training_program_location);
        //   this.setState({
        //     workoutLocation: item.annual_training_program_location,
        //   });
        // });

        // let graphLabels = [];
        // let graphData = [];

        // temp.forEach((item) => {
        //   console.log("week", item.week_number);
        //   graphLabels.push(item.week_number);
        // });

        // temp.forEach((item) => {
        //   graphData.push(item.final_result);
        // });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    console.log("first", this.state.graphData);
    return (
      <div className="loader_sec">
        <CoachHeader />
        {this.state.isLoading && this.state.reportSelectPicker?.length == 0 ? (
          <LoaderWrapper />
        ) : (
          <div className="dashboard-wrapper">
            <section className="myteams_wrapper">
              <div className="inner_teamsection_1">
                <CustomBreadcrumb
                  heading="My Player"
                  className={"mb-4"}
                  navList={[
                    {
                      name: "My Team",
                      link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                    },
                    {
                      name:
                        this.props.location.state.player.first_name +
                        " " +
                        this.props.location.state.player.last_name,
                      link: {
                        pathname: `/coachplayerinner/${this.props.match.params.id}/${this.props.match.params?.playerId}/${this.props.match.params.teamname}`,
                        state: {
                          player: this.props?.location?.state?.player,
                        },
                      },
                    },
                    {
                      name: "Reports",
                    },
                  ]}
                />

                <div className="row">
                  <div className="col-lg-6 col-md-6 mb-4">
                    <select
                      className="select_border_class"
                      defaultValue={this.state.pickerName}
                      onChange={this.onHandel}
                    >
                      <option value="">Report Type Select</option>
                      {this.state.reportSelectPicker &&
                        this.state.reportSelectPicker.map((item) => {
                          return (
                            <option value={item.value} key={item.id}>
                              {item.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-6 mb-4">
                    <select
                      name=""
                      className="select_border_class"
                      value={this.state.workoutLocation}
                      selected
                      onChange={this.handelChange}
                    >
                      <option value="">Workout Location</option>
                      <option value="Home">Home</option>
                      <option value="Gym">Gym</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                    <InputDateTimePicker
                      style={{ width: "100%" }}
                      onChange={this.handleDateChange}
                      name="DateOfBirth"
                      value={this.state.date}
                      placeholder="Graph Range Start Date"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                    <div className="d-flex ">
                      <InputDateTimePicker
                        style={{ width: "100%" }}
                        value={this.state.endDate}
                        onChange={this.endDateChange}
                        name="DateOfBirth"
                        placeholder="Graph Range End Date"
                        className={"mr-1"}
                      />
                      <div className="align-items-center d-flex">
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => this.athlete_workout_activity_report()}
                        >
                          <CheckCircleRoundedIcon
                            style={{
                              color: "var(--safetyOrange)",
                              fontSize: "40px",
                              cursor: "pointer",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state?.isLoading ? (
                  <LoaderWrapper />
                ) : (
                  <div>
                    {this.state.graphData.length > 0 ? (
                      <BarChartComponent
                        data={this.state.graphData}
                        labels={this.state.graphLabels}
                        title={this.state.workoutLocation}
                      />
                    ) : (
                      <NoDataFound
                        height={250}
                        width={250}
                        text="No Programs assigned yet."
                        screenCenter
                      />
                    )}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <Link
                    to={{
                      pathname: `/analyseworkoutcoach/${this.props.match.params.id}/${this.props.match.params.playerId}/${this.props.match.params.teamname}`,
                      state: this.props.location.state,
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <CustomButton style={{ padding: "0 50px" }}>
                      Analyse Report
                    </CustomButton>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default PlayerReport;
