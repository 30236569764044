import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import ShareIcon from "@material-ui/icons/Share";
import { Divider, IconButton } from "@material-ui/core";
import VideoModal from "./VideoModal";
import viewAthleteEvent from "../ViewAthleteEvent";
import AlternativeExerciseModal from "./AlternativeExerciseModal";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Fragment } from "react";
import { standardPostApi } from "container/API/ApiWrapper";
import CustomButton from "component/customButton/CustomButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkFileType } from "helpers/checkFileType";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2E84AF",
    color: "#fff",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ViewAthleteWorkoutTable({
  workoutDetails,
  startWorkoutClickEvent,
  weekDetail,
  daysDetail,
  coachViewWorkout,
}) {
  const classes = useStyles();
  const [viewVideoModal, setViewVideoModal] = useState(false);
  const [videoModalDetails, setVideoModalDetails] = useState("");
  const [viewAlternativeExercise, setViewAlternativeExercise] = useState(false);
  const [alternativeExerciseDetails, setAlternativeExerciseDetails] = useState(
    []
  );
  const [loaderAthleteWorkout, setLoaderWorkout] = useState(false);
  const [completeSets, setCompleteSets] = useState({ sets: "", load: "" });
  const [setsData, setSetsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);
  const toggleVideoModal = (data) => {
    setViewVideoModal(!viewVideoModal);
    setVideoModalDetails(data);
  };

  const toggleAlternativeExercise = (row) => {
    setViewAlternativeExercise(!viewAlternativeExercise);
    ListAthleteWorkout(row?.workout_exercise);
  };

  const closeAlternativeExercise = () => {
    setViewAlternativeExercise(false);
  };
  const ListAthleteWorkout = async (ExerciseId) => {
    setLoaderWorkout(true);
    try {
      const res = await standardPostApi(
        "exercise/get_alternate_exercises",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          exercise_id: ExerciseId,
        },
        true
      );
      if (res.data.code === 200) {
        setAlternativeExerciseDetails(res?.data?.data?.alternate_exercises);
      }
    } catch (error) {
      console.error("error of list athlete workout", error);
    } finally {
      setLoaderWorkout(false);
    }
  };
  const handelChange = (e, id) => {
    const { name, value } = e.target;
    setCompleteSets({
      ...completeSets,
      [id]: {
        ...completeSets?.[id],
        [name]: value,
      },
    });
  };
  const onSubmit = (id) => {
    let temp = setsData;
    const exist = temp?.filter((e) => e.id === id);
    if (exist?.length > 0) {
      temp.push({ ...completeSets, id: id, setNumber: exist?.length });
    } else {
      temp.push({ ...completeSets, id: id, setNumber: exist?.length });
    }
    setSetsData(temp);
    setCompleteSets({ ...completeSets, [id]: { sets: "", load: "" } });
  };
  const workoutFind = (id, sets) => {
    const exist = setsData?.filter((x) => x.id === id);
    if (exist?.length == sets) {
      return true;
    } else {
      return false;
    }
  };
  const setsNumber = (id) => {
    const exist = setsData?.filter((x) => x.id === id);
    return exist?.length;
  };
  const onCompleteSets = async (
    workout_group_id,
    exercise_id,
    id,
    intensity
  ) => {
    let temp = setsData?.filter((x) => x.id === id);
    setIsLoading(true);
    try {
      const res = await standardPostApi("complete_workout", undefined, {
        access_token: localStorage.getItem("access_token"),
        annual_training_program_id: weekDetail?.annual_training_program_id,
        annual_training_program_week_id: weekDetail?.id,
        annual_training_program_day_id: daysDetail?.id,
        annual_training_program_exercise_id: exercise_id,
        annual_training_program_exercise_set_number: temp.length,
        annual_training_program_reps_completed: completeSets[id]?.sets,
        annual_training_program_load_completed: completeSets[id]?.load,
        annual_training_program_workout_group_id: workout_group_id,
        workout_intensity: intensity,
      });
      if (res.data.code === 200) {
        toast.success(res.data.message, { autoClose: 2500 });
        setIsLoading(false);
      }
      if (res.data.code === 404) {
        toast.error(res.data.message, { autoClose: 2500 });
        setIsLoading(false);
        setSetsData([]);
      }
      if (res.data.code == 301) {
        toast.error(res.data.message, { autoClose: 2500 });
        setIsLoading(false);
        setSetsData([]);
      }
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
      setSetsData([]);
    }
  };
  const completeWorkout = async (intensity) => {
    setIsCompleteLoading(true);
    try {
      const res = await standardPostApi(
        "complete_single_day_workout",
        undefined,
        {
          access_token: localStorage.getItem("access_token"),
          annual_training_program_id: weekDetail?.annual_training_program_id,
          annual_training_program_week_id: weekDetail?.id,
          annual_training_program_day_id: daysDetail?.id,
          workout_direct_complete: 1,
          workout_intensity: intensity,
        },
        true,
        false
      );
      if (res.data.code == 301) {
        toast.error(res.data.message, { autoClose: 2500 });
        setIsCompleteLoading(false);
      }
      if (res.data.code == 200) {
        toast.success(res.data.message, { autoClose: 2500 });
        window.location.reload(false);
        setIsCompleteLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsCompleteLoading(false);
    }
  };
  return (
    <div>
      {workoutDetails?.map((item, i) => {
        return (
          <Fragment key={i}>
            {item?.workout_group?.map((data) => {
              return (
                <div className="row mb-5" key={item?.id}>
                  {/* {console.log('item', item?.id)} */}
                  <div className="col-lg p-0 " style={{ maxWidth: "5%" }}>
                    <div
                      className="  d-flex align-items-center justify-content-center"
                      style={{
                        height: "220px",
                        zIndex: 2,
                        width: "43px",
                        position: "relative",
                        backgroundColor: "var(--deepSkyBlue)",
                        borderRadius: "13px 0px 13px 13px",
                        marginTop: "1px",
                      }}
                    >
                      {/* <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "-19px",
                          height: "80px",
                          width: "19px",
                          backgroundColor: "var(--valhalla)",
                        }}
                      /> */}

                      <p
                        style={{
                          position: "absolute",
                          transform: "rotate(270deg)",
                          fontWeight: 500,
                          color: "var(--defaultWhite)",
                          whiteSpace: "nowrap",
                          fontStyle: "normal",
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          textAlign: "center",
                          width: "200px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {data?.group_name} - {data?.group_set_type}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-lg-11 p-0"
                    style={{ padding: 0, maxWidth: "95%" }}
                  >
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                        style={{ backgroundColor: "var(--blackRussian)" }}
                        size="medium"
                      >
                        <TableHead>
                          <TableRow
                            style={{
                              border: "1px solid var(--blackRussian)",
                            }}
                          >
                            <StyledTableCell
                              color="var(--defaultWhite)"
                              style={{
                                backgroundColor: "var(--valhalla)",
                                border: "none",
                                fontWeight: 500,
                                fontSize: "13px",
                                fontFamily: "Poppins",
                              }}
                            >
                              Exercise
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              color="var(--defaultWhite)"
                              style={{
                                backgroundColor: "var(--valhalla)",
                                border: "none",
                                fontWeight: 500,
                                fontSize: "13px",
                              }}
                            >
                              Reps
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              color="var(--defaultWhite)"
                              style={{
                                backgroundColor: "var(--valhalla)",
                                border: "none",
                                fontWeight: 500,
                                fontSize: "13px",
                              }}
                            >
                              Sets
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              color="var(--defaultWhite)"
                              style={{
                                backgroundColor: "var(--valhalla)",
                                border: "none",
                                fontWeight: 500,
                                fontSize: "13px",
                              }}
                            >
                              Rest
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              color="var(--defaultWhite)"
                              style={{
                                backgroundColor: "var(--valhalla)",
                                border: "none",
                                fontWeight: 500,
                                fontSize: "13px",
                              }}
                            >
                              Video
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              color="var(--defaultWhite)"
                              style={{
                                backgroundColor: "var(--valhalla)",
                                border: "none",
                                fontWeight: 500,
                                fontSize: "13px",
                              }}
                            >
                              Alternative
                            </StyledTableCell>
                            {startWorkoutClickEvent !== "startWorkout" &&
                              !coachViewWorkout && (
                                <StyledTableRow>
                                  <StyledTableCell
                                    align="center"
                                    color="var(--defaultWhite)"
                                    style={{
                                      backgroundColor: "var(--valhalla)",
                                      border: "none",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Set No.
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    color="var(--defaultWhite)"
                                    style={{
                                      backgroundColor: "var(--valhalla)",
                                      border: "none",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Completed Load
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    color="var(--defaultWhite)"
                                    style={{
                                      backgroundColor: "var(--valhalla)",
                                      border: "none",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Completed Reps
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {data?.workout_group_exercise?.map((row, index) => (
                            <StyledTableRow
                              key={row?.workout_exercise_name}
                              style={{
                                backgroundColor:
                                  index === 0 || index % 2 === 0
                                    ? "var(--blackRussian)"
                                    : "var(--jaguar)",
                                paddingLeft: 20,
                                // borderRadius: "13px",
                                border: "1px solid var(--blackRussian)",
                              }}
                            >
                              <StyledTableCell
                                style={{
                                  color: "var(--defaultWhite)",
                                  marginLeft: "88px",
                                  border: "none",
                                  padding: "16px",
                                  fontSize: "12px",
                                  // borderTopLeftRadius: 13,
                                  // borderBottomLeftRadius: 13,
                                }}
                                component="th"
                                scope="row"
                              >
                                {row?.workout_exercise_name}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: "var(--defaultWhite)",
                                  border: "none",
                                  padding: "16px",
                                  fontSize: "12px",
                                }}
                                align="center"
                              >
                                {row?.workout_reps}{" "}
                                {row?.workout_repetition_type}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: "var(--defaultWhite)",
                                  border: "none",
                                  padding: "16px",
                                  fontSize: "12px",
                                }}
                                align="center"
                              >
                                {row?.workout_sets}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: "var(--defaultWhite)",
                                  border: "none",
                                  padding: "16px",
                                  fontSize: "12px",
                                }}
                                align="center"
                              >
                                {row?.workout_rest} secs
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: "var(--defaultWhite)",
                                  border: "none",
                                  padding: "16px",
                                  fontSize: "12px",
                                }}
                                align="center"
                              >
                                {/* {row?.workout_exercise_type_media === "pdf" ? (
                                  <div
                                    // onClick={() => toggleVideoModal(row)}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <a
                                      href={row?.workout_exercise_video}
                                      target="_blank"
                                      style={{
                                        color: "var(--persimmon)",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        lineHeight: "21px",
                                        margin: 0,
                                        cursor: "pointer",
                                      }}
                                    >
                                      View
                                    </a>
                                  </div>
                                ) : ( */}
                                <div
                                  onClick={() => toggleVideoModal(row)}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "var(--persimmon)",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "21px",
                                      margin: 0,
                                      cursor: "pointer",
                                    }}
                                  >
                                    View
                                  </p>
                                </div>
                                {/* )} */}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: "var(--defaultWhite)",
                                  border: "none",
                                  padding: "16px",
                                  // borderTopRightRadius: 13,
                                  // borderBottomRightRadius: 13,
                                }}
                                align="center"
                              >
                                <div
                                  onClick={() => toggleAlternativeExercise(row)}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "var(--persimmon)",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "21px",
                                      margin: 0,
                                      cursor: "pointer",
                                    }}
                                  >
                                    View
                                  </p>
                                </div>
                              </StyledTableCell>
                              {startWorkoutClickEvent !== "startWorkout" &&
                                !coachViewWorkout && (
                                  <>
                                    {setsData?.length > 0 &&
                                      setsData?.map((item, index) => (
                                        <React.Fragment key={index}>
                                          {item?.id === row?.id && (
                                            <StyledTableRow>
                                              <StyledTableCell
                                                style={{
                                                  color: "var(--defaultWhite)",
                                                  border: "none",
                                                  padding: "16px",
                                                  borderTopRightRadius: 13,
                                                  borderBottomRightRadius: 13,
                                                }}
                                                align="center"
                                              >
                                                <div
                                                  onClick={() =>
                                                    toggleAlternativeExercise(
                                                      row
                                                    )
                                                  }
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      color: "var(--persimmon)",
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      lineHeight: "21px",
                                                      margin: 0,
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {item?.setNumber + 1}
                                                  </p>
                                                </div>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                style={{
                                                  color: "var(--defaultWhite)",
                                                  border: "none",
                                                  padding: "16px",
                                                  borderTopRightRadius: 13,
                                                  borderBottomRightRadius: 13,
                                                  paddingLeft: 50,
                                                }}
                                                align="center"
                                              >
                                                <div
                                                  // onClick={() =>
                                                  //   toggleAlternativeExercise(row)
                                                  // }
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {item?.[row?.id].load ?? "0"}
                                                </div>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                style={{
                                                  color: "var(--defaultWhite)",
                                                  border: "none",
                                                  padding: "16px",
                                                  borderTopRightRadius: 13,
                                                  borderBottomRightRadius: 13,
                                                  paddingLeft: 50,
                                                }}
                                                align="center"
                                              >
                                                <div
                                                  // onClick={() =>
                                                  //   toggleAlternativeExercise(row)
                                                  // }
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {item?.[row?.id]?.sets ?? "0"}
                                                </div>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    {!workoutFind(
                                      row?.id,
                                      row?.workout_sets
                                    ) && (
                                      <StyledTableRow>
                                        <StyledTableCell
                                          style={{
                                            color: "var(--defaultWhite)",
                                            border: "none",
                                            padding: "16px",
                                            borderTopRightRadius: 13,
                                            borderBottomRightRadius: 13,
                                          }}
                                          align="center"
                                        >
                                          <div
                                            onClick={() =>
                                              toggleAlternativeExercise(row)
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "var(--persimmon)",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                lineHeight: "21px",
                                                margin: 0,
                                                cursor: "pointer",
                                              }}
                                            >
                                              {/* {workoutFind(
                                              row?.id,
                                              row?.workout_sets
                                            )
                                              ? setsData?.length + 1
                                              : 1} */}
                                              {setsNumber(row?.id) + 1}
                                            </p>
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            color: "var(--defaultWhite)",
                                            border: "none",
                                            padding: "16px",
                                            // borderTopRightRadius: 13,
                                            // borderBottomRightRadius: 13,
                                            paddingLeft: 50,
                                          }}
                                          align="center"
                                        >
                                          <div
                                            // onClick={() =>
                                            //   toggleAlternativeExercise(row)
                                            // }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              style={{
                                                width: 30,
                                                paddingLeft: 4,
                                                outline: "none",
                                              }}
                                              value={
                                                completeSets?.[row?.id]?.load
                                              }
                                              name="load"
                                              onChange={(e) =>
                                                handelChange(e, row?.id)
                                              }
                                            />
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            color: "var(--defaultWhite)",
                                            border: "none",
                                            padding: "16px",
                                            // borderTopRightRadius: 13,
                                            // borderBottomRightRadius: 13,
                                            paddingLeft: 50,
                                          }}
                                          align="center"
                                        >
                                          <div
                                            // onClick={() =>
                                            //   toggleAlternativeExercise(row)
                                            // }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              style={{
                                                width: 30,
                                                paddingLeft: 4,
                                                outline: "none",
                                              }}
                                              onChange={(e) =>
                                                handelChange(e, row?.id)
                                              }
                                              value={
                                                completeSets?.[row?.id]?.sets
                                              }
                                              name="sets"
                                            />
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                  </>
                                )}
                              {startWorkoutClickEvent !== "startWorkout" &&
                                !workoutFind(row?.id, row?.workout_sets) &&
                                !coachViewWorkout && (
                                  <CustomButton
                                    style={{
                                      padding: 10,
                                      marginRight: 10,
                                      marginBottom: 15,
                                    }}
                                    onClick={() => {
                                      completeSets?.[row?.id]?.sets &&
                                        completeSets?.[row?.id]?.load &&
                                        onSubmit(row?.id);
                                      onCompleteSets(
                                        data?.id,
                                        row?.workout_exercise,
                                        row?.id,
                                        item?.intensity
                                      );
                                    }}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    children={"Add New Set"}
                                  />
                                )}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {startWorkoutClickEvent !== "startWorkout" &&
                    setsData?.length > 0 &&
                    !coachViewWorkout && (
                      <CustomButton
                        onClick={() => completeWorkout(item?.intensity)}
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                          border: "1px solid",
                          position: "absolute",
                          bottom: "5px",
                          right: "16px",
                        }}
                        isLoading={isCompleteLoading}
                        disabled={isCompleteLoading}
                      >
                        Complete Workout
                      </CustomButton>
                    )}
                </div>
              );
            })}
          </Fragment>
        );
      })}

      <VideoModal
        onHide={toggleVideoModal}
        show={viewVideoModal}
        videoModalDetails={videoModalDetails}
      />

      <AlternativeExerciseModal
        onHide={closeAlternativeExercise}
        show={viewAlternativeExercise}
        alternativeExerciseDetails={alternativeExerciseDetails}
        setLoaderWorkout={loaderAthleteWorkout}
        // exerciseId={exerciseId}
      />
    </div>
  );
}
