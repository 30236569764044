import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import newsletterIcon from "../../Custom/images/newsletter_icon.png";
import fbIcon from "../../Custom/images/fb_icon.png";
import twitterIcon from "../../Custom/images/twitter_icon.png";
import instaIcon from "../../Custom/images/insta_icon.png";
import free_trial_banner from "Custom/images/free_trial_banner.png";
import SvgIcon from "component/SvgIcon";
import CustomButton from "component/customButton/CustomButton";
function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  const { push } = useHistory();
  return (
    <div className="my-4">
      <Fragment>
        <footer className="footer">
          <div className="container text-center">
            {/* <div className="start_trial_banner">
              <div className="start_trial_banner_textContainer">
                <p>Start your 14 day Free Trial</p>
                <CustomButton
                  onClick={() => push("/sportsection")}
                  style={{ width: 150 }}
                >
                  Start Now
                </CustomButton>
              </div>
              <div className="position-relative">
                <img
                  src={free_trial_banner}
                  alt="no_image"
                  className="img-fluid"
                />
                <div className="image_overlay" />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-3">
                <div className="footer_menu">
                  <h5 className="header_menu">Information</h5>
                  <ul>
                    <li>
                      <SvgIcon name="arrow_polygon" />{" "}
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <SvgIcon name="arrow_polygon" />{" "}
                      <Link to="/cookiepolicy">Cookie Policy</Link>
                    </li>
                    <li>
                      <SvgIcon name="arrow_polygon" />{" "}
                      <Link to="/termsandconditions">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <SvgIcon name="arrow_polygon" />{" "}
                      <Link to="/faqs">FAQs</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer_menu">
                  <h5 className="text-center">Follow us</h5>
                  <ul className="social_links">
                    <li>
                      <a href="#">
                        <SvgIcon name="news_letter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <SvgIcon name="facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <SvgIcon name="twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <SvgIcon name="instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footer_menu">
                  <h5>Get in Touch</h5>
                  <ul>
                    <li>
                      <SvgIcon name="arrow_polygon" />{" "}
                      <a href="mailto:info@primecoach.co">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
      {isVisible && (
        <span
          onClick={ScrollToTop}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            background: "var(--safetyOrange)",
            width: "50px",
            height: "50px",
            textDecoration: "none",
            borderRadius: "35px",
            transition: "all 0.3s ease",
            color: "#fff",
            zIndex: "999",
            cursor: "pointer",
          }}
        >
          <i
            className="fa fa-arrow-up "
            onClick={ScrollToTop}
            aria-hidden="true"
          ></i>
        </span>
      )}
    </div>
  );
}

export default Footer;
