import React, { useState } from "react";
import moment from "moment";
import { standardPostApi } from "container/API/ApiWrapper";
import ActionButton from "component/actionButton/ActionButton";
import LoaderWrapper from "container/Loader/LoaderWrapper";

function ExportWorkoutPdf({ startDate, endDate, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const export_PDF = async (fileFormat) => {
    setIsLoading(true);
    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");
    try {
      const res = await standardPostApi(
        "export_atp_workout",
        undefined,
        {
          access_token: localStorage.getItem("access_token"),
          start_date: start_date,
          end_date: end_date,
          format: fileFormat,
        },
        true
      );

      // console.log("res", res.data.code);

      if (res.data.code === 200) {
        let hiddenElement = document.createElement("a");
        hiddenElement.href = res?.data?.data;
        hiddenElement.target = "_blank";
        // hiddenElement.download = "Athlete Workout.csv";
        hiddenElement.click();
      }
    } catch (error) {
      console.error("assign DayWise Calender Error", error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };
  return (
    <div>
      <div className="row">
        {isLoading ? (
          <LoaderWrapper style={{ height: "100%" }} />
        ) : (
          <>
            <div className="col-lg-6  align-item-center d-flex justify-content-center">
              <div>
                <ActionButton
                  type="red"
                  svgName={"pdf"}
                  className="m-auto "
                  onClick={() => export_PDF("pdf")}
                />
                <p
                  style={{
                    color: "#FFF",
                    fontSize: 18,
                    marginTop: 20,
                  }}
                >
                  Export to PDF
                </p>
              </div>
            </div>
            <div className="col-lg-6  align-item-center d-flex justify-content-center">
              <div>
                <ActionButton
                  type="green"
                  svgName={"csv"}
                  className="m-auto mb-3"
                  onClick={() => export_PDF("excel")}
                />
                <p
                  style={{
                    color: "#FFF",
                    fontSize: 18,
                    marginTop: 20,
                  }}
                >
                  Export to CSV
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExportWorkoutPdf;
