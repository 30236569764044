import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import React, { useEffect } from "react";
function EditWorkoutExerciseModal({
  exerciseNameUpdate,
  exerciseDescriptionUpdate,
  exerciseVideoUpdate,
  ExerciseGroupsIdUpdate,
  item,
  handleClose,
  onChangeUpdate,
  onSelectUploadTypeEdit,
  selectUploadTypeEdit,
  ExerciseGroupsSelectpicker,
  updateWorkoutExerciseLoading,
  updateWorkoutExercise,
  onChangeUpdateFile,
}) {
  return (
    <React.Fragment>
      <CustomInput
        className={"mb-3"}
        placeholder="Name"
        value={exerciseNameUpdate}
        name="exerciseNameUpdate"
        onChange={(e) => onChangeUpdate(e)}
      />

      {/* <CustomInput
                                      className={"mb-3"}
                                      placeholder="Description"
                                      value={exerciseDescriptionUpdate}
                                      name="exerciseDescriptionUpdate"
                                      onChange={(e) =>
                                        onChangeUpdate(e)
                                      }
                                    /> */}

      <CustomInput
        type="textarea"
        placeholder="Description"
        className="mb-2"
        value={exerciseDescriptionUpdate}
        name="exerciseDescriptionUpdate"
        onChange={(e) => onChangeUpdate(e)}
      />
      <select
        className="select_class my-2"
        onChange={onSelectUploadTypeEdit}
        value={selectUploadTypeEdit}
      >
        <option value="">Select A Upload Type</option>
        {[
          { id: 1, label: "File", value: 1 },
          {
            id: 2,
            label: "YouTube Link",
            value: 2,
          },
        ].map((data) => {
          return (
            <option key={data.id} value={data.id}>
              {data?.label}
            </option>
          );
        })}
      </select>
      {selectUploadTypeEdit == "2" && (
        <CustomInput
          className={"mb-3"}
          placeholder="Video link"
          value={exerciseVideoUpdate}
          name="exerciseVideoUpdate"
          onChange={(e) => onChangeUpdate(e)}
        />
      )}
      {selectUploadTypeEdit == "1" && (
        <CustomInput
          type="file"
          className={"mb-3"}
          placeholder="Video or Pdf"
          onChange={onChangeUpdateFile}
          inputStyle={{ paddingTop: "10px" }}
        />
      )}
      {/* {this?.state?.selectUploadTypeEdit ==
                                      "1" && (
                                      <video
                                        src={exercise_media_update}
                                        width="100%"
                                        height="345"
                                        autoPlay
                                        controls
                                      />
                                    )} */}
      <select
        className="select_class"
        name="ExerciseGroupsIdUpdate"
        defaultValue={ExerciseGroupsIdUpdate}
        selected
        onChange={(e) => onChangeUpdate(e)}
      >
        {ExerciseGroupsSelectpicker.map((data) => {
          return (
            <option key={data.id} value={data.value}>
              {data.label}
            </option>
          );
        })}
      </select>
      <div className="d-flex mt-4">
        <CustomButton
          dataDismiss="modal"
          flex={1}
          style={{ height: "50px" }}
          isLoading={updateWorkoutExerciseLoading}
          onClick={() => {
            updateWorkoutExercise(item, handleClose);
          }}
        >
          Edit
        </CustomButton>
      </div>
    </React.Fragment>
  );
}

export default EditWorkoutExerciseModal;
