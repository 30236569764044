import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
export class ViewVideoModal extends Component {
  render() {
    const { state } = this.props;

    const url = state?.viewVideoData;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="videopopupTitle">
                {/* Back to wall shoulder */}
                {state?.viewName}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <iframe
                  // onLoad={() => setLoading(false)}
                  // placeholder="abcccc"
                  width="100%"
                  title="video"
                  height="345"
                  // src={state?.viewVideoData}
                  src={`https://www.youtube.com/embed/${urlId}`}
                  frameBorder="0"
                  // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ViewVideoModal;
