import React from "react";
import { Modal } from "react-bootstrap";
import { standardPostApi } from "../../container/API/ApiWrapper";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../Custom/images/celender.jpg";

// moment and timestamp in react js
import moment from "moment";

// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorToast } from "utils/toastMessage";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CustomButton from "component/customButton/CustomButton";

export default class AddActivityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preAddUserActivity: [],
      date: new Date(),
      intensity: "",
      activityType: "",
      duration: "",
      customizeActivity: "",
      activityTypeError: "",
      durationError: "",
      dateError: "",
      IntensityError: "",
      customizeActivityError: "",
      isLoading: false,
      selectLoading: false,
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onValueChange(event) {
    this.setState({
      intensity: event.target.value,
    });
    // console.log("this is state", this.state.intensity);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // componentDidMount() {
  //   this.pre_add_user_activity();
  // }

  pre_add_user_activity = async () => {
    this.setState({ selectLoading: true });
    try {
      const res = await standardPostApi(
        "pre_add_user_activity",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true
      );
      if (res.data.code === 200) {
        // console.log("this is res of pre_add_user_activity*", res);
        this.setState({ preAddUserActivity: res.data.data.user_activity_type });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ selectLoading: false });
    }
  };

  add_user_activity = async () => {
    const weekId = this.props?.athleteList?.weeks?.find((data) => data);
    const start_date = moment(this.toTimestamp(this.state.date) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    const isValid = this.validationAddUserActivity();
    if (isValid) {
      this.setState({ isLoading: true });
      try {
        const res = await standardPostApi(
          "add_user_activity",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            annual_training_program_id: this.props.athleteList.id,
            annual_training_program_week_id: weekId.id,
            activity_type: this.state.activityType,
            activity_duration: this.state.duration,
            activity_date: start_date,
            activity_intensity: this.state.intensity,
            customize_activity: this.state.customizeActivity,
          },
          true
        );
        if (res.data.code === 200) {
          // console.log("This is Res of add_user_activity=>", res.data.data);
          toast.success(res.data.message);
          this.setState({
            date: new Date(),
            intensity: "",
            activityType: "",
            duration: "",
            customizeActivity: "",
            activityTypeError: "",
            durationError: "",
            dateError: "",
            IntensityError: "",
            customizeActivityError: "",
          });
          await this.props.onHide();
          window.location.reload(false);
          this.setState({ isLoading: false });
        }
      } catch (error) {
        console.log(error);
        this.setState({ isLoading: false });
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  validationAddUserActivity = () => {
    let activityTypeError = "";
    let durationError = "";
    let dateError = "";
    let IntensityError = "";
    let customizeActivityError = "";

    if (!this.state.activityType) {
      activityTypeError = "Please Select Activity Type";
    }

    if (!this.state.duration) {
      durationError = "Duration field is required.";
    }
    if (this.state.activityType == "0") {
      if (!this.state.customizeActivity) {
        customizeActivityError = "Customize Activity field is required";
      }
    }

    if (!this.state.date) {
      dateError = "Date field is required.";
    }
    if (!this.state.intensity) {
      IntensityError = "The field Intensity must be between 1 and 10.";
    }

    if (
      activityTypeError ||
      durationError ||
      dateError ||
      IntensityError ||
      customizeActivityError
    ) {
      this.setState({
        activityTypeError,
        durationError,
        dateError,
        IntensityError,
        customizeActivityError,
      });
      return false;
    } else {
      return true;
    }
  };

  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ date: date, hasSetDate: true });
    // console.log("The start date selected is ", this.state.date);
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  isInputNumber = (event) => {
    var char = String.fromCharCode(event.which);
    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  };
  showTostNotAssign = () => {
    errorToast("No workout assigned to athlete yet");
  };

  render() {
    const { activityType, duration, customizeActivity } = this.state;
    const { noWrkoutAssignAddActivity } = this.props;
    // console.log("this.props.athleteList", this.props.athleteList);

    // console.log(this.props.athleteList);
    // console.log("onChange", this.state.activityType);
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="add_activityTitle">
                Add Activity
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="d-flex w-100">
                  <select
                    className="select_class"
                    name="activityType"
                    value={activityType}
                    onChange={this.onChange}
                    onClick={() => this.pre_add_user_activity()}
                  >
                    <option value="">Select Activity Type </option>
                    {this.state.preAddUserActivity?.map((item) => {
                      return (
                        <option value={item?.value} key={item?.id}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </select>
                  {this.state.selectLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-spinner fa-spin fa-3x fa-fw"
                        // className="fa fa-spinner fa-pulse fa-3x fa-fw"
                        style={{
                          color: "var(--safetyOrange)",
                          fontSize: "20px",
                          // marginTop: "50px",
                        }}
                      />
                    </div>
                  )}
                </div>
                <p className="react_validation ">
                  {this.state.activityTypeError}
                </p>
              </div>

              {activityType === "0" && (
                <CustomInput
                  className={"mb-3"}
                  placeholder="Customize Activity"
                  name="customizeActivity"
                  onChange={this.onChange}
                  value={customizeActivity}
                  error={this.state.customizeActivityError}
                />
              )}

              <div className="form-group">
                <CustomInput
                  name="duration"
                  value={duration}
                  onChange={this.onChange}
                  type="number"
                  placeholder="Duration (in minutes)"
                  error={this.state.durationError}
                />
              </div>

              <div className="form-group">
                <InputDateTimePicker
                  style={{ width: "100%" }}
                  value={this.state.date}
                  onChange={this.handleDateChange}
                  name="DateOfBirth"
                  placeholder="Date"
                  error={this.state.dateError}
                />
              </div>

              <div className="form-group">
                <p className="edit-madical-status-span">Intensity</p>
                <div className="middle">
                  {/* {Array.from(Array(10).keys()).map((index) => ( */}
                  {Array.from(Array(10).keys())?.map((index) => (
                    <span key={index}>
                      <label>
                        <input
                          type="radio"
                          name="radio"
                          value={index + 1}
                          onChange={this.onValueChange}
                        />
                        <div className="front-end box d-flex align-items-center justify-content-center">
                          <span>{index + 1}</span>
                        </div>
                      </label>
                      &nbsp;
                    </span>
                  ))}
                </div>
                <p className="react_validation ">{this.state.IntensityError}</p>
              </div>

              <div className="d-flex">
                {noWrkoutAssignAddActivity ? (
                  <CustomButton
                    flex={1}
                    onClick={this.add_user_activity}
                    isLoading={this.state.isLoading}
                    disabled={this.state.isLoading}
                  >
                    Save
                  </CustomButton>
                ) : (
                  <CustomButton
                    flex={1}
                    onClick={() => this.showTostNotAssign()}
                    isLoading={this.state.isLoading}
                    disabled={this.state.isLoading}
                  >
                    Save
                  </CustomButton>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
