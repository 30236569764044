import NoDataFound from "component/lottiLoader/LottiLoader";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ViewAthleteWorkoutTable from "./ViewAthleteWorkoutTable";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ActionButton from "component/actionButton/ActionButton";
import CustomButton from "component/customButton/CustomButton";
import { standardPostApi } from "container/API/ApiWrapper";
import LoaderWrapper from "container/Loader/LoaderWrapper";

export class ViewAthleteWorkoutModal extends Component {
  state = { visiblePdfAndCsvButton: false, isLoading: false };

  pdfGeneraterTable = async (daysDetail, viewWorkoutModalArray, format) => {
    this.setState({ isLoading: true });
    try {
      const res = await standardPostApi(
        "export_atp_day_workout",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: this.props.atpID
            ? this.props.atpID
            : viewWorkoutModalArray?.annual_training_program_id,
          annual_training_program_week_id: this.props.atpWeekID
            ? this.props.atpWeekID
            : viewWorkoutModalArray?.id,
          annual_training_program_day_id: this.props?.atpDayID
            ? this.props?.atpDayID
            : daysDetail?.id,
          format: format,
        },
        true
      );

      // console.log("res", res.data.code);

      if (res.data.code === 200) {
        let hiddenElement = document.createElement("a");
        hiddenElement.href = res?.data?.data;
        hiddenElement.target = "_blank";
        hiddenElement.click();
      }
    } catch (error) {
      console.error("assign DayWise Calender Error", error);
    } finally {
      this.setState({ isLoading: false });
    }
    // // // const weekId = this.props.viewWorkoutModalArray;

    // const groups = workoutDetails.find((data) => data);
    // // const days = this.props.viewWorkoutData;
    // // console.log("THIS IS DAYSSSSS", days);

    // const doc = new jsPDF();

    // // console.log("groups", groups);

    // groups.workout_group.map((data, index) => {
    //   doc.autoTable({
    //     theme: "grid",
    //     head: [
    //       [
    //         {
    //           content: ` ${data.group_name} - ${data.group_set_type} -${" "}${
    //             data.group_rest && "REST " + data.group_rest + " secs"
    //           }`,
    //           colSpan: 4,
    //           styles: { halign: "center", fillColor: [22, 160, 133] },
    //         },
    //       ],
    //       ["Exercise", "Reps", "Sets", "Rest"],
    //     ],
    //     body: data.workout_group_exercise.map((item) => [
    //       item.workout_exercise_name,
    //       `${item.workout_reps} ${item.workout_repetition_type} ${
    //         item.workout_reps_each_side === "1" ? "ES " : " "
    //       }`,
    //       item.workout_sets,
    //       item.workout_rest,
    //     ]),
    //     margin: { top: 20, bottom: 20 },
    //   });
    // });
    // doc.text(`WORKOUT`, 10, 10);
    // doc.save(`Workout.pdf`);
    // doc.text(`${days.day_number}${" "}Workout`, 10, 10);

    // days.groups?.length === 0 && doc.text(`No data available`, 10, 20);

    // doc.save(`${days.day_number}${" "}Workout.pdf`);
  };

  // exportCSV = async (mainData, day) => {
  //   // console.log("mainData", mainData);

  //   this.setState({ downloadingCSV: true });

  //   let VALUES = [];

  //   const DAY_DETAILS = day;

  //   mainData[0].workout_group.map((item) => {
  //     VALUES.push(["", item.group_name, item.group_set_type, ""]);
  //     item.workout_group_exercise.map((exer) => {
  //       VALUES.push([
  //         exer.workout_exercise_name,
  //         exer.workout_sets,
  //         `${exer.workout_reps} ${exer.workout_repetition_type}`,
  //         exer.workout_rest,
  //       ]);
  //     });
  //   });

  //   let header_string = "Exercise,Sets,Reps,Rest\n";
  //   const rowString = VALUES.map(
  //     (d) => `${d[0]},${d[1]},${d[2]},${d[3]}\n`
  //   ).join("");
  //   const csvString = `${header_string}${rowString}`;
  //   let csvData = new Blob([csvString], { type: "text/csv" });
  //   let csvUrl = URL.createObjectURL(csvData);
  //   let hiddenElement = document.createElement("a");
  //   hiddenElement.href = csvUrl;
  //   hiddenElement.target = "_blank";
  //   hiddenElement.download = "workout.csv";
  //   hiddenElement.click();
  // };
  render() {
    const {
      workoutDetails,
      viewWorkoutLoader,
      startWorkoutClickEvent,
      switchStartWorkout,
      daysDetail,
      weekDetail,
      viewWorkoutModalArray,
      atpID,
      atpWeekID,
      atpDayID,
    } = this.props;

    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="view-workoutTitle">
                {startWorkoutClickEvent !== "startWorkout"
                  ? "View Workout"
                  : "Start Workout"}
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {viewWorkoutLoader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <i
                  className="fa fa-spinner fa-spin fa-3x fa-fw"
                  style={{
                    color: "var(--safetyOrange)",
                    fontSize: "40px",
                    marginTop: "50px",
                  }}
                />
              </div>
            ) : (
              <div className="modal-body text-right">
                <div>
                  <>
                    {startWorkoutClickEvent === "startWorkout" ? (
                      <>
                        <CustomButton
                          className={"mb-3"}
                          onClick={() => switchStartWorkout()}
                        >
                          Start Workout
                        </CustomButton>
                      </>
                    ) : (
                      <div style={{ marginTop: "20px" }} className="row">
                        <div className="col-lg-6 align-item-center d-flex justify-content-center">
                          <div>
                            <ActionButton
                              type="red"
                              svgName={"pdf"}
                              className="m-auto "
                              onClick={() =>
                                this.pdfGeneraterTable(
                                  daysDetail,
                                  viewWorkoutModalArray,
                                  "pdf"
                                )
                              }
                            />
                            <p
                              style={{
                                color: "#FFF",
                                fontSize: 18,
                                marginTop: 20,
                              }}
                            >
                              Export to PDF
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6  align-item-center d-flex justify-content-center">
                          <div>
                            <ActionButton
                              type="green"
                              svgName={"csv"}
                              className="m-auto mb-3"
                              onClick={() =>
                                this.pdfGeneraterTable(
                                  daysDetail,
                                  viewWorkoutModalArray,
                                  "excel"
                                )
                              }
                            />
                            <p
                              style={{
                                color: "#FFF",
                                fontSize: 18,
                                marginTop: 20,
                              }}
                            >
                              Export to CSV
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  {!viewWorkoutLoader && workoutDetails.length === 0 ? (
                    <NoDataFound
                      height={250}
                      width={250}
                      text="No Workout available yet"
                    />
                  ) : (
                    <div>
                      <ViewAthleteWorkoutTable
                        workoutDetails={workoutDetails}
                        startWorkoutClickEvent={startWorkoutClickEvent}
                        coachViewWorkout={this.props.coachViewWorkout}
                        daysDetail={daysDetail}
                        weekDetail={weekDetail}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        {this.state.isLoading && (
          <LoaderWrapper
            style={{
              position: "fixed",
              background: "rgb(31 29 51 / 50%)",
              height: "100%",
              width: "100%",
              top: 0,
              zIndex: 1000000,
            }}
          />
        )}
      </div>
    );
  }
}

export default ViewAthleteWorkoutModal;
