import React from "react";
import CoachHeader from "../../PublicLayout/CoachHeader";
import Footer from "../../PublicLayout/Footer";
import ExerciseWorkoutGroupSectionForCoach from "./ExerciseWorkoutGroupSectionForCoach";
// import ExerciseSectionRight from "./ExerciseSectionRight";
import ExerciseRightSectionForCoach from "./ExerciseRightSectionForCoach";
// import { standardPostApi } from "../API/ApiWrapper";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoaderWrapper from "../../Loader/LoaderWrapper";

import {
  standardPostApi,
  standardPostApiJsonBased,
} from "container/API/ApiWrapper";
import CustomInput from "component/customInput/CustomInput";
import NoDataFound from "component/lottiLoader/LottiLoader";
import { debounce } from "helpers/debounce";
// import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
class ExerciseSettingForCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loding: true,
      ExerciseGroups: [],
      ExerciseGroupsSelectpicker: [],
      addWorkoutExerciseField: "",
      visibleListGroupExercise: true,
      visibleNewListGroupExercise: false,
      workoutExerciseGroupUpdate: "",
      show: false,
      exerciseGroupName: "",
      exerciseGroupId: "",
      exercisesListOnclick: [],
      exercise_group_id: "",
      ExerciseName: "",
      ExerciseDescription: "",
      exerciseVideos: "",
      youtube_link: "",
      selectedGroupExercises: [],
      exerciseNameUpdate: "",
      exerciseDescriptionUpdate: "",
      exerciseVideoUpdate: "",
      exercise_media_update: null,
      deleteExerciseModal: false,
      exerciseGroupID: "",
      exerciseId: "",
      exerciseNameUpdateError: "",
      onAdd: false,
      userRoll: this.props.location.state,
      ExerciseGroupsId: "",
      ExerciseGroupsType: "",
      ExerciseGroupsIdUpdate: "",
      isLoading: false,
      searchText: "",
      debounceValue: "",
      exercise_media: null,
      VideoUploadProgress: [],
      updateWorkoutExerciseLoading: false,
      type_media_update: "",
      deleteWorkoutExerciseLoading: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onChangeFile(e) {
    // console.log("result", e.target.files[0]);
    let sizeInMB = e.target.files[0]?.size / (1024 * 1024).toFixed(2);
    if (sizeInMB < 50) {
      this.setState({ exercise_media: e.target.files[0] });
    } else {
      toast.error("please select a video less than 50MB!");
    }
  }
  componentDidMount() {
    this.admin_exercise_settings();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState?.debounceValue !== this.state.debounceValue) {
      this.admin_exercise_settings();
    }
  }
  showModal = async (item) => {
    // console.log("this is data for delete ", item);
    await this.setState({
      show: !this.state.show,
      exerciseGroupName: item.label,
      exerciseGroupId: item.id,
    });
  };

  hideModal = async () => {
    await this.setState({ show: false });
  };

  showDeleteWorkoutExerciseModal = async (item) => {
    // console.log("delete Exercise", item);
    await this.setState({
      deleteExerciseModal: !this.state.deleteExerciseModal,
      exerciseGroupID: item.exercise_group_id,
      exerciseId: item.id,
      exerciseNameUpdate: item.exercise,
      exerciseDescriptionUpdate: item.description,
      // exerciseVideoUpdate: item.video,
    });
  };

  hideDeleteWorkoutExerciseModal = async () => {
    await this.setState({ deleteExerciseModal: false });
  };
  admin_exercise_settings = async (message) => {
    const coachRoles = localStorage.getItem("access_role");
    try {
      const res = await standardPostApi(
        "admin_exercise_settings",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          type: coachRoles === "Coach" ? "coach" : "admin",
          search: this.state.debounceValue,
        },
        true
      );
      if ((res.data.code = 200)) {
        message === "onAdd"
          ? await this.setState({
              ExerciseGroups: res.data.data.ExerciseGroups,
              ExerciseGroupsSelectpicker:
                res.data.data.ExerciseGroupsSelectpicker.pickerArray,
              loding: false,
              // ExerciseGroupsId: res.data?.data?.ExerciseGroups[0]?.id,
              // ExerciseGroupsType: res.data?.data?.ExerciseGroups[0]?.type,
              // selectedGroupExercises:
              //   res.data?.data?.ExerciseGroups[0]?.exercises,
            })
          : await this.setState({
              ExerciseGroups: res.data.data.ExerciseGroups,
              ExerciseGroupsSelectpicker:
                res.data.data.ExerciseGroupsSelectpicker.pickerArray,
              loding: false,
              ExerciseGroupsId: res.data?.data?.ExerciseGroups[0]?.id,
              ExerciseGroupsType: res.data?.data?.ExerciseGroups[0]?.type,
              selectedGroupExercises:
                res.data?.data?.ExerciseGroups[0]?.exercises,
            });
        this.setSelectedExercise(
          res.data.data.ExerciseGroupsSelectpicker.pickerArray[0]?.id
        );

        // console.log("RESPONSE OF admin_exercise_settings", res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Add Workout exercise Group
  addWorkoutExerciseGroup = async () => {
    const coachRoles = localStorage.getItem("access_role");
    try {
      const res = await standardPostApi(
        "create_workout_exercise_group",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          exercise_group_name: this.state.addWorkoutExerciseField,
          api_action: "add",
          type: coachRoles === "Coach" ? "coach" : "admin",
        },
        true
      );
      if (res.data.code === 200) {
        await this.setState({
          visibleListGroupExercise: false,
          visibleNewListGroupExercise: true,
          addWorkoutExerciseGroup: "",
        });
        // console.log(
        //   "THIS IS RESPONSER OF ADD WORKOUT EXERCISE GROUP",
        //   res.data.data
        // );
        toast.success(res.data.message);
        await this.admin_exercise_settings();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Update Workout exercise Group

  updateWorkoutExerciseGroup = async (item) => {
    // console.log("data  for update function", item);
    try {
      const res = await standardPostApi(
        "create_workout_exercise_group",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          exercise_group_id: item.id,
          exercise_group_name:
            this.state.workoutExerciseGroupUpdate === ""
              ? item?.label
              : this.state.workoutExerciseGroupUpdate,
          api_action: "update",
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("THIS IS RES OF WORKOUT EXERCISE GROUP ", res.data.data);
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      window.location.reload(false);
    }
  };

  // Delete Workout exercise Group
  updateExercise = (item) => {
    this.setState({
      exerciseNameUpdate: item?.exercise,
      exerciseDescriptionUpdate: item?.description,
      exerciseVideoUpdate: item?.video,
      exercise_media_update: item?.video,
      ExerciseGroupsIdUpdate: item?.exercise_group_id,
      type_media_update: item?.type_media,
    });
  };
  onChangeUpdate = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeUpdateFile(e) {
    this.setState({ exercise_media_update: e.target.files[0] });
  }
  deleteWorkoutExerciseGroup = async () => {
    try {
      const res = await standardPostApi(
        "create_workout_exercise_group",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          exercise_group_id: this.state.exerciseGroupId,
          exercise_group_name: this.state.exerciseGroupName,
          api_action: "delete",
        },
        true
      );
      if (res.data.code === 200) {
        // console.log(
        //   "THIS IS RESPONSE OF DELETE WORKOUT EXERCISE GROUP",
        //   res.data.data
        // );
        toast.success(res.data.message);
        await this.hideModal();
        await this.admin_exercise_settings();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onHandel = async (e) => {
    this.setState({ ExerciseGroupsId: e.target.value });

    this.setSelectedExercise(e.target.value);
  };

  setSelectedExercise = async (id) => {
    const { ExerciseGroups } = this.state;

    ExerciseGroups.forEach(async (item) => {
      if (item.id == id) {
        await this.setState({
          pickerValue: id,
          selectedGroupExercises: item?.exercises,
          exercise_group_id: id,
          ExerciseGroupsType: item?.type,
        });
      }
    });
  };

  // Add Workout Exercises
  addWorkoutExercise = async (close) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        let tempUpload = this.state.VideoUploadProgress;
        tempUpload = percentCompleted;
        this.setState({
          VideoUploadProgress: tempUpload,
        });
      },
    };
    const coachRoles = localStorage.getItem("access_role");
    const isValid = this.validationAddWorkoutExercise();
    let fd = new FormData();
    fd.append("access_token", localStorage.getItem("access_token"));
    fd.append("exercise_group_id", this.state.ExerciseGroupsId);
    fd.append("exercise_name", this.state.ExerciseName);
    fd.append("exercise_description", this.state.ExerciseDescription);
    fd.append("youtube_link", this.state.youtube_link);
    fd.append("api_action", "add");
    fd.append("type", coachRoles === "Coach" ? "coach" : "admin");

    typeof this?.state?.exercise_media !== "string" &&
      this?.state?.exercise_media !== null &&
      fd.append(
        "exercise_media",
        this?.state?.exercise_media,
        this?.state?.exercise_media?.name
      );
    if (isValid) {
      this.setState({ isLoading: true });

      try {
        const res = await standardPostApiJsonBased(
          "v2/create_workout_exercise",
          {},
          fd,
          // {
          //   access_token: await localStorage.getItem("access_token"),
          //   exercise_group_id: this.state.ExerciseGroupsId,
          //   exercise_name: this.state.ExerciseName,
          //   exercise_description: this.state.ExerciseDescription,
          //   exercise_video: this.state.exerciseVideos,
          //   api_action: "add",
          //   type: coachRoles === "Coach" ? "coach" : "admin",
          // },
          true,
          true,
          config
        );
        if (res.data.code === 200) {
          await this.setState({ onAdd: true });
          // console.log("THIS IS RES OF ADD EXERCISE", res.data.data);
          // window.location.reload(false);

          // const index = this.state.selectedGroupExercises?.findIndex(
          //   (item) => item?.id === res?.data?.data?.id
          // );
          // let temp = [...this.state?.selectedGroupExercises];
          // temp[index] = res?.data?.data;
          // window.location.reload(false);
          const exerciseGroupIndex = this.state.ExerciseGroups?.findIndex(
            (item) => item?.id == res?.data?.data?.exercise_group_id
          );
          let exerciseGroupTemp = [...this.state.ExerciseGroups];
          exerciseGroupTemp[exerciseGroupIndex].exercises.push(res?.data?.data);
          toast.success(res.data.message);
          // await this.admin_exercise_settings("onAdd");
          // await this.setSelectedExercise(this.state.pickerValue);
          this.state.onAdd &&
            (await this.setState({
              ExerciseDescription: "",
              ExerciseName: "",
              exerciseVideos: "",
              youtube_link: "",
              exercise_media: null,
              ExerciseGroups: exerciseGroupTemp,
            }));
        }
      } catch (error) {
        console.log(error);
        this.setState({ isLoading: false, exercise_media: null });
      } finally {
        let tempUpload = this.state.VideoUploadProgress;
        tempUpload = null;
        this.setState({
          VideoUploadProgress: tempUpload,
          exercise_media: null,
          isLoading: false,
        });
        close();
      }
    }
  };

  validationAddWorkoutExercise = () => {
    let ExerciseNameError = "";
    // let ExerciseDescriptionError = "";
    // let ExerciseVideoError = "";

    if (!this.state.ExerciseName) {
      ExerciseNameError = toast.error("Name Field is Required.");
    }

    // if (!this.state.ExerciseDescription) {
    //   ExerciseDescriptionError = toast.error("Description Field is Required.");
    // }

    // if (!this.state.exercise_media) {
    //   ExerciseVideoError = toast.error("Video Field is Required.");
    // }

    if (ExerciseNameError) {
      this.setState({
        ExerciseNameError,
        // ExerciseDescriptionError,
        // ExerciseVideoError,
      });
      return false;
    } else {
      return true;
    }
  };

  // Update Workout Exercise
  updateWorkoutExercise = async (item, close) => {
    let fd = new FormData();
    fd.append("access_token", localStorage.getItem("access_token"));
    fd.append("exercise_group_id", this.state.ExerciseGroupsIdUpdate);
    fd.append("exercise_id", item?.id);
    fd.append(
      "exercise_name",
      this.state.exerciseNameUpdate === ""
        ? item?.exercise
        : this.state.exerciseNameUpdate
    );
    fd.append(
      "exercise_description",
      this.state.exerciseDescriptionUpdate === ""
        ? item?.description
        : this.state.exerciseDescriptionUpdate
    );
    fd.append("api_action", "update");

    typeof this?.state?.exercise_media_update !== "string" &&
      this?.state?.exercise_media_update !== null &&
      fd.append(
        "exercise_media",
        this.state.exercise_media_update === ""
          ? item?.video
          : this.state.exercise_media_update,
        this.state.exercise_media_update?.name === ""
          ? item?.video?.name
          : this.state.exercise_media_update?.name
      );
    const isValid = this.validationUpdateWorkoutExercise();
    // console.log("item.id", item);
    if (isValid) {
      this.setState({ updateWorkoutExerciseLoading: true });
      try {
        const res = await standardPostApiJsonBased(
          "v2/create_workout_exercise",
          {},
          fd,
          true
        );
        // const res = await standardPostApi(
        //   "create_workout_exercise",
        //   undefined,
        //   {
        //     access_token: await localStorage.getItem("access_token"),
        //     exercise_group_id: this.state.ExerciseGroupsIdUpdate,
        //     exercise_id: item?.id,
        //     exercise_name:
        //       this.state.exerciseNameUpdate === ""
        //         ? item?.exercise
        //         : this.state.exerciseNameUpdate,
        //     exercise_description:
        //       this.state.exerciseDescriptionUpdate === ""
        //         ? item?.description
        //         : this.state.exerciseDescriptionUpdate,
        //     exercise_video:
        //       this.state.exercise_media_update === ""
        //         ? item?.video
        //         : this.state.exercise_media_update,
        //     api_action: "update",
        //   },
        //   true
        // );
        if (res.data.code === 200) {
          toast.success(res.data.message);
          const index = this.state.selectedGroupExercises?.findIndex(
            (item) => item?.id === res?.data?.data?.id
          );
          let temp = [...this.state?.selectedGroupExercises];
          temp[index] = res?.data?.data;
          // window.location.reload(false);
          const exerciseGroupIndex = this.state.ExerciseGroups?.findIndex(
            (item) => item?.id == res?.data?.data?.exercise_group_id
          );
          let exerciseGroupTemp = [...this.state.ExerciseGroups];
          exerciseGroupTemp[exerciseGroupIndex].exercises = temp;

          this.setState({
            ExerciseDescriptionUpdateError: "",
            ExerciseNameUpdateError: "",
            ExerciseVideoUpdateError: "",
            exercise_media_update: null,
            selectedGroupExercises: temp,
            ExerciseGroups: exerciseGroupTemp,
          });
        }
        // await this.admin_exercise_settings();
        // this.setSelectedExercise(this.state.pickerValue);
        // console.log(
        //   "this is selectedGroupExercises array",
        //   this.state.selectedGroupExercises
        // );
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({
          updateWorkoutExerciseLoading: false,
          exercise_media_update: null,
        });
        close();
      }
    }
  };

  validationUpdateWorkoutExercise = () => {
    let ExerciseNameUpdateError = "";
    // let ExerciseDescriptionUpdateError = "";
    // let ExerciseVideoUpdateError = "";

    if (!this.state.exerciseNameUpdate) {
      ExerciseNameUpdateError = toast.error("Name Field is Required.");
    }

    // if (!this.state.exerciseDescriptionUpdate) {
    //   ExerciseDescriptionUpdateError = toast.error(
    //     "Description Field is Required."
    //   );
    // }

    // if (!this.state.exercise_media_update) {
    //   ExerciseVideoUpdateError = toast.error("Video Field is Required.");
    // }

    if (
      ExerciseNameUpdateError
      // ExerciseDescriptionUpdateError ||
      // ExerciseVideoUpdateError
    ) {
      this.setState({
        ExerciseNameUpdateError,
        // ExerciseDescriptionUpdateError,
        // ExerciseVideoUpdateError,
      });
      return false;
    } else {
      return true;
    }
  };

  // Delete Workout Exercise
  deleteWorkoutExercise = async () => {
    this.setState({ deleteWorkoutExerciseLoading: true });
    try {
      const res = await standardPostApi(
        "create_workout_exercise",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          exercise_group_id: this.state.exerciseGroupID,
          exercise_id: this.state.exerciseId,
          exercise_name: this.state.exerciseNameUpdate,
          exercise_description: this.state.exerciseDescriptionUpdate,
          exercise_video: " ",
          api_action: "delete",
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("THIS IS RESPONSE OF DELETE EXERCISE", res.data);
        toast.success(res.data.message);
        await this.hideDeleteWorkoutExerciseModal();
        const index = this.state?.selectedGroupExercises?.findIndex(
          (item) => item?.id === res?.data?.data?.id
        );
        // temp.splice(index, 1);
        const exerciseGroupIndex = this.state.ExerciseGroups?.findIndex(
          (item) => item?.id == res?.data?.data?.exercise_group_id
        );
        let exerciseGroupTemp = [...this.state.ExerciseGroups];
        exerciseGroupTemp[exerciseGroupIndex].exercises.splice(index, 1);
        // await this.admin_exercise_settings();
        // this.setSelectedExercise(this.state.pickerValue);
        // window.location.reload(false);
        this.setState({
          ExerciseGroups: exerciseGroupTemp,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ deleteWorkoutExerciseLoading: false });
    }
  };
  debounceLog = debounce(
    (text) => this.setState({ debounceValue: text }),
    1000
  );
  handelSearch = (e) => {
    this.setState({ searchText: e.target.value });
    this.debounceLog(e.target.value);
  };
  render() {
    const coachRoles = localStorage.getItem("access_role");
    return (
      <div className="loader_sec">
        <CoachHeader />
        {this.state.loding ? (
          <LoaderWrapper />
        ) : (
          <div className="dashboard-wrapper">
            <section className="myteams_wrapper">
              <div className="inner_teamsection_1">
                <div className="row">
                  <h2
                    className="col-6"
                    style={{
                      color: "white",
                      // textAlign: "center",
                      fontSize: "22px",
                      marginTop: "12px",
                      marginBottom: "30px",
                    }}
                  >
                    <span>
                      {coachRoles === "Coach"
                        ? `Exercise Settings For ${
                            localStorage.getItem("access_role") === "Coach"
                              ? "Coach"
                              : localStorage.getItem("access_role") ===
                                "S&C Coach"
                              ? "S&C Coach"
                              : "Admin"
                          }`
                        : "Exercise Settings"}
                    </span>
                  </h2>
                  <div className="col-6 ">
                    <CustomInput
                      className={"col-12 p-0"}
                      type="search"
                      placeholder={"Search"}
                      name="planSearchTxt"
                      value={this.state.searchText}
                      onChange={this.handelSearch}
                      search
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{ marginBottom: "5%" }}
                      id="coach-portal-content"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.ExerciseGroupsSelectpicker.length > 0 &&
                          this.state.ExerciseGroups.length > 0 ? (
                            <div className="row">
                              <div className="col-md-4">
                                <ExerciseWorkoutGroupSectionForCoach
                                  ExerciseGroupsSelectpicker={
                                    this.state.ExerciseGroupsSelectpicker
                                  }
                                  value={
                                    (this.state.addWorkoutExerciseField,
                                    this.state.workoutExerciseGroupUpdate)
                                  }
                                  onChange={(e) => this.onChange(e)}
                                  addWorkoutExerciseGroup={() =>
                                    this.addWorkoutExerciseGroup()
                                  }
                                  visibleListGroupExercise={
                                    this.state.visibleListGroupExercise
                                  }
                                  visibleNewListGroupExercise={
                                    this.state.visibleNewListGroupExercise
                                  }
                                  updateWorkoutExerciseGroup={(item) =>
                                    this.updateWorkoutExerciseGroup(item)
                                  }
                                  showModal={(item) => this.showModal(item)}
                                  show={this.state.show}
                                  hideModal={() => this.hideModal()}
                                  deleteWorkoutExerciseGroup={() =>
                                    this.deleteWorkoutExerciseGroup()
                                  }
                                  addWorkoutExerciseGroupReset={
                                    this.state.addWorkoutExerciseGroup
                                  }
                                />
                              </div>

                              <div
                                className="col-md-8"
                                id="admin-exercises-container"
                              >
                                <ExerciseRightSectionForCoach
                                  ExerciseGroups={this.state.ExerciseGroups}
                                  onHandel={(e) => {
                                    this.onHandel(e);
                                  }}
                                  ExerciseGroupsSelectpicker={
                                    this.state.ExerciseGroupsSelectpicker
                                  }
                                  VideoUploadProgress={
                                    this.state.VideoUploadProgress
                                  }
                                  onChange={(e) => this.onChange(e)}
                                  onChangeFile={(e) => this.onChangeFile(e)}
                                  onChangeUpdateFile={(e) =>
                                    this.onChangeUpdateFile(e)
                                  }
                                  value={[
                                    this.state.ExerciseName,
                                    this.state.ExerciseDescription,
                                    this.state.exerciseVideos,
                                    this.state.exerciseNameUpdate,
                                    this.state.exerciseDescriptionUpdate,
                                    this.state.exerciseVideoUpdate,
                                    this.state.ExerciseGroupsIdUpdate,
                                    this.state.youtube_link,
                                    this.state.type_media_update,
                                    this.state.exercise_media_update,
                                    this.state.exercise_media,
                                    this.state.deleteWorkoutExerciseLoading,
                                  ]}
                                  addWorkoutExercise={(close) =>
                                    this.addWorkoutExercise(close)
                                  }
                                  exercise_group_id={
                                    this.state.exercise_group_id
                                  }
                                  selectedGroupExercises={
                                    this.state.selectedGroupExercises
                                  }
                                  updateWorkoutExercise={(item, close) => {
                                    this.updateWorkoutExercise(item, close);
                                  }}
                                  updateWorkoutExerciseLoading={
                                    this.state?.updateWorkoutExerciseLoading
                                  }
                                  deleteExerciseModal={
                                    this.state.deleteExerciseModal
                                  }
                                  showDeleteWorkoutExerciseModal={(item) =>
                                    this.showDeleteWorkoutExerciseModal(item)
                                  }
                                  hideDeleteWorkoutExerciseModal={() =>
                                    this.hideDeleteWorkoutExerciseModal()
                                  }
                                  deleteWorkoutExercise={() =>
                                    this.deleteWorkoutExercise()
                                  }
                                  exerciseNameReset={this.state.ExerciseName}
                                  exerciseDescriptionReset={
                                    this.state.ExerciseDescription
                                  }
                                  exerciseVideoReset={this.state.exerciseVideos}
                                  ExerciseGroupsId={this.state.ExerciseGroupsId}
                                  isLoading={this.state.isLoading}
                                  ExerciseGroupsType={
                                    this.state.ExerciseGroupsType
                                  }
                                  setState={this.setState}
                                  updateExercise={(item) =>
                                    this.updateExercise(item)
                                  }
                                  onChangeUpdate={(e) => this.onChangeUpdate(e)}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <NoDataFound
                                height={250}
                                width={250}
                                text="No data avalable yet"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default ExerciseSettingForCoach;
