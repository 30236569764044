import { Button } from "@material-ui/core";
import React, { Component } from "react";
import AddResultSetModal from "./AddResultSetModal";
import EditIcon from "@material-ui/icons/Edit";
import ViewPlayerResultSet from "./ViewPlayerResultSet";

export class ResultTable extends Component {
  render() {
    const {
      selectPickerObject,
      togglerResultSetModal,
      parentState,
      homeTeamObj,
      awayTeamObj,
      onChange,
      handelCrateStatisticResult,
      statisticResultArray,
      onSelectChange,
      closeReseltSetModal,
      toggleViewPlayerModal,
      playerResultList,
    } = this.props;

    const coachRoles = localStorage.getItem("access_role");

    return (
      <div className="table-responsive table_react_cursor">
        <table className="table table-condensed coachList-table">
          <thead>
            <tr className="react_Testing_Table">
              <th style={{ padding: "0.78rem 0.76rem" }}> Criteria</th>
              <th style={{ padding: "0.78rem 0.76rem" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Home Team{" "}
                  {homeTeamObj?.team_name && `(${homeTeamObj?.team_name})`}
                </span>
              </th>
              <th style={{ padding: "0.78rem 0.76rem" }}>
                {" "}
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Away Team {awayTeamObj?.name && `(${awayTeamObj?.name})`}
                </span>{" "}
              </th>
              {coachRoles === "Assistant Coach" ? null : (
                <th style={{ padding: "0.78rem 0.76rem" }}>
                  {" "}
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></span>{" "}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {statisticResultArray?.length !== 0 &&
              statisticResultArray?.map((item) => {
                return (
                  <tr key={item?.id} className="react_Testing_Table">
                    <td
                      style={{
                        padding: "0.78rem 0.76rem",
                      }}
                    >
                      {item?.criteria?.name}
                    </td>
                    <td
                      style={{
                        padding: "0.78rem 0.76rem",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>{item?.criteria?.home_team?.result}</span>
                      </span>
                    </td>{" "}
                    <td
                      style={{
                        padding: "0.78rem 0.76rem",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span> {item?.criteria?.away_team?.result} </span>
                      </span>
                    </td>{" "}
                    {coachRoles === "Assistant Coach" ? null : (
                      <td
                        style={{
                          padding: "0.78rem 0.76rem",

                          width: "314px",
                        }}
                      >
                        <div
                          onClick={() => togglerResultSetModal(item)}
                          style={{
                            color: "#f75f03",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          Edit
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <AddResultSetModal
          onHide={closeReseltSetModal}
          show={parentState.resultSetModal}
          homeTeamObj={homeTeamObj}
          awayTeamObj={awayTeamObj}
          parentState={parentState}
          onChange={onChange}
          handelCrateStatisticResult={handelCrateStatisticResult}
          onSelectChange={onSelectChange}
          playerListObj={this.props.playerListObj}
        />

        <ViewPlayerResultSet
          show={parentState?.viewPlayerModal}
          onHide={toggleViewPlayerModal}
          playerResultList={playerResultList}
          statisticResultArray={statisticResultArray}
        />
      </div>
    );
  }
}

export default ResultTable;
