import React from "react";
import { standardPostApi } from "../../container/API/ApiWrapper";
import AthleteSectionSecondGraph from "../Charts/AthleteSectionSecondGraph";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// moment and timestamp in react js
import moment from "moment";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { Link } from "react-router-dom";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import { BarChartComponent } from "component/chart/Chart";
import CustomButton from "component/customButton/CustomButton";
import NoDataFound from "component/lottiLoader/LottiLoader";
import LoaderWrapper from "container/Loader/LoaderWrapper";

class WeeklyAverageIntensity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.user_id,
      start_date: "",
      end_date: "",
      reportSelectPicker: [],
      graphLabels: [],
      graphData: [],
      workoutLocation: "",
      reportTypeId: "",
      isLoading: false,
    };
  }

  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
    // console.log("this is update state", this.state);
  }

  componentDidMount() {
    // this.athlete_workout_activity_report();
    this.athleteWorkoutActivityReportForSelectPicker();
  }

  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ start_date: date });
    // console.log("The start date selected is ", this.state.date);
  };

  endDateChange = async (endDate) => {
    await this.setState({ end_date: endDate });
    // console.log("The end  date .... is ", this.state.endDate);
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  // onHandel = async (e) => {
  //   console.log("this is Event==>", e.target.value);

  //   this.state.reportSelectPicker.forEach((item) => {
  //     if (e.target.value == item.id) {
  //       this.setState({ pickerName: item.label });
  //     }
  //   });
  //   console.log("this is name =====>>", this.state.pickerName);
  //   this.athlete_workout_activity_report(e.target.value);
  // };

  // athlete_workout_activity_report = async (report_type_id) => {
  //   const start_date = moment(this.toTimestamp(this.state.date) * 1000)
  //     .format("YYYY-MM-DD")
  //     .toString();

  //   const end_date = moment(this.toTimestamp(this.state.endDate) * 1000)
  //     .format("YYYY-MM-DD")
  //     .toString();

  //   try {
  //     const res = await standardPostApi(
  //       "athlete_workout_activity_report",
  //       undefined,
  //       {
  //         access_token: await localStorage.getItem("access_token"),
  //         report_type_id: report_type_id,
  //         access_user_id: this.state.user_id,
  //         week_start: start_date,
  //         week_end: end_date,
  //       },
  //       true
  //     );
  //     if ((res.data.code = 200)) {
  //       console.log(
  //         "RESPONSE OF athlete_workout_activity_report",
  //         res.data?.data?.ReportTypeSelect?.pickerArray
  //       );

  //       res.data.data.WeekDetails.forEach((item) => {
  //         this.setState({
  //           workoutLocation: item.annual_training_program_location,
  //         });
  //       });

  //       let graphLabels = [];
  //       let graphData = [];

  //       res.data.data.WeekDetails.forEach((item) => {
  //         graphLabels.push(item.week_number);
  //       });

  //       res.data.data.WeekDetails.forEach((item) => {
  //         graphData.push(item.final_result);
  //       });

  //       await this.setState({
  //         reportSelectPicker: res.data?.data?.ReportTypeSelect?.pickerArray,
  //         graphLabels: graphLabels,
  //         graphData: graphData,
  //         pickerName: res.data?.data?.ReportTypeSelect?.pickerArray[12]?.id,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  athleteWorkoutActivityReportForSelectPicker = async () => {
    const start_date = moment().format("YYYY-MM-DD");

    const end_date = moment().format("YYYY-MM-DD");
    this.setState({ isLoading: true });
    try {
      const res = await standardPostApi(
        "athlete_workout_activity_reports",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: this.props.user_id,
          week_start_date: start_date,
          week_end_date: end_date,
        },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          reportSelectPicker: [
            ...res.data.data.ReportTypeSelect.pickerArray,
            ...res.data.data.ReportTypeSelect.other_activity_pickerArray.flat(),
          ],
        });
        // console.log(
        //   "Response athleteWorkoutActivityReportForSelectPicker",
        //   res.data?.data?.ReportTypeSelect?.pickerArray[12]
        // );
        let temp = res.data.data.WeekDetails;
        temp.forEach((item) => {
          this.setState({
            workoutLocation: item.annual_training_program_location,
          });
        });

        let graphLabels = Object.keys(temp?.[0]) ?? [];
        let graphData = [];

        // temp.forEach((item) => {
        //   graphLabels.push(item.week_number);
        // });

        graphLabels.forEach((item) => {
          console.log("labels", item);
          graphData.push(temp?.[0]?.[item]?.final_result);
        });

        this.setState({
          pickerName: res.data?.data?.ReportTypeSelect?.pickerArray[0]?.id,
          graphLabels: graphLabels,
          graphData: graphData,
        });
      }
    } catch (error) {
      console.error(
        "error of athleteWorkoutActivityReportForSelectPicker",
        error
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onHandel = async (e) => {
    this.setState({ pickerName: e.target.value });
    this.athleteWorkoutActivityReportAtOnchange(e.target.value);
  };

  athleteWorkoutActivityReportAtOnchange = async (report_type_id) => {
    this.setState({ graphData: [], graphLabels: [] });
    const start_date = moment().format("YYYY-MM-DD");

    const end_date = moment().format("YYYY-MM-DD");
    this.setState({ isLoading: true });
    try {
      const res = await standardPostApi(
        "athlete_workout_activity_reports",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: this.state.user_id,
          report_type_id: report_type_id,
          // week_start_date: start_date,
          // week_end_date: end_date,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log(
        //   "Res athleteWorkoutActivityReportAtOnchange",
        //   res.data?.data
        // );

        // temp.forEach((item) => {
        //   this.setState({
        //     workoutLocation: item.annual_training_program_location,
        //   });
        // });

        let temp = res.data.data.WeekDetails;
        let graphLabels = Object.keys(temp?.[0]) ?? [];
        let graphData = [];
        graphLabels.forEach((item) => {
          graphData.push(temp?.[0]?.[item]?.final_result);
        });

        // temp.forEach((item) => {
        //   graphLabels.push(item.week_number);
        // });

        this.setState({
          reportSelectPicker: [
            ...res.data?.data?.ReportTypeSelect?.pickerArray,
            ...res.data?.data?.ReportTypeSelect?.other_activity_pickerArray?.flat(),
          ],
          graphLabels: graphLabels,
          graphData: graphData,
        });
      }
    } catch (error) {
      console.error("error of athleteWorkoutActivityReportForSelectPicker");
    } finally {
      this.setState({ isLoading: false });
    }
  };

  athleteWorkoutActivityReportWithDate = async () => {
    this.setState({ graphData: [], graphLabels: [] });
    const start_date = moment(this.state.start_date).format("YYYY-MM-DD");

    const end_date = moment(this.state.end_date).format("YYYY-MM-DD");
    this.setState({ isLoading: true });
    try {
      const res = await standardPostApi(
        "athlete_workout_activity_reports",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: this.state.user_id,
          report_type_id: this.state.pickerName,
          week_start_date: start_date,
          week_end_date: end_date,
        },
        true
      );

      if (res.data.code === 200) {
        let temp = res.data.data.WeekDetails;

        // temp.forEach((item) => {
        //   this.setState({
        //     workoutLocation: item.annual_training_program_location,
        //   });
        // });

        let graphLabels = Object.keys(temp?.[0]) ?? [];
        let graphData = [];
        // temp.forEach((item) => {
        //   graphLabels.push(item.week_number);
        // });

        graphLabels.forEach((item) => {
          graphData.push(temp?.[0]?.[item]?.final_result);
        });

        this.setState({
          reportSelectPicker: [
            ...res.data?.data?.ReportTypeSelect?.pickerArray,
            ...res.data?.data?.ReportTypeSelect?.other_activity_pickerArray?.flat(),
          ],
          graphLabels: graphLabels,
          graphData: graphData,
        });
      }
    } catch (error) {
      console.error("error of athleteWorkoutActivityReportWithDate", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    console.log("graph", this.state.graphData);
    return (
      <div className="week_section mt-3">
        <h6 className="pb-2">Weekly Repots Graph</h6>
        <div className="row">
          <div className="col-lg-6  col-md-6 col-sm-6 mb-4">
            <select
              className="select_class"
              value={this.state.pickerName}
              onChange={this.onHandel}
            >
              <option value="">Report Type Select</option>
              {this.state.reportSelectPicker &&
                this.state.reportSelectPicker.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6  col-md-6 col-sm-6 mb-4">
            <select
              name=""
              className="select_class"
              defaultValue={this.state.workoutLocation ?? ""}
              selected
              onChange={this.handelChange}
            >
              <option value="">Workout Location</option>
              <option value="Home">Home</option>
              <option value="Gym">Gym</option>
            </select>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
            <InputDateTimePicker
              style={{ width: "100%" }}
              value={this.state.start_date}
              onChange={this.handleDateChange}
              placeholder="Start Date"
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="d-flex">
              <InputDateTimePicker
                style={{ width: "100%" }}
                value={this.state.end_date}
                onChange={this.endDateChange}
                className={"mr-2"}
                placeholder="End Date"
              />

              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                // onClick={() => this.athlete_workout_activity_report()}
                onClick={() => this.athleteWorkoutActivityReportWithDate()}
              >
                <CheckCircleRoundedIcon
                  style={{
                    color: "var(--safetyOrange)",
                    fontSize: "40px",
                    cursor: "pointer",
                  }}
                />
                {/* <i className="fa fa-check" aria-hidden="true"></i> */}
              </button>
            </div>
          </div>
        </div>
        {this.state.isLoading ? (
          <LoaderWrapper />
        ) : this.state.graphData.length > 0 ? (
          <BarChartComponent
            labels={this.state.graphLabels}
            data={this.state.graphData}
            title={this.state.workoutLocation ?? ""}
            isLoading={this.state.isLoading}
          />
        ) : (
          <NoDataFound height={250} width={250} text="No data available " />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            // marginBottom: "70px",
          }}
        >
          <Link
            to="/selfscreening/analyseworkoutathlete"
            style={{ textDecoration: "none" }}
          >
            <CustomButton>Analyse Workout</CustomButton>
            {/* <div onClick={() => alert("Coming soon!")}> */}
            {/* <button
              // type="submit"
              className="btn btn-default forgot_Password_Email_button"
              style={{ padding: "6px 52px" }}
            >
              Analyse Workout
            </button> */}
            {/* </div> */}
          </Link>
        </div>
      </div>
    );
  }
}

export default WeeklyAverageIntensity;
