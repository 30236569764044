import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import moment from "moment";
import React, { Component } from "react";
import "../CoachAddEvent.css";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "component/customButton/CustomButton";

export class ViewEventModal extends Component {
  render() {
    const {
      state,
      onChange,
      handelStartTimeChange,
      handelEndTimeChange,
      calendar_events_update,
      closeModal,
      toggleDeleteModal,
    } = this.props;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={() => closeModal()}
          animation={true}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="teamcreate">
                View Events
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="form-group"
              style={{ border: "none", padding: "10px 20px 0px" }}
            >
              <CustomInput
                type="text"
                placeholder="Event Name"
                value={state?.editEventName}
                name="editEventName"
                onChange={(e) => onChange(e)}
                className={"mb-3"}
                error={state.nameError}
              />
              <div className="my-3 d-flex">
                <InputDateTimePicker
                  value={state.editStartTime}
                  onChange={(date) => handelStartTimeChange(date)}
                  error={state.startTimeError}
                  type="time"
                  className={"mr-2"}
                />

                <InputDateTimePicker
                  value={state.editEndTime}
                  onChange={(date) => handelEndTimeChange(date)}
                  error={state.endTimeError}
                  type="time"
                />
              </div>
              <CustomInput
                rows="3"
                cols="50"
                name="editDescription"
                value={state.editDescription}
                onChange={(e) => onChange(e)}
                placeholder="Description"
                type="textarea"
                error={state.descriptionError}
              />
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              type="outlined"
              className="event_button"
              onClick={() => toggleDeleteModal()}
              disabled={state?.isDeleteLoading}
              isLoading={state?.isDeleteLoading}
            >
              Delete
            </CustomButton>
            {/* <button
              type='button'
              className='Model_btn_Cancel '
              data-dismiss='modal'
              style={{
                background: 'red',
                border: '1px solid red',
                color: '#fff',
              }}
              onClick={() => toggleDeleteModal()}
              disabled={state?.isDeleteLoading}
            >
              Delete
              {state?.isDeleteLoading && (
                <i
                  className='fa fa-spinner fa-pulse'
                  style={{ marginLeft: '7px', color: '#fff' }}
                />
              )}
            </button>{' '} */}
            <CustomButton
              onClick={() => calendar_events_update()}
              disabled={state?.isEditLoading}
              isLoading={state?.isEditLoading}
            >
              Save
            </CustomButton>
            {/* <button
              type='button'
              className='Model_btn '
              data-dismiss='modal'
              // onClick={props.delete_annual_training_program_workout}
              onClick={() => calendar_events_update()}
              style={{ padding: '5px ​36p' }}
              disabled={state?.isEditLoading}
            >
              Save{' '}
              {state?.isEditLoading && (
                <i
                  className='fa fa-spinner fa-pulse'
                  style={{ marginLeft: '7px', color: '#fff' }}
                />
              )}
            </button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ViewEventModal;
