import React, { useState } from "react";
import "./CustomInput.scss";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SvgIcon from "component/SvgIcon";

function InputDateTimePicker({
  type = "date",
  placeholder,
  value,
  onChange,
  error,
  maxDate,
  minDate,
  className,
  style,
}) {
  const [open, setOpen] = useState(false);
  // console.log({ value });
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {type === "time" ? (
        <KeyboardTimePicker
          placeholder={placeholder}
          mask="__:__ _M"
          value={value}
          ampm={true}
          onChange={onChange}
          // variant='inline'
          className={`date_time_picker_input ${className}`}
          open={open}
          keyboardIcon={<AccessTimeIcon style={{ color: "#FFF" }} />}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          error={!!error}
          helperText={error}
          style={style}
        />
      ) : (
        <KeyboardDatePicker
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          format="dd/MM/yyyy"
          className={`date_time_picker_input ${className}`}
          keyboardIcon={<SvgIcon name="calender" />}
          error={!!error}
          helperText={error}
          maxDate={maxDate}
          minDate={minDate}
          style={style}
        />
      )}
    </MuiPickersUtilsProvider>
  );
}

export default InputDateTimePicker;
