import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";
import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import React from "react";
import SetsResets from "../../component/AtpINSeison/SetsResets";
import { textTruncate } from "../../helpers/textTruncate";

class WorkoutGroups extends React.Component {
  render() {
    const { descriptionGroup } = this.props.value;
    const {
      resetSetsType,
      addNewWorkoutGroup,
      setTypeArray,
      numberOfExercise,
      customSetType,
    } = this.props;
    console.log(this.props.setTypeArray);

    return (
      <div
        className="col-lg-12 col-md-12 col-sm-12 p-3 mt-3"
        style={{ backgroundColor: "var(--blackRussian)" }}
      >
        <div className="week_heading">Workouts Groups</div>

        <div id="workout-builder-workout-groups-panel">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
              <CustomInput
                name="descriptionGroup"
                value={(descriptionGroup, this.props.descriptionGroup)}
                onChange={this.props.onChange}
                error={this.props.descriptionGroupError}
                placeholder="Description"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
              <select
                className="select_class"
                name="setsType"
                value={(this.props?.setsType, resetSetsType)}
                onChange={this.props.hangelExerciseGroup}
              >
                <option value="" disabled selected>
                  Select an Item{" "}
                </option>
                {setTypeArray?.map((item) => (
                  <option key={item?.id} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </select>
              {this.props.setsTypeError?.trim()?.length ? (
                <p className="react_validation">{this.props.setsTypeError}</p>
              ) : null}
            </div>

            {resetSetsType === "0" && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                  <CustomInput
                    placeholder="Custom sets type"
                    value={customSetType}
                    onChange={this.props.onChange}
                    error={this.props.customSetTypeError}
                    name="customSetType"
                  />
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                  <CustomInput
                    placeholder="Number of Exercise"
                    value={numberOfExercise}
                    onChange={this.props.onChange}
                    error={this.props.numberOfExerciseError}
                    name="numberOfExercise"
                    type="number"
                  />
                </div>
              </>
            )}
            {Number(
              setTypeArray?.find((x) => x.value == Number(resetSetsType))
                ?.number_of_excercise
            ) > 1 ||
            (Number(resetSetsType) == 0 && Number(numberOfExercise) > 1) ? (
              <SetsResets
                onChange={this.props.onChange}
                value={(this.props.setsGroup, this.props.restGroup)}
                onKeyPress={this.props.onKeyPress}
                setsGroupError={this.props.setsGroupError}
                restGroupError={this.props.restGroupError}
                setsGroup={this.props.setsGroup}
                cleanRestGroup={this.props.cleanRestGroup}
              />
            ) : null}

            <div className="col-lg-6 col-md-6 col-sm-6 mb-3 flex">
              <CustomButton
                flex={1}
                onClick={this.props.annual_training_program_workout_group}
                isLoading={addNewWorkoutGroup}
              >
                Add New Workout Group
              </CustomButton>
            </div>
          </div>

          {this.props.visiblePreWorkout_Group ? (
            <div className="row">
              {this.props.workoutGroupData &&
                this.props.workoutGroupData.map((item) => {
                  return (
                    <div
                      className="col-lg-4 col-md-4 col-sm-6 mt-4"
                      key={item?.id + ""}
                    >
                      <ProgramViewCard
                        containerBackCardStyle={{
                          backgroundColor: "var(--jaguar)",
                        }}
                        buttonText="Delete"
                        text={item?.group_name}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.showDeleteWorkoutGroup(item);
                        }}
                        Icon={"edit"}
                        copyIconClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.updateWorkoutGroupModal(item);
                        }}
                        cardClick={() => {
                          this.props.WorkoutGroupsShow(item);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default WorkoutGroups;
