import React, { useState, useRef, useEffect, memo } from "react";
// import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
// import { LightTooltip } from 'components/tooltip/LightTooltip';
function SvgIcon({
  name,
  className,
  svgFolderName,
  onCompleted,
  onError,
  style,
  ...rest
}) {
  const { error, loading, IconSvg } = useDynamicSVGImport(name, svgFolderName, {
    onCompleted,
    onError,
  });

  if (error) {
    return (
      // <LightTooltip title='Error on icon loading!' placement='top'>
      //   <ReportGmailerrorredIcon style={{ color: 'var(--red)' }} />
      // </LightTooltip>
      null
    );
  }
  if (loading) {
    return "";
  }
  if (IconSvg) {
    return <IconSvg {...rest} style={{ ...style }} className={className} />;
  }
  return null;
}

function useDynamicSVGImport(name, svgFolderName, options = {}) {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { onCompleted, onError } = options;
  useEffect(() => {
    setLoading(true);

    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../Custom/${
              svgFolderName ? `${svgFolderName}/${name}.svg` : `svg/${name}.svg`
            }`
          )
        ).default;

        if (onCompleted) {
          onCompleted(name, ImportedIconRef.current);
        }
      } catch (err) {
        console.log(err, "error");
        if (onError) {
          onError(err);
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name, onCompleted, onError, svgFolderName]);

  return { error, loading, IconSvg: ImportedIconRef.current };
}

export default memo(SvgIcon);
