import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import "./CoachPlayerInner.scss";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "component/customButton/CustomButton";
import CoachHeader from "container/PublicLayout/CoachHeader";
import { ImgType } from "helpers/FindImgType";
import User_Placeholder from "Custom/images/user_placeholder.png";
import React from "react";
import { useState } from "react";
import CustomModal from "component/customModal/CustomModal";
import DeleteModal from "component/deleteModal/DeleteModal";
import Footer from "container/PublicLayout/Footer";
import { useHistory } from "react-router-dom";

import { standardPostApiJsonBased } from "container/API/ApiWrapper";

function TeamCoachInner(props) {
  const { push } = useHistory();
  console.log("props", props);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow((prvs) => !prvs);
  };
  const onHide = () => {
    setShow((prvs) => !prvs);
  };
  const onDeleteCoach = async () => {
    const data = {
      access_token: await localStorage.getItem("access_token"),
      coach_id: props?.location?.state?.item?.id,
    };
    setIsLoading(true);
    try {
      const res = await standardPostApiJsonBased(
        "delete_coach",
        {},
        data,
        true
      );
      if (res.data.code === 200) {
        console.log("res", res.data);
        // console.log('criteriaHeadName', res.data);
        // this.fetchMedicalStatusWithCriterias();
        // this.toggleCriteriaModal();
        // successToast(res.data.message);
        push({ pathname: "/myteamwrapper" });
      }
    } catch (error) {
      console.error("create medical error", error);
    } finally {
      setIsLoading(false);
      //   this.setState({ crateCriteriaLoader: false });
    }
  };
  return (
    <div className="loader_sec">
      <CoachHeader />

      <div
        className="container-fluid coach_player_container pt-5"
        style={{ backgroundColor: "var(--jaguar)" }}
      >
        <CustomBreadcrumb
          navList={[
            {
              name: "My Team",
              link: "/",
            },
            {
              name: `${props?.location?.state?.item?.first_name} ${props?.location?.state?.item?.last_name}`,
              link: "/",
              isActive: true,
            },
          ]}
          heading="My Players"
        />
        <div className="d-flex align-items-center justify-content-center">
          <div
            className="player_card"
            style={{
              backgroundColor: "var(--blackRussian)",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              minWidth: "250px",
              margin: "10px 10px 20px",
              maxWidth: "500px",
            }}
          >
            <div>
              <img
                src={
                  props?.location?.state?.item?.profile_image
                    ? ImgType(props?.location?.state?.item?.profile_image)
                      ? props?.location?.state?.item?.profile_image
                      : User_Placeholder
                    : User_Placeholder
                }
                alt="no_image"
                className="img-fluid profile_picture"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  objectFit: "contain",
                  objectFit: "cover",
                  border: "1px solid",
                  marginBottom: "10px",
                }}
              />
            </div>
            <p
              className="profile_name"
              style={{
                color: "#FFF",
                textAlign: "center",
                marginBottom: "0px",
                paddingBottom: "10px",
              }}
            >
              {props?.location?.state?.item?.first_name}{" "}
              {props?.location?.state?.item?.last_name}
            </p>
            <div className="d-flex align-items-center">
              {/* <Link
                  to={{
                    pathname: `/teamPlayerEvent/${this.props.match.params.id}/${this.props.match.params.playerId}/${this.props.match.params.teamname}`,
                    state: this.props.location.state,
                  }}
                >
                  <img
                    src={ColorCalenderSvg}
                    alt="color-svg"
                    className="img-fluid mr-4"
                  />
                </Link> */}

              <CustomButton onClick={() => showModal()}>
                Remove Coach
              </CustomButton>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-2" />
          <div className="col-lg-8" style={{ textAlign: "center" }}>
            <div className="row px-5">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <span className="edit-madical-status-span">Email</span>
                  <p className="edit-madical-status-p">
                    {props?.location?.state?.item?.email ?? "N/A"}
                    {/* {parentState?.userStatusObj?.team_name ?? "N/A"} */}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <span className="edit-madical-status-span">Address</span>
                  <p className="edit-madical-status-p">
                    {props?.location?.state?.item?.address ?? "N/A"}
                    {/* {parentState?.userStatusObj?.team_name ?? "N/A"} */}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <span className="edit-madical-status-span">Phone Number</span>
                  <p className="edit-madical-status-p">
                    {props?.location?.state?.item?.phone ?? "N/A"}
                    {/* {parentState?.userStatusObj?.team_name ?? "N/A"} */}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <span className="edit-madical-status-span">
                    Date of Birth
                  </span>
                  <p className="edit-madical-status-p">
                    {props?.location?.state?.item?.dob ?? "N/A"}
                    {/* {parentState?.userStatusObj?.team_name ?? "N/A"} */}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <span className="edit-madical-status-span">Role</span>
                  <p className="edit-madical-status-p">
                    {props?.location?.state?.item?.role ?? "N/A"}
                    {/* {parentState?.userStatusObj?.team_name ?? "N/A"} */}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <span className="edit-madical-status-span">
                    Specialization
                  </span>
                  <p className="edit-madical-status-p">
                    {props?.location?.state?.item?.specialization ?? "N/A"}
                    {/* {parentState?.userStatusObj?.team_name ?? "N/A"} */}
                  </p>
                </div>
              </div>
              {/* {listData({
                  props: this.props,
                  showModal: this.showModal,
                  toggleViewSpacificReportModal:
                    this.toggleViewSpacificReportModal,
                })?.map((item, index) => (
                  <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                    <ButtonCard item={item} />
                  </div>
                ))} */}
            </div>
          </div>
          <div className="col-lg-2" />
        </div>
      </div>
      <DeleteModal
        show={show}
        onHide={onHide}
        onSubmit={onDeleteCoach}
        isLoading={isLoading}
      />
      <Footer />
    </div>
  );
}

export default TeamCoachInner;
