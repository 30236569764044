import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function CustomModal({
  actionElement,
  headingTitle,
  children,
  show,
  className,
  modalSize,
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(show);
  }, [show]);
  const handleClose = () => setIsOpen(false);
  return (
    <div className={className}>
      {actionElement ? (
        <div onClick={() => setIsOpen(true)} className="flex">
          {actionElement}{" "}
        </div>
      ) : null}

      <Modal
        show={isOpen}
        onHide={handleClose}
        backdropClassName="modal_backdrop"
        contentClassName="modal_container"
        centered
        size={modalSize}
      >
        <Modal.Body>
          <div className="modal-header">
            <h5 className="modal-title" id="userdetailTitle">
              {headingTitle}
            </h5>
            <button
              type="button"
              className="modal_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {React.cloneElement(children, {
              handleClose: handleClose,
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomModal;
