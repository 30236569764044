import CoachHeader from "container/PublicLayout/CoachHeader";
import Footer from "container/PublicLayout/Footer";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "Custom/images/celender.jpg";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import AdjustIcon from "@material-ui/icons/Adjust";
import {
  standardPostApi,
  standardPostApiJsonBased,
} from "container/API/ApiWrapper";
import { successToast } from "utils/toastMessage";
import NoDataFound from "component/lottiLoader/LottiLoader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import Buttons from "container/Coach After Login/Buttons";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import CustomButton from "component/customButton/CustomButton";
import LoaderWrapper from "container/Loader/LoaderWrapper";
import userImg from "../../../Custom/images/user_placeholder.png";
export class Attandance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: [],
      isLoading: false,
      EventId: "",
      selectPickerObject: {},
      attendanceDetails: [],
      createAttancanceLoader: false,
      attandanceLoader: false,
      sentPlayerLoader: false,
      activePlayerId: "",
      pickerName: "",
    };
  }

  componentDidMount() {
    this.fetchCalenderEvents();
  }

  fetchCalenderEvents = async (eventId, eventObj, event) => {
    // console.log("eventId", eventId, eventObj, event);
    try {
      const res = await standardPostApi(
        "get_calendar_events",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team_id: this.props.match.params.id,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("res.data?.data?.event", res.data?.data?.event);

        this.setState({
          eventList: res.data?.data?.event,
          EventId: eventId ? eventId : res.data?.data?.event[0].id,
          selectPickerObject: eventObj ? eventObj : res.data?.data?.event[0],
        });
        this.fetchAttandance(this.state.EventId);
      }
    } catch (error) {
      console.log("error of fetch calander Events", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onHandel = (e) => {
    let selectPickerObject = {};

    this.state.eventList.map((item) => {
      if (e.target.value == item.id) {
        selectPickerObject = item;
      }
    });

    this.setState({
      EventId: e.target.value,
      selectPickerObject: selectPickerObject,
      pickerName: selectPickerObject?.event_name,
    });

    this.fetchAttandance(e.target.value);
  };

  fetchAttandance = async (eventId) => {
    this.setState({ attandanceLoader: true });
    try {
      const res = await standardPostApi(
        "get_player_with_attendance",
        undefined,
        {
          access_token: localStorage.getItem("access_token"),
          // team_id: this.props.match.params.id,
          event_id: eventId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("Response of fetch attandance", res.data.data);
        this.setState({ attendanceDetails: res.data?.data?.players });
      }
    } catch (error) {
      console.error("Error of Fetch Attandance", error);
    } finally {
      this.setState({ attandanceLoader: false });
    }
  };

  handelCraeteAttandance = async (item, roll) => {
    roll === "player" && this.setState({ activePlayerId: item.id });
    const data =
      roll === "player"
        ? {
            access_token: localStorage.getItem("access_token"),
            event_id: this.state.EventId,
            // team_id: this.props.match.params.id,
            player_id: item.id,
          }
        : {
            access_token: localStorage.getItem("access_token"),
            event_id: this.state.EventId,
            team_id: this.props.match.params.id,
          };

    roll === "player"
      ? this.setState({ sentPlayerLoader: true })
      : this.setState({ createAttancanceLoader: true });

    try {
      const res = await standardPostApi(
        "create_attendance",
        undefined,
        data,
        true
      );
      if (res.data.code === 200) {
        // console.log("res of handel create attandance", res.data.data);
        successToast(res.data.message);

        roll === "player"
          ? await this.fetchCalenderEvents(
              this.state.EventId,
              this.state.selectPickerObject
            )
          : await this.fetchCalenderEvents(this.state.EventId, null);

        this.fetchAttandance(this.state.EventId);
      }
    } catch (error) {
      console.error("error of creeate attandance", error);
    } finally {
      roll === "player"
        ? this.setState({ sentPlayerLoader: false })
        : this.setState({ createAttancanceLoader: false });
    }
  };

  render() {
    const { eventList, selectPickerObject, attendanceDetails } = this.state;
    const coachRoles = localStorage.getItem("access_role");
    // console.log("EventId", this.state.EventId);

    // console.log("this.state.selectPickerObject", this.state.selectPickerObject);
    return (
      <div className="loader_sec">
        <CoachHeader />
        <div className="dashboard-wrapper">
          <section className="myteams_wrapper">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 side-buttons left-side-section">
                <Buttons
                  coachRoles={coachRoles}
                  props={this.props}
                  isActive="attendance"
                />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 right-side-section">
                <div className="d-md-flex align-items-center ">
                  <CustomBreadcrumb
                    heading="My Player"
                    className={"mb-4"}
                    navList={[
                      {
                        name: "My Team",
                        link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                      },
                      {
                        name: this.props.match.params.teamname,
                        link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                      },
                      {
                        name: "Attendance",
                      },
                    ]}
                  />
                </div>
                {/* <Buttons coachRoles={coachRoles} props={this.props} isActive="attendance"/> */}

                {this.state.isLoading && <LoaderWrapper />}
                <div className="row mt-5">
                  <div className="col-lg-9 col-md-9 col-sm-9 select-section-width">
                    <select
                      style={{ border: "1px solid" }}
                      className="select_class"
                      value={this.state.EventId}
                      onChange={this.onHandel}
                    >
                      {!this.state.isLoading &&
                        this.state.eventList.length === 0 && (
                          <option value="">Sorry! no event available</option>
                        )}
                      {eventList?.length !== 0 &&
                        eventList?.map((item) => {
                          return (
                            <option
                              className="dropdown-item dropdown-menu react_select_menu"
                              value={item.id}
                              key={item?.id}
                            >
                              {item?.event_name}
                            </option>
                          );
                        })}
                    </select>
                    {this.state.isLoading && <LoaderWrapper />}
                  </div>
                  <div className="d-flex col-lg-3 col-md-3 col-sm-3 select-button-width">
                    {this.state?.selectPickerObject?.is_mail_sent === false ? (
                      <CustomButton
                        flex={1}
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                        onClick={() => this.handelCraeteAttandance(null, null)}
                        disabled={this.state.createAttancanceLoader}
                      >
                        Send Mail to {this.props.match.params.teamname}
                      </CustomButton>
                    ) : (
                      <CustomButton
                        flex={1}
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                        disabled={true}
                      >
                        Send Mail to {this.props.match.params.teamname}
                      </CustomButton>
                    )}
                  </div>
                </div>

                {!this.state.isLoading &&
                  !this.state.attandanceLoader &&
                  this.state.attendanceDetails.length === 0 && (
                    <NoDataFound
                      height={250}
                      width={250}
                      text="No data avalable yet"
                    />
                  )}

                {this.state.attandanceLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <LoaderWrapper />
                  </div>
                ) : (
                  <div className="table-responsive mt-5 table_react_cursor">
                    {attendanceDetails?.length !== 0 && (
                      <div
                        className="d-flex mb-3"
                        style={{ fontWeight: "500", color: "white" }}
                      >
                        Attandance
                      </div>
                    )}

                    <table className="table ">
                      {attendanceDetails?.length !== 0 && (
                        <thead>
                          <tr className="react_Testing_Table">
                            <>
                              <th>Player Name</th>{" "}
                              <th>
                                <span>Status</span>
                              </th>
                              <th>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {selectPickerObject?.event_name}
                                </span>
                              </th>
                            </>

                            <th></th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {attendanceDetails?.length !== 0 &&
                          attendanceDetails?.map((item) => {
                            return (
                              <tr key={item.id} className="react_Testing_Table">
                                <td>
                                  <img
                                    src={userImg}
                                    alt="No Img.."
                                    className="table-usr-img"
                                  />
                                  {item.user_first_name} {item.user_last_name} (
                                  {item?.email})
                                </td>

                                <td>
                                  {item?.attendance?.is_available === "1" ? (
                                    <span
                                      className="text-truncate"
                                      style={{
                                        padding: "5px 12px",
                                        color: "#141223",
                                        backgroundColor: "#5BB85D",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      Accepted
                                    </span>
                                  ) : (
                                    <span
                                      className="text-truncate"
                                      style={{
                                        padding: "5px 12px",
                                        color: "#141223",
                                        backgroundColor: "#EFAD4D",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      Not Accepted
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.attendance?.is_available === "1" ? (
                                      <CheckCircleRoundedIcon
                                        style={{ color: "#5BB85D" }}
                                      />
                                    ) : (
                                      // <AdjustIcon
                                      //   style={{ color: "#5CB85C" }}
                                      // />
                                      <HighlightOffRoundedIcon
                                        style={{ color: "#EFAD4D" }}
                                      />
                                      // <AdjustIcon
                                      //   style={{ color: "#D9535F" }}
                                      // />
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    {item?.attendance?.is_email_send === "1" ? (
                                      <CustomButton
                                        style={{
                                          background: "transparent",
                                          cursor: "not-allowed",
                                          padding: "5px 40px",
                                          color: "#9F9BC9",
                                        }}
                                      >
                                        Sent
                                      </CustomButton>
                                    ) : (
                                      <CustomButton
                                        onClick={() =>
                                          this.handelCraeteAttandance(
                                            item,
                                            "player"
                                          )
                                        }
                                        disabled={
                                          this.state.sentPlayerLoader &&
                                          item.id === this.state.activePlayerId
                                        }
                                        style={
                                          this.state.sentPlayerLoader &&
                                          item.id === this.state.activePlayerId
                                            ? {
                                                background: "transparent",
                                                cursor: "wait",
                                                color: "var(--safetyOrange)",
                                              }
                                            : {
                                                background: "transparent",
                                                color: "var(--safetyOrange)",
                                              }
                                        }
                                      >
                                        Send Mail
                                      </CustomButton>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Attandance;
