import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import Checkbox from "component/Checkbox/Checkbox";
import { Tooltip } from "@material-ui/core";
import { Fragment } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddAwayTeamModal from "./addAwayTeamModal";
import CustomInput from "component/customInput/CustomInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SvgIcon from "component/SvgIcon";
import CustomButton from "component/customButton/CustomButton";

export class CreateStatisticModal extends Component {
  render() {
    const {
      show,
      onHide,
      onChange,
      parentState,
      homeTeamName,
      addNewStatistic,
      deleteEvent,
      handelCreateStatistics,
      toggleAwayTeamAddModal,
      handelAddAwayTeam,
    } = this.props;

    return (
      <div>
        <Modal
          show={show}
          onHide={() => false}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                Create Statistic
              </h5>
              <Tooltip arrow title="Close">
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                  disabled={parentState.disableCloaseButton}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Tooltip>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Name Of Statistic"
                  name="statisticName"
                  value={parentState.statisticName}
                  onChange={(e) => onChange(e)}
                />
              </div>

              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Home Team"
                  value={homeTeamName}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <select
                  className="select_class"
                  name="awayTeam"
                  value={parentState.awayTeam}
                  onChange={(e) => {
                    if (e.target.value === "awayPopup") {
                      toggleAwayTeamAddModal();
                    } else {
                      onChange(e);
                    }
                  }}
                >
                  <option value="">Select Away Team</option>
                  <option
                    value="awayPopup"
                    style={{ color: "var(--safetyOrange)" }}
                  >
                    Add away team
                  </option>
                  {parentState.awayTeamList.map((item) => {
                    return (
                      <React.Fragment key={item?.id}>
                        <option value={item?.id}>{item?.name}</option>
                      </React.Fragment>
                    );
                  })}
                </select>
              </div>

              <div className="d-flex align-items-center mb-3">
                <CustomInput
                  type="text"
                  style={{ width: "100%" }}
                  placeholder="Criteria Name"
                  name="criteria"
                  value={parentState.criteria}
                  onChange={(e) => onChange(e)}
                  error={parentState.criteriaError}
                />
                <Tooltip arrow title="Add new Criteria">
                  <AddCircleIcon
                    onClick={() => addNewStatistic()}
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#F75F03",
                      marginLeft: "15px",
                    }}
                  />
                </Tooltip>
              </div>
              {parentState?.inputList.map((item, index) => {
                return (
                  <div className="d-flex align-items-center mb-3">
                    <CustomInput
                      style={{ width: "100%" }}
                      type="text"
                      value={item.name}
                      readOnly={true}
                    />
                    <button
                      type="button"
                      onClick={() => deleteEvent(index)}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <SvgIcon
                        name="trash"
                        style={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "30px",
                        }}
                      />
                    </button>
                  </div>
                );
              })}

              <div className="d-flex">
                <CustomButton
                  flex={1}
                  data-dismiss="modal"
                  onClick={() => handelCreateStatistics()}
                  disabled={parentState.createLoader}
                  isLoading={parentState.createLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <AddAwayTeamModal
          onHide={toggleAwayTeamAddModal}
          show={parentState.awayTeamAddModal}
          onChange={onChange}
          parentState={parentState}
          handelAddAwayTeam={handelAddAwayTeam}
        />
      </div>
    );
  }
}

export default CreateStatisticModal;
