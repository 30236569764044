import CustomButton from "component/customButton/CustomButton";
import CustomModal from "component/customModal/CustomModal";
import SvgIcon from "component/SvgIcon";
import { standardPostApi } from "container/API/ApiWrapper";
import LoaderWrapper from "container/Loader/LoaderWrapper";
import React, { useEffect, useState } from "react";
import InnerHeader from "../InnerHeader";
import moment from "moment";
import User_Placeholder from "../../../Custom/images/user_placeholder.png";
import EditPlayerProfile from "./EditPlayerProfile";
import ChangePassword from "./ChangePassword";
import { ImgType } from "helpers/FindImgType";

function PlayerProfile() {
  const [isLoading, setIsLoading] = useState(true);
  const [profileDetail, setProfileDetail] = useState(null);
  useEffect(() => {
    user_profile();
  }, []);
  const user_profile = async () => {
    try {
      setIsLoading(true);
      const res = await standardPostApi(
        "user_profile",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        console.log("THIS IS A USER PROFILE ->", res.data.data);
        setProfileDetail(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="background_color pb-2">
      {/* {console.log("first=====>>>", profileDetail?.profile_image)} */}
      <InnerHeader />
      {isLoading ? (
        <LoaderWrapper />
      ) : (
        <div className="container-fluid coach_profile_container ">
          <div className="row my-5">
            <div className="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
              <div className="image_profile">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={
                    profileDetail?.profile_image
                      ? ImgType(profileDetail?.profile_image)
                        ? profileDetail?.profile_image
                        : User_Placeholder
                      : User_Placeholder
                  }
                  alt="no_image"
                  className="img-fluid "
                />
              </div>
              <div className="user_details">
                <p className="user_name">{`${profileDetail?.first_name} ${profileDetail?.last_name}`}</p>
                <p className="coach_type">{profileDetail?.role}</p>
                <div
                  className="d-flex align-items-center"
                  style={{ marginLeft: -3 }}
                >
                  <SvgIcon
                    name={"location"}
                    style={{
                      height: 18,
                      width: 18,
                      marginRight: 2,
                    }}
                  />

                  <p className="description">
                    {profileDetail?.address ?? "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="row button_container">
                <CustomModal
                  headingTitle={"Change Password"}
                  className="col-lg-6 col-md-6 col-sm-12 d-flex"
                  actionElement={
                    <CustomButton flex={1} className={"mb-2"}>
                      Change Password
                    </CustomButton>
                  }
                >
                  <ChangePassword />
                </CustomModal>
                <CustomModal
                  className="col-lg-6 col-md-6 col-sm-12 d-flex"
                  headingTitle={"Edit Profile"}
                  actionElement={
                    <CustomButton flex={1} className={"mb-2"}>
                      Edit Profile
                    </CustomButton>
                  }
                >
                  <EditPlayerProfile profile={profileDetail} />
                </CustomModal>
              </div>
            </div>
          </div>

          <div className="info-container">
            <p className="heading">Personal Details</p>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Email Address:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">{profileDetail?.email ?? "N/A"}</p>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Phone Number:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">{profileDetail?.phone ?? "N/A"}</p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Gender:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">{profileDetail?.gender ?? "N/A"}</p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Date Of Birth:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">
                  {profileDetail?.dob
                    ? moment(profileDetail?.dob, "YYYY-MM-DD").format(
                        "DD-MMM-YYYY"
                      )
                    : "N/A"}
                </p>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Specialisation:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">
                  {profileDetail?.sport_name ?? "N/A"}
                </p>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Address:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">{profileDetail?.address ?? "N/A"}</p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Body Weight (in lbs):</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">
                  {profileDetail?.body_weight ?? "N/A"}
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <p className="user_detail_heading">Height (in cms):</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="user_detail">{profileDetail?.height ?? "N/A"}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlayerProfile;
