import CustomButton from "component/customButton/CustomButton";
import React from "react";
import "./SportCard.scss";
function SportCard({ item, hideButton }) {
  // console.log("item", item);
  return (
    <div className="sport_card_container position-relative">
      <div className="sport_card_container_back" />
      <div className="sport_card_container_front d-flex align-items-center ">
        <div style={{ flex: 1, overflow: "hidden" }} className="px-2">
          <p className={`text-truncate ${hideButton && "mb-0 ml-1"}`}>
            {item?.team_name ?? "N/A"}
          </p>
          {!hideButton && <CustomButton>View Detail</CustomButton>}
        </div>
        <div style={{ flex: 0.7 }}>
          <img
            src={
              item?.sport_image
              //  ?? require("Custom/images/athletics-img.png")
            }
            alt="s"
          />
          {/* {team.sport_image} */}
        </div>
      </div>
    </div>
  );
}

export default SportCard;
