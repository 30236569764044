import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import Checkbox from "../../component/Checkbox/Checkbox";
import React, { useEffect, useState } from "react";
import SvgIcon from "component/SvgIcon";

function UpdateWorkout({
  item,
  exerciseData,
  editIconClick,
  updateSave,
  handleClose,
  isLoading,
}) {
  // console.log("itewweas=====>", item);
  const [updateData, setUpdateData] = useState({
    updateRepsData: item?.workout_reps,
    updateRepetitionType:
      item?.workout_repetition_type.charAt(0).toLowerCase() +
      item?.workout_repetition_type.slice(1),
    updateLoadData: item.workout_load,
    updateExerciseField: item?.workout_exercise_name,
    exerciseGroupId: item?.id,
    updateLoadRequired: item?.workout_load_required,
    updateRepsEachSide: item.workout_reps_each_side,
  });
  useEffect(() => {
    setUpdateData({
      ...item,
      updateRepsData: item?.workout_reps,
      updateRepetitionType:
        item?.workout_repetition_type.charAt(0).toLowerCase() +
        item?.workout_repetition_type.slice(1),
      updateLoadData: item.workout_load,
      updateExerciseField: item?.workout_exercise_name,
      exerciseGroupId: item?.id,
      updateLoadRequired: item?.workout_load_required,
      updateRepsEachSide: item.workout_reps_each_side,
    });
  }, [item?.workout_exercise_name]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-10">
          <CustomInput
            className={"mb-3"}
            value={updateData.updateExerciseField}
            name="updateExerciseField"
            placeholder="Exercise"
            onChange={handleChange}
            readOnly={true}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2">
          <div onClick={editIconClick}>
            <SvgIcon
              name="pencil"
              style={{ width: "25px", height: "42px", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>

      <CustomInput
        className={"mb-3"}
        value={updateData.updateRepsData}
        name="updateRepsData"
        placeholder="Reps"
        onChange={handleChange}
        type="number"
      />
      <select
        className="select_class mb-3"
        name="updateRepetitionType"
        value={updateData.updateRepetitionType}
        onChange={handleChange}
      >
        <option value="">Select Repetition Type</option>

        <option value="repetition">Repetition</option>
        <option value="minutes">Minutes</option>
        <option value="seconds">Seconds</option>
      </select>
      <div className="row mb-3">
        <div className="col-lg-6 col-md-6 col-sm-6 d-flex">
          <Checkbox
            checked={updateData.updateLoadRequired == "1" ? true : false}
            toggleCb={() =>
              setUpdateData((prev) => {
                return {
                  ...prev,
                  updateLoadRequired:
                    prev?.updateLoadRequired == "1" ? "0" : "1",
                };
              })
            }
          />
          <p
            style={{
              color: "white",
              fontSize: "12px",
              marginTop: "4px",
            }}
          >
            Load Required?
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 d-flex">
          <Checkbox
            checked={updateData.updateRepsEachSide == "1" ? true : false}
            toggleCb={() =>
              setUpdateData((prev) => {
                return {
                  ...prev,
                  updateRepsEachSide:
                    prev?.updateRepsEachSide == "1" ? "0" : "1",
                };
              })
            }
          />
          <p
            style={{
              color: "white",
              fontSize: "12px",
              marginTop: "4px",
            }}
          >
            Reps Each Side?
          </p>
        </div>
      </div>
      {updateData.updateLoadRequired !== "0" && (
        <CustomInput
          className={"mb-3"}
          placeholder="Load"
          value={
            updateData.updateLoadRequired === "0"
              ? ""
              : updateData.updateLoadData
          }
          name="updateLoadData"
          onChange={handleChange}
          type="number"
        />
      )}
      {exerciseData.group_set_type === "Procedural" ? (
        <>
          <CustomInput
            className={"mb-3"}
            placeholder="Sets"
            value={item?.workout_sets}
            name="updateSetsWorkoutExercise"
            readOnly={true}
            //   onChange={this.props.onChange}
          />
          <CustomInput
            className={"mb-3"}
            placeholder="Rest"
            name="updateRestWorkoutExercise"
            value={item?.workout_rest}
            readOnly={true}
            //   onChange={this.props.onChange}
          />
        </>
      ) : null}
      <div className="d-flex">
        <CustomButton
          flex={1}
          onClick={() => updateSave(updateData, handleClose)}
          isLoading={isLoading}
        >
          Save
        </CustomButton>
      </div>
    </>
  );
}

export default UpdateWorkout;
