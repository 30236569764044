import styled from "tachyons-components";

export const Section = styled("div")`
flex flex-wrap content-center justify-center w-100 h-100`;

export const Article = styled("div")`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

export const Prop = styled("h3")`
f5 f4-ns mb0 white`;

export const Title = styled("h1")`
f4 f3-ns white w-100 tc`;

export const list = [
  // {
  //   prop: "balls",
  //   name: "Balls",
  // },
  {
    prop: "bars",
    name: "Bars",
  },
  // {
  //   prop: "bubbles",
  //   name: "Bubbles"
  // },
  // {
  //   prop: "cubes",
  //   name: "Cubes"
  // },
  // {
  //   prop: "cylon",
  //   name: "Cylon"
  // },
  // {
  //   prop: "spin",
  //   name: "Spin"
  // },
  // {
  //   prop: "spinningBubbles",
  //   name: "SpinningBubbles"
  // },
  // {
  //   prop: "spokes",
  //   name: "Spokes"
  // }
];
