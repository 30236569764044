import CustomLinkButton from "component/customLinkButton/CustomLinkButton";
import DeleteModal from "component/deleteModal/DeleteModal";
import { standardPostApi } from "container/API/ApiWrapper";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { errorToast } from "utils/toastMessage";
import { toast } from "react-toastify";
function Buttons({
  coachRoles,
  props,
  onShowAddPlayer,
  onShowAddCoach,
  isActive,
}) {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteTeamPage, setDeleteTeamPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onHideModal = () => {
    setShowDelete(!showDelete);
  };
  const params = useParams();
  const { push } = useHistory();
  const deleteTeamWithModal = async () => {
    setIsLoading(true);
    try {
      const res = await standardPostApi(
        "delete_team",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: params?.id,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("This is response of delete Team ", res);
        toast.success(res.data.message);
        // alert(res.data.message);
        setIsLoading(false);
        push({ pathname: "/myteamwrapper" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-100">
      {coachRoles === "S&C Coach" ? (
        <CustomLinkButton
          children="Add Player"
          onClick={(e) => {
            onShowAddPlayer
              ? onShowAddPlayer()
              : push({
                  pathname: `/myplayers/${params?.id}/${params?.teamname}
              `,
                  state: {
                    addPlayer: true,
                  },
                });
          }}
          svgName={
            isActive === "addPlayers" ? "add-player-active" : "add-player"
          }
          disabled={isActive === "addPlayers" ? true : false}
        />
      ) : coachRoles === "Assistant Coach" ? null : (
        <CustomLinkButton
          children="Add Player"
          onClick={(e) => {
            onShowAddPlayer
              ? onShowAddPlayer()
              : push({
                  pathname: `/myplayers/${params?.id}/${params?.teamname}
              `,
                  state: {
                    addPlayer: true,
                  },
                });
          }}
          svgName={
            isActive === "addPlayers" ? "add-player-active" : "add-player"
          }
          disabled={isActive === "addPlayers" ? true : false}
        />
      )}

      {coachRoles === "S&C Coach" ? null : coachRoles ===
        "Assistant Coach" ? null : (
        <CustomLinkButton
          children="Add Coach"
          onClick={(e) => {
            onShowAddCoach
              ? onShowAddCoach()
              : push({
                  pathname: `/myplayers/${params?.id}/${params?.teamname}
              `,
                  state: {
                    addCoach: true,
                  },
                });
          }}
          svgName={isActive === "addCoach" ? "add-coach-active" : "add-coach"}
          disabled={isActive === "addCoach" ? true : false}
        />
      )}

      {coachRoles === "S&C Coach" ? null : coachRoles ===
        "Assistant Coach" ? null : (
        <Link
          to={`/coachAddEvent/${props.match.params.id}/${props.match.params.teamname}`}
        >
          <CustomLinkButton
            svgName={isActive === "addEvent" ? "add-event-active" : "add-event"}
            disabled={isActive === "addEvent" ? true : false}
          >
            Add Event
          </CustomLinkButton>
        </Link>
      )}
      <Link
        to={`/reports/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={isActive === "reports" ? "report-active" : "reports"}
          disabled={isActive === "reports" ? true : false}
        >
          Reports
        </CustomLinkButton>
      </Link>
      <Link
        to={`/testing/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={isActive === "testing" ? "testing-active" : "testing"}
          disabled={isActive === "testing" ? true : false}
        >
          Testing
        </CustomLinkButton>
      </Link>
      <Link
        to={`/screeningProtocol/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={isActive === "screening" ? "screening-active" : "screening"}
          disabled={isActive === "screening" ? true : false}
        >
          Screening
        </CustomLinkButton>
      </Link>
      <Link
        to={`/madicalRoom/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={isActive === "medical" ? "madical-active" : "madical"}
          disabled={isActive === "medical" ? true : false}
        >
          Medical
        </CustomLinkButton>
      </Link>

      {/* )} */}

      {coachRoles === "S&C Coach" ? null : coachRoles ===
        "Assistant Coach" ? null : (
        <Link
          to={`/coachAward/${props.match.params.id}/${props.match.params.teamname}`}
        >
          <CustomLinkButton
            svgName={isActive === "award" ? "award-active" : "award"}
            disabled={isActive === "award" ? true : false}
          >
            Award
          </CustomLinkButton>
        </Link>
      )}
      <Link
        to={`/coachstatistic/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={isActive === "statistic" ? "statistic-active" : "statistic"}
          disabled={isActive === "statistic" ? true : false}
        >
          Statistic
        </CustomLinkButton>
      </Link>
      <Link
        to={`/coachLibrary/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={
            isActive === "coachLibrary"
              ? "coach-library-active"
              : "coach-library"
          }
          disabled={isActive === "coachLibrary" ? true : false}
        >
          Coach Library
        </CustomLinkButton>
      </Link>
      <Link
        to={`/attendance/${props.match.params.id}/${props.match.params.teamname}`}
      >
        <CustomLinkButton
          svgName={
            isActive === "attendance" ? "attendance-active" : "attendance"
          }
          disabled={isActive === "attendance" ? true : false}
        >
          Attendance
        </CustomLinkButton>
      </Link>

      {coachRoles === "S&C Coach" ? null : coachRoles ===
        "Assistant Coach" ? null : (
        <CustomLinkButton
          svgName="delete"
          // disabled={showDelete}
          onClick={() => {
            setShowDelete(true);
            // this.state.CratedByMe
            //   ? showDeleteModal()
            //   : errorToast("You are not authorized to perform this operation.");
          }}
        >
          Delete Team
        </CustomLinkButton>
      )}
      <DeleteModal
        show={showDelete}
        onHide={onHideModal}
        text="Are you sure you want to delete this team, this cannot be undone?"
        onSubmit={deleteTeamWithModal}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Buttons;
