import CustomButton from "component/customButton/CustomButton";
import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./CoachAlternativeExercise.scss";

export class AddCoachAlternativeExercise extends Component {
  render() {
    const {
      show,
      onHide,
      parentState,
      onRelatableExerciseChange,
      handelCreateAlternativeExercise,
      exerciseGroupDetail,
      relatableExerciseGroupOnChange,
    } = this.props;
    return (
      <div>
        <Modal
          show={show}
          onHide={() => false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="videopopupTitle">
                Add Alternative Exercise
              </h5>
              <button
                type="button"
                className="modal_close"
                
                onClick={() =>
                  parentState?.createAlternativeExerciseLoader ? null : onHide()
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body viewCoachAlternativeExerciseModal_container">
              <div>
                <p className="heading-text">Exercise Group</p>
                <p className="heading-answer">
                {parentState?.exerciseGroupName}
                </p>
              </div>

              <div>
                <p className="heading-text">Exercise</p>
                <p className="heading-answer">
                {parentState?.exerciseName}
                </p>
              </div>
           <div className="mb-3">
            <p className="heading-text mb-1">Related Alternative Group</p>
            <select
                    className="select_class"
                    name="relatableExerciseGroup"
                    value={parentState.relatableExerciseGroup}
                    onChange={(e) => relatableExerciseGroupOnChange(e)}
                  >
                    {exerciseGroupDetail.map((item) => {
                      return (
                        <option value={item.id} key={item?.id}>
                          {item?.exercise_group}
                        </option>
                      );
                    })}
                  </select>
           </div>

           <div>
            <p className="heading-text mb-1">Related Alternative Exercise</p>
          <div className="reactMultiSelectCheckboxes">
          <ReactMultiSelectCheckboxes
          
                      options={parentState?.RelatableOption}
                      placeholderButtonLabel="Select Exercise"
                      onChange={(e) => onRelatableExerciseChange(e)}
style={{
  background:"red"
}}
                      
classNamePrefix="popup_container"
className="popup_container"
                    />
          </div>
            </div>

            <div className="d-flex">
              <CustomButton
              disabled={parentState?.createAlternativeExerciseLoader}
              isLoading={parentState?.createAlternativeExerciseLoader}
              onClick={() => handelCreateAlternativeExercise()}
              flex={1}
className="mt-3"
              >
                Save

              </CustomButton>
            </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap ",
                }}
              >
                <div className="form-group">
                  <label htmlFor="" style={{ fontWeight: "bold" }}>
                    Related Alternative Group
                  </label>
                  <select
                    className="form-control"
                    name="relatableExerciseGroup"
                    value={parentState.relatableExerciseGroup}
                    onChange={(e) => relatableExerciseGroupOnChange(e)}
                  >
                    {exerciseGroupDetail.map((item) => {
                      return (
                        <option value={item.id} key={item?.id}>
                          {item?.exercise_group}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="" style={{ fontWeight: "bold" }}>
                    Related Alternative Exercise
                  </label>
                  <span className="basic-multi-select">
                    <ReactMultiSelectCheckboxes
                      options={parentState?.RelatableOption}
                      placeholderButtonLabel="Select Exercise"
                      onChange={(e) => onRelatableExerciseChange(e)}
                    />
                  </span>
                </div>
              </div> */}
            </div>
          </Modal.Body>

        </Modal>
      </div>
    );
  }
}

export default AddCoachAlternativeExercise;
