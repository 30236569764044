import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React from "react";

const switchHeight = 24;
const switchWidth = 53;
const styles = props => ({
    root: {
      width: switchWidth,
      height: switchHeight,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: "#FFF",
      "&$checked": {
        transform: `translateX(${switchHeight + 4}px)`,
        color: '#FFF',
        "& + $track": {
          opacity: 1,
          backgroundColor: props?.isChecked ? "#F75F03" : '#EFAD4D',
          borderColor: props?.isChecked ? "#F75F03" : '#EFAD4D',
        },
      },
    },
    thumb: {
      width: switchHeight - 4,
      height: switchHeight - 4,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ##F75F03`,
      borderRadius: switchHeight / 2,
      opacity: 1,
      backgroundColor: "#F75F03",
    },
    checked: {},
  })
const AntSwitch = withStyles(styles)(Switch);

export default function SwitchButton({
  isChecked,
  onChange,
  name,
  leftText,
  rightText,
  textStyle,
  switchClassName
}) {
    
  return (
    <FormGroup>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          {leftText && <p className="mb-0"
          
          style={{
            fontFamily:"Poppins",
            fontSize:12,
            color: !isChecked ? '#F75F03' : '#FFF',
            ...textStyle
          }}
          >{leftText}</p>}
          <Grid item>
            <AntSwitch checked={isChecked} onChange={onChange} name={name} isChecked={isChecked} className={switchClassName}/>
          </Grid>
          {rightText && <p className="mb-0"
          
          style={{
            fontFamily:"Poppins",
            fontSize:12,
            color: isChecked ? '#EFAD4D' : '#FFF',
            ...textStyle
          }}
          >{rightText}</p>}
        </Grid>
      </Typography>
    </FormGroup>
  );
}
