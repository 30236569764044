import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import {
  standardPostApi,
  standardPostApiJsonBased,
} from "container/API/ApiWrapper";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import User_Placeholder from "Custom/images/user_placeholder.png";
import "./CoachProfile.scss";
import SvgIcon from "component/SvgIcon";
import { ImgType } from "helpers/FindImgType";

const imgArr = [".png", ".svg", ".jpg", "jpeg"];

function EditProfile({ profile, handleClose }) {
  const [details, setDetails] = useState({
    profile_image: null,
    ...profile,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [coachSpecialization, setCoachSpecialization] = useState([]);
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    address: "",
  });

  useEffect(() => {
    pre_update_profile();
  }, []);

  const pre_update_profile = async () => {
    try {
      const res = await standardPostApi(
        "pre_update_profile",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      console.log("update profile res", res);
      if (res.data.code === 200) {
        setCoachSpecialization(
          res.data?.data?.CoachSpecialization?.pickerArray ?? []
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  const UpdateUserProfile = async () => {
    const DOB = moment(toTimestamp(details?.dob) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    setIsLoading(true);
    try {
      let accessToken = await localStorage.getItem("access_token");
      let fd = new FormData();
      fd.append("access_token", accessToken);
      fd.append("first_name", details?.first_name);
      fd.append("last_name", details?.last_name);
      fd.append("dob", DOB);
      fd.append("address", details?.address);
      // fd.append("specialization", details?.specialization);

      typeof details?.profile_image !== "string" &&
        details?.profile_image !== null &&
        fd.append(
          "profile_image",
          details?.profile_image,
          details?.profile_image?.name
        );
      const res = await standardPostApiJsonBased(
        "update_user_profile",
        {
          // "Content-Type": "multipart/form-data",
        },
        // {
        //   access_token:  localStorage.getItem('access_token'),
        //   first_name: details?.first_name,
        //   last_name: details?.last_name,
        //   dob: DOB,
        //   address: details?.address,
        //   specialization: details?.specialization,
        // },
        fd,
        true
      );
      if (res.data.code === 200) {
        toast.success(res.data.message);
        handleClose && handleClose();
        // setUserDetail(res.data.data);

        window.location.reload(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleChange = (event) => {
    const { value, name } = event?.target;
    setDetails({
      ...details,
      [name]: value,
    });

    error[name] &&
      setError({
        ...error,
        [name]: "",
      });
  };

  const onValidate = () => {
    const { first_name, last_name, dob, address, phone } = details;
    // console.log({details});
    let isError = false;
    let tempError = { ...error };
    const temp_dob = new Date(dob);
    const today = new Date();
    if (!dob) {
      isError = true;
      tempError = {
        ...tempError,
        dob: "Please select a Date of Birth.",
      };
    } else if (today.getFullYear() - temp_dob.getFullYear() < 16) {
      isError = true;
      tempError = {
        ...tempError,
        dob: "You must be at least 16 year old, in order to sign up.",
      };
    }

    if (!first_name?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        first_name: "The First Name field is required.",
      };
    }

    if (!last_name?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        last_name: "The Surname field is required.",
      };
    }

    // if (!address?.trim()?.length) {
    //   isError = true;
    //   tempError = {
    //     ...tempError,
    //     address: "Address field is required.",
    //   };
    // }

    setError(tempError);
    return isError;
  };

  const onSubmit = () => {
    if (onValidate()) {
      return null;
    }
    UpdateUserProfile();
  };
  const inputFile = useRef(null);
  return (
    <div className="edit_profile_container">
      <div className="col-lg-12 col-md-12 col-sm-12 mb-4 align-items-center justify-content-center d-flex">
        <div
          className="position-relative"
          onClick={() => inputFile.current.click()}
        >
          {typeof details?.profile_image === "string" ? (
            <img
              src={
                details?.profile_image
                  ? ImgType(details?.profile_image)
                    ? details?.profile_image
                    : User_Placeholder
                  : User_Placeholder
              }
              // src={
              //   details?.profile_image ===
              //   "https://prime-coach.co.uk/developers/backend/storage"
              //     ? User_Placeholder
              //     : details?.profile_image
              //   // ? typeof details?.profile_image === "string"
              //   //   ? details?.profile_image
              //   //   : URL.createObjectURL(details?.profile_image)
              //   // : User_Placeholder
              // }
              alt="userPlaceholder"
              className="profile_picture"
            />
          ) : (
            <img
              src={URL.createObjectURL(details?.profile_image)}
              // src={
              //   details?.profile_image ===
              //   "https://prime-coach.co.uk/developers/backend/storage"
              //     ? User_Placeholder
              //     : details?.profile_image
              //   // ? typeof details?.profile_image === "string"
              //   //   ? details?.profile_image
              //   //   : URL.createObjectURL(details?.profile_image)
              //   // : User_Placeholder
              // }
              alt="userPlaceholder"
              className="profile_picture"
            />
          )}

          <div className="edit_pencil_container">
            <SvgIcon name="pencil" className={"pencilEdit"} />
          </div>
        </div>
      </div>

      <input
        ref={inputFile}
        onClick={(event) => (event.target.value = null)}
        type="file"
        className="d-none"
        accept="image/*"
        onChange={(event) =>
          setDetails({
            ...details,
            profile_image: event?.target?.files[0],
          })
        }
      />

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a first name"
        value={details?.first_name}
        name="first_name"
        error={error?.first_name}
        onChange={onHandleChange}
      />
      <CustomInput
        className={"mb-3"}
        placeholder="Enter a last name"
        value={details?.last_name}
        name="last_name"
        error={error?.last_name}
        onChange={onHandleChange}
      />

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a email"
        value={details?.email}
        name="email"
        error={error?.email}
        onChange={onHandleChange}
        readOnly
      />

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a phone number"
        value={details?.phone}
        name="phone"
        error={error?.phone}
        onChange={onHandleChange}
        readOnly
      />

      <InputDateTimePicker
        className={"mb-3 d-flex"}
        placeholder="Enter a date of birth"
        value={details?.dob}
        name="dob"
        error={error?.dob}
        onChange={(value) => {
          setDetails({
            ...details,
            dob: value,
          });
          error?.dob &&
            setError({
              ...error,
              dob: "",
            });
        }}
      />
      {/* {console.log(details)} */}
      <select
        className="select_class mb-3"
        name="specialization"
        onChange={onHandleChange}
        // defaultValue={
        //   (specializationPreValue, userProfileData.specialization)
        // }
      >
        {/* <option value="" hidden>
                      {userProfileData.specialization}
                    </option> */}
        <option value="" disabled selected>
          Select Coach Specialisation
        </option>
        {coachSpecialization &&
          coachSpecialization?.map((item) => {
            return (
              <option value={item.value} key={item.id}>
                {item.label}
              </option>
            );
          })}
      </select>

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a address"
        value={details?.address}
        onChange={onHandleChange}
        name="address"
        error={error?.address}
      />

      <div className="d-flex">
        <CustomButton onClick={onSubmit} flex={1} isLoading={isLoading}>
          Save
        </CustomButton>
      </div>
    </div>
  );
}

export default EditProfile;
