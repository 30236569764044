import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import Checkbox from "component/Checkbox/Checkbox";
import { Tooltip } from "@material-ui/core";
import { Fragment } from "react";
import CustomInput from "component/customInput/CustomInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";

export class MadicalCriteriaModal extends Component {
  render() {
    const {
      show,
      onHide,
      onChange,
      parentState,
      // toggleCb,
      toggleTypeButton,
      addNewCriteria,
      handelCreateMadicalStatus,
      deleteEvent,
    } = this.props;
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                Create Medical Criteria
              </h5>
              <Tooltip arrow title="Close">
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Tooltip>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Name"
                  name="criteriaHeadName"
                  value={parentState.criteriaHeadName}
                  onChange={(e) => onChange(e)}
                />
                {/* <label htmlFor='' style={{ fontWeight: 'bold' }}>
                  Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Name'
                  name='criteriaHeadName'
                  value={parentState.criteriaHeadName}
                  onChange={(e) => onChange(e)}
                /> */}
                {/* <p className="react_validation">{state.protocolNameError}</p> */}
              </div>
              <div className="d-flex align-items-center">
                <CustomInput
                  style={{
                    width: "100%",
                    marginRight: "12px",
                    marginBottom: "12px",
                  }}
                  type="text"
                  placeholder="Name of the criteria"
                  name="criteriaName"
                  value={parentState.criteriaName}
                  onChange={(e) => onChange(e)}
                  error={parentState.criteriaNameError}
                />
                <Tooltip arrow title="Add new criteria">
                  <AddCircleIcon
                    onClick={() => addNewCriteria()}
                    style={{
                      width: "45px",
                      height: "50px",
                      color: "#F75F03",
                    }}
                  />
                </Tooltip>
              </div>
              {parentState.inputList.length !== 0 &&
                parentState.inputList.map((item, index) => {
                  return (
                    <div className="d-flex align-items-center mb-3" key={index}>
                      <CustomInput
                        style={{ width: "100%", marginRight: "30px" }}
                        type="text"
                        placeholder=""
                        value={item?.name}
                        readOnly={true}
                      />
                      <Tooltip arrow title="Delete criteria">
                        <button
                          type="button"
                          onClick={() => deleteEvent(index)}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                        >
                          <SvgIcon
                            name="trash"
                            style={{
                              width: "25px",
                              height: "50px",
                            }}
                          />
                        </button>
                      </Tooltip>
                    </div>
                  );
                })}

              <div className="d-flex" style={{ width: "100%" }}>
                <CustomButton
                  flex={1}
                  dataDismiss="modal"
                  onClick={() => handelCreateMadicalStatus()}
                  disabled={parentState.crateCriteriaLoader}
                  style={
                    parentState.crateCriteriaLoader
                      ? { cursor: "not-allowed", fontWeight: "bold" }
                      : { cursor: "pointer", fontWeight: "bold" }
                  }
                  isLoading={parentState.crateCriteriaLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default MadicalCriteriaModal;
