import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function RoundOff(num) {
  return parseInt(Math.round(Number(num)));
}

export default class WorkoutCompletedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { setPercent, repsPercent, loadPercent } = this.props;
    return (
      <div>
        <div className="col-xs-12 ">
          <h2
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: "500",
              textAlign: "center",
              marginBottom: "3%",
            }}
          >
            Workout Completed
            {/* <i
              style={{ fontSize: "large", color: "green" }}
              className="fa fa-check"
            ></i> */}
          </h2>
        </div>
        <div
          className="popup_heading"
          style={{
            // marginBottom: "2%",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <label className="label_color" style={{ fontWeight: "100" }}>
            Please estimate the workout intensity
          </label>
        </div>
        <ul
          className="d-flex justify-content-around"
          style={{ color: "var(--safetyOrange)", fontSize: "12px" }}
        >
          <li>low</li>
          <li>medium</li>
          <li>high</li>
        </ul>
        <div className="middle">
          {Array.from(Array(10).keys()).map((index) => (
            <span key={index}>
              <label>
                <input
                  type="radio"
                  name="radio11"
                  value={index + 1}
                  onChange={this.props.setSelectedIntensity}
                />
                <div className="front-end box">
                  <span>{index + 1}</span>
                </div>
              </label>
              &nbsp;
            </span>
          ))}
        </div>
        {this.props.visibleWellBeingInformation ? (
          <div>
            <h2
              className="label_color text-center mt-3"
              style={{ fontSize: "16px", fontWeight: "100" }}
            >
              Well-Being Information
            </h2>
            <ul
              className="d-flex justify-content-around col-xl-12"
              style={{
                padding: "0 100px",
                color: "var(--safetyOrange)",
                fontSize: "12px",
              }}
            >
              <li>Fresh</li>
              <li>Sore</li>
              <li>Fatigue</li>
              <li>Sleep</li>
            </ul>
            <div className="middleNot">
              {this.props.wellInfo &&
                this.props.wellInfo.map((item, index) => (
                  <span key={index}>
                    <label>
                      <input type="radio" value={index + 1} />
                      <div
                        className="front-end box"
                        style={{ width: "54px", margin: "0 5px" }}
                      >
                        <span>{RoundOff(item)}</span>
                      </div>
                    </label>
                    &nbsp;
                  </span>
                ))}
            </div>
            <h2
              className="label_color text-center mt-3 mb-3"
              style={{ fontSize: "16px", fontWeight: "100" }}
            >
              Accomplishments
            </h2>

            <div
              className="d-flex align-items-center justify-content-center"
              style={{ padding: "0 80px" }}
              // style={{ padding: "0 203px", display: "flex", marginLeft: "2px" }}
            >
              <div>
                <CircularProgressbar
                  value={setPercent}
                  text={`${setPercent}%`}
                  styles={buildStyles({
                    stroke: "var(--valhalla)",
                    pathColor:
                      setPercent > 25 ? `rgba(91, 184, 93, 1)` : "#f00",
                    textColor: setPercent > 25 ? `rgba(91, 184, 93,1)` : "#f00",
                  })}
                />
              </div>
              <div style={{ margin: "0 15px" }}>
                <CircularProgressbar
                  value={repsPercent}
                  text={`${repsPercent}%`}
                  styles={buildStyles({
                    pathColor:
                      repsPercent > 25 ? `rgba(239, 173, 77, 1)` : "#f00",
                    textColor:
                      repsPercent > 25 ? `rgba(239, 173, 77, 1)` : "#f00",
                  })}
                />
              </div>
              <div>
                <CircularProgressbar
                  value={loadPercent}
                  text={`${loadPercent}%`}
                  styles={buildStyles({
                    pathColor: loadPercent > 25 ? `rgba(255, 0, 0,1)` : "#f00",
                    textColor: loadPercent > 25 ? `rgba(255, 0, 0,1)` : "#f00",
                  })}
                />
              </div>
            </div>
            <ul
              className="d-flex justify-content-around"
              style={{
                padding: "0 76px",
                color: "white",
                // fontSize: "38px",
                // fontWeight: "900",
                // lineHeight: "50px",
                // marginTop: "10px",
                // textAlign: "center",
                // marginBottom: "3%",
              }}
            >
              <li>Sets</li>
              <li>Reps</li>
              <li>Load</li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
