import React from "react";
import { Line, Bar, Pie, Doughnut } from "react-chartjs-2";
import "./Chart.scss";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LoaderWrapper from "container/Loader/LoaderWrapper";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const LineChartExample = ({ data, legend }) => {
  return (
    <Line
      className="line-chart"
      data={data}
      options={{
        legend: {
          display: legend,
          labels: {
            boxWidth: 12,
            padding: 20,
            fontColor: "#6783b8",
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: false,
                fontSize: 12,
                fontColor: "#9eaecf",
                padding: 10,
              },
              gridLines: {
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              ticks: {
                fontSize: 12,
                fontColor: "#9eaecf",
                source: "auto",
                padding: 5,
              },
              gridLines: {
                color: "transparent",
                tickMarkLength: 10,
                offsetGridLines: true,
              },
            },
          ],
        },
      }}
    />
  );
};

export const BarChartComponent = ({
  labels,
  data,
  backgroundColor,
  borderColor,
  className,
  isLoading,
  onClick,
  title,
}) => {
  return isLoading ? (
    <LoaderWrapper />
  ) : (
    <Bar
      className={`BarChartExample ${className}`}
      data={{
        labels,
        datasets: [
          {
            label: "",
            data: data,
            backgroundColor: backgroundColor ?? "#f75f03",
            borderColor: borderColor ?? "#f75f03",
            borderRadius: 15,
            barThickness: 5,
            maxBarThickness: 5,
          },
        ],
      }}
      options={{
        onClick: onClick,
        plugins: {
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              pinch: {
                enabled: true,
              },
              // wheel: {
              //   enabled: true,
              // },
              mode: "x",
            },
          },
          tooltips: {
            backgroundColor: "red",
            titleFont: 143,
            bodyColor: "#FFF",
            yAlign: 10,
            xAlign: 10,
            // enabled: true,
            // backgroundColor: "#eff6ff",
            // titleFontSize: 13,
            // titleFontColor: "#6783b8",
            // titleMarginBottom: 6,
            // bodyFontColor: "#9eaecf",
            // bodyFontSize: 12,
            // bodySpacing: 4,
            // yPadding: 10,
            // xPadding: 10,
            // footerMarginTop: 0,
            // displayColors: false,
          },
          title: {
            display: !!title,
            text: title,
            font: {
              size: 16,
            },
            position: "bottom",
            color: "#FFF",
            padding: {
              top: 30,
              bottom: 30,
            },
          },
        },
        responsive: true,

        maintainAspectRatio: false,

        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },

          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      }}
    />
  );
};

export const PieChartExample = ({ data }) => {
  return (
    <Pie
      data={data}
      options={{
        legend: {
          display: false,
        },
        rotation: -0.2,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
      }}
    />
  );
};

export const DoughnutExample = ({ data }) => {
  return (
    <Doughnut
      data={data}
      options={{
        legend: {
          display: false,
        },
        rotation: 1,
        cutoutPercentage: 40,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
      }}
    />
  );
};

// export const PolarExample = ({ data }) => {
//   return (
//     <Polar
//       data={data}
//       options={{
//         legend: {
//           display: false,
//         },
//         maintainAspectRatio: false,
//         tooltips: {
//           enabled: true,
//           backgroundColor: "#eff6ff",
//           titleFontSize: 13,
//           titleFontColor: "#6783b8",
//           titleMarginBottom: 6,
//           bodyFontColor: "#9eaecf",
//           bodyFontSize: 12,
//           bodySpacing: 4,
//           yPadding: 10,
//           xPadding: 10,
//           footerMarginTop: 0,
//           displayColors: false,
//         },
//       }}
//     />
//   );
// };
