import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../Custom/images/logo.png";
// for jquery in reactJs
import $ from "jquery";
import { findDOMNode } from "react-dom";
import SvgIcon from "component/SvgIcon";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: "navbar1",
      isActive: "home",
    };
  }

  handleToggle = () => {
    const el = findDOMNode(this.refs.toggle);
    $(el).slideToggle();
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return this.setState({
        header: "navbar1",
      });
    } else if (window.scrollY > 70) {
      return this.setState({
        header: "navbar2",
      });
    }
  };

  render() {
    const { header } = this.state;

    const {
      history: { location },
    } = this.props;
    return (
      <div>
        <header id="header">
          <nav className={`${header}  navbar-expand-lg navbar-light `}>
            <div className="nav-body">
              <div className="web-logo">
                <Link to="/" className="navbar-brand logo">
                  <SvgIcon
                    name={"prime_coach_logo"}
                    style={{ width: 180, height: 60 }}
                  />
                </Link>
              </div>

              <div ref="toggle" id="navbarSupportedContent">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 15px",
                    }}
                  >
                    <SvgIcon
                      name={"login-icon"}
                      style={{ width: 20, height: 20 }}
                    />

                    <Link
                      to={`/loginsection/${"athlete"}/${"coach"}`}
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        color: "#FFFF",
                        padding: "0 6px",
                      }}
                    >
                      Login
                    </Link>
                    {/* </span> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 15px",
                    }}
                  >
                    <SvgIcon
                      name={"free_trial_icon"}
                      style={{ width: 20, height: 20 }}
                    />

                    <Link
                      to="/sportsection"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        color: "#FFFF",
                        padding: "0 6px",
                      }}
                    >
                      Free Trial
                    </Link>
                    {/* </span> */}
                  </div>
                </div>
                <ul className="navbar-nav ml-auto">
                  <li
                    className="nav-item"
                    // onClick={() => this.setState({ isActive: "home" })}
                  >
                    <Link
                      className={activeTabClass(location, ["/"])}
                      to="/"
                      // className="nav-link home "
                      // style={{
                      //   color:
                      //     this.state.isActive == "home" ? "#f75f03" : "white",
                      // }}
                    >
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    // onClick={() => {
                    //   // this.setState(() => ({ isActive: "forathletes" }));
                    //   this.setState({ isActive: "forathletes" });
                    //   console.log("forathle");
                    // }}
                  >
                    <Link
                      className={activeTabClass(location, ["/forathletes"])}
                      to="/forathletes"
                      // className="nav-link athlete"
                      // style={{
                      //   color:
                      //     this.state.isActive == "forathletes"
                      //       ? "#f75f03"
                      //       : "white",
                      // }}
                    >
                      For Athletes
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    // onClick={() => this.setState({ isActive: "forcoach" })}
                  >
                    <Link
                      className={activeTabClass(location, ["/forcoach"])}
                      to="/forcoach"
                      // className="nav-link"
                      // style={{
                      //   color:
                      //     this.state.isActive == "forcoach"
                      //       ? "#f75f03"
                      //       : "white",
                      // }}
                    >
                      For Coaches
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* </div> */}
          </nav>
        </header>
      </div>
    );
  }
}
function withMyHook(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useHistory();
    return <Component {...props} history={myHookValue} />;
  };
}
export default withMyHook(Header);
const activeTabClass = (location, path) => {
  const { pathname } = location;
  return path.includes(pathname) ? "nav_link_active" : "";
};

// function Header() {
//   <div>
//     <div>header</div>
//   </div>;
//   return (
// <div>
//   <header id="header">
//     <nav className="navbar navbar-expand-lg navbar-light ">
//       <div className="container">
//         <div className="web-logo">
//           {/* <a className="navbar-brand logo" href="javaScript;"> */}
//           <Link to="/" className="navbar-brand logo">
//             <img src={logo} className="img-fluid" alt={logo} />
//             <div>
//               <span>Prime</span>
//               <br />
//               Coach
//             </div>
//             {/* </a> */}
//           </Link>
//         </div>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//           onClick={this.handleToggle}
//         >
//           {/* <span className="navbar-toggler-icon"></span>  */}
//           <span>
//             <i className="fa fa-bars" aria-hidden="true"></i>
//           </span>
//         </button>

//         <div
//           className="collapse navbar-collapse"
//           id="navbarSupportedContent"
//         >
//           <ul className="navbar-nav ml-auto">
//             <li className="nav-item active">
//               {/* <a className="nav-link home" href="home.php"> */}
//               <Link to="/" className="nav-link home">
//                 Home <span className="sr-only">(current)</span>
//                 {/* </a> */}
//               </Link>
//             </li>
//             <li className="nav-item">
//               {/* <a className="nav-link athlete" href="forathlete.php"> */}
//               <Link to="/forathletes" className="nav-link athlete">
//                 For Athletes
//                 {/* </a> */}
//               </Link>
//             </li>
//             <li className="nav-item">
//               {/* <a className="nav-link" href="forcoaches.php"> */}
//               <Link to="/forcoach" className="nav-link">
//                 For Coaches
//               </Link>
//               {/* </a> */}
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="javaScript;">
//                 Pricing
//               </a>
//             </li>
//             <li className="nav-item">
//               {/* <a className="nav-link" href="loginselection.php"> */}
//               <Link to="/loginsection" className="nav-link">
//                 Login
//                 {/* </a> */}
//               </Link>
//             </li>
//             <li className="nav-item">
//               {/* <a className="nav-link" href="javaScript;"> */}
//               <Link to="/sportsection" className="nav-link">
//                 Free Trial
//               </Link>
//               {/* </a> */}
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   </header>
// </div>
//   );
// }

// export default Header;
