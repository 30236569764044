import React from 'react'
import "./CardWrapper.scss"
function CardWrapper({children , height , width , style , showBackgroundImage , className}) {
  return (
    <div className='position-relative cardWrapper_container w-100'>
        <div className='cardWrapper_back' style={{
            height
        }}/>
        <div className={`cardWrapper_front ${className}`}
        style={{
            height,
            width,
            backgroundImage : !showBackgroundImage && "none",
            ...style
        }}
        >
            {children}
        </div>
    </div>
  )
}

export default CardWrapper