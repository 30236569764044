import AddCircleIcon from "@material-ui/icons/AddCircle";
import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import CustomModal from "component/customModal/CustomModal";
import React from "react";
import DeleteWorkoutExerciseModalForExercise from "./DeleteWorkoutExerciseModalForExercise";
import ViewVideoModalForCoach from "./viewVideoModalForCoach";
import NoDataFound from "component/lottiLoader/LottiLoader";
import LinearWithValueLabel from "container/Pages/screeningProtocol/progressBar/LinerBar";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Select from "react-select";
import EditWorkoutExerciseModal from "./EditWorkoutExerciseModal";
import AddNewWorkoutExercise from "./AddNewWorkoutExercise";
class ExerciseRightSectionForCoach extends React.Component {
  state = {
    a: false,
    viewModal: false,
    viewVideoData: "",
    viewName: "",
    searchValue: "",
    filterData: [],
    selectUploadType: "",
    selectUploadTypeEdit: "",
  };
  // componentDidMount() {
  //   this.setState({ filterData: this.props.selectedGroupExercises });
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.ExerciseGroups !== this.props?.ExerciseGroups) {
      prevProps.ExerciseGroups.forEach(async (item) => {
        if (item.id == prevProps?.ExerciseGroupsId) {
          this.setState({
            filterData: item?.exercises,
          });
        }
      });
    }
  }
  toggleViewModal = async (item) => {
    await this.setState({
      viewModal: !this.state.viewModal,
      viewVideoData: item,
      viewName: item?.exercise,
    });
  };
  handleInputChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchValue: searchTerm });

    const filteredItems = this.props.selectedGroupExercises?.filter((user) =>
      user?.exercise.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.setState({ filterData: filteredItems });
  };
  onSelectUploadType = (e) => {
    this.setState({ selectUploadType: e?.target?.value });
  };
  onSelectUploadTypeEdit = (e) => {
    this.setState({ selectUploadTypeEdit: e?.target?.value });
  };
  onClickEdit = (item) => {
    this.setState({
      selectUploadTypeEdit:
        item?.type_media === "video"
          ? "1"
          : item?.type_media === "youtube video"
          ? "2"
          : item?.type_media === "pdf"
          ? "1"
          : null,
    });
    this.props.updateExercise(item);
  };
  render() {
    const [
      ExerciseName,
      ExerciseDescription,
      exerciseVideos,
      exerciseNameUpdate,
      exerciseDescriptionUpdate,
      exerciseVideoUpdate,
      ExerciseGroupsIdUpdate,
      youtube_link,
      type_media_update,
      exercise_media_update,
      exercise_media,
      deleteWorkoutExerciseLoading,
    ] = this.props.value;

    const {
      exerciseNameReset,
      exerciseDescriptionReset,
      exerciseVideoReset,
      ExerciseGroupsId,
      ExerciseGroupsType,
      isLoading,
      selectedGroupExercises,
      VideoUploadProgress,
    } = this.props;
    const coachRoles = localStorage.getItem("access_role");
    const options = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];
    // console.log(
    //   "selectedGroupExercises data",
    //   selectedGroupExercises,
    //   this?.state?.filterData
    // );
    return (
      <div>
        <div
          className="col-lg-12 col-md-12 col-sm-12 py-3"
          style={{
            backgroundColor: "var(--blackRussian)",
            borderRadius: "15px",
          }}
        >
          <div>
            <h2
              style={{
                color: "white",
                fontSize: "20px",
                padding: "15px 6px",
                borderBottom: "1px solid ",
              }}
            >
              Exercise Groups
            </h2>
          </div>
          <div className="panel-body row">
            {/* <h3
                  style={{
                    color: 'white',
                    marginTop: '10%',
                    marginBottom: '5%',
                  }}
                >
                  Exercise Groups
                </h3> */}

            <div
              className="col-lg-12 col-md-12 col-sm-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <select
                className="select_class my-2"
                onChange={(e) => {
                  this.props.onHandel(e);

                  this.props.ExerciseGroups.forEach(async (item) => {
                    if (item.id == e.target?.value) {
                      this.setState({
                        filterData: item?.exercises,
                      });
                    }
                  });
                }}
                value={ExerciseGroupsId}
              >
                <option value="">Select Exercise Group</option>
                {this.props?.ExerciseGroups.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>
                      {data?.exercise_group}
                    </option>
                  );
                })}
              </select>

              {(ExerciseGroupsType !== "admin" || coachRoles == "Admin") && (
                <CustomModal
                  className="d-flex"
                  headingTitle={"Add New Exercise"}
                  actionElement={
                    <AddCircleIcon
                      style={{
                        width: "59px",
                        height: "59px",
                        color: "#F75F03",
                        marginLeft: "16px",
                        cursor: "pointer",
                      }}
                    />
                  }
                >
                  <AddNewWorkoutExercise
                    ExerciseName={ExerciseName}
                    ExerciseDescription={ExerciseDescription}
                    onChange={this.props.onChange}
                    onSelectUploadTyp={this.onSelectUploadType}
                    selectUploadType={this.state.selectUploadType}
                    onChangeFile={this.props?.onChangeFile}
                    exercise_media={exercise_media}
                    ExerciseGroupsSelectpicker={
                      this.props.ExerciseGroupsSelectpicker
                    }
                    VideoUploadProgress={VideoUploadProgress}
                    ExerciseGroupsId={ExerciseGroupsId}
                    onHandel={this.props.onHandel}
                    isLoading={isLoading}
                    addWorkoutExercise={this.props.addWorkoutExercise}
                    youtube_link={youtube_link}
                  />
                  {/* <React.Fragment>
                    <CustomInput
                      className={"mb-3"}
                      placeholder="Name"
                      value={ExerciseName}
                      name="ExerciseName"
                      onChange={(e) => this.props.onChange(e)}
                    />
                    <CustomInput
                      type="textarea"
                      placeholder="Description"
                      className="mb-2"
                      defaultValue={ExerciseDescription}
                      name="ExerciseDescription"
                      onChange={(e) => this.props.onChange(e)}
                      // defaultValue={state?.viewModalData?.description}
                      // readOnly
                    />
                    <select
                      className="select_class my-2"
                      onChange={this.onSelectUploadType}
                      value={this.state.selectUploadType}
                    >
                      <option value="">Select A Upload Type </option>
                      {[
                        { id: 1, label: "File", value: 1 },
                        { id: 2, label: "YouTube Link", value: 2 },
                      ].map((data) => {
                        return (
                          <option key={data.id} value={data.id}>
                            {data?.label}
                          </option>
                        );
                      })}
                    </select>
                    {this.state.selectUploadType == 2 && (
                      <CustomInput
                        className={"mb-3"}
                        placeholder="Video link"
                        defaultValue={youtube_link}
                        name="youtube_link"
                        onChange={(e) => this.props.onChange(e)}
                      />
                    )}
                    {this?.state?.selectUploadType == 1 && (
                      <div>
                        <input
                          type="file"
                          onChange={this.props?.onChangeFile}
                          style={{ display: "none" }}
                          accept="video/mp4"
                          ref={(ref) => (this.fileInput = ref)}
                          onClick={(e) => (e.target.value = null)}
                        />
                        <button
                          className="screening_protocol_comment_btn"
                          onClick={() => this.fileInput.click()}
                          style={{ cursor: "pointer", marginBottom: "10px" }}
                        >
                          <CloudUploadOutlinedIcon
                            style={{
                              color: "var(--safetyOrange)",
                              marginRight: "7px",
                            }}
                          />
                          Upload video
                        </button>
                      </div>
                      // <CustomInput
                      //   type="file"
                      //   // accept="video/mp4,application/pdf"
                      //   className={"mb-3"}
                      //   placeholder="Video link"
                      //   onChange={this.props?.onChangeFile}
                      //   inputStyle={{ paddingTop: "10px" }}
                      // />
                    )}
                    {this?.state?.selectUploadType == 1 && (
                      <div>
                        {exercise_media ? (
                          <video
                            width="220"
                            height="120"
                            controls
                            style={{
                              outline: 0,
                              width: "100%",
                              height: "230px",
                            }}
                            src={URL.createObjectURL(exercise_media)}
                          ></video>
                        ) : null}
                      </div>
                    )}
                    <select
                      className="select_class"
                      defaultValue={ExerciseGroupsId}
                      onChange={this.props.onHandel}
                      selected
                      readOnly
                    >
                      {this.props.ExerciseGroupsSelectpicker.map((data) => {
                        return (
                          <option key={data.id} value={data.value}>
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                    {VideoUploadProgress !== null &&
                      VideoUploadProgress !== undefined &&
                      VideoUploadProgress.length !== 0 && (
                        <LinearWithValueLabel progress={VideoUploadProgress} />
                      )}
                    <div className="d-flex mt-4">
                      <CustomButton
                        dataDismiss="modal"
                        flex={1}
                        style={{ height: "50px" }}
                        onClick={() => this.props.addWorkoutExercise()}
                        isLoading={isLoading}
                        disabled={isLoading}
                      >
                        Save
                      </CustomButton>
                    </div>
                  </React.Fragment> */}
                </CustomModal>
              )}
            </div>
            <div className="col-12 ">
              {/* <Select
                defaultValue={ExerciseGroupsId}
                onChange={this.props.onHandel}
                options={options}
              /> */}
              <CustomInput
                className={"col-12 p-0 mt-2 mb-4"}
                type="search"
                placeholder={"Search"}
                name="planSearchTxt"
                value={this.state.searchValue}
                onChange={this.handleInputChange}
                search
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive exercise_group_table exercise-workout-group mb-3">
              {this?.state?.filterData?.length > 0 ||
              selectedGroupExercises?.length > 0 ? (
                <table className="table">
                  <thead className="exercise_group_table_thead">
                    <tr>
                      <th
                        style={{
                          borderTopLeftRadius: "13px",
                          borderBottomLeftRadius: "13px",
                        }}
                      >
                        Name
                      </th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Exercise Group</th>
                      <th
                        style={{
                          borderTopRightRadius: "13px",
                          borderBottomRightRadius: "13px",
                        }}
                      ></th>
                    </tr>
                  </thead>

                  <tbody style={{ color: "white" }}>
                    {/* yaha se hataya */}
                    {/* <tr>
                    <td className='text-truncate'>
                      {(ExerciseName, exerciseNameReset)}
                    </td>
                    <td className='text-truncate'>
                      {(ExerciseDescription, exerciseDescriptionReset)}
                    </td>
                    <td className='text-truncate'>
                      <a href={(exerciseVideos, exerciseVideoReset)}>
                        {(exerciseVideos, exerciseVideoReset)}
                      </a>
                    </td>
                    <td className='text-truncate'>
                      {
                        this.props.ExerciseGroupsSelectpicker.find(
                          (item) => item?.value == ExerciseGroupsId
                        )?.label
                      }
                    </td>
                    <td className='text-truncate'>
                      <button
                        className='btn admin_button'
                        onClick={this.props.addWorkoutExercise}>
                        Add
                      </button>
                    </td>
                  </tr> */}

                    {(this.state?.filterData?.length > 0
                      ? this.state?.filterData
                      : selectedGroupExercises
                    ).map((item) => {
                      return (
                        <tr key={item.id} className="react_Testing_Table">
                          <td className="text-truncate">
                            {(exerciseNameUpdate, item.exercise)}
                          </td>
                          <td className="text-truncate">
                            {(exerciseDescriptionUpdate, item.description)}
                          </td>
                          <td
                            className="text-truncate"
                            style={{ textTransform: "capitalize" }}
                          >
                            {/* <a
                              href={(exerciseVideoUpdate, item.video)}
                              target="_blank"
                            > */}
                            {
                              (exerciseVideoUpdate,
                              item.type_media ? item.type_media : "Description")
                            }
                            {/* </a> */}
                          </td>
                          <td className="text-truncate">
                            {
                              this.props.ExerciseGroupsSelectpicker.find(
                                (data) => data?.id == item?.exercise_group_id
                              )?.label
                            }

                            {/* <select
                            className="col-md-12 form-control exercise-group-id"
                            // defaultValue={item.exercise_group_id}
                            selected
                            readOnly
                          >
                            {this.props.ExerciseGroupsSelectpicker.map(
                              (data) => {
                                return (
                                  <option key={data.id} value={data.value}>
                                    {data.label}
                                  </option>
                                );
                              }
                            )}
                          </select> */}
                          </td>
                          <td className="text-truncate">
                            <span
                              style={
                                item?.type !== "admin"
                                  ? { display: "flex" }
                                  : {
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }
                              }
                            >
                              {(item?.type !== "admin" ||
                                coachRoles == "Admin") && (
                                <CustomModal
                                  className="d-flex"
                                  headingTitle={"Edit New Exercise"}
                                  actionElement={
                                    <div
                                      className="btn admin_button"
                                      onClick={() => {
                                        this.setState({
                                          selectUploadTypeEdit:
                                            item?.type_media === "video"
                                              ? "1"
                                              : item?.type_media ===
                                                "youtube video"
                                              ? "2"
                                              : item?.type_media === "pdf"
                                              ? "1"
                                              : null,
                                        });
                                        this.props.updateExercise(item);
                                      }}
                                    >
                                      Edit
                                    </div>
                                  }
                                >
                                  <EditWorkoutExerciseModal
                                    exerciseNameUpdate={exerciseNameUpdate}
                                    exerciseDescriptionUpdate={
                                      exerciseDescriptionUpdate
                                    }
                                    exerciseVideoUpdate={exerciseVideoUpdate}
                                    ExerciseGroupsIdUpdate={
                                      ExerciseGroupsIdUpdate
                                    }
                                    item={item}
                                    onChangeUpdate={this.props.onChangeUpdate}
                                    onSelectUploadTypeEdit={
                                      this.onSelectUploadTypeEdit
                                    }
                                    selectUploadTypeEdit={
                                      this.state.selectUploadTypeEdit
                                    }
                                    ExerciseGroupsSelectpicker={
                                      this.props.ExerciseGroupsSelectpicker
                                    }
                                    updateWorkoutExerciseLoading={
                                      this.props?.updateWorkoutExerciseLoading
                                    }
                                    updateWorkoutExercise={
                                      this.props.updateWorkoutExercise
                                    }
                                    onChangeUpdateFile={
                                      this.props?.onChangeUpdateFile
                                    }
                                  />
                                </CustomModal>
                                // <div
                                //   className="btn admin_button"
                                //   onClick={() =>
                                //     this.props.updateWorkoutExercise(item)
                                //   }
                                // >
                                //   Edit
                                // </div>
                              )}

                              {(item?.type !== "admin" ||
                                coachRoles == "Admin") && (
                                <button
                                  className="btn admin_button"
                                  onClick={() =>
                                    this.props.showDeleteWorkoutExerciseModal(
                                      item
                                    )
                                  }
                                >
                                  Delete
                                </button>
                              )}
                              {/* <Link to={item?.video}> */}
                              <button
                                className="btn admin_button"
                                onClick={() => this.toggleViewModal(item)}
                              >
                                {/* <a
                                  href={(exerciseVideoUpdate, item.video)}
                                  target="_blank"
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--safetyOrange)",
                                  }}
                                > */}
                                View
                                {/* </a> */}
                              </button>
                              {/* </Link> */}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>
                  <NoDataFound text={"No Data Found"} />
                </div>
              )}
            </div>
          </div>
        </div>
        <DeleteWorkoutExerciseModalForExercise
          show={this.props.deleteExerciseModal}
          onHide={this.props.hideDeleteWorkoutExerciseModal}
          deleteWorkoutExercise={this.props.deleteWorkoutExercise}
          loading={deleteWorkoutExerciseLoading}
        />

        <ViewVideoModalForCoach
          show={this.state.viewModal}
          onHide={this.toggleViewModal}
          state={this.state}
        />
      </div>
    );
  }
}

export default ExerciseRightSectionForCoach;
