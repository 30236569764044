import React, { Component } from "react";
import "../Annual Training Plan/AnnualTrainingPlan.css";
import { Link } from "react-router-dom";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
import { Modal, Form } from "react-bootstrap";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../Custom/images/celender.jpg";
// moment and timestamp in react js
import moment from "moment";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { standardPostApi } from "../API/ApiWrapper";

import LoaderWrapper from "../Loader/LoaderWrapper";
import Image from "component/ImageComponent/Image";
import NoDataFound from "component/lottiLoader/LottiLoader";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import CustomInput from "component/customInput/CustomInput";
import CustomButton from "component/customButton/CustomButton";
import TrainingPlanCard from "component/card/trainingPlanCard/TrainingPlanCard";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import SvgIcon from "component/SvgIcon";
import { EventEmitter } from "Custom/js/event";

toast.configure();
class AnnualTrainingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      date: null,
      hasSetDate: false,
      endDate: null,
      hasSetEndDate: false,
      WorkoutLocation: [],
      name: "",
      locationChange: "",
      nameError: "",
      startDateError: "",
      endDateError: "",
      locationError: "",
      listAnnualTrainingPlan: [],
      addProgram: {},
      planSearchTxt: "",
      addLoader: false,
      // userData: {},
    };
    this.onChange = this.onChange.bind(this);
    EventEmitter.subscribe("CALL_PLAN_LIST", () => this.handleEvent());
  }
  handleEvent() {
    this.fetch_list_annual_training_program();
  }
  componentWillUnmount() {
    EventEmitter.unsubscribe('"CALL_PLAN_LIST"');
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log("onChange", this.state);
  }

  componentDidMount() {
    this.fetch_pre_add_annual_training_program();
    this.fetch_list_annual_training_program();
  }

  fetch_pre_add_annual_training_program = async () => {
    try {
      const res = await standardPostApi(
        "pre_add_annual_training_program",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          WorkoutLocation: res.data.data.WorkoutLocation.pickerArray,
        });
        // console.log(
        //   "This is res of pre_add_annual_training_program",
        //   res.data.data.WorkoutLocation
        // );
      }
    } catch (error) {
      console.log(error);
    }
  };

  add_annual_training_program = async () => {
    const start_date = moment(this.toTimestamp(this.state.date) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    const end_date = moment(this.toTimestamp(this.state.endDate) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    const isValid = this.validationAddTranningPlan();

    if (isValid) {
      this.setState({ addLoader: true });
      try {
        const res = await standardPostApi(
          "add_annual_training_program",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            name: this.state.name,
            start_date: start_date,
            end_date: end_date,
            location: this.state.locationChange,
          },
          true
        );
        if (res.data.code === 200) {
          await this.setState({ addProgram: res.data.data });
          // console.log(
          //   "This is res of Add Annual Tranning Plan ",
          //   res.data.data
          // );
          await this.setState({
            name: "",
            locationChange: "",
            date: "",
            endDate: "",
          });
          await this.hideModal();
          toast.success(res.data.message);
          await this.fetch_list_annual_training_program();
          this.setState({ addLoader: false });
        }
      } catch (error) {
        console.log(error);
        this.setState({ addLoader: false });
      } finally {
        this.setState({ addLoader: false });
      }
    }
  };

  fetch_list_annual_training_program = async () => {
    try {
      const res = await standardPostApi(
        "list_annual_training_program",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true
      );
      if (res.data.code === 200) {
        this.setState({
          listAnnualTrainingPlan: res.data.data,
          loading: false,
        });
        // console.log("This is res list_annual_training_program", res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  validationAddTranningPlan = () => {
    let nameError = "";
    let startDateError = "";
    let endDateError = "";
    let locationError = "";

    if (!this.state.name) {
      nameError = "This field is required";
    }
    if (!this.state.hasSetDate) {
      startDateError = "This field is required";
    }

    if (!this.state.hasSetEndDate) {
      endDateError = "This field is required";
    }

    if (!this.state.locationChange) {
      locationError = "Choose Location";
    }

    if (nameError || startDateError || endDateError || locationError) {
      this.setState({ nameError, startDateError, endDateError, locationError });
      return false;
    } else {
      return true;
    }
  };

  showModal = async () => {
    await this.setState({ show: !this.state.show });
  };

  hideModal = async () => {
    await this.setState({ show: false });
  };
  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ date: date, hasSetDate: true });
    // console.log("The start date selected is ", this.state.date);
  };

  endDateChange = async (endDate) => {
    await this.setState({ endDate: endDate, hasSetEndDate: true });
    // console.log("The end  date .... is ", this.state.endDate);
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  renderAnnualProgram = () => {
    const annualPrograms = this.state.listAnnualTrainingPlan;
    const { planSearchTxt } = this.state;
    return (
      annualPrograms &&
      annualPrograms.map((item) => {
        if (planSearchTxt != null || planSearchTxt !== "") {
          let mainTerm = item.name;
          let subTerm = planSearchTxt;
          let searchRegex = new RegExp(subTerm, "i");
          let res = mainTerm.match(searchRegex);
          let isMatch = res != null && res.length > 0;
          if (isMatch === false) {
            if (item?.users?.length) {
              for (var i = 0; item?.users?.length > i; i++) {
                let Name =
                  item?.users[i]?.first_name + " " + item?.users[i]?.last_name;
                let subTermName = planSearchTxt;
                let searchName = new RegExp(subTermName, "i");
                let resName = Name.match(searchName);
                let searchEmail = new RegExp(subTermName, "i");
                let resEmail = item?.users[i]?.email.match(searchEmail);
                let isMatchPlayer =
                  (resName != null && resName.length > 0) ||
                  (resEmail != null && resEmail.length > 0);
                if (isMatchPlayer) {
                  break;
                } else if (i === item?.users?.length - 1) {
                  return null;
                }
              }
            } else {
              return null;
            }
          }
        }
        return (
          <div className="col-lg-4 col-md-4 col-sm-6" key={item?.id}>
            <TrainingPlanCard
              item={item}
              setPlanList={this.state.listAnnualTrainingPlan}
            />
          </div>
        );
      })
    );
  };

  render() {
    // console.log("WorkoutLocation", this.state.WorkoutLocation);
    const location = this.state.WorkoutLocation;
    const { name, locationChange, planSearchTxt } = this.state;
    const annualPrograms = this.state.listAnnualTrainingPlan;
    const item = this.state.addProgram;
    const { loading } = this.state;
    return (
      <div className="loader_sec">
        <CoachHeader />
        {loading ? (
          <LoaderWrapper />
        ) : (
          <div className="background_color container-fluid pt-4">
            <CustomBreadcrumb heading={"My Annual Programs"} className="mb-4" />
            <div className="row mb-4">
              <div className="col-12 col-lg-9 col-md-8 col-sm-6">
                <CustomInput
                  className={"col-12 col-lg-9 col-md-12 col-sm-12 px-0"}
                  type="search"
                  placeholder={"Search"}
                  name="planSearchTxt"
                  value={planSearchTxt}
                  onChange={this.onChange}
                  search
                />
              </div>
              <div className=" col-12 col-lg-3 col-md-4 col-sm-6">
                <CustomButton className={"w-100"} onClick={this.showModal}>
                  Add New Program
                </CustomButton>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-4 col-md-4 col-sm-6"  >
                <TrainingPlanCard />
                 </div>  */}
              {annualPrograms?.length > 0 ? (
                this.renderAnnualProgram()
              ) : (
                <div className="align-items-center justify-content-center d-flex col-lg-12 col-sm-12 col-md-12">
                  <NoDataFound text="No annual training program available yet." />
                </div>
              )}
            </div>
          </div>
        )}
        <Footer />
        {/* ReactJs Model For this page */}
        <Modal
          show={this.state.show}
          onHide={() => this.hideModal()}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="programcreate">
                Add Annual Training Plan
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.hideModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <CustomInput
                name="name"
                value={name}
                onChange={this.onChange}
                placeholder="ATP Name"
                error={this.state.nameError || this.state.nameErrorNull}
                className="mb-3"
              />
              <div className="flex">
                <InputDateTimePicker
                  name="DateOfBirth"
                  placeholder={"Start Date"}
                  className="flex mb-3"
                  value={this.state.date}
                  onChange={this.handleDateChange}
                  error={this.state.startDateError}
                />
              </div>
              <div className="flex">
                <InputDateTimePicker
                  name="DateOfBirth"
                  placeholder={"End Date"}
                  className="flex mb-3"
                  value={this.state.endDate}
                  onChange={this.endDateChange}
                  error={this.state.endDateError}
                />
              </div>

              <select
                className="select_class"
                name="locationChange"
                value={locationChange}
                onChange={this.onChange}
              >
                <option value="" disabled selected>
                  Select Location
                </option>
                {location &&
                  location.map((location) => {
                    return (
                      <option value={location.value} key={location.id}>
                        {location.label}
                      </option>
                    );
                  })}
              </select>
              <p className="react_validation mb-3">
                {this.state.locationError}
              </p>

              <div className="flex">
                <CustomButton
                  flex={1}
                  onClick={this.add_annual_training_program}
                  isLoading={this.state.addLoader}
                  disabled={this.state.addLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AnnualTrainingPlan;

const commitCode = () => {
  // return <div className="dashboard-wrapper">
  //   <section className="myteams_wrapper">
  //     <div className="container-fluid pr-0">
  //       <div
  //         className="inner_teamsection"
  //         style={{ padding: "41px ​30p" }}
  //       >
  //         <div className="d-lg-flex justify-content-between align-items-center">
  //           <div className="heading">
  //             My Annual Programs
  //             <div className="form-group">
  //               <input
  //                 type="text"
  //                 className="form-control"
  //                 placeholder="Search Program"
  //                 name="planSearchTxt"
  //                 value={planSearchTxt}
  //                 onChange={(e) => this.onChange(e)}
  //               />
  //             </div>
  //           </div>
  //           <button
  //             className="Model_Btn_term"
  //             href="javascript;"
  //             data-toggle="modal"
  //             data-target="#programcreate"
  //             onClick={() => {
  //               this.showModal();
  //             }}
  //           >
  //             Add New Program{" "}
  //             <i className="fa fa-plus" aria-hidden="true"></i>
  //             {/* </a> */}
  //           </button>
  //         </div>
  //         {annualPrograms.length === 0 && (
  //           // <div>No annual traning program avalable yet</div>
  //           <NoDataFound
  //             height={250}
  //             width={250}
  //             text="No annual traning program available yet."
  //           />
  //         )}
  //         <div className="row mt-5">
  // {annualPrograms &&
  //   annualPrograms.map((item) => {
  //     if (planSearchTxt != null || planSearchTxt != "") {
  //       let mainTerm = item.name;
  //       let subTerm = planSearchTxt;
  //       let searchRegex = new RegExp(subTerm, "i");
  //       let res = mainTerm.match(searchRegex);
  //       let isMatch = res != null && res.length > 0;
  //       if (isMatch == false) {
  //         if (item?.users?.length) {
  //           for (var i = 0; item?.users?.length > i; i++) {
  //             let Name =
  //               item?.users[i]?.first_name +
  //               " " +
  //               item?.users[i]?.last_name;
  //             let subTermName = planSearchTxt;
  //             let searchName = new RegExp(subTermName, "i");
  //             let resName = Name.match(searchName);
  //             let searchEmail = new RegExp(subTermName, "i");
  //             let resEmail =
  //               item?.users[i]?.email.match(searchEmail);
  //             let isMatchPlayer =
  //               (resName != null && resName.length > 0) ||
  //               (resEmail != null && resEmail.length > 0);
  //             if (isMatchPlayer) {
  //               break;
  //             } else if (i == item?.users?.length - 1) {
  //               return null;
  //             }
  //           }
  //         } else {
  //           return null;
  //         }
  //       }
  //     }
  //     // if (planSearchTxt != null || planSearchTxt != "") {
  //     //   const mainTerm = item.name;
  //     //   const subTerm = planSearchTxt;
  //     //   const searchRegex = new RegExp(subTerm, "i");
  //     //   const res = mainTerm.match(searchRegex);
  //     //   const isMatch = res != null && res.length > 0;
  //     //   if (isMatch == false) return null;
  //     // }
  //     console.log("itemmmmmmmmmmmm", item);
  //     return (
  //       <div className="col-lg-3 col-md-4" key={item.id}>
  //         <div className="program_schadual">
  //           <Link
  //             to={{
  //               pathname: `/annualprograminner/${"in_season"}/${"off_season"}/${"pre_season"}/${"transition"}`,
  //               state: item,
  //             }}
  //           >
  //             <div className="program_name">{item.name}</div>
  //             <figure className="">
  //               <Image
  //                 image={item.image}
  //                 className="img-fluid"
  //               />
  //             </figure>
  //           </Link>
  //         </div>
  //       </div>
  //     );
  //   })}
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // </div>
};
