import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
// import Checkbox from "../../component/Checkbox/Checkbox";
import Checkbox from "component/Checkbox/Checkbox";
import { Fragment } from "react";
// import LinearWithValueLabel from "../progressBar/LinerBar";
import { Tooltip } from "@material-ui/core";
import CustomInput from "component/customInput/CustomInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SvgIcon from "component/SvgIcon";
import CustomButton from "component/customButton/CustomButton";

export class CreateCoachLibrary extends Component {
  render() {
    const {
      show,
      onHide,
      parentState,
      onChange,
      addNewTips,
      hendelAddCoachLibrary,
      currentTeamName,
      deleteEvent,
    } = this.props;
    return (
      <div>
        <Modal
          show={show}
          // onHide={onHide}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                Create Coach Library
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={parentState.createLibraryLoader ? null : onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Activity Name"
                  name="activityName"
                  value={parentState.activityName}
                  onChange={(e) => onChange(e)}
                />
              </div>

              <div className="form-group">
                <CustomInput
                  type="file"
                  accept="video/mp4"
                  className={"mb-3"}
                  placeholder="Video link"
                  onChange={this.props?.onChangeExerciseMedia}
                  inputStyle={{ paddingTop: "10px" }}
                />
                {/* <CustomInput
                  type="text"
                  placeholder="Video Link"
                  name="videoName"
                  value={parentState.videoName}
                  onChange={(e) => onChange(e)}
                /> */}
              </div>

              <div className="form-group">
                <CustomInput
                  type="text"
                  value={currentTeamName}
                  readOnly={true}
                />
              </div>

              <div className="form-group">
                <CustomInput
                  type="textarea"
                  name="description"
                  value={parentState.description}
                  onChange={(e) => onChange(e)}
                  placeholder="Description"
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <CustomInput
                  type="text"
                  placeholder="Tips Name"
                  style={{ width: "100%" }}
                  name="tips"
                  value={parentState.tips}
                  onChange={(e) => onChange(e)}
                  error={parentState.tipsError}
                />
                <Tooltip arrow title="Add new tip">
                  <AddCircleIcon
                    onClick={() => addNewTips()}
                    style={{
                      width: "40px",
                      marginLeft: "15px",
                      height: "40px",
                      color: "#F75F03",
                    }}
                  />
                </Tooltip>
              </div>
              {parentState.inputList.map((item, index) => {
                return (
                  <div className="d-flex align-items-center mb-3">
                    <CustomInput
                      style={{ width: "87%" }}
                      type="text"
                      readOnly={true}
                      value={item?.tip}
                    />
                    <Tooltip arrow title="Delete test">
                      <button
                        type="button"
                        onClick={() => deleteEvent(index)}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <SvgIcon
                          name="trash"
                          style={{
                            width: "27px",
                            height: "27px",
                            marginLeft: "22px",
                          }}
                        />
                      </button>
                    </Tooltip>
                  </div>
                );
              })}
              <div className="d-flex">
                <CustomButton
                  flex={1}
                  data-dismiss="modal"
                  onClick={() => hendelAddCoachLibrary()}
                  disabled={parentState.createLibraryLoader}
                  isLoading={parentState.createLibraryLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CreateCoachLibrary;
