import React from "react";
import { Modal } from "react-bootstrap";

function HelpModal(props) {
  return (
    <Modal
      show={props.showModal}
      onHide={props.onHidePress}
      animation={true}
      // size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName="modal_backdrop"
      contentClassName="modal_container"
    >
      <Modal.Body key={Math.random().toString()}>
        <div className="modal-header">
          <h5 className="modal-title" id="how-to-earnTitle">
            {props.modalTitle}
          </h5>
          <button
            type="button"
            className="modal_close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.onHidePress}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ color: "white" }}>
          <ul className="list-inline">
            {props.contentArray &&
              props.contentArray.map((item) => {
                return (
                  <li key={item.point} className="help_modal_content">
                    {"\u2022 " + item.point}
                  </li>
                );
              })}
            {props.subPointArray &&
              props.subPointArray.map((data) => {
                return (
                  <li key={data.point} className="help_modal_SubPoints">
                    {"\u2212 " + data.point}
                  </li>
                );
              })}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default HelpModal;
