import SvgIcon from 'component/SvgIcon';
import React from 'react';
import Popover from '@material-ui/core/Popover';
import CustomCalender from './CustomCalender';

function CustomCalenderPopup({ onChange, value }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'calender-popover' : undefined;

  return (
    <div className='d-flex'>
      <div
        aria-describedby={id}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      >
        <SvgIcon name='calender' />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{
          // width: '35%',
          borderRadius: 14,
        }}
      >
        <div
          style={{
            background: 'var(--valhalla)',
          }}
        >
          <CustomCalender onChange={onChange} value={value} />
        </div>
      </Popover>
    </div>
  );
}

export default CustomCalenderPopup;
