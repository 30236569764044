import React, { Component } from "react";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
import { Link } from "react-router-dom";
import { standardPostApi } from "../API/ApiWrapper";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderWrapper from "../../container/Loader/LoaderWrapper";
import Buttons from "container/Coach After Login/Buttons";
import userImg from "../../Custom/images/user_placeholder.png";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import NoDataFound from "component/lottiLoader/LottiLoader";
import { ImgType } from "helpers/FindImgType";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Athelete: [],
      headsArray: [],
      resultsArray: [],
      loader: false,
      otherActivity: [],
      otherActivityValue: "",
    };
  }

  componentDidMount() {
    this.list_team_player_report();
  }

  list_team_player_report = async () => {
    try {
      this.setState({ loader: true });
      const res = await standardPostApi(
        "list_team_player_report",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: this.props.match.params.id,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log(
        //   'RESPONSE OF list_team_player_report ->',
        //   res.data.data.Athelete
        // );
        // await this.setState({ Athelete: res.data.data.Athelete });
        let aths = res.data.data.Athelete.sort((a, b) => {
          const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        let head = aths[0].activities;
        let ath_results = [];
        let heads = [];

        aths.forEach((item) => {
          let other = item?.other_activities?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.value,
            0
          );
          ath_results.push([
            <Link
              to={{
                pathname: `/playerreport/${this.props.match.params.id}/${item.id}/${this.props.match.params.teamname}`,
                state: { player: item },
              }}
              style={{ color: "white" }}
            >
              <img
                // src={item?.profile_image}
                src={
                  item.profile_image
                    ? ImgType(item?.profile_image)
                      ? item?.profile_image
                      : userImg
                    : userImg
                }
                alt="No Img..."
                className="table-usr-img"
              />

              {`${item.first_name}  ${item.last_name} (${item?.email})`}
            </Link>,

            ...Object.values(item.activities),
            other,
          ]);
        });
        heads = ["Player Name", ...Object.keys(head)];
        console.log(
          "Value",
          aths?.map((x) => Object.values(x?.other_activities))
        );
        console.log("setValue", Object.values(aths[0]?.other_activities));
        await this.setState({
          headsArray: heads,
          resultsArray: ath_results,
          otherActivity: aths?.map((x) => Object.values(x?.other_activities)),
          // loader: false,
        });
        // console.log(
        //   'heads ',
        //   this.state.headsArray,
        //   ' & ath_results ',
        //   this.state.resultsArray
        // );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loader: false });
    }
  };

  //
  onOtherActivitySelect = (e) => {
    this.setState({ otherActivityValue: e.target.value });
  };
  render() {
    const { headsArray } = this.state;
    console.log("this is Athlete", this.state.resultsArray);
    const coachRoles = localStorage.getItem("access_role");
    return (
      <div className="loader_sec">
        <CoachHeader />
        <div className="dashboard-wrapper">
          <section className="myteams_wrapper">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 side-buttons left-side-section">
                <Buttons
                  coachRoles={coachRoles}
                  props={this.props}
                  isActive="reports"
                />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 right-side-section">
                <CustomBreadcrumb
                  heading={"My Players"}
                  className={"mb-4"}
                  navList={[
                    {
                      name: "My Team",
                      link: `/myteamwrapper`,
                    },
                    {
                      name: this.props.match.params.teamname,
                      link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                    },
                    {
                      name: "Reports",
                    },
                  ]}
                />
                {this.state.resultsArray?.length === 0 &&
                  !this.state.loader && (
                    <NoDataFound
                      height={250}
                      width={250}
                      text="No data avalable yet"
                    />
                  )}
                {/* <Buttons coachRoles={coachRoles} props={this.props}  isActive="reports"/> */}
                {this.state.loader ? (
                  <LoaderWrapper />
                ) : (
                  <div className="table-responsive mt-5 ">
                    <table className="table">
                      <thead>
                        <tr className="react_Testing_Table">
                          {headsArray.map((item, index) => {
                            return <th key={index}>{item}</th>;
                          })}
                          {this.state.otherActivity.length > 0 && (
                            <th>Others</th>
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.resultsArray.map((item, index) => {
                          return (
                            <tr className="react_Testing_Table" key={index}>
                              {item.map((i, index) => {
                                return <td key={index}>{i}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Reports;
