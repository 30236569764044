import "./CustomButton.css";
import React from "react";
function CustomButton({
  type = "filled",
  onClick,
  className,
  style,
  children,
  disabled,
  isLoading,
  dataDismiss,
  flex,
  id,
}) {
  return (
    <button
      id={id}
      data-dismiss={dataDismiss}
      className={`${
        type === "filled" ? "custom_button" : "custom_button_outlined"
      } ${className}`}
      style={{
        flex: flex,
        ...style,
      }}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {isLoading && <i className="fa fa-spinner fa-pulse mr-2" />}
      {children}
    </button>
  );
}

export default CustomButton;
