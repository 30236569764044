import React from "react";
import "./ActionButton.scss";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import SvgIcon from "component/SvgIcon";
function ActionButton({ type = "add", onClick, className, svgName }) {
  return (
    <div className="text-center">
      <div className={`actionButton_container ${className}`} onClick={onClick}>
        <SvgIcon
          name={
            type === "add"
              ? "green_ellipse"
              : type === "delete"
              ? "red_ellipse"
              : type === "green"
              ? "green_ellipse"
              : type === "red"
              ? "red_ellipse"
              : "yellow_ellipse"
          }
          className={"color_svg"}
        />

        {type === "add" ? (
          <AddRoundedIcon
            style={{
              height: 60,
              width: 60,
              color: "#5BB85D",
            }}
          />
        ) : (
          <SvgIcon
            name={svgName ? svgName : type === "delete" ? "trash" : "pencil"}
            style={{
              height: 30,
            }}
          />
        )}
      </div>
      <span
        style={{
          fontSize: "14px",
          color:
            type === "add"
              ? "#5BB85D"
              : type === "delete"
              ? "#E81E16"
              : "#EFAD4D",
        }}
      >
        {type === "add"
          ? "Create"
          : type === "delete"
          ? "Delete"
          : type === "edit"
          ? "edit"
          : "export"}
      </span>
    </div>
  );
}

export default ActionButton;
