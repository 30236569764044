import React from "react";
import { Tooltip } from "@material-ui/core";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import "./CoachAlternativeExercise.scss";
function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
export class ViewCoachAlternativeExerciseModal extends Component {
  render() {
    const { show, onHide, parentState } = this.props;
    const url = parentState?.exerciseObj?.video;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body className="viewCoachAlternativeExerciseModal_container">
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                View Alternative Exercise
              </h5>
              <Tooltip arrow title="Esc">
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Tooltip>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div>
                  <p className="heading-text">Exercise Group Name</p>
                  <p className="heading-answer">
                    {parentState?.exerciseObj?.exercise_group?.exercise_group}
                  </p>
                </div>

                <div>
                  <p className="heading-text">Exercise Name</p>
                  <p className="heading-answer">
                    {parentState?.exerciseObj?.exercise}
                  </p>
                </div>

                {parentState?.exerciseObj?.video ? (
                  <div>
                    <p className="heading-text"> Exercise Video</p>
                    {parentState?.exerciseObj?.type_media === "video" ? (
                      <video
                        src={parentState?.exerciseObj?.video}
                        width="100%"
                        height="315"
                        autoPlay
                        controls
                      />
                    ) : (
                      <iframe
                        width="100%"
                        height={
                          parentState?.exerciseObj?.type_media === "pdf"
                            ? "500"
                            : "315"
                        }
                        src={
                          parentState?.exerciseObj?.type_media === "pdf"
                            ? parentState?.exerciseObj?.video
                            : `https://www.youtube.com/embed/${urlId}`
                        }
                        title=" video"
                        frameborder="0"
                        //   allow="autoplay; encrypted-media"
                        allowFullScreen
                      ></iframe>
                    )}
                  </div>
                ) : (
                  <div>
                    <p className="heading-text"> Exercise Description</p>
                    <p style={{ color: "white" }}>
                      {parentState?.exerciseObj?.description}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ViewCoachAlternativeExerciseModal;
