import React from "react";
import SportWrapper from "./SportWrapper";

function index() {
  return (
    <div>
      <SportWrapper />
    </div>
  );
}

export default index;
