import React from "react";
import { Modal } from "react-bootstrap";

function PlayerDetailModal({ item }) {
  console.log("item=========>>>>>>>>", item);
  return (
    <div>
      <div className="form-group">
        <span className="edit-madical-status-span">Name</span>
        <p className="edit-madical-status-p">
          {item?.first_name} {item?.last_name}
        </p>
      </div>
      <div className="form-group">
        <span className="edit-madical-status-span">Email Address</span>
        <p className="edit-madical-status-p">{item?.email}</p>
      </div>
      <div className="form-group">
        <span className="edit-madical-status-span">Team Name</span>
        <p className="edit-madical-status-p">
          {item?.assign_team?.length !== 0
            ? `${item?.assign_team[0]?.name}  (${item?.sports?.name})`
            : "N/A"}
          {}
        </p>
      </div>

      <div className="form-group">
        <span className="edit-madical-status-span">ATP’s</span>
        {item?.assign_atp?.length !== 0 ? (
          item?.assign_atp.map((data) => {
            return (
              <p className="edit-madical-status-p" key={data?.id}>
                {data?.name}
              </p>
            );
          })
        ) : (
          <p className="edit-madical-status-p">N/A</p>
        )}
      </div>
    </div>
  );
}

export default PlayerDetailModal;
