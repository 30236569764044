import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import React from "react";
import { Form } from "react-bootstrap";

export default class FourthModal extends React.Component {
  state = { weightType: 0, weight: "pounds" };

  render() {
    const { bodyWeight, sleep_level, water_level } = this.props.value;
    const { weightType, weight } = this.state;
    return (
      <div className="row">
        <div className="col-md-12 ">
          <Form>
            <div className="form-group">
              <label className="label_color">What is your Body Weight?</label>
              <div style={{ display: "flex" }}>
                <CustomInput
                  style={{ width: "80%", marginRight: "15px" }}
                  placeholder={`in ${weight}`}
                  name="bodyWeight"
                  value={bodyWeight}
                  onChange={this.props.onChange}
                  maxLength={5}
                />
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder={`in ${weight}`}
                  name="bodyWeight"
                  value={bodyWeight}
                  onChange={this.props.onChange}
                  onKeyPress={this.props.onKeyPress}
                  maxLength={5}
                  style={{ width: "85%" }}
                /> */}
                <div
                  style={{
                    backgroundColor: "var(--valhalla)",
                    width: "20%",
                    borderRadius: "25px",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {/* <CustomButton
                    onClick={() => {
                      this.setState({ weightType: 0, weight: "pounds" });
                    }}
                  >
                    lbs
                  </CustomButton> */}
                  <button
                    type="button"
                    style={
                      weightType === 0
                        ? {
                            padding: "4px 8px",
                            fontSize: "12px",
                            color: "rgb(255, 255, 255)",
                            border: "1px solid var(--safetyOrange)",
                            marginRight: "5px",
                            backgroundColor: "var(--safetyOrange)",
                            fontWeight: "500",

                            cursor: "pointer",
                            borderRadius: "5px",
                          }
                        : {
                            padding: "4px 8px",
                            color: "white",
                            fontSize: "12px",
                            border: "1px solid white",
                            marginRight: "5px",
                            backgroundColor: "transparent",
                            fontWeight: "500",
                            borderRadius: "5px",

                            cursor: "pointer",
                          }
                    }
                    onClick={() => {
                      this.setState({ weightType: 0, weight: "pounds" });
                    }}
                  >
                    lbs
                  </button>
                  {/* <CustomButton
                    onClick={() => {
                      this.setState({ weightType: 1, weight: "kg" });
                    }}
                  >
                    Kg
                  </CustomButton> */}
                  <button
                    type="button"
                    style={
                      weightType === 1
                        ? {
                            padding: "4px 8px",
                            fontSize: "12px",
                            color: "rgb(255, 255, 255)",
                            border: "1px solid var(--safetyOrange)",
                            borderRadius: "5px",
                            backgroundColor: "var(--safetyOrange)",
                            fontWeight: "500",
                            cursor: "pointer",
                          }
                        : {
                            padding: "4px 8px",
                            fontSize: "12px",
                            color: "white",
                            border: "1px solid white",
                            borderRadius: "5px",
                            backgroundColor: "transparent",
                            fontWeight: "500",
                            // borderTopLeftRadius: "5px",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => {
                      this.setState({ weightType: 1, weight: "kg" });
                    }}
                  >
                    kg
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="label_color">
                How many hours did you sleep?
              </label>
              <CustomInput
                placeholder="in hours"
                name="sleep_level"
                value={sleep_level}
                onChange={this.props.onChange}
                maxLength={2}
              />
              {/* <input
                type="text"
                className="form-control"
                placeholder="in hours"
                name="sleep_level"
                value={sleep_level}
                onChange={this.props.onChange}
                onKeyPress={this.props.onKeyPress}
                maxLength={2}
              /> */}
            </div>
            <div className="form-group">
              <label className="label_color">
                How much water have you intake?{" "}
              </label>
              <CustomInput
                placeholder="in liters"
                name="water_level"
                value={water_level}
                onChange={this.props.onChange}
                maxLength={2}
              />
              {/* <input
                type="text"
                className="form-control"
                placeholder="in liters"
                name="water_level"
                value={water_level}
                onChange={this.props.onChange}
                onKeyPress={this.props.onKeyPress}
                maxLength={2}
              /> */}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {/* <CustomButton
                onClick={() => this.props.workout_sleep_water_level(weightType)}
                id="exercise-input-btn-save"
              >
                Submit
              </CustomButton> */}
              <button
                type="button"
                id="exercise-input-btn-save"
                // className="btn btn-success col-md-2 col-md-offset-2"
                // onClick={() => this.props.fourthModalfunction()}
                onClick={() => this.props.workout_sleep_water_level(weightType)}
                style={{
                  height: "42px",
                  background: "#f75f03",
                  borderRadius: "21px",
                  border: "none",
                  fontSize: "14px",
                  color: "#141223",
                  cursor: "pointer",
                  padding: "0 25px",
                  fontWeight: "500",
                }}
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
