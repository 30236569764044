import React from "react";
import { Link } from "react-router-dom";
import Header from "../PublicLayout/Header";
import Footer from "container/PublicLayout/Footer";
import {
  standardPostApi,
  standardPostApiJsonBased,
} from "container/API/ApiWrapper";
import { successToast } from "utils/toastMessage";
import { connect } from "react-redux";
import { FORGOT_PASSWORD_EMAIL } from "store/actions/athleteAction/athlete";
import userImg from "Custom/images/sign_in_as_coach.png";
import CustomInput from "component/customInput/CustomInput";
import CustomButton from "component/customButton/CustomButton";

class EmailWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", isLoading: false, emailError: "" };
  }

  // componentDidMount() {
  //   alert("coming soon!");
  // }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log('on Change', this.state);
  };

  handelForgotPassword = async () => {
    const isValid = this.validation();

    if (isValid) {
      this.setState({ isLoading: true });
      try {
        const res = await standardPostApiJsonBased(
          "forgot_password",
          undefined,
          { email: this.state.email },
          true
        );
        if (res.data.code === 200) {
          // console.log('response of forgot password', res.data);
          successToast(res.data.message);
          this.props.FORGOT_PASSWORD_EMAIL(this.state.email);

          this.props.history.push("/passwordconfirmation");
        }
      } catch (error) {
        console.error("error of forgot passsword", error);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  validation = () => {
    let emailError = "";
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!this.state.email) {
      emailError = "The Email field is required.";
    } else if (!this.state.email.match(emailReg)) {
      emailError = "This Email Address is not valid. ";
    }

    if (emailError) {
      this.setState({ emailError });
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { email, isLoading, emailError } = this.state;
    return (
      <div className="loader_sec">
        <Header />

        <div className="login-page-wrapper">
          <section className="login_page">
            <div
              className="container"
              style={{ marginTop: "2%", marginBottom: "17vw" }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={userImg}
                    alt="no_img"
                    className="img-fluid"
                    // style={{ width: '100%' }}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <h2 style={{ color: "#fff", textAlign: "center" }}>
                    Forgot Password
                  </h2>
                  <h6
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      textAlign: "center",
                      marginBottom: "revert",
                    }}
                  >
                    Enter your registered email address to reset your password.
                  </h6>

                  {/* <div className='col-lg-6 '> */}
                  <div className="form-group">
                    <CustomInput
                      placeholder="Email address"
                      type="email"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.handelForgotPassword();
                        }
                      }}
                      error={emailError}
                    ></CustomInput>
                    {/* <label style={{ color: '#fff', textAlign: 'center' }}>
                        Email
                      </label>
                      <input
                        className='form-control'
                        type='email'
                        name='email'
                        value={email}
                        onChange={this.onChange}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            this.handelForgotPassword();
                          }
                        }}
                      />

                      <p
                        className='react_validation'
                        style={{ marginTop: '5px' }}
                      >
                        {emailError}
                      </p> */}
                  </div>
                  <div className="d-flex">
                    <CustomButton
                      onClick={() => this.handelForgotPassword()}
                      disabled={isLoading}
                      isLoading={isLoading}
                      flex={1}
                    >
                      Next
                    </CustomButton>
                  </div>
                  {/* <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '5%',
                    }}
                  > */}
                  {/* <Link to="/passwordconfirmation"> */}
                  {/* <button
                      // type="submit"
                      className='btn btn-default forgot_Password_Email_button'
                      style={{ padding: '6px 52px' }}
                      onClick={() => this.handelForgotPassword()}
                      disabled={isLoading}
                    >
                      Submit{' '}
                      {isLoading && <i className='fa fa-spinner fa-pulse' />}
                    </button> */}
                  {/* </Link> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

// export default EmailWrapper;

const mapDispatchToProps = (dispatch) => {
  return {
    FORGOT_PASSWORD_EMAIL: (data) => dispatch(FORGOT_PASSWORD_EMAIL(data)),
  };
};
export default connect(null, mapDispatchToProps)(EmailWrapper);
