import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import Checkbox from "component/Checkbox/Checkbox";
import { Tooltip } from "@material-ui/core";
import { Fragment } from "react";

// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../Custom/images/celender.jpg";
import moment from "moment";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CustomButton from "component/customButton/CustomButton";
import CustomRadioButton from "component/customRadioButton/CustomRadioButton";

export class AddCriteriaResutModal extends Component {
  render() {
    const {
      show,
      onHide,
      parentState,
      onChange,
      handleAddDateChange,
      handelAddUserMadicalStatus,
      selectPickerName,
      toggleTypeButton,
      toggleTypeOutButton,
    } = this.props;
    const coachRoles = localStorage.getItem("access_role");
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h3 className="modal-title" id="protocol">
                Edit Medical Status
              </h3>
              <Tooltip arrow title="Close">
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Tooltip>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <span className="edit-madical-status-span">Team Name</span>
                <p className="edit-madical-status-p">
                  {parentState?.playerObj?.team_name ?? "N/A"}
                </p>
              </div>

              <div className="form-group">
                <span className="edit-madical-status-span">Player Name</span>
                <p className="edit-madical-status-p">{`${parentState?.playerObj?.user_first_name} ${parentState?.playerObj?.user_last_name}`}</p>
              </div>

              <div className="form-group">
                <span className="edit-madical-status-span">
                  Medical Criteria Name
                </span>
                <p className="edit-madical-status-p">{selectPickerName}</p>
              </div>

              <div className="form-group">
                <span className="edit-madical-status-span">Criteria Name</span>
                <p className="edit-madical-status-p">
                  {parentState?.criteriaObj?.name}
                </p>
              </div>
              <div className="form-group">
                <span className="edit-madical-status-span">Date</span>
                <p className="edit-madical-status-p">
                  {moment(parentState?.date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="form-group">
                <CustomInput
                  type="textarea"
                  rows="3"
                  cols="70"
                  name="comment"
                  value={parentState.comment}
                  onChange={(e) => onChange(e)}
                  readOnly={coachRoles === "Assistant Coach"}
                  error={parentState.commentError}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="form-group">
                  <span className="edit-madical-status-span">Type</span>
                  <Tooltip title="Tap this button to switch out and in">
                    <div className="d-flex">
                      <CustomRadioButton
                        title="Available"
                        isChecked={
                          parentState.inOutSwitchBtn &&
                          parentState.inOutSwitchBtn === "in"
                            ? false
                            : true
                        }
                        onClick={() => toggleTypeOutButton("in")}
                        disabled={coachRoles === "Assistant Coach"}
                        className={"mr-3"}
                      />
                      <CustomRadioButton
                        title="Unavailable"
                        isChecked={
                          parentState.inOutSwitchBtn &&
                          parentState.inOutSwitchBtn === "out"
                            ? false
                            : true
                        }
                        onClick={() => toggleTypeButton("out")}
                        disabled={coachRoles === "Assistant Coach"}
                        className={"ml-3"}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="d-flex">
                {coachRoles !== "Assistant Coach" && (
                  <CustomButton
                    flex={1}
                    onClick={() => handelAddUserMadicalStatus()}
                    disabled={parentState.addLodaer}
                    isLoading={parentState.addLodaer}
                    data-dismiss="modal"
                    style={{ fontWeight: "bold" }}
                  >
                    Edit
                  </CustomButton>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddCriteriaResutModal;
