import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import { standardPostApi } from "container/API/ApiWrapper";
import React, { useState } from "react";
import { successToast } from "utils/toastMessage";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

function ChangePassword({ handleClose }) {
  const [oldVisibili, setOldVisibili] = useState(false);
  const [newVisibili, setNewVisibili] = useState(false);
  const [confirmVisibili, setConfirmVisibili] = useState(false);
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [error, setError] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const validation = () => {
    let tempError = error;
    let isError = false;
    const { new_password, confirm_password, old_password } = passwords;
    // changed regex
    let passReg =
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;

    if (!old_password) {
      isError = true;
      tempError = {
        ...tempError,
        old_password: "The old password field is required.",
      };
    }
    if (!new_password) {
      isError = true;
      tempError = {
        ...tempError,
        new_password: "The new password field is required.",
      };
    } else if (!new_password?.match(passReg)) {
      isError = true;
      tempError = {
        ...tempError,
        new_password:
          "Password must have minimum 8 characters, at least one number, at least one upper case, at least one lower case, at least one special character",
      };
    }
    if (!confirm_password) {
      isError = true;
      tempError = {
        ...tempError,
        confirm_password: "This Confirm New Password field is required",
      };
    } else if (!confirm_password.match(new_password)) {
      isError = true;
      tempError = {
        ...tempError,
        confirm_password:
          "New password and confirmation password do not match.",
      };
    }
    setError(tempError);
    return isError;
  };
  const refreshPage = async () => {
    await window.location.reload(false);
  };

  const onChangePassword = async () => {
    const isValid = validation();
    if (!isValid) {
      try {
        setIsLoading(true);
        const res = await standardPostApi(
          "change_password",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            ...passwords,
          },
          true
        );
        // console.log("this is responser", res.data.data);
        if (res.data.code === 200) {
          successToast(res?.data?.message);
          handleClose && handleClose();
          return null;
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onHandleChange = (event) => {
    const { value, name } = event?.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });

    error[name] &&
      setError({
        ...error,
        [name]: "",
      });
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <CustomInput
          placeholder={"Old Password"}
          type={oldVisibili === false ? "password" : "text"}
          className={"mb-3"}
          value={passwords?.old_password}
          error={error?.old_password}
          name="old_password"
          onChange={onHandleChange}
        />
        {oldVisibili === false ? (
          <VisibilityIcon
            className="addPlayer-pass-hide"
            color="white"
            onClick={() => setOldVisibili(!oldVisibili)}
            style={{
              height: "20px",
              position: "absolute",
              top: "15px",
              right: "14px",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        ) : (
          <VisibilityOffIcon
            className="addPlayer-pass-hide"
            color="white"
            onClick={() => setOldVisibili(!oldVisibili)}
            style={{
              height: "20px",
              position: "absolute",
              top: "15px",
              right: "14px",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        )}
      </div>
      <div style={{ position: "relative" }}>
        <CustomInput
          placeholder={"New Password"}
          type={newVisibili === false ? "password" : "text"}
          className={"mb-3"}
          value={passwords?.new_password}
          error={error?.new_password}
          onChange={onHandleChange}
          name="new_password"
        />
        {newVisibili === false ? (
          <VisibilityIcon
            className="addPlayer-pass-hide"
            color="white"
            onClick={() => setNewVisibili(!newVisibili)}
            style={{
              height: "20px",
              position: "absolute",
              top: "15px",
              right: "14px",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        ) : (
          <VisibilityOffIcon
            className="addPlayer-pass-hide"
            color="white"
            onClick={() => setNewVisibili(!newVisibili)}
            style={{
              height: "20px",
              position: "absolute",
              top: "15px",
              right: "14px",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        )}
      </div>

      <div style={{ position: "relative" }}>
        <CustomInput
          placeholder={"Confirm Password"}
          type={confirmVisibili === false ? "password" : "text"}
          className={"mb-3"}
          onChange={onHandleChange}
          value={passwords?.confirm_password}
          error={error?.confirm_password}
          name="confirm_password"
        />
        {confirmVisibili === false ? (
          <VisibilityIcon
            className="addPlayer-pass-hide"
            color="white"
            onClick={() => setConfirmVisibili(!confirmVisibili)}
            style={{
              height: "20px",
              position: "absolute",
              top: "15px",
              right: "14px",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        ) : (
          <VisibilityOffIcon
            className="addPlayer-pass-hide"
            color="white"
            onClick={() => setConfirmVisibili(!confirmVisibili)}
            style={{
              height: "20px",
              position: "absolute",
              top: "15px",
              right: "14px",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        )}
      </div>

      <div className="d-flex">
        <CustomButton flex={1} onClick={onChangePassword} isLoading={isLoading}>
          Save
        </CustomButton>
      </div>
    </div>
  );
}

export default ChangePassword;
