import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import CustomModal from "component/customModal/CustomModal";
import React from "react";
import { Modal } from "react-bootstrap";
import Checkbox from "../../component/Checkbox/Checkbox";
import UpdateWorkout from "./UpdateWorkout";
import Select from "react-select";
class SuperSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateModal: false,
    };
  }
  showModal = () => {
    this.setState({ showUpdateModal: true });
  };
  hideModal = () => {
    this.setState({ showUpdateModal: false });
  };
  render() {
    const exerciseData = this.props.workoutGroupIdForExerciseObject;
    const {
      repsData,
      loadData,
      repetitionType,
      setsWorkoutExercise,
      restWorkoutExercise,
      // updateExerciseField,
      updateRepsData,
      // updateLoadData,
      updateRepetitionType,
      updateSetsWorkoutExercise,
      updateRestWorkoutExercise,
    } = this.props.value;
    const {
      updateExerciseField,
      workoutExerciseLoader,
      updateLoadData,
      warningMessage,
    } = this.props;

    // console.log({ warningMessage });
    // const {  } = this.props;
    // console.log("dd", updateLoadData);

    // console.log(
    //   "THIS IS updateExerciseField--->",
    //   this.props.exerciseGroupName
    // );

    const exerciseGroupName = this.props.exerciseGroupName?.map((item) => {
      return { label: item?.exercise, value: item?.id, id: item?.id };
    });
    return (
      <div
        className="col-lg-12 col-md-12 col-sm-12 p-3 mt-3"
        style={{ backgroundColor: "var(--blackRussian)" }}
      >
        {warningMessage && (
          <div className="react_validation px-0">{warningMessage}</div>
        )}

        <div className="week_heading">
          {exerciseData.group_name} - {exerciseData.group_set_type}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <Select
              defaultValue={this.props.exerciseGroupId}
              onChange={this.props.onHandelChange}
              options={this.props.preWorkoutGroupExercise}
              placeholder="Select Exercise Group"
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#2d2b40",
                  color: "#fff",
                  "&:hover": {
                    background: state.isFocused ? "#f75f03" : "#f75f03",
                  },
                }),
                control: (base, state) => ({
                  ...base,
                  background: "#2d2b40",
                  borderRadius: "25px",
                  borderColor: "#2d2b40",
                  boxShadow: state.isFocused ? null : null,
                  height: "50px",
                  marginBottom: "1rem",
                  padding: "0px 10px",
                  color: "#fff",
                  "&:hover": {
                    borderColor: state.isFocused ? "#2d2b40" : "#2d2b40",
                  },
                }),
                menu: (base) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                }),
                menuList: (base) => ({
                  ...base,
                  padding: 0,
                }),
                placeholder: (base, state) => ({
                  color: "#FFF",
                  display: state.isFocused ? "none" : "block",
                }),
                singleValue: () => ({ color: "#FFF" }),
                input: () => ({ color: "#FFF" }),
                indicatorSeparator: () => ({ display: "none" }),
              }}
            />
            {/* <select
              className="select_class mb-3"
              onChange={this.props.onHandelChange}
              value={this.props.exerciseGroupId}
            >
              <option value="">Select Exercise Group</option>
              {this.props.preWorkoutGroupExercise &&
                this.props.preWorkoutGroupExercise.map((data) => {
                  return (
                    <option value={data.value} key={data.id}>
                      {data.label}
                    </option>
                  );
                })}
            </select> */}
            <CustomInput
              className={"mb-3"}
              name="repsData"
              value={(repsData, this.props.resertRepsData)}
              onChange={this.props.onChange}
              error={this.props.repsDataError}
              placeholder="Reps"
            />
            <div className="d-flex">
              <Checkbox
                checked={this.props.loadRequiredChecked}
                toggleCb={() => this.props.toggleCb()}
              />
              <p className="mt-1" style={{ fontSize: "12px" }}>
                Load Required?
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <Select
              defaultValue={this.props.exerciseGroupId}
              onChange={(e) => this.props.SelectExercisePicker(e)}
              options={exerciseGroupName}
              placeholder="Select Exercise Name"
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#2d2b40",
                  color: "#fff",
                  "&:hover": {
                    background: state.isFocused ? "#f75f03" : "#f75f03",
                  },
                }),
                control: (base, state) => ({
                  ...base,
                  background: "#2d2b40",
                  borderRadius: "25px",
                  borderColor: "#2d2b40",
                  boxShadow: state.isFocused ? null : null,
                  height: "50px",
                  marginBottom: "1rem",
                  padding: "0px 10px",
                  color: "#fff",
                  "&:hover": {
                    borderColor: state.isFocused ? "#2d2b40" : "#2d2b40",
                  },
                }),
                menu: (base) => ({
                  ...base,
                  borderRadius: 0,
                  marginTop: 0,
                }),
                menuList: (base) => ({
                  ...base,
                  padding: 0,
                }),
                placeholder: (base, state) => ({
                  color: "#FFF",
                  display: state.isFocused ? "none" : "block",
                }),
                singleValue: () => ({ color: "#FFF" }),
                input: () => ({ color: "#FFF" }),
                indicatorSeparator: () => ({ display: "none" }),
              }}
            />
            {/* <select
              className="select_class mb-3"
              onChange={(e) =>
                this.props.SelectExercisePicker(
                  JSON.parse(decodeURIComponent(e.target.value))
                )
              }
              value={this.props.exerciseGroupId}
            >
              <option value="">
                {this.props.exercisePickerName
                  ? this.props.exercisePickerName
                  : "Select Exercise Name"}
              </option>
              {this.props.exerciseGroupName &&
                this.props.exerciseGroupName.map((data) => {
                  return (
                    <option
                      value={encodeURIComponent(JSON.stringify(data))}
                      key={data.id}
                    >
                      {data?.exercise}
                    </option>
                  );
                })}
            </select> */}
            <select
              className="select_class"
              name="repetitionType"
              value={(repetitionType, this.props.resetRepetitionType)}
              onChange={this.props.onChange}
            >
              <option value="">Select Repetition Type </option>
              <option value="repetition">Repetition</option>
              <option value="minutes">Minutes</option>
              <option value="seconds">Seconds</option>
            </select>
            <p className="react_validation">{this.props.repetitionTypeError}</p>
            <div className="d-flex">
              <Checkbox
                checked={this.props.repsEachside}
                toggleCb={() => this.props.toggleRips()}
              />
              <p className="mt-1" style={{ fontSize: "12px" }}>
                Reps Each Side?
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            {this.props.loadRequiredChecked !== 0 && (
              <CustomInput
                type="number"
                className={"mb-3"}
                name="loadData"
                value={(loadData, this.props.resertLoadData)}
                onChange={this.props.onChange}
                placeholder="Load"
                error={this.props.loadDataError}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            {Number(exerciseData.number_of_exercise) === 1 && (
              <CustomInput
                type="number"
                className={"mb-3"}
                name="setsWorkoutExercise"
                value={(setsWorkoutExercise, this.props.setsWorkoutExercise)}
                onChange={this.props.onChange}
                placeholder="Sets"
                error={this.props.setsWorkoutExerciseError}
              />
            )}
            <div className="d-flex">
              <CustomButton
                flex={1}
                isLoading={workoutExerciseLoader}
                disabled={
                  workoutExerciseLoader || warningMessage ? false : true
                }
                onClick={
                  this.props.annual_training_program_workout_group_exercise
                }
              >
                Save
              </CustomButton>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            {Number(exerciseData.number_of_exercise) === 1 && (
              <CustomInput
                type="number"
                className={"mb-3"}
                name="restWorkoutExercise"
                value={(restWorkoutExercise, this.props.restWorkoutExercise)}
                onChange={this.props.onChange}
                placeholder="Rest"
                error={this.props.restWorkoutExerciseError}
              />
            )}
          </div>
        </div>

        <div className="table-responsive mt-5 table_react_cursor">
          <table className="table">
            <thead>
              {Number(exerciseData.number_of_exercise) === 1 ? (
                <tr className="react_Testing_Table">
                  <th>Exercise</th>
                  <th>Reps</th>
                  <th>Repetition type</th>
                  <th>Load</th>
                  <th>Sets</th>
                  <th>Rest</th>
                  <th></th>
                </tr>
              ) : (
                <tr className="react_Testing_Table">
                  <th>Exercise</th>
                  <th>Reps</th>
                  <th>Repetition type</th>
                  <th>Load</th>
                  <th></th>
                </tr>
              )}
            </thead>

            {/* this is check update start */}
            {/* {this.props.visiblePreWorkoutGroupExercise ? ( */}
            <tbody>
              {this.props.workout_group_exercise_Array &&
                this.props.workout_group_exercise_Array.map((item, index) => {
                  return (
                    //   <table
                    //     className="table table-condensed react_workout_table"
                    //     key={item.id}
                    //   >

                    <tr className="react_Testing_Table" key={index}>
                      <td>
                        {item.workout_exercise_name}
                        {/* <input
                          type="text"
                          disabled="disabled"
                          className="form-control col-md-12 builder-exercise"
                          id="workout-builder-row-0"
                          name="updateExerciseField"
                          value={item.workout_exercise_name}
                          onChange={this.props.onChange}
                        /> */}

                        {/* <button
                          className="btn btn-md  btn_update_exercise_react "
                          onClick={() =>
                            this.props.showUpdateGroupExerciseModal(item, index)
                          }
                        >
                          <i className="fas fa-dumbbell"></i>
                          Update Exercise
                        </button> */}
                      </td>
                      <td>
                        {updateRepsData
                          ? updateRepsData[index]
                          : item.workout_reps}
                        {/* <input
                          type="text"
                          className="form-control col-md-12 builder-reps"
                          defaultValue={
                            updateRepsData
                              ? updateRepsData[index]
                              : item.workout_reps
                          }
                          name="updateRepsData"
                          onChange={(e) =>
                            this.props.handelUpdateRepsData(e, index)
                          }
                        /> */}
                      </td>
                      <td>
                        {
                          (updateRepetitionType,
                          item.workout_repetition_type.charAt(0).toLowerCase() +
                            item.workout_repetition_type.slice(1))
                        }
                        {/* <select
                          className="form-control col-md-12 superset_select_react"
                          name="updateRepetitionType"
                          defaultValue={
                            (updateRepetitionType,
                            item.workout_repetition_type
                              .charAt(0)
                              .toLowerCase() +
                              item.workout_repetition_type.slice(1))
                          }
                          onChange={this.props.onChange}
                        >
                          <option value="">Select Repetition Type</option>

                          <option value="repetition">Repetition</option>
                          <option value="minutes">Minutes</option>
                          <option value="seconds">Seconds</option>
                        </select> */}
                      </td>
                      <td>
                        {item.workout_load_required === "0"
                          ? "0"
                          : updateLoadData[index]
                          ? updateLoadData[index]
                          : item.workout_load}
                        {/* <input
                          type="text"
                          className="form-control col-md-12 builder-load"
                          defaultValue={
                            item.workout_load_required === "0"
                              ? ""
                              : updateLoadData[index]
                              ? updateLoadData[index]
                              : item.workout_load
                          }
                          name="updateLoadData"
                          onChange={(e) =>
                            this.props.handleUploadLoadDataName(e, index)
                          }
                          disabled={
                            item.workout_load_required == "1" ? false : true
                          }
                          style={
                            item.workout_load_required == "1"
                              ? { backgroundColor: "#fff" }
                              : {
                                  backgroundColor: "#A9A9A9	",
                                  border: "1px solid #A9A9A9",
                                  cursor: "not-allowed",
                                }
                          }
                          title={
                            item.workout_load_required == "1"
                              ? ""
                              : "Please enable load required to fill load"
                          }
                        /> */}
                      </td>
                      {/* <td>
                        <Checkbox
                          checked={
                            item.workout_load_required == "1" ? true : false
                            // item.workout_load_required == 1 ? 1 : 0
                          }
                          toggleCb={() =>
                            this.props.toggleUpdateLoadRequired(item, index)
                          }
                        />
                      </td> */}
                      {/* <td>
                        <Checkbox
                          checked={
                            item.workout_reps_each_side == "1" ? true : false
                          }
                          toggleCb={() =>
                            this.props.toggleUpdateRepsEachSide(item, index)
                          }
                        />
                      </td> */}

                      {Number(exerciseData.number_of_exercise) === 1 ? (
                        <>
                          <td>
                            {(updateSetsWorkoutExercise, item.workout_sets)}
                            {/* <input
                              style={{
                                backgroundColor: "#bfbbbb",
                                textAlign: "center",
                                pointerEvents: "none",
                              }}
                              type="text"
                              className="form-control col-md-12 builder-reps"
                              defaultValue={
                                (updateSetsWorkoutExercise, item.workout_sets)
                              }
                              name="updateSetsWorkoutExercise"
                              onChange={this.props.onChange}
                              readOnly
                            /> */}
                          </td>

                          <td>
                            {(updateRestWorkoutExercise, item.workout_rest)}
                            {/* <input
                              style={{
                                backgroundColor: "#bfbbbb",
                                textAlign: "center",
                                pointerEvents: "none",
                              }}
                              type="text"
                              className="form-control col-md-12 builder-reps"
                              name="updateRestWorkoutExercise"
                              defaultValue={
                                (updateRestWorkoutExercise, item.workout_rest)
                              }
                              onChange={this.props.onChange}
                              readOnly
                            /> */}
                          </td>
                        </>
                      ) : null}

                      <td className="d-flex justify-content-end">
                        <CustomModal
                          actionElement={
                            <button
                              style={{
                                backgroundColor: "transparent",
                                color: "#EFAD4D",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              Update
                            </button>
                          }
                          headingTitle={`Update ${exerciseData.group_name} -
                          ${exerciseData.group_set_type}`}
                        >
                          <UpdateWorkout
                            item={item}
                            exerciseData={exerciseData}
                            updateSave={
                              this.props
                                .update_annual_training_program_workout_group_exercise
                            }
                            isLoading={this.props.updateExerciseLoader}
                            editIconClick={() =>
                              this.props.showUpdateGroupExerciseModal(
                                item,
                                index
                              )
                            }
                          />
                        </CustomModal>
                        {/* <button
                          className="mr-2"
                          style={{
                            backgroundColor: "transparent",
                            color: "#EFAD4D",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={
                            () => this.showModal()
                            // this.props.update_annual_training_program_workout_group_exercise(
                            //   item,
                            //   index
                            //   // handleClose
                            // )
                          }
                        >
                          Update
                        </button> */}
                        <button
                          className="ml-2"
                          style={{
                            backgroundColor: "transparent",
                            color: "#F75F03",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.props.showDeleteWorkoutExerciseModal(item)
                          }
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SuperSet;
