import React from 'react';
import { Link } from 'react-router-dom';
import arrowRightBlackImg from '../../Custom/images/arrow-right-black-img.png';
import setImgPng from '../../Custom/images/set-img.png';
import screenImg from '../../Custom/images/screen-img.png';
import succes from '../../Custom/images/succes.png';
import winImgPng from '../../Custom/images/win-img.png';
import SvgIcon from 'component/SvgIcon';
import CustomButton from 'component/customButton/CustomButton';

const BannerWrapper = (props) => {
  return (
    <div>
      <div className='page-wrapper'>
        <section className='banner_wrapper'>
          <div className='overlay'></div>
          <div className='banner_content'>
            <div className='banner_image d-flex flex-column justify-content-center'>
              <div className='container banner-container'>
                <h1>
                  <span>Smart</span> Online Strength <span>&</span> Conditioning Coach
                </h1>

                <Link
                  to='/sportsection'
                  // className='start_btn'
                  style={{ textDecoration: 'none' }}
                >
                  <CustomButton>Start Now</CustomButton>
                </Link>
              </div>
            </div>

            <div className='how-it-work-wrapper'>
              <div className=''>
                <div className='heading_text' style={{ textAlign: 'center' }}>
                  <h6>
                    How <span>Prime Coach</span> Works?
                  </h6>
                  <SvgIcon
                    name={'prime-coach-work-icon'}
                    style={{
                      width: 20,
                      hegiht: 20,
                      marginBottom: 3,
                    }}
                  />
                </div>
                <div
                  className='row text-center '
                  style={{ backgroundColor: '#141223' }}
                >
                  <div className='col-lg-3 coach-work-box select'>
                    <div className='inner_boxes'>
                      {/* <figure>
                        <img
                          src={setImgPng}
                          className='img-fluid'
                          alt={setImgPng}
                        />
                      </figure> */}
                      <SvgIcon name={'select-coach-icon'} />
                      <div className='details'>
                        <div className='detail-title'>SELECT</div>
                        <div className='details-line'></div>
                        <p>
                          Start your training journey by selecting your sport,
                          your preferred training location (gym or home), the
                          number of weekly sessions and the duration of your
                          programme.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 coach-work-box'>
                    <div className='inner_boxes'>
                      {/* <figure>
                        <img
                          src={screenImg}
                          className='img-fluid'
                          alt={screenImg}
                        />
                      </figure> */}
                      <SvgIcon name={'screen-coach-icon'} />
                      <div className='details'>
                        <div className='detail-title'>SCREEN</div>
                        <div className='details-line'></div>
                        <p>
                          Perform self-screening so our smart technology can
                          identify any movement dysfunction. You’ll be assigned
                          a tailored pre-training corrective programme based on
                          your screening result.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 coach-work-box trial'>
                    <div className='inner_boxes'>
                      {/* <figure>
                        <img src={succes} className='img-fluid' alt={succes} />
                      </figure> */}
                      <SvgIcon name={'train-coach-icon'} />
                      <div className='details'>
                        <div className='detail-title'>TRAIN HARDER</div>
                        <div className='details-line'></div>
                        <p>
                          Become stronger, fitter and faster than ever before
                          with our unique, sports specific programme tailored to
                          your individual needs. Get in touch with your personal
                          coach to check your progress and form with the
                          Feedback setting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 coach-work-box'>
                    <div className='inner_boxes'>
                      {/* <figure>
                        <img
                          src={winImgPng}
                          className='img-fluid'
                          alt={winImgPng}
                        />
                      </figure> */}
                      <SvgIcon name={'perform-coach-icon'} />
                      <div className='details'>
                        <div className='detail-title'>PERFORM</div>
                        <div className='details-line'></div>
                        <p>
                          Take your training to the next level so you perform at
                          your best. Become a better athlete with Prime Coach.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BannerWrapper;
