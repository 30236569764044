import React from 'react';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
function CustomRadioButton({
  title,
  isChecked,
  onClick,
  className,
  textClassName,
  radioStyle,
  disabled,
}) {
  return (
    <div
      className={`d-flex align-items-center flex-wrap ${className}`}
      onClick={disabled ? () => null : onClick}
    >
      {isChecked ? (
        <RadioButtonUncheckedRoundedIcon
          style={{
            color: 'var(--safetyOrange)',
            ...radioStyle,
          }}
        />
      ) : (
        <RadioButtonCheckedRoundedIcon
          style={{
            color: 'var(--safetyOrange)',
            ...radioStyle,
          }}
        />
      )}

      <p className={`mb-0 ${textClassName}`} style={{ color: '#FFF' }}>
        {title}
      </p>
    </div>
  );
}

export default CustomRadioButton;
