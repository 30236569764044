import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import React from "react";
import CardWrapper from "../cardWrapper/CardWrapper";
import "./TrainingPlanCard.scss";
import toDoList from "Custom/images/to-do-list.png";
import { useHistory } from "react-router";
import CustomModal from "component/customModal/CustomModal";
import AnnualTrainingPlanCopy from "container/Annual Training Plan/AnnualTrainingPlanCopy";
function TrainingPlanCard({ item, setPlanList }) {
  const { push } = useHistory();
  return (
    <CardWrapper
      showBackgroundImage
      height={110}
      className="trainingPlan_container"
    >
      <div className="text-truncate" style={{ flex: 1 }}>
        <p className="text-truncate">{item?.name}</p>

        <div className="d-flex align-items-center">
          <CustomButton
            className={"mr-3"}
            onClick={() =>
              push({
                pathname: `/annualprograminner/${"in_season"}/${"off_season"}/${"pre_season"}/${"transition"}`,
                state: item,
              })
            }
          >
            View Detail
          </CustomButton>
          <CustomModal
            actionElement={
              <button
                style={{
                  color: "#5BB85D",
                  padding: 0,
                  backgroundColor: "transparent",
                  fontSize: "17px",
                  border: "none",
                  cursor: "pointer",
                }}
                // onClick={copyIconClick}
              >
                <i className="fa fa-clone"></i>
              </button>
            }
          >
            <AnnualTrainingPlanCopy data={item} setPlanList={setPlanList} />
          </CustomModal>
        </div>
      </div>
      <div
        style={{
          height: 120,
          width: 70,
          marginLeft: 20,
          position: "relative",
        }}
      >
        <img src={toDoList} alt="ss" />
      </div>
    </CardWrapper>
  );
}

export default TrainingPlanCard;
