import React from "react";
import "../Coach After Login/TranningPlan.css";
import { Link } from "react-router-dom";
import CoachHeader from "../PublicLayout/CoachHeader";
import Footer from "../PublicLayout/Footer";
import TranningPlanGraph from "../../component/Charts/TranningPlanGraph";
import { standardPostApi } from "../API/ApiWrapper";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../Custom/images/celender.jpg";
// moment and timestamp in react js
import moment from "moment";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import { BarChartComponent } from "component/chart/Chart";
import CustomButton from "component/customButton/CustomButton";
class TranningPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      endDate: new Date(),
      graphLabels: [],
      graphData: [],
      weeklyIntensityArray: [],
      selectData: 1,
      workoutLocation: "",
    };
  }

  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ date: date });
    // console.log("The start date selected is ", this.state.date);
  };

  endDateChange = async (endDate) => {
    await this.setState({ endDate: endDate });
    // console.log("The end  date .... is ", this.state.endDate);
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  componentDidMount() {
    this.weekly_assigned_intensity_wethout_date();
  }

  handelChange = () => {
    // console.log("this is Working");
    this.weekly_assigned_intensity_wethout_date();
  };

  weekly_assigned_intensity_wethout_date = async () => {
    try {
      const res = await standardPostApi(
        "weekly_assigned_intensity",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),

          access_user_id: this.props.match.params.playerId,
        },
        true,
        false
      );
      if (res.data.code === 200) {
        // console.log("RESPONSE OF weekly_assigned_intensity ->", res.data.data);

        let graphLabels = [];
        let graphData = [];

        res.data.data.forEach((item) => {
          graphLabels.push(item.week_number);
        });
        // console.log("labels", graphLabels);

        res.data.data.forEach((item) => {
          graphData.push(item.average_intensity);
        });
        // console.log("graphData", graphData);

        res.data.data.forEach((item) => {
          this.setState({
            workoutLocation: item.annual_training_program_location,
          });
        });

        await this.setState({
          graphLabels: graphLabels,
          graphData: graphData,
          weeklyIntensityArray: res.data.data,
        });
        // toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  weekly_assigned_intensity = async () => {
    const start_date = moment(this.toTimestamp(this.state.date) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    const end_date = moment(this.toTimestamp(this.state.endDate) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    try {
      const res = await standardPostApi(
        "weekly_assigned_intensity",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          week_start_date: start_date,
          week_end_date: end_date,
          access_user_id: this.props.match.params.playerId,
        },
        true,
        false
      );
      if (res.data.code === 200) {
        // console.log("RESPONSE OF weekly_assigned_intensity ->", res.data.data);

        let graphLabels = [];
        let graphData = [];

        res.data.data.forEach((item) => {
          graphLabels.push(item.week_number);
        });
        // console.log("labels", graphLabels);

        res.data.data.forEach((item) => {
          graphData.push(item.average_intensity);
        });
        // console.log("graphData", graphData);

        await this.setState({
          graphLabels: graphLabels,
          graphData: graphData,
          weeklyIntensityArray: res.data.data,
        });
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    // console.log("this is From Location-->", this.props.location.state);
    // console.log("this is workoutLocation", this.state.workoutLocation);
    return (
      <div className="loader_sec">
        <CoachHeader />
        <div className="dashboard-wrapper">
          <section className=" myteams_wrapper">
            <div className="inner_teamsection_1">
              <CustomBreadcrumb
                heading="My Player"
                className={"mb-4"}
                navList={[
                  {
                    name: "My Team",
                    link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                  },
                  {
                    name:
                      this.props.location.state.player.first_name +
                      " " +
                      this.props.location.state.player.last_name,
                    link: {
                      pathname: `/coachplayerinner/${this.props.match.params.id}/${this.props.match.params?.playerId}/${this.props.match.params.teamname}`,
                      state: {
                        player: this.props?.location?.state?.player,
                      },
                    },
                  },
                  {
                    name: "Training Plan",
                  },
                ]}
              />

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <select
                    className="select_border_class"
                    onChange={this.handelChange}
                    defaultValue={this.state.selectData}
                  >
                    <option value="">Select Data</option>
                    <option value={this.state.selectData}>
                      Assigned Intensity Average
                    </option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <select
                    name=""
                    className="select_border_class"
                    value={this.state.workoutLocation}
                    selected
                    onChange={this.handelChange}
                  >
                    <option value="">Workout Location</option>
                    <option value="Home">Home</option>
                    <option value="Gym">Gym</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <InputDateTimePicker
                    style={{ width: "100%" }}
                    onChange={this.handleDateChange}
                    name="DateOfBirth"
                    value={this.state.date}
                    placeholder="Graph Range Start Date"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="d-flex ">
                    <InputDateTimePicker
                      style={{ width: "100%" }}
                      value={this.state.endDate}
                      onChange={this.endDateChange}
                      name="DateOfBirth"
                      placeholder="Graph Range End Date"
                      className={"mr-1"}
                    />
                    <div className="align-items-center d-flex">
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => this.weekly_assigned_intensity()}
                      >
                        <CheckCircleRoundedIcon
                          style={{
                            color: "var(--safetyOrange)",
                            fontSize: "40px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <BarChartComponent
                  data={this.state.graphData}
                  labels={this.state.graphLabels}
                  title={this.state.workoutLocation}
                />
              </div>

              <div className="row my-4">
                <div className="col-lg-2 col-md-2 " />
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 ">
                      <Link
                        to={{
                          pathname: `/tranningplanweek/${
                            this.props.match.params.id
                          }/${this.props.match.params.playerId}/${
                            this.props.match.params.teamname
                          }/${"in_season"}`,
                          state: this.props.location.state,
                        }}
                        // to="/tranningplanweek"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="d-flex">
                          <CustomButton
                            flex={1}
                            style={{ backgroundColor: "#02A8EF" }}
                          >
                            In Season
                          </CustomButton>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 ">
                      <Link
                        to={{
                          pathname: `/tranningplanweek/${
                            this.props.match.params.id
                          }/${this.props.match.params.playerId}/${
                            this.props.match.params.teamname
                          }/${"off_season"}`,
                          state: this.props.location.state,
                        }}
                        // to="/tranningplanweek"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="d-flex">
                          <CustomButton
                            flex={1}
                            style={{ backgroundColor: "#5BB85D" }}
                          >
                            Off Season
                          </CustomButton>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 ">
                      <Link
                        to={{
                          pathname: `/tranningplanweek/${
                            this.props.match.params.id
                          }/${this.props.match.params.playerId}/${
                            this.props.match.params.teamname
                          }/${"pre_season"}`,
                          state: this.props.location.state,
                        }}
                        // to="/tranningplanweek"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="d-flex">
                          <CustomButton
                            flex={1}
                            style={{ backgroundColor: "#E81E16" }}
                          >
                            {" "}
                            Pre Season
                          </CustomButton>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <Link
                        to={{
                          pathname: `/tranningplanweek/${
                            this.props.match.params.id
                          }/${this.props.match.params.playerId}/${
                            this.props.match.params.teamname
                          }/${"transition"}`,
                          state: this.props.location.state,
                        }}
                        // to="/tranningplanweek"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="d-flex">
                          <CustomButton
                            flex={1}
                            style={{ backgroundColor: "#EFAD4D" }}
                          >
                            Transition
                          </CustomButton>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 " />
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TranningPlan;
