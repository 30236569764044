import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import { standardPostApi } from "container/API/ApiWrapper";
import { EventEmitter } from "Custom/js/event";
import React, { useState } from "react";
function AnnualTrainingPlanCopy({ data, handleClose, setPlanList }) {
  const [copyName, setCopyName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const copyAnnualTrainingPlan = async () => {
    if (!copyName.trim()) {
      setError("Plan Name Required");
    } else {
      setIsLoading(true);
      try {
        const res = await standardPostApi(
          "copy_annual_traning_program",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            annual_training_program_id: data?.id,
            name: copyName,
          },
          true
        );
        if (res.data.code === 200) {
          EventEmitter.dispatch("CALL_PLAN_LIST");
          handleClose();
          setIsLoading(false);
          // console.log("This is res list_annual_training_program", res);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <CustomInput
        placeholder="plan Name"
        name="programName"
        value={copyName}
        onChange={(e) => {
          setCopyName(e.target.value);
          setError("");
        }}
        error={error}
      />
      <div className="d-flex mt-3">
        <CustomButton
          disabled={isLoading}
          isLoading={isLoading}
          flex={1}
          onClick={() => copyAnnualTrainingPlan()}
        >
          Copy
        </CustomButton>
      </div>
    </div>
  );
}

export default AnnualTrainingPlanCopy;
