import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";
import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import React from "react";
// import { Form } from "react-bootstrap";
import { errorToast } from "../../utils/toastMessage";
class DaysWorkout extends React.Component {
  render() {
    const { description, workoutLocationChange, intensity, workoutType } =
      this.props.value;
    return (
      <div
        className="col-lg-12 col-md-12 col-sm-12 mt-3 p-3"
        style={{ backgroundColor: "var(--blackRussian)" }}
      >
        <div className="week_heading">Workouts</div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <CustomInput
              className={"mb-3"}
              name="description"
              value={(description, this.props.descriptionClean)}
              onChange={this.props.onChange}
              placeholder="Description"
              error={this.props.descriptionError}
            />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <select
              name="workoutLocationChange"
              value={
                (workoutLocationChange, this.props.workoutLocationChangeClen)
              }
              onChange={this.props.onChange}
              className="select_class "
            >
              <option value="">Select Workout Location</option>
              {this.props.workoutLocation &&
                this.props.workoutLocation.map((data) => {
                  return (
                    <option value={data.value} key={data.id}>
                      {data.label}
                    </option>
                  );
                })}
            </select>
            {this.props.workoutLocationError && (
              <p className="react_validation">
                {this.props.workoutLocationError}
              </p>
            )}
          </div> */}

          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group">
              <p className="edit-madical-status-span m-0">Intensity</p>
              <div className="middle">
                {Array.from(Array(10).keys())?.map((index) => (
                  <span key={index}>
                    <label>
                      <input
                        type="radio"
                        name="intensity"
                        value={index + 1}
                        onChange={this.props.onChange}
                        // checked={intensity === undefined && false}
                      />
                      <div className="front-end box d-flex align-items-center justify-content-center">
                        <span>{index + 1}</span>
                      </div>
                    </label>
                    &nbsp;
                  </span>
                ))}
              </div>
              <p className="react_validation ">{this.props.intensityError}</p>
            </div>
            {/* <CustomInput
              className={"mb-3"}
              name="intensity"
              value={(intensity, this.props.intensityClean)}
              onChange={this.props.onChange}
              maxLength={4}
              placeholder="Intensity"
              error={this.props.intensityError}
            /> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
            <select
              name="workoutType"
              value={(workoutType, this.props.workoutTypeClean)}
              onChange={this.props.onChange}
              className="select_class "
            >
              <option value="">Select Workout Type </option>
              {this.props.WorkoutType &&
                this.props.WorkoutType.map((item) => {
                  return (
                    <option value={item.value} key={item.id}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
            {this.props.workoutTypeError && (
              <p className="react_validation">{this.props.workoutTypeError}</p>
            )}
          </div>

          {this.props.workoutTypeClean === "0" && (
            <div className="col-lg-6 col-md-6 col-sm-6">
              <CustomInput
                placeholder="Custom Workout Type"
                name="customize_workout"
                className={"mb-3"}
                value={this.props.customize_workout}
                onChange={this.props.onChange}
                error={this.props.customize_workout_error}
              />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-6 flex">
            {this.props.workout.length !== 1 ? (
              <CustomButton onClick={this.props.addWorkout} flex={1}>
                Add New Workout
              </CustomButton>
            ) : (
              <CustomButton
                flex={1}
                onClick={() =>
                  errorToast(
                    "You can add only single workout for a single day."
                  )
                }
              >
                Add New Workout
              </CustomButton>
            )}
          </div>
        </div>

        <div>
          {this.props.workoutLoder === true ? null : (
            <div>
              {this.props.workout &&
                this.props.workout.map((item) => {
                  return (
                    <div
                      className="col-lg-4 col-md-4 col-sm-6 mt-4"
                      key={item?.id + ""}
                    >
                      <ProgramViewCard
                        containerBackCardStyle={{
                          backgroundColor: "var(--jaguar)",
                        }}
                        buttonText="Delete"
                        text={item.name}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.workoutDeleteModal(item);
                        }}
                        Icon={"edit"}
                        copyIconClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.showModal(item);
                        }}
                        cardClick={() => {
                          this.props.DaysWorkoutButton(item);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DaysWorkout;
