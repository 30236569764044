import React, { Component, useState } from "react";
import InnerHeader from "../../container/PublicLayout/InnerHeader";
import Footer from "../../container/PublicLayout/Footer";
import { Modal } from "react-bootstrap";
import { Redirect } from "react-router";
import $, { data } from "jquery";
import { findDOMNode } from "react-dom";
import { standardPostApi } from "../../container/API/ApiWrapper";
import Checkbox from "../../component/Checkbox/Checkbox";
import AssignedWeek from "../../component/SelfScreen/AssignedWeek";
import WeeklyReport from "../../component/SelfScreen/WeeklyReport";
import WeeklyAverageIntensity from "../../component/SelfScreen/WeeklyAverageIntensity";
// import AthleteSectionGraph from "../../component/Charts/AthleteSectionGraph";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// moment and timestamp in react js
import moment from "moment";
// react toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../container/Loader/LoaderWrapper";
import { successToast } from "../../utils/toastMessage";
import { connect } from "react-redux";
import { listAthleteWorkoutRequest } from "../../store/actions";
import { ContactlessOutlined } from "@material-ui/icons";
import black from "Custom/images/black.jpg";
import NoDataFound from "component/lottiLoader/LottiLoader";
import AthleteCoachLibrary from "component/SelfScreen/AthleteCoachLibrary/AthleteCoachLibrary";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { BarChartComponent } from "component/chart/Chart";
import CustomButton from "component/customButton/CustomButton";
import CustomRadioButton from "component/customRadioButton/CustomRadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem" }}
        color="white"
        style={{ color: "white" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

function SelfScreeningModel(props) {
  const url = props.modalVideo;
  const urlId = getId(url);
  // const [loading, setLoading] = useState(true);

  // console.log("urlID", urlId);

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHidePress}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={true}
      backdropClassName="modal_backdrop"
      contentClassName="modal_container"
    >
      <Modal.Body>
        <div className="modal-header">
          <h5 className="modal-title" id="videopopupTitle">
            {/* Back to wall shoulder */}
            {props.modalName}
          </h5>
          <button
            type="button"
            className="modal_close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.onHidePress}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <iframe
              // onLoad={() => setLoading(false)}
              // placeholder="abcccc"
              width="100%"
              title="video"
              height="345"
              src={`https://www.youtube.com/embed/${urlId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="row" style={{ color: "white" }}>
              <div
                className="col-lg-9 col-md-9 col-sm-9 my-3"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                Dysfunction
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end my-3"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                Applies?
              </div>
              {props.modalDysfunctionArray &&
                props.modalDysfunctionArray.map((item, index) => {
                  console.log("first", item.image);
                  return (
                    <>
                      <div className="col-lg-9 col-md-9 col-sm-9 mb-4">
                        {item?.image?.length ? (
                          <img
                            className="dysfunction-tile-body mr-2"
                            src={item.image}
                            alt={item.image}
                          />
                        ) : (
                          <img
                            style={{
                              backgroundColor: "white",
                              width: "50px",
                              borderRadius: "5px",
                            }}
                            className="dysfunction-tile-body mr-2"
                            src={require("../../Custom/images/image-placeholder.png")}
                            alt={item.image}
                          />
                        )}

                        <span>{item.name}</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end mb-4">
                        <FormControlLabel
                          style={{ margin: 0 }}
                          value={item?.id}
                          control={<GreenRadio checked={item?.checked} />}
                          onClick={() => props.toggleCb(index, item.id)}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div className="d-flex">
            <CustomButton onClick={props.onButtonClick} flex={1}>
              Save
            </CustomButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const GreenRadio = withStyles({
  root: {
    color: "var(--safetyOrange)",
    "&$checked": {
      color: "var(--safetyOrange)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
function ExercisesModal(props) {
  const viedioUrl = props.exerciseModalvideo;
  const urlId = getId(viedioUrl);
  return (
    <Modal
      show={props.showCorrectiveExercises}
      onHide={props.onHidePressExercises}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={true}
      backdropClassName="modal_backdrop"
      contentClassName="modal_container"
    >
      <Modal.Body>
        <div className="modal-header">
          <h5 className="modal-title" id="videopopupTitle">
            {/* Modal title */}
            {props.exerciseModalName}
          </h5>
          <button
            type="button"
            className="modal_close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.onHidePressExercises}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <iframe
              title="video"
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${urlId}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen=""
            ></iframe>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

class SelfScreening extends Component {
  userData;
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      show: false,
      signupPage: true,
      selfScreening: [],
      modalName: "",
      modalVideo: "",
      modalDysfunction: [],
      correctiveExercises: [],
      CorrectiveExercisesModal: false,
      exerciseModalName: "",
      exerciseModalvideo: "",
      checkedItems: new Map(),
      dysfunction: [],
      selfScreeningItem: null,
      selfScreeningItemArray: [],
      isChecked: false,
      checkboxes: [],
      visibleWeeklyTopGraph: false,
      visibleAssignedWeek: false,
      visibleWeeklyReport: false,
      visibleWeeklyAverageIntensity: false,
      visibleCorrectiveExercises: true,
      viewWorkoutModalArray: {},
      startWorkoutArray: {},
      RESULT: {},
      user_id: "",
      WEEK_RESULT: {},
      date: new Date(),
      endDate: new Date(),
      graphLabels: [],
      graphData: [],
      indexFromGraph: "",
      selectedDysfunctions: [],
      visibleNeedAnalysis: false,
      viewMore: false,
      trainingSessionReport: "",
      fullArray: {},
      sportAnalysesLoader: false,
      // WEEK_INDEX: 0,
      expanded: "",
    };
    !localStorage.getItem("access_token") && this.props.history.push("/");
    this.checkLoginUrl();
    this.deleteEvent = this.deleteEvent.bind(this);
  }

  checkLoginUrl = async () => {
    const token = await localStorage.getItem("access_token");
    const role = await localStorage.getItem("access_role");
    if (token == null && role == null) {
      this.setState({ signupPage: false });
    }
  };

  //  date picker
  handleDateChange = async (date, moment) => {
    await this.setState({ date: date });
  };

  endDateChange = async (endDate) => {
    await this.setState({ endDate: endDate });
  };

  toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  //  end date picker

  // handleToggle = () => {
  //   const el = findDOMNode(this.refs.toggle);
  //   // $(el).toggle("#toggle");
  //   $(el).show();
  //   // $(el).hide("#commonInjury");
  // };
  // handleToggleInjuries = () => {
  //   const el = findDOMNode(this.refs.toggle);
  //   // $(el).toggle("#toggleinjury");
  //   $(el).hide();
  // };

  showModal = async (item) => {
    await this.setState({
      show: !this.state.show,
      modalName: item.name,
      modalVideo: item.video,
      modalDysfunction: item.dysfunction,
      selfScreeningItem: item,
      selfScreeningItemArray: item.dysfunction,
    });
    this.initiateDysFunctionsArray();
  };

  hideModal = async (item) => {
    await this.setState({ show: false, selectedDysfunctions: [] });
  };

  showCorrectiveExercises = async (data) => {
    await this.setState({
      CorrectiveExercisesModal: !this.state.CorrectiveExercisesModal,
      exerciseModalName: data.name,
      exerciseModalvideo: data.video,
    });
  };

  hideCorrectiveExercises = async (data) => {
    await this.setState({ CorrectiveExercisesModal: false });
  };

  componentDidMount() {
    this.fetchSelfScreeningData();
    this.list_athlete_workout();
    this.user_profile();
    this.weekly_assigned_intensity_Wethout_Data();
    this.sport_need_analysis();
  }

  fetchSelfScreeningData = async () => {
    localStorage.setItem("modalName", "ViewWorkout");
    try {
      const res = await standardPostApi(
        "list_self_screening",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        // console.log(
        //   "this is Response of list_self_screening--->",
        //   res.data.data
        // );
        this.fetchCorrectiveExercises();
        this.setState({ selfScreening: res.data.data, loading: false });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchCorrectiveExercises = async () => {
    try {
      const res = await standardPostApi(
        "list_corrective_exercises",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true
      );
      if (res.data.code === 200) {
        // console.log(
        //   "this is Response of list_corrective_exercises--->",
        //   res.data.data
        // );
        this.setState({ correctiveExercises: res.data.data, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  deleteEvent = (index) => {
    const copyPostArray = Object.assign([], this.state.selfScreening);
    copyPostArray.splice(index, 1);
    this.setState({
      selfScreening: copyPostArray,
      show: false,
    });
  };

  initiateDysFunctionsArray = async () => {
    const { selectedDysfunctions, selfScreeningItem } = this.state;
    for (let i = 0; i < selfScreeningItem.dysfunction.length; i++) {
      selectedDysfunctions.push({
        id: selfScreeningItem.dysfunction[i].id,
        status: 0,
      });
    }
  };

  toggleCheckBox = async (index, id) => {
    const { selectedDysfunctions, selfScreeningItem } = this.state;

    const changedCheckbox = selfScreeningItem.dysfunction.find(
      (cb) => cb.id === id
    );
    changedCheckbox.checked = !changedCheckbox.checked;
    if (changedCheckbox.checked === true) {
      selectedDysfunctions[index].status = 1;
    } else if (changedCheckbox.checked === false) {
      selectedDysfunctions[index].status = 0;
    }

    const checkboxes = Object.assign(
      {},
      selfScreeningItem.dysfunction,
      changedCheckbox
    );
    this.setState({ checkboxes });
  };

  checkIfAllFalse = () => {
    const { selfScreening } = this.state;
    let new_array = [];
    selfScreening.forEach((item) => {
      if (item.saved_for_user === true) {
        new_array.push(item);
      }
    });
    if (selfScreening.length === new_array.length) {
      return false;
    } else {
      return true;
    }
  };

  saveSelfScreening = async () => {
    const { selfScreeningItem, selectedDysfunctions } = this.state;
    try {
      const res = await standardPostApi(
        "save_self_screening",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          screening_id: selfScreeningItem.id,
          dysfunctions: JSON.stringify(selectedDysfunctions),
        },
        true
      );
      if (res.data.code === 200) {
        this.fetchSelfScreeningData();
        this.setState({
          show: false,
          selectedDysfunctions: [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // *********************These Are Athlete Dashboard Apis*********************
  list_athlete_workout = async () => {
    try {
      const res = await standardPostApi(
        "list_athlete_workout",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code == 301) {
        toast.error(res.data.message);
      }
      if (res.data.code === 200) {
        this.setState({ fullArray: res.data.data });
        this.props.listAthleteWorkoutRequest(res.data.data);
        const resultFirst = res.data.data.find(
          (data) => data.annual_program_completed === false
        );

        if (resultFirst) {
          this.setState({ RESULT: resultFirst });
        } else {
          this.setState({ RESULT: res.data.data[res.data.data.length - 1] });
        }

        const weekResult = this.state.RESULT.weeks.find(
          (item) => item.week_completed === false
        );
        if (weekResult) {
          this.setState({ WEEK_RESULT: weekResult });
        } else {
          this.setState({
            WEEK_RESULT:
              this.state.RESULT.weeks[this.state.RESULT.weeks.length - 1],
          });
        }

        await this.setState({
          loading: false,
          visibleWeeklyTopGraph: true,
          visibleAssignedWeek: true,
          visibleWeeklyReport: true,
          visibleWeeklyAverageIntensity: true,
          visibleCorrectiveExercises: true,
          visibleNeedAnalysis: true,
          viewWorkoutModalArray: this.state.WEEK_RESULT,
          startWorkoutArray: this.state.RESULT,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  user_profile = async () => {
    try {
      const res = await standardPostApi(
        "user_profile",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        await this.setState({ user_id: res.data.data.id });
        // console.log("THIS IS A USER PROFILE---->", res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // ******************************For Graph********************************************************

  weekly_assigned_intensity_Wethout_Data = async () => {
    try {
      const res = await standardPostApi(
        "weekly_assigned_intensity",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: this.state.user_id,
        },
        true,
        false
      );
      if (res.data.code === 200) {
        let graphLabels = [];
        let graphData = [];

        res.data.data.forEach((item) => {
          graphLabels.push(item?.week_number);
        });

        res.data.data.forEach((item) => {
          graphData.push(item?.average_intensity);
        });

        await this.setState({
          graphLabels: graphLabels,
          graphData: graphData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  weekly_assigned_intensity = async () => {
    const start_date = moment(this.toTimestamp(this.state.date) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    const end_date = moment(this.toTimestamp(this.state.endDate) * 1000)
      .format("YYYY-MM-DD")
      .toString();

    try {
      const res = await standardPostApi(
        "weekly_assigned_intensity",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: this.state.user_id,
          week_start_date: start_date,
          week_end_date: end_date,
        },
        true
      );
      if (res.data.code === 200) {
        let graphLabels = [];
        let graphData = [];

        res.data.data.forEach((item) => {
          graphLabels.push(item?.week_number);
        });

        res.data.data.forEach((item) => {
          graphData.push(item?.average_intensity);
        });

        await this.setState({
          graphLabels: graphLabels,
          graphData: graphData,
        });
        toast.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  sport_need_analysis = async () => {
    this.setState({ sportAnalysesLoader: true });
    try {
      const res = await standardPostApi(
        "sport_need_analysis_v1",
        undefined,
        { access_token: await localStorage.getItem("access_token") },
        true,
        false
      );
      if (res.data.code === 200) {
        await this.setState({ SportAnalysis: res.data.data });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ sportAnalysesLoader: false });
    }
  };

  NeedsAnalysisViewMore = async () => {
    const { viewMore } = this.state;
    await this.setState({ viewMore: !viewMore });
  };

  trainingSessionSpecificReportApi = async (week) => {
    const days = week.days.find((data) => data);

    try {
      const res = await standardPostApi(
        "training_session_specific_report",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: week?.annual_training_program_id,
          annual_training_program_week_id: week?.id,
          annual_training_program_week_day_id: days?.id,
          access_user_id: this.state.user_id,
        },
        true,
        false
      );
      if (res.data.code === 200) {
        this.setState({ trainingSessionReport: res.data.data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  nextWeek = async () => {
    const { viewWorkoutModalArray, startWorkoutArray } = this.state;

    const week_index = startWorkoutArray?.weeks?.findIndex(
      (x) => x.id === viewWorkoutModalArray?.id
    );
    if (week_index !== -1) {
      if (week_index < startWorkoutArray?.weeks?.length - 1) {
        this.setState({
          viewWorkoutModalArray: startWorkoutArray?.weeks?.[week_index + 1],
        });
      }
    }
  };
  prevWeek = async () => {
    const { viewWorkoutModalArray, startWorkoutArray } = this.state;

    const week_index = startWorkoutArray?.weeks?.findIndex(
      (x) => x.id === viewWorkoutModalArray?.id
    );
    if (week_index !== -1) {
      if (week_index > 0) {
        this.setState({
          viewWorkoutModalArray: startWorkoutArray?.weeks?.[week_index - 1],
        });
      }
    }
  };

  handleChange = (panel) => (event, newExpanded) => {
    // setExpanded(newExpanded ? panel : false);
    this.setState({ expanded: newExpanded ? panel : false });
  };
  render() {
    if (this.state.signupPage === false) {
      return <Redirect to="/" />;
    }

    const {
      selfScreening,
      correctiveExercises,
      selfScreeningItemArray,
      selfScreeningItem,
      viewMore,
      trainingSessionReport,
      sportAnalysesLoader,
      SportAnalysis,
    } = this.state;

    var result;
    result =
      selfScreeningItem &&
      selfScreeningItem.dysfunction.map(function (el) {
        var o = Object.assign({}, el);
        o.checked = false;
        return o;
      });
    console.log("sahfaskkashdkas", this.state.SportAnalysis);
    return (
      <div
        className="loader_sec"
        style={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <InnerHeader />
        {this.state.loading ? (
          //
          <span style={{ height: "100vh" }}>
            <Loader />
          </span>
        ) : (
          <>
            {this.state.visibleWeeklyTopGraph && (
              <div>
                <div className="dashboard-wrapper">
                  <section className="assigned-pagewrapper">
                    <div className="week_section">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <select name="" className="select_class">
                            <option value="">Select Data</option>
                            <option value="">Assigned Intensity Average</option>
                          </select>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <select name="" className="select_class">
                            <option value="">Workout Location</option>
                            <option value="">Home</option>
                            <option value="">Gym</option>
                          </select>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <InputDateTimePicker
                            style={{ width: "100%" }}
                            value={this.state.date}
                            onChange={this.handleDateChange}
                            name="DateOfBirth"
                            placeholder="Start Date"
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="d-flex">
                            <InputDateTimePicker
                              style={{ width: "100%" }}
                              value={this.state.endDate}
                              onChange={this.endDateChange}
                              name="DateOfBirth"
                              placeholder="End Date"
                              className={"mr-2"}
                            />
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={this.weekly_assigned_intensity}
                            >
                              <CheckCircleRoundedIcon
                                style={{
                                  color: "var(--safetyOrange)",
                                  fontSize: "40px",
                                  cursor: "pointer",
                                }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <BarChartComponent
                          data={this.state.graphData}
                          labels={this.state.graphLabels}
                          title={
                            "Weekly Assigned Average Intensity - Period - Home"
                          }
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            )}
            {selfScreening.length > 0 && this.checkIfAllFalse() && (
              <div className="week_section mt-3">
                <h5 className="mb-3">Self Screening</h5>

                <SelfScreeningModel
                  onHidePress={() => this.hideModal()}
                  showModal={this.state.show}
                  modalName={this.state.modalName}
                  modalVideo={this.state.modalVideo}
                  modalDysfunctionArray={
                    selfScreeningItem && selfScreeningItem.dysfunction
                  }
                  onButtonClick={() => {
                    this.saveSelfScreening();
                  }}
                  toggleCb={(index, id) => this.toggleCheckBox(index, id)}
                />
                <div className="table-responsive table_react_cursor">
                  <table className="table">
                    <thead>
                      <tr
                        className="react_Testing_Table"
                        style={{ backgroundColor: "var(--blackRussian)" }}
                      >
                        <th>Exercise</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selfScreening &&
                        selfScreening.map((item) => {
                          return (
                            item.saved_for_user === false && (
                              <tr
                                key={data.id}
                                className="react_Testing_Table_1"
                              >
                                <td>{item.name}</td>
                                <td className="d-flex justify-content-end">
                                  <button
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor: "transparent",
                                      color: "var(--safetyOrange)",
                                      border: "none",
                                    }}
                                    onClick={(e) => {
                                      this.showModal(item);
                                    }}
                                  >
                                    Start Screening
                                  </button>
                                </td>
                              </tr>
                            )
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {this.state?.visibleAssignedWeek && (
              <div>
                <AssignedWeek
                  viewWorkoutModalArray={this.state?.viewWorkoutModalArray}
                  startWorkoutArray={this.state?.startWorkoutArray}
                  trainingSessionSpecificReportApi={
                    this.trainingSessionSpecificReportApi
                  }
                  trainingSessionReport={trainingSessionReport}
                  prevWeek={this.prevWeek}
                  nextWeek={this.nextWeek}
                  hideNext={
                    this.state.startWorkoutArray?.weeks?.findIndex(
                      (x) => x?.id === this.state?.viewWorkoutModalArray?.id
                    ) ===
                    this.state?.startWorkoutArray?.weeks?.length - 1
                  }
                  hidePrev={
                    this.state.startWorkoutArray?.weeks?.findIndex(
                      (x) => x.id === this.state?.viewWorkoutModalArray?.id
                    ) === 0
                  }
                  userId={this.state?.user_id}
                />
              </div>
            )}
            {!this.checkIfAllFalse() && !this.state?.visibleAssignedWeek && (
              <div>
                <div className="week_section_react_Corrective_Exercises">
                  <div className="heading" style={{ textAlign: "center" }}>
                    <h3>No exercise assign yet.</h3>
                  </div>
                  <NoDataFound
                    height={250}
                    width={250}
                    // text="No exercise assign yet."
                  />
                </div>
              </div>
            )}
            {this.state.correctiveExercises.length > 0 && (
              <div className="week_section mt-3" id="corrective-exercises">
                <h5 className="mb-3">Corrective Exercises</h5>

                <ExercisesModal
                  onHidePressExercises={() => this.hideCorrectiveExercises()}
                  showCorrectiveExercises={this.state.CorrectiveExercisesModal}
                  exerciseModalName={this.state.exerciseModalName}
                  exerciseModalvideo={this.state.exerciseModalvideo}
                />
                <div className="table-responsive table_react_cursor">
                  <table className="table">
                    <thead>
                      <tr
                        className="react_Testing_Table"
                        style={{ backgroundColor: "var(--blackRussian)" }}
                      >
                        <th>Exercise</th>
                        <th>Video</th>
                      </tr>
                    </thead>
                    <tbody key={data.name}>
                      {correctiveExercises &&
                        correctiveExercises.map((data) => {
                          return (
                            <tr className="react_Testing_Table_1">
                              <td>{data.name}</td>
                              <td>
                                <button
                                  href="javaScript;"
                                  data-toggle="modal"
                                  data-target="#videopopup"
                                  className="Video_Model"
                                  onClick={(e) => {
                                    this.showCorrectiveExercises(data);
                                  }}
                                >
                                  <i
                                    className="fa fa-play"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {this.state.visibleWeeklyReport && (
              <div>
                <AthleteCoachLibrary />
              </div>
            )}

            {this.state.visibleWeeklyReport && (
              <div>
                <WeeklyReport
                  startWorkoutArray={this.state.startWorkoutArray}
                />
              </div>
            )}
            {this.state.visibleWeeklyAverageIntensity && (
              <div>
                <WeeklyAverageIntensity user_id={this.state.user_id} />
              </div>
            )}
            {this.state.visibleNeedAnalysis && (
              <div
                className={`week_section mt-3 ${
                  viewMore === true ? "pb-5" : ""
                }`}
                id="need-analysis"
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      // className={`${viewMore === true
                      //   ? "needs-analysis-view-more-add"
                      //   : "basketball_box  needs-analysis-view-more"
                      //   }`}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.SportAnalysis,
                        }}
                      ></div> */}

                      <div>
                        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Needs Analysis
                        </h2>
                        <h2 style={{ textAlign: "center" }}>
                          {SportAnalysis?.sport_name}
                        </h2>
                        <div>
                          {SportAnalysis?.sport_description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: SportAnalysis?.sport_description,
                              }}
                            />
                          )}

                          {SportAnalysis?.need_analysis?.map((x, i) => (
                            <Accordion
                              key={x?.id}
                              expanded={this.state.expanded === x?.id}
                              onChange={this.handleChange(x?.id)}
                              style={{ backgroundColor: "var(--jaguar)" }}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography
                                  style={{ color: "white", fontSize: "1.5rem" }}
                                >
                                  {x?.title}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography style={{ color: "white" }}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: x?.desc,
                                    }}
                                  />
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </div>

                      {sportAnalysesLoader && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i
                            className="fa fa-spinner fa-spin fa-3x fa-fw"
                            style={{
                              color: "var(--safetyOrange)",
                              fontSize: "40px",
                            }}
                          />
                        </div>
                      )}
                      {!sportAnalysesLoader &&
                        this.state.SportAnalysis === undefined && (
                          <NoDataFound
                            height={250}
                            width={250}
                            text="No need analysis available yet"
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <Footer />
      </div>
    );
  }
}

// export default SelfScreening;

const mapDispatchToProps = (dispatch) => {
  return {
    listAthleteWorkoutRequest: (data) =>
      dispatch(listAthleteWorkoutRequest(data)),
  };
};
export default connect(null, mapDispatchToProps)(SelfScreening);
