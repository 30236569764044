import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import "./StartWorkoutAlternativeExercise.css";
import Checkbox from "component/Checkbox/Checkbox";
import NoDataFound from "component/lottiLoader/LottiLoader";
import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
class ViewExerciseModal extends React.Component {
  render() {
    const { show, onHide, alternativeExerciseObj } = this.props;
    const url = alternativeExerciseObj.video;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5
                className="modal-title"
                id="teamcreate"
                style={{ color: "#fff" }}
              >
                View Alternative Exercise
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <span className="edit-madical-status-span">Exercise Group</span>
                <p className="edit-madical-status-p">
                  {alternativeExerciseObj?.exercise_group?.exercise_group}
                </p>
              </div>
              <div className="form-group">
                <span className="edit-madical-status-span">Exercise</span>
                <p className="edit-madical-status-p">
                  {alternativeExerciseObj?.exercise}
                </p>
              </div>
              {alternativeExerciseObj?.video && (
                <div>
                  <iframe
                    title="video"
                    width="100%"
                    height="315"
                    // src={alternativeExerciseObj.video}
                    src={`https://www.youtube.com/embed/${urlId}`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen=""
                  ></iframe>
                </div>
              )}
            </div>
     
         
            {/* <div className="reps_check">
                <div className="form-group">
                  <label htmlFor="">Reps</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={alternativeExerciseObj?.alt_repetition}
                    readOnly
                  />
                </div>
                <div className="form-group" style={{ marginLeft: "10%" }}>
                  <label htmlFor="">Load</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={alternativeExerciseObj?.alt_load}
                    readOnly
                  />
                </div>
              </div>
              <div className="reps_check">
                <div className="form-group ">
                  <label htmlFor="">Reps Each Side?</label>
                  <Checkbox
                    checked={
                      alternativeExerciseObj?.alt_repetition_each_side === "1"
                        ? true
                        : false
                    }
                    readOnly
                    disabled
                  />
                </div>

                <div className="form-group load_required">
                  <label htmlFor="">Load Required?</label>
                  <Checkbox
                    checked={
                      alternativeExerciseObj?.alt_load_required === "1"
                        ? true
                        : false
                    }
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Repetition type</label>
                <select
                  className="form-control col-md-12 superset_select_react"
                  name="updateRepetitionType"
                  defaultValue={alternativeExerciseObj?.alt_repetition_type}
                  readOnly
                  disabled
                >
                  <option value="">Select Repetition Type</option>
                  <option value="repetition">Repetition</option>
                  <option value="minutes">Minutes</option>
                  <option value="seconds">Seconds</option>
                </select>
              </div>
              <div className="reps_check">
                <div className="form-group ">
                  <label htmlFor="">Sets</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={alternativeExerciseObj?.alt_sets}
                    readOnly
                  />
                </div>

                <div className="form-group" style={{ marginLeft: "10%" }}>
                  <label htmlFor="">Rest</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={alternativeExerciseObj?.alt_rest}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group ">
                <label htmlFor=""> Description</label>
                <textarea
                  rows="4"
                  cols="50"
                  className="form-control"
                  defaultValue={alternativeExerciseObj?.description}
                  readOnly
                ></textarea>
              </div> */}
            {/* </div> */}
          </Modal.Body>
      
        </Modal>
      </div>
    );
  }
}

export class StartWorkoutAlternativeExercise extends Component {
  state = {
    alternativeExerciseDetails: [],
    viewModal: false,
    alternativeExerciseObj: {},
  };

  handelView = async (data) => {
    await this.setState({
      viewModal: !this.state.viewModal,
      alternativeExerciseObj: data,
    });
  };

  render() {
    const {
      show,
      onHide,
      alternativeExerciseDetails,
      onAlternativeSelect,
      loaderAlternativeExercise,
    } = this.props;

    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5
                className="modal-title"
                id="teamcreate"
                style={{ color: "#fff" }}
              >
                Alternative Exercise
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {!loaderAlternativeExercise &&
              alternativeExerciseDetails.length === 0 && (
                <NoDataFound
                  height={250}
                  width={250}
                  text="No alternative exercise avalable yet"
                />
              )}

            {loaderAlternativeExercise ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <i
                  className="fa fa-spinner fa-spin fa-3x fa-fw"
                  // className="fa fa-spinner fa-pulse fa-3x fa-fw"
                  style={{
                    color: "var(--safetyOrange)",
                    fontSize: "50px",
                    marginTop: "50px",
                    marginbottom: "60px",
                  }}
                />
              </div>
            ) : (
              <div>
                {/* <table className="table table-condensed react_workout_table"> */}
                {/* {alternativeExerciseDetails.length !== 0 && (
                    <thead>
                      <tr style={{ color: "#fff" }}>
                        <th>Exercise</th>
                        <th>View</th>
                        <th>Assign</th>
                      </tr>
                    </thead>
                  )} */}
                {/* <tbody> */}
                {alternativeExerciseDetails.length !== 0 && (
                  <div className="row mt-4">
                    {alternativeExerciseDetails.map((data) => {
                      return (
                        <React.Fragment key={data?.id}>
                          <div className="col-lg-2 col-md-3 col-sm-12" />
                          <div className="col-lg-8 col-md-6 col-sm-12">
                            <ProgramViewCard
                              text={data?.exercise}
                              buttonText={"View"}
                              onClick={() => this.handelView(data)}
                              containerBackCardStyle={{
                                backgroundColor: "var(--jaguar)",
                              }}
                            />
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-12" />
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
                {/* {alternativeExerciseDetails.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <input
                              type="text"
                              className="form-control col-md-12 builder-exercise"
                              defaultValue={data?.exercise}
                              style={{ cursor: "not-allowed" }}
                              readOnly
                            />
                          </td>

                          <td>
                            <button
                              title="Save"
                              className="btn btn-md btn-primary workout-builder-save-workout-exercise "
                              onClick={() => this.handelView(data)}
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                          </td>

                          <td>
                            <button
                              title="Save"
                              className="btn btn-md btn-primary workout-builder-save-workout-exercise "
                              onClick={() => onAlternativeSelect(data)}
                            >
                              <i className="fa fa-check"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}
              </div>
            )}

            {/* <div style={{ borderBottom: "1px solid #fff" }}></div> */}
          </Modal.Body>
          {/* <ModalFooter
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "none",
            }}
          >
            <div>
              <button type="button" className="Model_btn " onClick={onHide}>
                close
              </button>
            </div>
          </ModalFooter> */}
        </Modal>

        <ViewExerciseModal
          show={this.state.viewModal}
          onHide={this.handelView}
          alternativeExerciseObj={this.state.alternativeExerciseObj}
        />
      </div>
    );
  }
}

export default StartWorkoutAlternativeExercise;
