import React from "react";
import "./ButtonCard.scss";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { Link } from "react-router-dom";
function ButtonCard({ item }) {
  return (
    <Link to={item?.link} className="button_card_a_tag"
    onClick={item?.onClick}
    >
      <div className="button_card_container position-relative">
        <div className="button_card_back" />
        <div className="button_card_front">
          <p className="mb-0">{item?.name}</p>
          <ArrowForwardIosRoundedIcon />
        </div>
      </div>
    </Link>
  );
}

export default ButtonCard;
