import { ImgType } from "helpers/FindImgType";
import { capitalizeFirstLetter } from "helpers/textTruncate";
import React from "react";
import PlayerImg from "../../../Custom/images/user_placeholder.png";
import "./PlayerCard.scss";

function PlayerCard({ item }) {
  console.log("playerImg", item);
  return (
    <div className="Player-card w-100">
      <img
        src={
          item.profile_image
            ? ImgType(item?.profile_image)
              ? item?.profile_image
              : PlayerImg
            : PlayerImg
        }
        alt="No Img.."
        className="Player-img"
      />
      <p className="text-truncate w-100 text-center">
        {capitalizeFirstLetter(item?.first_name)}{" "}
        {capitalizeFirstLetter(item?.last_name)}
      </p>
    </div>
  );
}

export default PlayerCard;
