import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export class CreatePageSetModal extends Component {
  render() {
    const {
      show,
      onHide,
      testing_protocol_id,
      createUpdateTestingProtocolResultset,
      isLoading,
    } = this.props;

    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          animation={true}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header" style={{ border: "none" }}>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="delete_modal_react d-flex align-items-center justify-content-center flex-column">
              <SvgIcon name="modal-create-icon" />
              <span
                className="mt-3 mb-3"
                style={{ fontSize: 20, fontWeight: "bold", color: "white" }}
              >
                Are you sure?
              </span>
              <span style={{ color: "white", textAlign: "center" }}>
                you want to add new testing results page?
              </span>
              <div className="d-flex mt-4">
                <CustomButton
                  className={"mr-2"}
                  data-dismiss="modal"
                  onClick={onHide}
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    border: "1px solid white",
                  }}
                >
                  No
                </CustomButton>
                <CustomButton
                  className={"ml-2"}
                  dataDismiss="modal"
                  onClick={() =>
                    createUpdateTestingProtocolResultset(
                      testing_protocol_id,
                      "message"
                    )
                  }
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Yes
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <button
              type="button"
              className="Model_btn_Cancel "
              data-dismiss="modal"
              onClick={onHide}
            >
              No
            </button>
            <button
              type="button"
              className="Model_btn_ok "
              data-dismiss="modal"
              onClick={() =>
                createUpdateTestingProtocolResultset(
                  testing_protocol_id,
                  "message"
                )
              }
            >
              Yes
            </button>
          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

export default CreatePageSetModal;
