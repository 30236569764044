import moment from "moment";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class ViewAthleteEventModal extends Component {
  render() {
    const {
      state,
      onChange,
      handelStartTimeChange,
      handelEndTimeChange,
      calendar_events_update,
      closeModal,
      toggleDeleteModal,
    } = this.props;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={() => closeModal()}
          animation={true}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="teamcreate">
                View Events
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <span className="edit-madical-status-span">Event Name</span>
                <p className="edit-madical-status-p">{state?.editEventName}</p>
              </div>
              {state?.editStartTime && (
                <div className="form-group">
                  <span className="edit-madical-status-span">Start Time</span>
                  <p className="edit-madical-status-p">
                    {moment(state.editStartTime, "HH:mm:ss").format("hh:mm a")}
                  </p>
                </div>
              )}
              {state?.editEndTime && (
                <div className="form-group">
                  <span className="edit-madical-status-span">End Time</span>
                  <p className="edit-madical-status-p">
                    {moment(state.editEndTime, "HH:mm:ss").format("hh:mm a")}
                  </p>
                </div>
              )}

              <div className="form-group">
                <span className="edit-madical-status-span">Description</span>
                <p className="edit-madical-status-p">{state.editDescription}</p>
              </div>

              <div className="form-group">
                <span className="edit-madical-status-span">Attendance</span>
                <p className="edit-madical-status-p">
                  {state?.userAttandance === false
                    ? "Not Available"
                    : "Available"}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ViewAthleteEventModal;
