import CustomInput from "component/customInput/CustomInput";
import React from "react";
import DeleteExerciseGroupModalForCoach from "./DeleteExerciseGroupModalForCoach";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import SvgIcon from "component/SvgIcon";
import AddCircleIcon from "@material-ui/icons/AddCircle";

class ExerciseWorkoutGroupSectionForCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // listExerciseGroup: this.props.ExerciseGroupsSelectpicker
    };
  }

  render() {
    // console.log("this is new component", this.props.ExerciseGroupsSelectpicker);

    // console.log("props from state", this.state.listExerciseGroup);

    const { addWorkoutExerciseField, workoutExerciseGroupUpdate } =
      this.props.value;
    return (
      <div>
        <div
          className="col-lg-12 py-3"
          style={{
            backgroundColor: "var(--blackRussian)",
            borderRadius: "15px",
          }}
        >
          <div>
            <h2
              style={{
                color: "white",
                fontSize: "20px",
                padding: "15px 6px",
                borderBottom: "1px solid ",
              }}
            >
              Exercise Groups
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-9 py-2">
              <CustomInput
                placeholder="Add New Group"
                name="addWorkoutExerciseField"
                value={
                  (addWorkoutExerciseField,
                  this.props.addWorkoutExerciseGroupReset)
                }
                onChange={this.props.onChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 d-flex align-items-center">
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                // className="btn admin_button"
                onClick={this.props.addWorkoutExerciseGroup}
              >
                <AddCircleIcon
                  // onClick={() => addNewCriteria()}
                  style={{
                    width: "45px",
                    height: "50px",
                    color: "#F75F03",
                  }}
                />
              </button>
            </div>
          </div>
          {this.props.visibleListGroupExercise && (
            <div className="exercise-workout-group pr-4">
              {this.props.ExerciseGroupsSelectpicker.map((item) => {
                return (
                  <div className="row" key={item?.id}>
                    <div className="col-lg-9 col-md-9 col-sm-9 py-2">
                      <CustomInput
                        name="workoutExerciseGroupUpdate"
                        value={(workoutExerciseGroupUpdate, item.label)}
                        onChange={this.props.onChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 d-flex align-items-center justify-content-around">
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() =>
                          this.props.updateWorkoutExerciseGroup(item)
                        }
                      >
                        <CheckCircleRoundedIcon
                          style={{
                            color: "var(--safetyOrange)",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          marginLeft: "10px",
                        }}
                        onClick={() => this.props.showModal(item)}
                      >
                        <SvgIcon
                          name="trash"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {this.props.visibleNewListGroupExercise && (
            <div className="row mb-3 exercise-workout-group">
              {this.props.ExerciseGroupsSelectpicker.map((item) => {
                return (
                  <>
                    <div className="col-lg-9 col-md-9 col-sm-9 py-2">
                      <CustomInput
                        name="workoutExerciseGroupUpdate"
                        value={(workoutExerciseGroupUpdate, item.label)}
                        onChange={this.props.onChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 d-flex align-items-center justify-content-around">
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.props.updateWorkoutExerciseGroup(item)
                        }
                      >
                        <CheckCircleRoundedIcon
                          style={{
                            color: "var(--safetyOrange)",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => this.props.showModal(item)}
                      >
                        <SvgIcon
                          name="trash"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </button>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
        {/* <div className="col-md-12">
          <div className=" home_sc_admin ">
            <div className="pannel_heading_react  ">
              <h2 style={{ color: "white", fontSize: "38px" }}>
                Exercise Groups
              </h2>
            </div>
            <div className="panel-body exercise_group_table_Second">
              <table className="table table-condensed table-bordered">
                <thead>
                  <tr>
                    <th>Exercise Group</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style={{ color: "black" }}>
                  <tr>
                    <td>
                      
                      <input
                        className="col-md-12 form-control exercise-description"
                        name="addWorkoutExerciseField"
                        value={
                          (addWorkoutExerciseField,
                          this.props.addWorkoutExerciseGroupReset)
                        }
                        onChange={this.props.onChange}
                      />
                    </td>
                    <td>
                      <button
                        // className="btn admin_button"
                        onClick={this.props.addWorkoutExerciseGroup}
                      >
                        <CheckCircleRoundedIcon
                          style={{
                            color: "var(--safetyOrange)",
                            fontSize: "40px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                    </td>
                  </tr>

                  {this.props.visibleListGroupExercise && (
                    <>
                      {this.props.ExerciseGroupsSelectpicker.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <input
                                className="col-md-12 form-control exercise-description"
                                name="workoutExerciseGroupUpdate"
                                defaultValue={
                                  (workoutExerciseGroupUpdate, item.label)
                                }
                                onChange={this.props.onChange}
                                style={
                                  item.type !== "admin"
                                    ? {}
                                    : {
                                        backgroundColor: "lightGray",
                                        cursor: "not-allowed",
                                      }
                                }
                              />
                            </td>
                            {item.type !== "admin" && (
                              <td style={{ display: "flex" }}>
                                <button
                                  className="btn admin_button"
                                  onClick={() =>
                                    this.props.updateWorkoutExerciseGroup(item)
                                  }
                                >
                                  <i className="fa fa-check"></i>
                                </button>

                                <button
                                  className="btn admin_button"
                                  onClick={() => this.props.showModal(item)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  )}

                  {this.props.visibleNewListGroupExercise && (
                    <>
                      {this.props.ExerciseGroupsSelectpicker.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <input
                                className="col-md-12 form-control exercise-description"
                                name="workoutExerciseGroupUpdate"
                                defaultValue={
                                  (workoutExerciseGroupUpdate, item.label)
                                }
                                onChange={this.props.onChange}
                              />
                            </td>
                            <td style={{ display: "flex" }}>
                              <button
                                className="btn admin_button"
                                onClick={() =>
                                  this.props.updateWorkoutExerciseGroup(item)
                                }
                              >
                                <i className="fa fa-check"></i>
                              </button>

                              <button
                                className="btn admin_button"
                                onClick={() => this.props.showModal(item)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
        <DeleteExerciseGroupModalForCoach
          show={this.props.show}
          onHide={this.props.hideModal}
          deleteWorkoutExerciseGroup={this.props.deleteWorkoutExerciseGroup}
        />
      </div>
    );
  }
}

export default ExerciseWorkoutGroupSectionForCoach;
