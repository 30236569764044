import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import { standardPostApi } from "container/API/ApiWrapper";
import CoachHeader from "container/PublicLayout/CoachHeader";
import Footer from "container/PublicLayout/Footer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

function EditPlayerDetails(props) {
  const {
    location: { state },
    push,
  } = useHistory();
  // console.log({ state }, { props });
  const [sportPosition, setSportPosition] = useState([]);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    address: "",
    access_user_id: props.match.params.playerId,
    access_sport_position: "",
    ...state?.player,
  });

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    address: "",
    access_sport_position: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // state?.player &&
    //   setUserDetails({
    //     ...state?.player,
    //     access_user_id: props.match.params.playerId,
    //     // access_sport_position:state?.player?.sport_position
    //   });

    playerSportPosition();
  }, [state]);

  // useEffect(() => {
  //   playerSportPosition();
  // }, []);

  const onHandleChange = (event) => {
    const { name, value } = event?.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });

    error[name] &&
      setError({
        ...error,
        [name]: "",
      });
  };
  const onHandleChangeDate = (date) => {
    setUserDetails({
      ...userDetails,
      dob: date,
    });
  };
  const onValidate = () => {
    let isError = false;
    let tempError = { ...error };
    const { first_name, last_name, address, dob } = userDetails;
    if (!first_name?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        first_name: "First name field is required.",
      };
    }
    if (!last_name?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        last_name: "Last name field is required.",
      };
    }

    if (!address?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        address: "Address field is required.",
      };
    }

    if (!dob) {
      isError = true;
      tempError = {
        ...tempError,
        dob: "Date of birth field is required.",
      };
    }
    setError(tempError);
    return isError;
  };

  const playerSportPosition = async () => {
    try {
      const res = await standardPostApi(
        "pre_add_coach_player_in_team",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: props.match.params.id,
        },
        true
      );
      if (res.data.code === 200) {
        res.data.data.SportPositions.pickerArray.forEach((item) => {
          if (item.label === props.location.state.player.sport_position) {
            console.log("dataPlayre", item);
            setUserDetails({
              ...state?.player,
              access_sport_position: item.value,
            });
          }
        });

        setSportPosition(res?.data?.data?.SportPositions?.pickerArray ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };
  const update_user_profile = async () => {
    if (onValidate()) {
      return null;
    }

    const start_date = moment(toTimestamp(userDetails?.dob) * 1000)
      .format("YYYY-MM-DD")
      .toString();
    setIsLoading(true);
    try {
      const res = await standardPostApi(
        "update_user_profile",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          first_name: userDetails?.first_name,
          last_name: userDetails?.last_name,

          address: userDetails?.address,

          access_sport_position: userDetails?.access_sport_position,

          dob: start_date,
          access_user_id: props.match.params.playerId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("This is Reasponse of update_user_profile", res.data.data);
        toast.success(res.data.message, { autoClose: 2500 });
        setIsLoading(false);
        push(
          `/myplayers/${props?.match?.params?.id}/${props?.match?.params?.teamname}`
        );

        // await this.props.location.state;
        // await this.setState({
        //   playerDetails: res.data.data,
        // });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="loader_sec">
      <CoachHeader />

      <div className="container-fluid background_color">
        <CustomBreadcrumb
          heading={"My Players"}
          navList={[
            {
              name: "My Team",
              link: `/myplayers/${props.match.params.id}/${props.match.params.teamname}`,
            },
            {
              name: `${state?.player?.first_name} ${state?.player?.last_name}`,
              link: {
                pathname: `/coachplayerinner/${props.match.params.id}/${props.match.params?.playerId}/${props.match.params.teamname}`,
                state: {
                  player: props?.location?.state?.player,
                },
              },
            },
            {
              name: "Edit User Detail",
            },
          ]}
          className="py-4"
        />

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <CustomInput
              placeholder={"First Name"}
              name="first_name"
              onChange={onHandleChange}
              value={userDetails?.first_name}
              error={error?.first_name}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <CustomInput
              placeholder={"Last Name"}
              name="last_name"
              onChange={onHandleChange}
              error={error?.last_name}
              value={userDetails?.last_name}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <CustomInput
              placeholder={"Email"}
              name="email"
              onChange={onHandleChange}
              value={userDetails?.email}
              readOnly
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <InputDateTimePicker
              placeholder={"Date of birth"}
              className="d-flex"
              name="dob"
              onChange={onHandleChangeDate}
              value={userDetails?.dob}
              error={error?.dob}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            {/* {console.log({sportPosition} , userDetails?.access_sport_position )} */}
            <select
              className="select_class"
              // defaultValue={
              //   (playerSportPosition, this.state.defaultSportPosition)
              // }
              selected
              name="access_sport_position"
              onChange={onHandleChange}
              value={userDetails?.access_sport_position}
              // onChange={(e) => this.onChange(e)}
            >
              <option value="">Select Sport Position</option>
              {sportPosition &&
                sportPosition.map((item) => {
                  return (
                    <option value={item.value} key={item.id}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <CustomInput
              placeholder={"Address"}
              name="address"
              onChange={onHandleChange}
              value={userDetails?.address}
              error={error?.address}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
            <div className="d-flex">
              <CustomButton
                onClick={update_user_profile}
                isLoading={isLoading}
                flex={1}
              >
                Save
              </CustomButton>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default EditPlayerDetails;
