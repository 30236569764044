import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import NoDataFound from "component/lottiLoader/LottiLoader";
import LoaderWrapper from "container/Loader/LoaderWrapper";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { standardPostApi } from "../../container/API/ApiWrapper";
import CoachHeader from "../../container/PublicLayout/CoachHeader";
import Footer from "../../container/PublicLayout/Footer";
import TranningPlanDay from "./TranningPlanDay";

class TranningPlanWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekList: this.props.location.state,
      listDays: {},
      redirectToDays: false,
      weekResult: [],
      RESULT: {},
      isLoading: false,
      selectedWeek: null,
      // WEEK_RESULT: {},
    };
  }

  componentDidMount() {
    this.list_athlete_workout();
  }

  list_athlete_workout = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await standardPostApi(
        "list_athlete_workout",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          access_user_id: this.props.match.params.playerId,
          access_training_type: this.props.match.params.tranningType,
        },
        true,
        false
      );
      if (res.data.code === 200) {
        const resultFirst = res.data.data.find(
          (data) => data.annual_program_completed === false
        );

        if (resultFirst) {
          this.setState({ RESULT: resultFirst });
        } else {
          this.setState({ RESULT: res.data.data[res.data.data.length - 1] });
        }
        await this.setState({
          weekResult: this.state.RESULT.weeks,
          selectedWeek: this.state.RESULT.weeks[0] ?? null,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, weekResult, selectedWeek } = this.state;
    return (
      <div className="loader_sec">
        <CoachHeader />
        <div className="dashboard-wrapper">
          <section className="myteams_wrapper">
            <div className="inner_teamsection">
              <CustomBreadcrumb
                heading="My Player"
                className={"mb-4"}
                navList={[
                  {
                    name: "My Team",
                    link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                  },
                  {
                    name:
                      this.props.location.state.player.first_name +
                      " " +
                      this.props.location.state.player.last_name,
                    link: {
                      pathname: `/coachplayerinner/${this.props.match.params.id}/${this.props.match.params?.playerId}/${this.props.match.params.teamname}`,
                      state: {
                        player: this.props?.location?.state?.player,
                      },
                    },
                  },
                  {
                    name: "Training Plan",
                    link: {
                      pathname: `/tranningplan/${this.props.match.params.id}/${this.props.match.params.playerId}/${this.props.match.params.teamname}`,
                      state: this.props.location.state,
                    },
                  },
                  {
                    name:
                      this.props.match.params.tranningType === "in_season"
                        ? "In Season "
                        : this.props.match.params.tranningType === "off_season"
                        ? "Off Season "
                        : this.props.match.params.tranningType === "pre_season"
                        ? "Pre Season "
                        : this.props.match.params.tranningType === "transition"
                        ? "Transition "
                        : null,
                  },
                ]}
              />
              {/* <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <select
                    className="select_border_class"
                    onChange={(event) =>
                      this.setState({
                        weekResult: JSON.parse(event?.target?.value),
                      })
                    }
                    value={JSON.stringify(weekResult)}
                  >
                    <option disabled selected>
                      Select Week
                    </option>
                    {this.state.weekData?.map((item) => (
                      <option value={JSON.stringify(item)} key={item?.id}>
                        {item?.week_number}
                      </option>
                    ))}
                  </select>
                </div>

                {this.state.weekResult &&
                  this.state.weekResult.days.map((item) => (
                    <div
                      className="col-lg-4 col-md-4 col-sm-6"
                      key={item?.id + ""}
                    >
                      <ProgramViewCard
                        text={item.day_number}
                        buttonText={"View Workout"}
                      />
                    </div>
                  ))}

                {!isLoading && this.state?.weekResult === null && (
                  <NoDataFound
                    height={250}
                    width={250}
                    text="No Programs assigned yet."
                    screenCenter
                  />
                )}
              </div> */}

              {isLoading ? (
                <LoaderWrapper />
              ) : weekResult.length === 0 ? (
                <NoDataFound
                  height={250}
                  width={250}
                  text="No Week available yet"
                />
              ) : (
                <div>
                  <select
                    className="select_border_class"
                    onChange={(event) =>
                      this.setState({
                        selectedWeek: JSON.parse(event?.target?.value),
                      })
                    }
                    value={JSON.stringify(selectedWeek)}
                  >
                    {weekResult?.map((item) => (
                      <option value={JSON.stringify(item)} key={item?.id}>
                        {item?.week_number}
                      </option>
                    ))}
                  </select>

                  {selectedWeek && (
                    <TranningPlanDay
                      location={{
                        state: {
                          days: selectedWeek?.days,
                          state: this.props.location.state,
                          week: selectedWeek,
                          tranningType: this.props.match.params?.tranningType,
                        },
                      }}
                      // location={
                      //   (state = {
                      //     days: item.days,
                      //     state: this.props.location.state,
                      //     week: item,
                      //     tranningType: this.props.match.params.tranningType,
                      //   })
                      // }
                    />
                  )}
                </div>
              )}

              {/* {this.state.weekResult &&
                this.state.weekResult.map((item) => {
                  return (
                    <>
                      <select
                        className="select_border_class"
                        onChange={(event) =>
                          this.setState({
                            weekResult: JSON.parse(event?.target?.value),
                          })
                        }
                        value={JSON.stringify(weekResult)}
                        // onChange={this.handelChange}
                        // defaultValue={this.state.selectData}
                      >
                        <option value={item}>{item.week_number}</option>
                      </select>
                  
                      <TranningPlanDay
                        location={{
                          state: {
                            days: item.days,
                            state: this.props.location.state,
                            week: item,
                            tranningType: this.props.match.params.tranningType,
                          },
                        }}
                      />
                    </>
                  );
                })}

              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <LoaderWrapper />
                </div>
              )}

              {!isLoading && this.state.weekResult.length === 0 && (
                <NoDataFound
                  height={250}
                  width={250}
                  text="No Week available yet"
                />
              )} */}
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TranningPlanWeek;
