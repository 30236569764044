import SvgIcon from "component/SvgIcon";
import React from "react";
import "./CustomInput.scss";
function CustomInput({
  type = "text",
  name,
  value,
  onChange,
  style,
  className,
  placeholder,
  error = "",
  inputClassName,
  inputStyle,
  readOnly,
  maxLength,
  rows = "4",
  cols = "50",
  search,
  accept,
}) {
  return (
    <div style={style} className={className}>
      {type === "textarea" ? (
        <textarea
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className={` custom_text_area ${inputClassName}`}
          style={inputStyle}
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          disabled={readOnly}
        />
      ) : (
        <div>
          <input
            type={type}
            name={name}
            accept={accept}
            value={value}
            onChange={onChange}
            className={`custom_input ${inputClassName}`}
            style={inputStyle}
            placeholder={placeholder}
            disabled={readOnly}
            maxLength={maxLength}
          />
          {search && (
            <SvgIcon
              name={"search"}
              style={{
                position: "absolute",
                right: "25px",
                width: "20px",
                height: "50px",
              }}
            />
          )}
        </div>
      )}
      {error?.trim()?.length !== 0 && (
        <p className="custom_input_error">{error}</p>
      )}
    </div>
  );
}

export default CustomInput;
