import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../PublicLayout/Header";
import Footer from "../PublicLayout/Footer";
import { standardPostApi } from "../API/ApiWrapper";
import show from "../../Custom/images/show.png";
import hide from "../../Custom/images/hide.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { errorToast } from "../../utils/toastMessage";
import userImg from "Custom/images/coach-login2.png";
import userAthleteImg from "Custom/images/sign_in_as_athlete.png";
import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";

class LoginPageWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      login: false,
      person: this.props.match.params.person,
      passwordToggle: false,
      isLoading: false,
      // userDetails: {},
    };
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.match.params.person !== this.props.match.params.person) {
      this.setState({ person: this.props.match.params.person });
    }
  }
  login = async (e) => {
    e.preventDefault();
    const isValid = this.validation();
    if (isValid) {
      this.setState({ isLoading: true });
      try {
        const res = await standardPostApi(
          "login",
          undefined,
          {
            email: this.state.email,
            password: this.state.password,
            role: this.state.person === "coach" ? 3 : 2,
            device_token: "874874874874",
            device_type: "ios",
            login_type: "web",
          },
          true
        );

        if (res.data.code === 200) {
          localStorage.setItem("access_token", res.data.data.access_token);
          localStorage.setItem("access_role", res.data.data.role);
          this.setState({ login: true });
          // console.log('response of log in ', res.data.data);
          await this.saveDeviceToServer();
          this.props.history.push(
            this.state.person === "coach" ? "/myteamwrapper" : "/selfscreening"
          );
        }
      } catch (error) {
        errorToast(error?.message);
        console.log(error);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  validation = (e) => {
    let emailError = "";
    let passwordError = "";
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!this.state.email) {
      emailError = "The Email field is required.";
    } else if (!this.state.email.match(emailReg)) {
      emailError = "This Email Address is not valid. ";
    }
    if (!this.state.password) {
      passwordError = " The Password field is required.";
    }
    if (emailError || passwordError) {
      this.setState({ emailError, passwordError });
      return false;
    } else {
      return true;
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    // console.log(this.state);
  }

  togglePassfunc = async () => {
    const { passwordToggle } = this.state;
    await this.setState({ passwordToggle: !passwordToggle });
  };

  async saveDeviceToServer() {
    const playerId = localStorage.getItem("useragentid");
    // console.log('Devise state at Login', {
    //   access_token: await localStorage.getItem('access_token'),
    //   player_id: playerId,
    //   device_type: 'web',
    //   device_id: '12345978',
    //   device_model: 'dell',
    // });
    try {
      const res = await standardPostApi(
        "save_device",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          player_id: playerId,
          device_type: "web",
          device_id: "12345978",
          device_model: "dell",
        },
        true,
        false
      );
      if (res.data.status) {
        // console.log('Push Notification connected');
      }
    } catch (error) {
      console.log("error of save divice", error);
    }
  }

  render() {
    // if (this.state.login) {
    //   return localStorage.getItem("access_token") ? (
    //     <Redirect
    //       to={
    //         this.state.person === "coach" ? "/myteamwrapper" : "/selfscreening"
    //       }
    //     />
    //   ) : null;
    // }

    // if (this.state.login) {
    //   this.props.history.push(
    //     this.state.person === "coach" ? "/myteamwrapper" : "/selfscreening"
    //   );
    // }

    // console.log(
    //   "snfkjbhafjfdfhozbujzbxcujbzjxc----------------->",
    //   localStorage.getItem("useragentid")
    // );

    const { email, password, passwordToggle, isLoading } = this.state;

    return (
      <div className="loader_sec">
        <Header />
        <div className="login-page-wrapper">
          <section className="login_page">
            <div
              className="container"
              style={{ marginTop: "0", marginBottom: "10%" }}
            >
              <div className="row">
                <div className="col-lg-6">
                  {this.state.person === "coach" ? (
                    <img src={userImg} alt="no_img" className="img-fluid" />
                  ) : (
                    <img
                      src={userAthleteImg}
                      alt="no_img"
                      className="img-fluid"
                    />
                  )}
                </div>
                <div className="col-lg-6 m-auto">
                  <form action="" className="login_form">
                    {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '14px',
                      }}
                    >
                     
                    </div> */}
                    <div
                      className="heading text-center"
                      style={{ textTransform: "none", fontWeight: "500" }}
                    >
                      {this.state.person === "coach"
                        ? "Coach Login"
                        : "Athlete Login"}
                    </div>
                    <div className="form-group">
                      {/* <input
                        type='text'
                        className='form-control'
                        placeholder='Email Address'
                        name='email'
                        value={email}
                        onChange={this.onChange}
                      /> */}
                      <CustomInput
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        error={this.state.emailError}
                      ></CustomInput>

                      {/* <p className='react_validation'>
                        {this.state.emailError}
                      </p> */}
                    </div>

                    <div
                      className="form-group"
                      style={{ position: "relative" }}
                    >
                      <CustomInput
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        type={passwordToggle === false ? "password" : "text"}
                        error={this.state.passwordError}
                      ></CustomInput>
                      {passwordToggle === false ? (
                        <VisibilityIcon
                          className="addPlayer-pass-hide"
                          onClick={() => this.togglePassfunc()}
                          style={{
                            height: "20px",
                            position: "absolute",
                            top: "15px",
                            right: "14px",
                            cursor: "pointer",
                            opacity: "0.5",
                            color: "#f75f03",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          className="addPlayer-pass-hide"
                          color="red"
                          onClick={() => this.togglePassfunc()}
                          style={{
                            height: "20px",
                            position: "absolute",
                            top: "15px",
                            right: "14px",
                            cursor: "pointer",
                            opacity: "0.5",
                            color: "#f75f03",
                          }}
                        />
                      )}
                      <Link to="/forgotpassword" className="forgot_password">
                        Forgot Password ?
                      </Link>
                    </div>
                    <div className="d-flex">
                      <CustomButton
                        onClick={this.login}
                        disabled={isLoading}
                        isLoading={isLoading}
                        flex={1}
                      >
                        Log in
                      </CustomButton>
                    </div>
                    {this.state.person === "coach" ? (
                      <Link
                        to={`/login/${"athlete"}`}
                        className="link-to-athlete-for-coach d-flex flex-column"
                      >
                        Or Login as Athlete
                        <div className="border_bottom_Link"></div>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="/sportsection"
                          className="forgot_password"
                          style={{ justifyContent: "center", fontSize: "15px" }}
                        >
                          Register as a New User
                        </Link>
                        <Link
                          to={`/login/${"coach"}`}
                          className="link-to-athlete-for-coach d-flex flex-column"
                          style={{ paddingTop: "35px" }}
                        >
                          Or Login as Coach
                          <div className="border_bottom_Link"></div>
                        </Link>
                      </>
                    )}

                    {/* <button
                      type='submit'
                      className='btn btn-primary submit_btn'
                      onClick={this.login}
                      style={{ padding: '5px 26px' }}
                      disabled={isLoading}
                    >
                      Log in{' '}
                    </button> */}
                    {/* <div className='page_links mt-4'> */}
                    {/* /forgotpassword */}
                    {/* <Link to='/forgotpassword' className='forgot_password'> */}
                    {/* <div onClick={() => alert("coming soon!")}>
                        <Link className="forgot_password"> */}
                    {/* Forgot Your Password ? */}
                    {/* </Link> */}
                    {/* </div> */}
                    {/* <Link
                        to='/sportsection'
                        className='forgot_password' */}
                    {/* // style={{ marginBottom: "10%" }}
                      >
                        Register as a New User
                      </Link>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default LoginPageWrapper;
