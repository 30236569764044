import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";

import show from "../../../Custom/images/show.png";
import hide from "../../../Custom/images/hide.png";
import { standardPostApi } from "container/API/ApiWrapper";
// datepicker in react
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "../../../Custom/images/celender.jpg";
import { successToast } from "utils/toastMessage";
import moment from "moment";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CustomButton from "component/customButton/CustomButton";

export class RegisterMainCoachModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      address: "",
      sport: "",
      allSports: [],
      date: new Date(),
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      passwordError: "",
      confirmPasswordError: "",
      dboError: "",
      addressError: "",
      sportError: "",
      loading: false,
      togglePass: false,
      toggleConfirmPass: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log('state change', this.state);
  };

  handleDateChange = async (date) => {
    await this.setState({ date: date });
    // console.log('The start date selected is ', this.state.date);
  };

  componentDidMount() {
    this.fetchAllSports();
  }

  fetchAllSports = async () => {
    try {
      const res = await standardPostApi("list_all_sports", undefined, {}, true);
      if (res.data.code === 200) {
        // console.log('Response of all sports ', res.data.data.AllSports);
        this.setState({ allSports: res.data?.data?.AllSports });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handelCreateNewCoach = async () => {
    const { firstName, lastName, email, password, address, sport, date } =
      this.state;

    const dob = moment(date).format("YYYY-MM-DD");

    const isValid = this.validationCreateCoach();
    if (isValid) {
      this.setState({ loading: true });
      try {
        const res = await standardPostApi(
          "register_final",
          undefined,
          {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            dob: dob,
            address: address,
            role: "3",
            sport: sport,
          },
          true
        );
        if (res.data.code === 200) {
          // console.log('Response of create coach', res.data.data);
          successToast(res.data.message);
          this.props.onHide();
        }
      } catch (err) {
        console.error(err);
        this.setState({ loading: false });
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  validationCreateCoach = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      address,
      sport,
    } = this.state;

    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let dboError = "";
    let addressError = "";
    let sportError = "";

    const dob = new Date(this.state.date);
    const today = new Date();
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let passReg =
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;

    if (!firstName) {
      firstNameError = "First name field is required.";
    }
    if (!lastName) {
      lastNameError = "Last name field is required.";
    }

    if (!email) {
      emailError = "Email field is not a valid.";
    } else if (!email.match(emailReg)) {
      emailError = "Email field is not a valid.";
    }

    if (!password) {
      passwordError = "Password field is required.";
    } else if (!password.match(passReg)) {
      passwordError =
        "Password must have minimum 8 characters, at least one number, at least one upper case, at least one lower case, at least one special character";
    }

    if (!confirmPassword) {
      confirmPasswordError = "Confirm password is required.";
    } else if (!confirmPassword.match(password)) {
      confirmPasswordError = "Password and confirmation password do not match.";
    }

    if (!this.state.date) {
      // alert("Please select a Date of Birth.");
      dboError = "Please select a Date of Birth.";
      // return false;
    } else if (today.getFullYear() - dob.getFullYear() < 16) {
      dboError = "You must be at least 16 year old, in order to sign up.";
      // return false;
    }

    if (!address) {
      addressError = "Address field is required.";
    }

    if (!sport) {
      sportError = "Sport field is required.";
    }

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      passwordError ||
      confirmPasswordError ||
      dboError ||
      addressError ||
      sportError
    ) {
      this.setState({
        firstNameError,
        lastNameError,
        emailError,
        passwordError,
        confirmPasswordError,
        dboError,
        addressError,
        sportError,
      });
      return false;
    } else {
      return true;
    }
  };

  togglePasswordIcon = () => {
    this.setState({ togglePass: !this.state.togglePass });
  };

  toggleConfirmPasswordIcom = () => {
    this.setState({ toggleConfirmPass: !this.state.toggleConfirmPass });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      address,
      sport,
      allSports,
    } = this.state;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="coachprofileTitle">
                Register New Head Coach
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group" style={{ position: "relative" }}>
                <CustomInput
                  placeholder="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => this.onChange(e)}
                  error={this.state.firstNameError}
                />
                {/* <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => this.onChange(e)}
                />
                <p className="react_validation">{this.state.firstNameError}</p> */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <CustomInput
                  placeholder="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => this.onChange(e)}
                  error={this.state.lastNameError}
                />
                {/* <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => this.onChange(e)}
                />
                <p className="react_validation">{this.state.lastNameError}</p> */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <CustomInput
                  name="email"
                  value={email}
                  onChange={(e) => this.onChange(e)}
                  type="email"
                  placeholder="Email Address"
                  error={this.state.emailError}
                />
                {/* <label>Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => this.onChange(e)}
                />
                <p className="react_validation">{this.state.emailError}</p> */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <CustomInput
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => this.onChange(e)}
                  error={this.state.passwordError}
                />
                {/* <label>Password</label>
                <input
                  type={this.state.togglePass === false ? "password" : "text"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => this.onChange(e)}
                />

                <p className="react_validation">{this.state.passwordError}</p>

                {this.state.togglePass === false ? (
                  <img
                    src={show}
                    alt="show"
                    style={{
                      position: "absolute",
                      height: "20px",
                      top: "41px",
                      right: "13px",
                      cursor: "pointer",
                      opacity: "0.5",
                    }}
                    onClick={() => this.togglePasswordIcon()}
                  />
                ) : (
                  <img
                    src={hide}
                    alt="hide"
                    style={{
                      position: "absolute",
                      height: "20px",
                      top: "41px",
                      right: "13px",
                      cursor: "pointer",
                      opacity: "0.5",
                    }}
                    onClick={() => this.togglePasswordIcon()}
                  />
                )} */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <CustomInput
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => this.onChange(e)}
                  placeholder="Confirm Password"
                  error={this.state.confirmPasswordError}
                />
                {/* <label>Confirm Password</label>
                <input
                  type={
                    this.state.toggleConfirmPass === false ? "password" : "text"
                  }
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => this.onChange(e)}
                />
                <p className="react_validation">
                  {this.state.confirmPasswordError}
                </p>

                {this.state.toggleConfirmPass === false ? (
                  <img
                    src={show}
                    alt="show"
                    style={{
                      position: "absolute",
                      height: "20px",
                      top: "41px",
                      right: "13px",
                      cursor: "pointer",
                      opacity: "0.5",
                    }}
                    onClick={() => this.toggleConfirmPasswordIcom()}
                  />
                ) : (
                  <img
                    src={hide}
                    alt="hide"
                    style={{
                      position: "absolute",
                      height: "20px",
                      top: "41px",
                      right: "13px",
                      cursor: "pointer",
                      opacity: "0.5",
                    }}
                    onClick={() => this.toggleConfirmPasswordIcom()}
                  />
                )} */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <InputDateTimePicker
                  style={{ width: "100%" }}
                  value={this.state.date}
                  onChange={this.handleDateChange}
                  placeholder="Date of Birth"
                  error={this.state.dboError}
                />
                {/* <label>Date Of Birth</label>
                <DatePicker
                  selected={this.state.date}
                  onChange={this.handleDateChange}
                  name="DateOfBirth"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <img className="celender_img " src={Calander} alt={Calander} />
                <p className="react_validation ">{this.state.dboError}</p> */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <CustomInput
                  name="address"
                  value={address}
                  onChange={(e) => this.onChange(e)}
                  placeholder="Address"
                  error={this.state.addressError}
                />
                {/* <label>Address </label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={address}
                  onChange={(e) => this.onChange(e)}
                />
                <p className="react_validation ">{this.state.addressError}</p> */}
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <select
                  name="sport"
                  value={sport}
                  onChange={(e) => this.onChange(e)}
                  className="select_class"
                >
                  <option value="">Select Sport</option>
                  {allSports?.length !== 0 &&
                    allSports?.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item?.sport_name}
                        </option>
                      );
                    })}
                </select>
                <p className="react_validation ">{this.state.sportError}</p>
              </div>
              <div className="d-flex">
                <CustomButton
                  flex={1}
                  onClick={(e) => {
                    this.handelCreateNewCoach();
                  }}
                  disabled={this.state.loading}
                  isLoading={this.state.loading}
                >
                  Register
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
          {/* <ModalFooter>
            <button
              type="button"
              className="Model_btn"
              data-dismiss="modal"
              onClick={(e) => {
                this.handelCreateNewCoach();
              }}
            >
              Register{" "}
              {this.state.loading && (
                <i
                  className="fa fa-spinner fa-spin fa-3x fa-fw"
                  style={{
                    color: "#fff",
                    fontSize: "20px",

                    // marginTop: "50px",
                  }}
                />
              )}
            </button>
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

export default RegisterMainCoachModal;
