export const checkFileType = (url) => {
  let docType = ["pdf"];
  let temp = url.split(".");
  let urlType = temp[temp?.length - 1];
  if (docType.includes(urlType)) {
    return "pdf";
  } else {
    return "video";
  }
};
