import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import UpdateSetsRest from "component/AtpINSeison/UpdateSetsRest";
import CustomInput from "component/customInput/CustomInput";
import CustomButton from "component/customButton/CustomButton";

class UpdateWorkoutGroupModal extends Component {
  render() {
    const {
      state,
      onHide,
      onHandelChange,
      hideSetsAndRest,
      updateSets,
      updateRest,
      updateDescription,
      updateSetsType,
      update_annual_training_program_workout_group,
      onChange,
      setTypeArray,
    } = this.props;

    const {
      numberOfExerciseUpdate,
      customSetTypeUpdate,
      customSetTypeUpdateError,
      numberOfExerciseUpdateError,
    } = state;
    return (
      <div>
        <Modal
          show={state.updateWorkoutGroupModal}
          onHide={onHide}
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                Edit Workouts Groups
              </h5>

              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group ">
                <CustomInput
                  error={state.UpdateDescriptionError}
                  name="updateDescription"
                  value={updateDescription}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="form-group ">
                <select
                  className="select_class"
                  name="updateSetsType"
                  value={updateSetsType}
                  onChange={(e) => onHandelChange(e)}
                >
                  <option value="" disabled selected>
                    Select an Item{" "}
                  </option>
                  {setTypeArray?.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                <p className="react_validation">{state.updateSetsTypeError}</p>
              </div>
              {/* {!hideSetsAndRest ? null : (
                <UpdateSetsRest
                  state={state}
                  updateSets={updateSets}
                  updateRest={updateRest}
                  onChange={(e) => onHandelChange(e)}
                  onKeyPress={this.props.onKeyPress}
                />
              )} */}

              {updateSetsType === "0" && (
                <>
                  <div className="form-group">
                    <CustomInput
                      placeholder="Custom sets type"
                      value={customSetTypeUpdate}
                      onChange={this.props.onChange}
                      error={customSetTypeUpdateError}
                      name="customSetTypeUpdate"
                    />
                  </div>{" "}
                  <div className="form-group">
                    <CustomInput
                      placeholder="Number of Exercise"
                      value={numberOfExerciseUpdate}
                      onChange={this.props.onChange}
                      error={numberOfExerciseUpdateError}
                      name="numberOfExerciseUpdate"
                      type="number"
                    />
                  </div>
                </>
              )}
              {Number(
                setTypeArray?.find((x) => x.value == Number(updateSetsType))
                  ?.number_of_excercise
              ) > 1 ||
              (Number(updateSetsType) == 0 &&
                Number(numberOfExerciseUpdate) > 1) ? (
                <UpdateSetsRest
                  state={state}
                  updateSets={updateSets}
                  updateRest={updateRest}
                  onChange={(e) => onHandelChange(e)}
                  onKeyPress={this.props.onKeyPress}
                />
              ) : null}
              <div className="d-flex">
                <CustomButton
                  flex={1}
                  onClick={update_annual_training_program_workout_group}
                  isLoading={state.updateGroupExerciseLoader}
                  disabled={state.updateGroupExerciseLoader}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default UpdateWorkoutGroupModal;
// workout_modal_label
