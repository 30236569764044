import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import moment from "moment";
import React from "react";
import "./EventCard.scss";
function EventCard({ item, onClick, buttonText = "Edit Event" }) {
  return (
    <div className="event_card_container position-relative">
      <div className="event_card_container_back" />
      <div className="event_card_container_front">
        <p className="event_card_heading">{item?.event_name ?? item?.title}</p>

        <div className="d-flex align-items-center">
          {item.event_time && (
            <div>
              <div className="d-flex align-items-center ">
                <SvgIcon name="clock" className={"mr-1"} />
                <p className="mb-0 time_heading">From</p>
              </div>
              <p className="event_time">
                {moment(item.event_time, "HH:mm:ss").format("hh:mm a")}
              </p>
            </div>
          )}
          {item.event_end_time && (
            <div>
              <div className="d-flex align-items-center">
                <SvgIcon name="clock" className={"mr-1"} />

                <p className="mb-0 time_heading">To</p>
              </div>
              <p className="event_time">
                {moment(item.event_end_time, "HH:mm:ss").format("hh:mm a")}
              </p>
            </div>
          )}
        </div>

        <p className="event_description">
          {item?.event_description ?? item?.description}
        </p>
        <div>
          <CustomButton
            type="outlined"
            className="event_button"
            onClick={onClick}
          >
            {buttonText}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
