import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import "./videoModal.css";
import Checkbox from "component/Checkbox/Checkbox";
import { standardPostApi } from "container/API/ApiWrapper";
import { errorToast } from "utils/toastMessage";
import NoDataFound from "component/lottiLoader/LottiLoader";
import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
class ViewExerciseModal extends React.Component {
  render() {
    const { show, onHide, alternativeExerciseObj } = this.props;
    const url = alternativeExerciseObj.video;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5
                className="modal-title"
                id="teamcreate"
                style={{ color: "#fff" }}
              >
                View Alternative Exercise
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <span className="edit-madical-status-span">Exercise Group</span>
                <p className="edit-madical-status-p">
                  {alternativeExerciseObj?.exercise_group?.exercise_group}
                </p>
              </div>
              <div className="form-group">
                <span className="edit-madical-status-span">Exercise</span>
                <p className="edit-madical-status-p">
                  {alternativeExerciseObj?.exercise}
                </p>
              </div>
              {alternativeExerciseObj?.video && (
                <div>
                  <iframe
                    title="video"
                    width="100%"
                    height="315"
                    // src={alternativeExerciseObj.video}
                    src={
                      alternativeExerciseObj?.type_media === "youtube video"
                        ? `https://www.youtube.com/embed/${urlId}`
                        : alternativeExerciseObj?.video
                    }
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen=""
                  ></iframe>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export class AlternativeExerciseModal extends Component {
  state = {
    // alternativeExerciseDetails: [],
    viewModal: false,
    alternativeExerciseObj: {},
  };

  // componentDidMount = () => {
  //   this.ListAthleteWorkout();
  // };

  // ListAthleteWorkout = async () => {
  //   try {
  //     const res = await standardPostApi(
  //       "list_alternative_exercise",
  //       undefined,
  //       { access_token: await localStorage.getItem("access_token") },
  //       true
  //     );
  //     if (res.data.code === 200) {
  //       // console.log("Response of List athlete Workout", res.data);
  //       this.setState({ alternativeExerciseDetails: res.data.data });
  //     }
  //   } catch (error) {
  //     console.error("Error of list athlete workout", error);
  //   }
  // };

  // ListAthleteWorkout = async () => {
  //   console.log("exerciseId", this.state.exerciseId);
  //   try {
  //     const res = await standardPostApi(
  //       "exercise/get_alternate_exercises",
  //       undefined,
  //       {
  //         access_token: await localStorage.getItem("access_token"),
  //         exercise_id: this.props?.exerciseId,
  //       },
  //       true
  //     );
  //     if (res.data.code === 200) {
  //       // console.log(
  //       //   "Response of List Athlete Workout",
  //       //   res?.data?.data?.alternate_exercises
  //       // );
  //       this.setState({
  //         alternativeExerciseDetails: res?.data?.data?.alternate_exercises,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("error of list athlete workout", error);
  //   }
  // };

  handelView = async (data) => {
    await this.setState({
      viewModal: !this.state.viewModal,
      alternativeExerciseObj: data,
    });
  };

  render() {
    const { show, onHide, alternativeExerciseDetails, setLoaderWorkout } =
      this.props;
    // console.log("setLoaderWorkout", setLoaderWorkout);
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5
                className="modal-title"
                id="teamcreate"
                style={{ color: "#fff" }}
              >
                Alternative Exercise
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {!setLoaderWorkout && alternativeExerciseDetails?.length === 0 && (
              <NoDataFound
                height={250}
                width={250}
                text="No alternative exercise avalable yet"
              />
            )}

            {setLoaderWorkout ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <i
                  className="fa fa-spinner fa-spin fa-3x fa-fw"
                  // className="fa fa-spinner fa-pulse fa-3x fa-fw"
                  style={{
                    color: "var(--safetyOrange)",
                    fontSize: "50px",
                    marginTop: "50px",
                    marginbottom: "60px",
                  }}
                />
              </div>
            ) : (
              <div>
                {/* <table className="table table-condensed react_workout_table"> */}
                {alternativeExerciseDetails.length !== 0 && (
                  <div className="row mt-4">
                    {alternativeExerciseDetails.map((data) => {
                      return (
                        <React.Fragment key={data?.id}>
                          <div className="col-lg-2 col-md-3 col-sm-12" />
                          <div className="col-lg-8 col-md-6 col-sm-12">
                            <ProgramViewCard
                              // item={item}
                              text={data?.exercise}
                              buttonText={"View"}
                              onClick={() => this.handelView(data)}
                              containerBackCardStyle={{
                                backgroundColor: "var(--jaguar)",
                              }}
                            />
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-12" />
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}

                {/* </tbody> */}

                {/* </table> */}
              </div>
            )}

            {/* <div className="modal-body">
              <div className="form-group">
                <label htmlFor="">Exercise</label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input type="text" className="form-control" readOnly />
                  <button
                    title="Save"
                    className="btn btn-md btn-primary workout-builder-save-workout-exercise "
                    style={{ marginLeft: "10px" }}
                  >
                    <i className="fa fa-eye"></i>
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div style={{ borderBottom: "1px solid #fff" }}></div> */}
          </Modal.Body>
          {/* <ModalFooter
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "none",
            }}
          >
            <div>
              <button type="button" className="Model_btn " onClick={onHide}>
                close
              </button>
            </div>
          </ModalFooter> */}
        </Modal>

        {/* {!this.state.alternativeExerciseObj === null && ( */}
        <ViewExerciseModal
          show={this.state.viewModal}
          onHide={this.handelView}
          alternativeExerciseObj={this.state.alternativeExerciseObj}
        />
        {/* )} */}
      </div>
    );
  }
}

export default AlternativeExerciseModal;
