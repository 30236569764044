import { Tooltip } from "@material-ui/core";
import CustomInput from "component/customInput/CustomInput";
import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
export class ViewActivityModal extends Component {
  render() {
    const { show, onHide, activityDetails, currentTeamName } = this.props;

    const url = activityDetails?.video;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="protocol">
                View Coach Library
              </h5>
              <Tooltip arrow title="close">
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </Tooltip>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <span className="edit-madical-status-span">Activity Name</span>
                <p className="edit-madical-status-p">
                  {activityDetails?.activity_name}
                </p>
                {/* <label htmlFor="" style={{ fontWeight: 'bold' }}>
                  Activity Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={activityDetails?.activity_name}
                  readOnly
                  style={{ cursor: 'not-allowed' }}
                /> */}
              </div>

              <div className="form-group">
                <span className="edit-madical-status-span">Team Name</span>
                <p className="edit-madical-status-p">{currentTeamName}</p>
                {/* <label htmlFor="" style={{ fontWeight: 'bold' }}>
                  Team Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={currentTeamName}
                  readOnly
                  style={{ cursor: 'not-allowed' }}
                /> */}
              </div>

              <div className="form-group">
                <span className="edit-madical-status-span">Description</span>
                <p className="edit-madical-status-p">
                  {activityDetails?.description}
                </p>
                {/* <label htmlFor="" style={{ fontWeight: 'bold' }}>
                  Description
                </label>
                <textarea
                  cols="4"
                  rows="4"
                  className="form-control"
                  style={{ resize: 'none', cursor: 'not-allowed' }}
                  defaultValue={activityDetails?.description}
                  readOnly
                /> */}
              </div>
              {activityDetails?.video && (
                <div>
                  <span className="edit-madical-status-span">video</span>
                  {activityDetails?.type_media === "video" ? (
                    <video
                      src={activityDetails?.video}
                      width="100%"
                      height="315"
                      autoPlay
                      controls
                    ></video>
                  ) : (
                    <iframe
                      width="100%"
                      height="315"
                      // src={activityDetails?.youtube_link}
                      src={
                        activityDetails?.type_media === "pdf"
                          ? activityDetails?.video
                          : `https://www.youtube.com/embed/${urlId}`
                      }
                      title=" video"
                      frameborder="0"
                      //   allow="autoplay; encrypted-media"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              )}
              <div>
                <span className="edit-madical-status-span">Tips</span>
                <ul style={{ marginLeft: "21px" }}>
                  {activityDetails?.tips?.length !== 0 &&
                    activityDetails?.tips?.map((item) => {
                      return (
                        <li
                          key={item.id}
                          style={{ listStyle: "unset", color: "white" }}
                        >
                          {item?.tip}
                        </li>
                      );
                    })}
                </ul>
              </div>

              {/* <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ borderTop: 'none' }}>Tips</th>
                    </tr>
                  </thead>

                  <tbody>
                    {activityDetails?.tips?.length !== 0 &&
                      activityDetails?.tips?.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td
                              style={{
                                borderTop: 'none',
                                cursor: 'not-allowed',
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                readOnly
                                defaultValue={item?.tip}
                                style={{ cursor: 'not-allowed' }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div> */}
            </div>
          </Modal.Body>
          {/* <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip arrow title="close">
              <button type="button" className="Model_btn " onClick={onHide}>
                Close
              </button>
            </Tooltip>
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

export default ViewActivityModal;
