import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import SvgIcon from "component/SvgIcon";
import { toast } from "react-toastify";
import React, { useRef, useState } from "react";
import User_Placeholder from "../../../Custom/images/user_placeholder.png";
import moment from "moment";
import {
  standardPostApi,
  standardPostApiJsonBased,
} from "container/API/ApiWrapper";
import { ImgType } from "helpers/FindImgType";
const imgArr = [".png", ".svg", ".jpg", "jpeg"];
function EditPlayerProfile({ profile, handleClose }) {
  const [details, setDetails] = useState({
    profile_image: null,
    ...profile,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    address: "",
    phone: "",
    body_weight: "",
    gender: "",
    height: "",
  });
  const onHandleChange = (event) => {
    const { value, name } = event?.target;
    setDetails({
      ...details,
      [name]: value,
    });

    error[name] &&
      setError({
        ...error,
        [name]: "",
      });
  };
  const UpdateUserProfile = async () => {
    console.log("data", details);
    const DOB = moment(details?.dob).format("YYYY-MM-DD");

    setIsLoading(true);

    try {
      let accessToken = await localStorage.getItem("access_token");
      console.log("accessToken", accessToken);
      let fd = new FormData();
      fd.append("access_token", accessToken);
      fd.append("first_name", details?.first_name);
      fd.append("last_name", details?.last_name);
      fd.append("dob", DOB);
      fd.append("address", details?.address);
      fd.append("phone", details?.phone);
      fd.append("body_weight", details?.body_weight);
      fd.append("height", details?.height);
      fd.append("gender", details?.gender);

      typeof details?.profile_image !== "string" &&
        details?.profile_image !== null &&
        fd.append(
          "profile_image",
          details?.profile_image,
          details?.profile_image?.name
        );
      const res = await standardPostApiJsonBased(
        "update_user_profile",
        {
          // "Content-Type": "multipart/form-data",
        },
        // {
        //   access_token:  localStorage.getItem('access_token'),
        //   first_name: details?.first_name,
        //   last_name: details?.last_name,
        //   dob: DOB,
        //   address: details?.address,
        //   specialization: details?.specialization,
        // },
        fd,
        true
      );
      if (res.data.code === 200) {
        toast.success(res.data.message);
        handleClose && handleClose();
        window.location.reload(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onValidate = () => {
    const {
      first_name,
      last_name,
      dob,
      address,
      phone,
      gender,
      body_weight,
      height,
    } = details;
    // console.log({details});
    let isError = false;
    let tempError = { ...error };
    const temp_dob = new Date(dob);
    const today = new Date();
    if (!dob) {
      isError = true;
      tempError = {
        ...tempError,
        dob: "Please select a Date of Birth.",
      };
    } else if (today.getFullYear() - temp_dob.getFullYear() < 16) {
      isError = true;
      tempError = {
        ...tempError,
        dob: "You must be at least 16 year old, in order to sign up.",
      };
    }

    if (!first_name?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        first_name: "The First Name field is required.",
      };
    }

    if (!last_name?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        last_name: "The Surname field is required.",
      };
    }

    // if (!address?.trim()?.length) {
    //   isError = true;
    //   tempError = {
    //     ...tempError,
    //     address: "Address field is required.",
    //   };
    // }
    // if (!phone?.trim()?.length) {
    //   isError = true;
    //   tempError = {
    //     ...tempError,
    //     phone: "Address field is required.",
    //   };
    // }
    if (!gender?.trim()?.length) {
      isError = true;
      tempError = {
        ...tempError,
        gender: "gender field is required.",
      };
    }
    // if (!body_weight?.trim()?.length) {
    //   isError = true;
    //   tempError = {
    //     ...tempError,
    //     body_weight: "Body Weight field is required.",
    //   };
    // }
    // if (!height?.trim()?.length) {
    //   isError = true;
    //   tempError = {
    //     ...tempError,
    //     height: "Height field is required.",
    //   };
    // }
    setError(tempError);
    return isError;
  };
  const onSubmit = () => {
    if (onValidate()) {
      return null;
    }
    UpdateUserProfile();
  };
  const inputFile = useRef(null);
  return (
    <div className="edit_profile_container">
      <div className="col-lg-12 col-md-12 col-sm-12 mb-4 align-items-center justify-content-center d-flex">
        <div
          className="position-relative"
          onClick={() => inputFile.current.click()}
        >
          {typeof details?.profile_image === "string" ? (
            <img
              src={
                details?.profile_image
                  ? ImgType(details?.profile_image)
                    ? details?.profile_image
                    : User_Placeholder
                  : User_Placeholder
              }
              alt="userPlaceholder"
              className="profile_picture"
            />
          ) : (
            <img
              src={URL.createObjectURL(details?.profile_image)}
              alt="userPlaceholder"
              className="profile_picture"
            />
          )}

          <div className="edit_pencil_container">
            <SvgIcon name="pencil" className={"pencilEdit"} />
          </div>
        </div>
      </div>

      <input
        ref={inputFile}
        onClick={(event) => (event.target.value = null)}
        type="file"
        className="d-none"
        accept="image/*"
        onChange={(event) =>
          setDetails({
            ...details,
            profile_image: event?.target?.files[0],
          })
        }
      />

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a first name"
        value={details?.first_name}
        name="first_name"
        error={error?.first_name}
        onChange={onHandleChange}
      />
      <CustomInput
        className={"mb-3"}
        placeholder="Enter a last name"
        value={details?.last_name}
        name="last_name"
        error={error?.last_name}
        onChange={onHandleChange}
      />

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a email"
        value={details?.email}
        name="email"
        error={error?.email}
        onChange={onHandleChange}
        readOnly
      />

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a phone number"
        value={details?.phone}
        name="phone"
        error={error?.phone}
        onChange={onHandleChange}
        // readOnly
      />

      <InputDateTimePicker
        className={"mb-3 d-flex"}
        placeholder="Enter a date of birth"
        value={details?.dob}
        name="dob"
        error={error?.dob}
        onChange={(value) => {
          setDetails({
            ...details,
            dob: value,
          });
          error?.dob &&
            setError({
              ...error,
              dob: "",
            });
        }}
      />
      {/* {console.log(details)} */}

      <select
        className="select_class"
        value={details?.gender}
        name="gender"
        onChange={onHandleChange}
      >
        <option value="">Select a Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <p
        style={{ fontSize: "12px", color: "var(--appRed)", padding: "0 20px" }}
      >
        {error?.gender}
      </p>

      <CustomInput
        className={"mb-3"}
        placeholder="Enter a address"
        value={details?.address}
        onChange={onHandleChange}
        name="address"
        error={error?.address}
      />
      <CustomInput
        className={"mb-3"}
        type="number"
        placeholder="Enter a Body Weight (in lbs)"
        value={details?.body_weight}
        onChange={onHandleChange}
        name="body_weight"
        error={error?.body_weight}
      />
      <CustomInput
        type="number"
        className={"mb-3"}
        placeholder="Enter a Height (in cms)"
        value={details?.height}
        onChange={onHandleChange}
        name="height"
        error={error?.height}
      />
      <div className="d-flex">
        <CustomButton onClick={onSubmit} flex={1} isLoading={isLoading}>
          Save
        </CustomButton>
      </div>
    </div>
  );
}

export default EditPlayerProfile;
