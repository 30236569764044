import React, { Component } from "react";
import { Modal } from "react-bootstrap";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[2]?.length === 11 ? match[2] : null;
}
export class viewVideoModalForCoach extends Component {
  render() {
    const { state } = this.props;
    const url = state?.viewVideoData?.video;
    const urlId = getId(url);
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="videopopupTitle">
                {/* Back to wall shoulder */}
                {state?.viewName}
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {state?.viewVideoData?.type_media ? (
                <div>
                  {state?.viewVideoData?.type_media === "video" ? (
                    <video
                      src={state?.viewVideoData?.video}
                      width="100%"
                      height="345"
                      autoPlay
                      controls
                    />
                  ) : (
                    <iframe
                      // onLoad={() => setLoading(false)}
                      // placeholder="abcccc"
                      width="100%"
                      title="video"
                      height={
                        state?.viewVideoData?.type_media === "pdf"
                          ? "500"
                          : "345"
                      }
                      // src={state?.viewVideoData}
                      src={
                        state?.viewVideoData?.type_media === "pdf"
                          ? state?.viewVideoData?.video
                          : `https://www.youtube.com/embed/${urlId}`
                      }
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              ) : (
                <div>
                  <p style={{ color: "white" }}>
                    {state?.viewVideoData?.description}
                  </p>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default viewVideoModalForCoach;
