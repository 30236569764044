// import React, { Component } from "react";
// import { Bar } from "react-chartjs-2";

// class AthleteSectionSecondGraph extends Component {
//   constructor(props) {
//     super();
//     this.state = {};
//   }

//   render() {
//     // console.log("....graph_Result...graph..", this.props.graph_Result);

//     return (
//       <div style={{ width: "100%", cursor: "pointer" }}>
//         <Bar
//           data={{
//             labels: this.props.graphLabels,

//             datasets: [
//               {
//                 // label: "",
//                 label: "",
//                 // backgroundColor: "rgba(30, 139, 195, 0.5)",
//                 backgroundColor: this.props.graph_colorbg,
//                 borderColor: this.props.graph_color,
//                 borderWidth: 2,
//                 data: this.props.graph_Result,
//               },
//             ],
//           }}
//           options={{
//             // onClick: (e, element) => alert("hi"),
//             title: {
//               display: true,
// text: `Team Testing Results for ${"\u0022"}${
//   this.props.exercise
// } ${"\u0022"}exercise`,
//               fontSize: 20,
//             },
//             legend: {
//               display: false,
//               position: "top",
//             },
//             scales: {
//               yAxes: [
//                 {
//                   ticks: {
//                     min: 0.0,
//                     // max: 1000,
//                     // stepSize: 5,
//                   },
//                 },
//               ],
//             },
//           }}
//         />
//       </div>
//     );
//   }
// }

// export default AthleteSectionSecondGraph;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function TestingGraph({
  graphLabels,
  graph_Result,
  graph_color,
  graph_colorbg,
  exercise,
}) {
  // console.log({
  //   graphLabels,
  //   graph_Result,
  //   graph_color,
  //   graph_colorbg,
  //   exercise,
  // });
  const data = {
    labels: graphLabels,
    datasets: [
      {
        label: "",
        data: graph_Result,
        backgroundColor: graph_colorbg,
        borderColor: graph_color,
        borderRadius: 15,
        barThickness: 5,
        maxBarThickness: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: `Team Testing Results for ${"\u0022"}${exercise} ${"\u0022"}exercise`,
        fontSize: 20,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },

      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", cursor: "pointer" }}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default TestingGraph;

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };
