import NoDataFound from "component/lottiLoader/LottiLoader";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export class ViewTeamPlayerWorkout extends Component {
  render() {
    const { viewWorrkoutList } = this.props;
    console.log("data", viewWorrkoutList);
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          animation={true}
          centered
          size="lg"
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body style={{ background: "#141223" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="view-workoutTitle">
                View Workout{" "}
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              {!viewWorrkoutList && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <i
                    className="fa fa-spinner fa-spin fa-3x fa-fw"
                    style={{
                      color: "var(--safetyOrange)",
                      fontSize: "40px",
                    }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!viewWorrkoutList ? (
                  <NoDataFound
                    height={250}
                    width={250}
                    text="No workout assigned yet."
                    screenCenter
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: viewWorrkoutList,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ViewTeamPlayerWorkout;
