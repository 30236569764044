import CustomButton from "component/customButton/CustomButton";
import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";

class LogoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassToggle: false,
      newPassToggle: false,
      confirmPassToggle: false,
    };
  }

  render() {
    const { show, onHide, logoutLoader } = this.props;
    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}

        backdropClassName="modal_backdrop"
        contentClassName="modal_container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // size="sm"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="coachprofileTitle">
                Logout
              </h5>
              {/* <button
                type="button"              className="modal_close"

                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>

            <p
              style={{
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                marginTop: "34px",
                marginLeft: "17px",
                color:"#FFF"
              }}
            >
              Are you sure you want to logout !!
            </p>
          </Modal.Body>
          <ModalFooter
          className="border-0"
          >
            <CustomButton
            type="outlined"
            onClick={onHide}

            >
            Cancel
            </CustomButton>
<CustomButton
isLoading={logoutLoader}
onClick={this.props.logoutApi}
style={{
  minWidth:90
}}
>
Ok
</CustomButton>

     
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default LogoutModal;
