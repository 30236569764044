import CoachHeader from "container/PublicLayout/CoachHeader";
import Footer from "container/PublicLayout/Footer";
import React, { Component } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import { standardPostApi } from "container/API/ApiWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDeleteModal from "../UserDeleteModal";
import ResetPasswordModal from "../ResetPasswordModal";
import NoDataFound from "component/lottiLoader/LottiLoader";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import CustomInput from "component/customInput/CustomInput";
import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import PlayerDetailModal from "../PlayerDetailModal";
import CustomModal from "component/customModal/CustomModal";

export class UsersSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      emailError: "",
      userSearchDetails: [],
      isLoading: false,
      userDeleteModal: false,
      userId: "",
      deleteLoader: false,
      resetPasswordModal: false,
      userIdForResetPassword: "",
      password: "",
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      confirmPasswordLoader: false,
      passwordVisible: false,
      passwordConfirmVisible: false,
      visibleLotty: false,
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    // console.log("this is ONCHANGE ", this.state);
  }

  showDeleteModal = async (data) => {
    // console.log("user data ", data);

    await this.setState({
      userId: data.id,
      userDeleteModal: !this.state.userDeleteModal,
    });
  };

  showModal = async (data) => {
    // console.log("userId", data);
    await this.setState({
      resetPasswordModal: !this.state.resetPasswordModal,
      userIdForResetPassword: data.id,
    });
  };

  hideModal = async () => {
    await this.setState({ resetPasswordModal: false });
  };

  hideDeleteModal = async () => {
    await this.setState({ userDeleteModal: false });
  };

  admin_user_search = async () => {
    const isValid = this.validationUserSearch();

    if (isValid) {
      this.setState({ isLoading: true });
      try {
        const res = await standardPostApi(
          "admin_user_search",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
          },
          true
        );

        if (res.data.code === 301) {
          await this.setState({
            userSearchDetails: [],
            visibleLotty: true,
          });
        }
        if (res.data.code === 200) {
          console.log("res", res.data.data);
          await this.setState({
            userSearchDetails: res?.data?.data,
            // visibleUserDetail: true,
            visibleLotty: false,
          });
          //   console.log("THIS IS RES OF admin_user_search =>", res.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  validationUserSearch = () => {
    let emailError = "";
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!this.state.email) {
      return true;
    } else if (!this.state.email.match(emailReg)) {
      emailError = toast.error("Please Enter Valid Emial Id", {
        autoClose: 2500,
      });
    }

    if (emailError) {
      this.setState({ emailError });
      return false;
    } else {
      return true;
    }
  };

  admin_delete_user = async () => {
    this.setState({ deleteLoader: true });
    try {
      const res = await standardPostApi(
        "admin_delete_user",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          user_id: this.state.userId,
        },
        true
      );
      if (res.data.code === 200) {
        toast.success(res.data.message, { autoClose: 2500 });
        await this.hideDeleteModal();
        this.admin_user_search();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ deleteLoader: false });
    }
  };

  admin_reset_password = async () => {
    const isValid = this.validationResetPassword();
    if (isValid) {
      this.setState({ confirmPasswordLoader: true });
      try {
        const res = await standardPostApi(
          "admin_reset_password",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            user_id: this.state.userIdForResetPassword,
            password: this.state.password,
          },
          true
        );
        if (res.data.code === 200) {
          toast.success(res.data.message, { autoClose: 2500 });
          await this.hideModal();
          this.setState({
            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
          });
          this.admin_user_search();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ confirmPasswordLoader: false });
      }
    }
  };

  validationResetPassword = () => {
    let passwordError = "";
    let confirmPasswordError = "";

    let passReg =
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;

    if (!this.state.password) {
      passwordError = "New Password Field is Required";
    } else if (!this.state.password.match(passReg)) {
      passwordError =
        "Password must have minimum 8 characters, at least one number, at least one upper case, at least one lower case, at least one special character";
    }

    if (!this.state.confirmPassword) {
      confirmPasswordError = "Confirm New Password Field is Required";
    } else if (!this.state.confirmPassword.match(this.state.password)) {
      confirmPasswordError =
        "The New Password and Confirm New Password do not match.";
    }

    if (passwordError || confirmPasswordError) {
      this.setState({ passwordError, confirmPasswordError });
      return false;
    } else {
      return true;
    }
  };
  toggleShowPassword = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };
  toggleShowConfirmPassword = () => {
    this.setState({
      passwordConfirmVisible: !this.state.passwordConfirmVisible,
    });
  };

  render() {
    const { firstName, lastName, email, userSearchDetails } = this.state;
    return (
      <div>
        <div className="loader_sec">
          <CoachHeader />
          <div className="dashboard-wrapper">
            <section className="myteams_wrapper">
              <div className="inner_teamsection_1">
                <CustomBreadcrumb
                  heading="Search Users"
                  className={"mb-4"}
                  navList={[
                    {
                      name: "Admin",
                      link: `/coachList`,
                    },
                    {
                      name: "Users",
                    },
                  ]}
                />
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <CustomInput
                      className={"mb-3"}
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => this.onChange(e)}
                    />
                    <CustomInput
                      className={"mb-3"}
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      value={email}
                      onChange={(e) => this.onChange(e)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <CustomInput
                      className={"mb-3"}
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => this.onChange(e)}
                    />
                    <div className="d-flex">
                      <CustomButton
                        className={"mb-3"}
                        onClick={() => this.admin_user_search()}
                        flex={1}
                        style={{ fontWeight: 500 }}
                      >
                        Search
                      </CustomButton>
                    </div>
                  </div>
                </div>

                {this.state.visibleLotty && (
                  <>
                    {!this.state.isLoading && userSearchDetails?.length === 0 && (
                      <div style={{ margin: "20px " }}>
                        <NoDataFound
                          height={250}
                          width={250}
                          text="No user avalable."
                        />
                      </div>
                    )}
                  </>
                )}

                {this.state.isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      className="fa fa-spinner fa-spin fa-3x fa-fw"
                      style={{
                        color: "var(--safetyOrange)",
                        fontSize: "50px",
                        marginBottom: "50px",
                      }}
                    />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table  ">
                      <thead>
                        {userSearchDetails?.length !== 0 && (
                          <tr className="react_Testing_Table_1">
                            <th>First Name</th>
                            <th>Email Address </th>

                            <th></th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {userSearchDetails?.length !== 0 &&
                          userSearchDetails?.users?.map((item) => {
                            return (
                              <tr
                                key={item?.id}
                                className="react_Testing_Table_1"
                              >
                                <td className="d-flex align-items-center">
                                  {item?.first_name} {item?.last_name}
                                  {item.role === "Athlete" ? (
                                    <SvgIcon
                                      name={"player_icon_p"}
                                      className={"ml-2"}
                                      style={{ width: "20px", height: "20px" }}
                                    />
                                  ) : (
                                    <SvgIcon
                                      name={"coach_icon_c"}
                                      className={"ml-2"}
                                      style={{ width: "20px", height: "20px" }}
                                    />
                                  )}
                                </td>

                                <td>{item?.email}</td>

                                <td className="d-flex align-items-center justify-content-end">
                                  {item?.role === "Athlete" && (
                                    <CustomModal
                                      headingTitle={"Player Detail"}
                                      actionElement={
                                        <button
                                          className="admin_button"
                                          style={{ color: "#EFAD4D" }}
                                        >
                                          View Detail
                                        </button>
                                      }
                                    >
                                      <PlayerDetailModal item={item} />
                                    </CustomModal>
                                  )}
                                  <button
                                    className="admin_button"
                                    onClick={() => this.showDeleteModal(item)}
                                  >
                                    <SvgIcon
                                      name={"trash"}
                                      style={{ width: "20px", height: "12px" }}
                                    />
                                  </button>
                                  <button
                                    className="admin_button"
                                    onClick={() => this.showModal(item)}
                                  >
                                    Reset Password
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </div>
        <UserDeleteModal
          show={this.state.userDeleteModal}
          onHide={this.hideDeleteModal}
          admin_delete_user={() => this.admin_delete_user()}
          deleteLoader={this.state.deleteLoader}
        />

        <ResetPasswordModal
          show={this.state.resetPasswordModal}
          onHide={this.hideModal}
          admin_reset_password={() => this.admin_reset_password()}
          value={(this.state.password, this.state.confirmPassword)}
          onChange={(e) => this.onChange(e)}
          passwordError={this.state.passwordError}
          confirmPasswordError={this.state.confirmPasswordError}
          confirmPasswordLoader={this.state.confirmPasswordLoader}
          toggleShowPassword={this.toggleShowPassword}
          passwordVisible={this.state.passwordVisible}
          passwordConfirmVisible={this.state.passwordConfirmVisible}
          toggleShowConfirmPassword={this.toggleShowConfirmPassword}
        />
      </div>
    );
  }
}

export default UsersSection;
