import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import ShareIcon from "@material-ui/icons/Share";
import { Button, Divider, IconButton } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import CreateCoachAwardModal from "./CreateCoachAwardModal";

import { Fragment } from "react";
import NoDataFound from "component/lottiLoader/LottiLoader";
import UpdateCoachAwardModal from "./UpdateCoachAwardModal";
import AssignAwardModal from "./AssignAwardModal";
import LoaderWrapper from "container/Loader/LoaderWrapper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "var(--safetyOrange)",
    color: "#fff",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CreateCoachAwardTable({
  workoutDetails,
  listAward,
  toggleModal,
  parentState,
  toggleAssignTo,
  onChange,
  handelCreateAward,
  toggleUpdateAwardModal,
  updateChange,
  toggleUpdateAssignTo,
  handelUpdateAwards,
  toggleAssignModal,
  teamInfo,
  handlePlayerRadio,
  handelCoachRadio,
  closeAssignModal,
  handelAsssignAwards,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <div>
      {parentState.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <LoaderWrapper />
        </div>
      ) : (
        <>
          {!parentState.isLoading && listAward.length === 0 ? (
            <NoDataFound
              height={250}
              width={250}
              text="No award available yet."
            />
          ) : (
            <table className="table table-condensed coachList-table">
              <thead>
                <tr
                  style={{ fontSize: "16px" }}
                  className="react_Testing_Table"
                >
                  <th style={{ padding: "0.78rem 0.76rem" }}> Award Name</th>
                  <th style={{ padding: "0.78rem 0.76rem" }}>
                    Awarded time(s)
                  </th>
                  <th style={{ padding: "0.78rem 0.76rem" }}></th>
                </tr>
              </thead>
              <tbody>
                {listAward.length !== 0 &&
                  listAward.map((item) => {
                    return (
                      <tr className="react_Testing_Table">
                        <td style={{ padding: "0.78rem 0.76rem" }}>
                          {item?.name}
                        </td>
                        <td
                          style={{
                            padding: "0.78rem 0.76rem",
                            paddingLeft: "5rem",
                          }}
                        >
                          {item.award_assigned_count}
                        </td>

                        <td
                          style={{
                            padding: "0.78rem 0.76rem",
                            width: "314px",
                          }}
                        >
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <div
                              onClick={() => toggleUpdateAwardModal(item)}
                              style={{
                                color: "#EFAD4D",
                                cursor: "pointer",
                              }}
                            >
                              Update
                            </div>

                            <UpdateCoachAwardModal
                              onHide={toggleUpdateAwardModal}
                              show={
                                parentState.updateModalId === item.id &&
                                parentState.updateModal
                              }
                              parentState={parentState}
                              toggleUpdateAssignTo={toggleUpdateAssignTo}
                              updateChange={updateChange}
                              handelUpdateAwards={handelUpdateAwards}
                            />
                            <div
                              onClick={() => toggleAssignModal(item)}
                              style={{
                                marginLeft: "40px",
                                color: "#F75F03",
                                cursor: "pointer",
                              }}
                            >
                              Assign
                            </div>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </>
      )}
      <CreateCoachAwardModal
        onHide={toggleModal}
        show={parentState.viewModal}
        assignTo={parentState.assignTo}
        toggleAssignTo={toggleAssignTo}
        onChange={(e) => onChange(e)}
        awardName={parentState.awardName}
        parentState={parentState}
        handelCreateAward={handelCreateAward}
        updateChange={updateChange}
      />

      <AssignAwardModal
        onHide={closeAssignModal}
        show={parentState.assignModal}
        parentState={parentState}
        teamInfo={teamInfo}
        handlePlayerRadio={handlePlayerRadio}
        handelCoachRadio={handelCoachRadio}
        handelAsssignAwards={handelAsssignAwards}
        isLoading={isLoading}
      />
    </div>
  );
}
