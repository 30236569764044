import CustomInput from "component/customInput/CustomInput";
import React, { Component } from "react";

class UpdateSetsRest extends Component {
  render() {
    const { state, updateSets, updateRest } = this.props;

    return (
      <div className="row mb-3">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <CustomInput
            placeholder="Sets"
            name="updateSets"
            value={updateSets}
            onChange={(e) => this.props.onChange(e)}
            error={state.updateSetsError}
          />
          {/* <label htmlFor="">Sets</label>
            <input
              type="text"
              className="form-control"
              name="updateSets"
              defaultValue={
                (updateSets, state.updateWorkoutGroupDetails?.group_sets)
              }
              onChange={(e) => this.props.onChange(e)}
              onKeyPress={this.props.onKeyPress}
            />
            <p className="react_validation">{state.updateSetsError}</p> */}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <CustomInput
            placeholder="Rest"
            name="updateRest"
            value={updateRest}
            onChange={(e) => this.props.onChange(e)}
            error={state.updateRestError}
          />
          {/* <label htmlFor="">Rest</label>
            <input
              type="text"
              className="form-control"
              name="updateRest"
              defaultValue={
                (updateRest, state.updateWorkoutGroupDetails?.group_rest)
              }
              onChange={(e) => this.props.onChange(e)}
              onKeyPress={this.props.onKeyPress}
            />
            <p className="react_validation">{state.updateRestError}</p> */}
        </div>
      </div>
    );
  }
}
export default UpdateSetsRest;
