import React, { Component } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
// import firstVideo from "Custom/Standing.mp4";
import no_video from "Custom/images/no_video.jpg";
import { Tooltip } from "@material-ui/core";
import CustomInput from "component/customInput/CustomInput";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import CancelIcon from "@material-ui/icons/Cancel";

export class AddResultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      show,
      onHide,
      state,
      onChange,
      onAddFileChange,
      addTestResultTest,
      isLoading,
      removeUploadVideo,
    } = this.props;

    const exercise = state.exerciseDetail;
    const FileUpload = state.uploadAddVideo;

    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="videopopupTitle">
                Add New Testing Result
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <CustomInput type="text" value={exercise?.name} readOnly={true} />

              <div className="mt-3 d-flex justify-content-center mb-3">
                <video width="100%" height="250" controls>
                  <source src={exercise?.video_ref} type="video/mp4" />
                </video>
              </div>

              <div
                style={{
                  backgroundColor: "var(--jaguar)",
                  padding: "20px",
                  borderRadius: "15px",
                }}
              >
                {/* {exercise?.comment_allowed !== "0" && ( */}
                <div className="form-group">
                  <CustomInput
                    placeholder="Comment"
                    name="addComment"
                    value={state.addComment}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                {/* )} */}
                <div>
                  <div style={{ marginBottom: "2%" }}></div>
                  <input
                    type="file"
                    ref={(ref) => (this.fileInput = ref)}
                    onChange={(e) => onAddFileChange(e)}
                    accept="video/mp4"
                    style={{ display: "none" }}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <button
                    className="screening_protocol_comment_btn"
                    onClick={() => this.fileInput.click()}
                    style={{ cursor: "pointer" }}
                  >
                    <CloudUploadOutlinedIcon
                      style={{
                        color: "var(--safetyOrange)",
                        marginRight: "7px",
                      }}
                    />
                    Upload Athlete Video
                  </button>
                </div>
                {FileUpload && (
                  <div className="mt-3 d-flex justify-content-center mb-3">
                    <video width="100%" height="250" controls>
                      <source
                        src={URL.createObjectURL(FileUpload)}
                        type="video/mp4"
                      />
                    </video>
                    <div
                      onClick={() => removeUploadVideo()}
                      style={{
                        position: "absolute",
                        right: "18px",
                        bottom: "304px",
                      }}
                    >
                      <CancelIcon
                        style={{ color: "white", fontSize: "30px" }}
                      />
                    </div>
                  </div>
                )}

                <div className="d-flex mt-3">
                  <CustomButton
                    onClick={() => addTestResultTest()}
                    isLoading={isLoading}
                    disabled={isLoading}
                    flex={1}
                  >
                    Add
                  </CustomButton>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddResultModal;
