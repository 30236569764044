import ProgramViewCard from "component/card/programViewCard/ProgramViewCard";
import { standardPostApi } from "container/API/ApiWrapper";
import ViewAthleteWorkoutModal from "container/Events/athleteEvent/modal/ViewAthleteWorkoutModal";
import React from "react";
import { Link } from "react-router-dom";
import CoachHeader from "../../container/PublicLayout/CoachHeader";
import Footer from "../../container/PublicLayout/Footer";

class TranningPlanDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      List_Days: this.props.location.state.days,
      viewWorkoutLoader: false,
      workoutDetails: [],
      viewWorkoutModal: false,
      dayId: "",
    };
  }

  toggleViewModal = async (item) => {
    this.setState({
      viewWorkoutModal: !this.state.viewWorkoutModal,
    });
    this.assignDayWorkoutCalander(item?.id);
  };

  assignDayWorkoutCalander = async (dayId) => {
    const Atp = this.props.location.state.week;

    this.setState({ viewWorkoutLoader: true });
    try {
      const res = await standardPostApi(
        "assigned_day_workout_calender",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: Atp?.annual_training_program_id,
          annual_training_program_week_id: Atp?.id,
          annual_training_program_day_id: dayId,
        },
        true
      );

      if (res.data.code === 200) {
        this.setState({ workoutDetails: res?.data?.data });
      }
    } catch (error) {
      console.error("assign DayWise Calender Error", error);
    } finally {
      this.setState({ viewWorkoutLoader: false });
    }
  };

  closeVideModal = () => {
    this.setState({ viewWorkoutModal: false });
  };

  render() {
    console.log("dayyyyyyyy", this.state.dayId);
    return (
      <React.Fragment>
        <div className="row mt-5">
          {this.props.location.state &&
            this.props.location.state.days.map((item) => {
              return (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 mt-4"
                  key={item?.id + ""}
                >
                  <ProgramViewCard
                    item={item}
                    text={item.day_number}
                    buttonText={"View Workout"}
                    onClick={() => {
                      this.toggleViewModal(item);
                      this.setState({ dayId: item?.id });
                    }}
                  />
                </div>
              );
            })}
        </div>

        <ViewAthleteWorkoutModal
          show={this.state.viewWorkoutModal}
          onHide={this.closeVideModal}
          workoutDetails={this.state?.workoutDetails}
          viewWorkoutLoader={this.state.viewWorkoutLoader}
          coachViewWorkout={true}
          atpID={this.props.location.state.week?.annual_training_program_id}
          atpWeekID={this.props.location.state.week?.id}
          atpDayID={this.state.dayId}
        />
      </React.Fragment>
    );
  }
}

export default TranningPlanDay;
