import CustomInput from "component/customInput/CustomInput";
import React from "react";

function SetsResets(props) {
  const { setsGroup, restGroup } = props.value;
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
        <CustomInput
          name="setsGroup"
          value={(setsGroup, props.setsGroup)}
          onChange={props.onChange}
          error={props.setsGroupError}
          placeholder="Sets"
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
        <CustomInput
          name="restGroup"
          value={(restGroup, props.cleanRestGroup)}
          onChange={props.onChange}
          placeholder="Rest"
          error={props.restGroupError}
        />
      </div>
    </>
  );
}

export default SetsResets;
