import CoachHeader from "container/PublicLayout/CoachHeader";
import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calander from "Custom/images/celender.jpg";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Footer from "container/PublicLayout/Footer";
import CreateCoachLibrary from "./CreateCoachLIbrary";
import { ContactsOutlined } from "@material-ui/icons";
import {
  standardPostApi,
  standardPostApiJsonBased,
} from "container/API/ApiWrapper";
import { errorToast, successToast } from "utils/toastMessage";
import ViewActivityModal from "./ViewActivityModal";
import NoDataFound from "component/lottiLoader/LottiLoader";
import Buttons from "container/Coach After Login/Buttons";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import CustomButton from "component/customButton/CustomButton";
import ActionButton from "component/actionButton/ActionButton";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PlayerImg from "../../../Custom/images/user_placeholder.png";
import LoaderWrapper from "container/Loader/LoaderWrapper";

export class CoachLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createModal: false,
      activityName: "",
      videoName: "",
      // teamName: "",
      description: "",
      tips: "",
      inputList: [],
      tipsError: "",
      createLibraryLoader: false,
      coachLibraryList: [],
      pickerLoader: false,
      coachLibraryPickerId: "",
      selectPickerObject: {},
      assignToTeamLoader: false,
      isAssignToAllTeam: false,
      playerList: [],
      activityDetails: {},
      viewActivityModal: false,
      reseltSetLoader: false,
      sentPlayerLoader: false,
      activePlayerId: "",
      selectPicker: "active",
      enableDiableLoader: false,
      cl_exercise_media: null,
    };
  }

  componentDidMount() {
    this.fetchCoachLibrary();
  }

  toggleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      activityName: "",
      description: "",
      videoName: "",
      tips: "",
      inputList: [],
      tipsError: "",
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log("state", this.state);
  };
  onChangeExerciseMedia = (e) => {
    this.setState({ cl_exercise_media: e.target.files[0] });
  };
  onSelectChange = (data) => {
    this.setState({ selectPicker: data });
    // this.fetchAssignaedCoachLibraryForCoach(this.state.coachLibraryPickerId);
    this.fetchCoachLibrary();
  };
  addNewTips = async () => {
    const { inputList, tips } = this.state;
    const isValid = this.addNewTipsValidation();

    if (isValid) {
      inputList.push({
        tip: tips,
        id: Math.random(),
      });

      await this.setState({
        inputList,
        tips: "",
        tipsError: "",
      });
    }
  };

  addNewTipsValidation = () => {
    if (!this.state.tips) {
      this.setState({
        tipsError: "Tips field is required.",
      });
      return false;
    } else {
      return true;
    }
  };

  deleteEvent = (index) => {
    const copyPostArray = Object.assign([], this.state.inputList);
    copyPostArray.splice(index, 1);
    this.setState({
      inputList: copyPostArray,
    });
  };

  hendelAddCoachLibrary = async () => {
    let tipsList = [];

    this.state.inputList.map((item) => {
      tipsList.push({ tip: item.tip });
    });

    // console.log("tipsList", tipsList);
    let fd = new FormData();
    fd.append("access_token", localStorage.getItem("access_token"));
    fd.append("activity_name", this.state.activityName);
    fd.append("description", this.state.description);
    fd.append("team_id", this.props.match.params.id);
    fd.append("tips", JSON.stringify(tipsList));
    this?.state?.cl_exercise_media !== null &&
      fd.append(
        "exercise_media",
        this?.state?.cl_exercise_media,
        this?.state?.cl_exercise_media?.name
      );

    // let data = {
    //   access_token: await localStorage.getItem("access_token"),
    //   activity_name: this.state.activityName,
    //   description: this.state.description,
    //   youtube_link: this.state.videoName,
    //   team_id: this.props.match.params.id,
    //   tips: tipsList,
    // };

    const isValid = this.createCoachLibraryValidation();

    if (isValid) {
      this.setState({ createLibraryLoader: true });
      try {
        const res = await standardPostApiJsonBased(
          "v2/add_coach_library",
          undefined,
          fd,
          true
        );
        if (res.data.code === 200) {
          // console.log('Response of Add coach library', res.data.data);
          successToast(res.data.message);
          // this.setState({
          //   activityName: "",
          //   description: "",
          //   videoName: "",
          //   tips: "",
          //   inputList: [],
          // });
          this.fetchCoachLibrary();
          this.toggleCreateModal();
        }
      } catch (error) {
        console.error("error of handel Add Coach library", error);
      } finally {
        this.setState({ createLibraryLoader: false });
      }
    }
  };

  createCoachLibraryValidation = () => {
    let regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    if (!this.state.activityName) {
      errorToast("Activity name field is required.");
      return false;
    } else if (!this.state.description) {
      errorToast("Description field is required.");
      return false;
    } else if (this.state.inputList.length === 0) {
      errorToast("Plaese add atleast one Tip");
    } else {
      return true;
    }
  };

  fetchCoachLibrary = async () => {
    this.setState({ pickerLoader: true });
    try {
      const res = await standardPostApi(
        "get_coach_library",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team_id: this.props.match.params.id,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log('response of coach library', res.data.data.coach_library);

        const temp = res.data.data.coach_library.filter(
          (x) => x?.is_enabled === this.state.selectPicker
        );

        // console.log('temp', temp);

        // console.log("temp", temp.length);

        // temp.length === 0 ? alert("0000") : alert("111");

        if (temp.length === 0) {
          this.setState({
            coachLibraryList: [],
            coachLibraryPickerId: "",
            selectPickerObject: {},
            isAssignToAllTeam: false,
            playerList: [],
            activityDetails: {},
          });
        } else {
          this.setState({
            coachLibraryList: temp,
            // coachLibraryPickerId: res.data?.data?.coach_library[0].id,
            // selectPickerObject: res.data?.data?.coach_library[0],
            coachLibraryPickerId: temp[0].id,
            selectPickerObject: temp[0],
          });
        }
        temp.length !== 0 &&
          this.fetchAssignaedCoachLibraryForCoach(
            this.state.coachLibraryPickerId
          );
      }
    } catch (error) {
      console.error("error of coach libraey", error);
    } finally {
      this.setState({ pickerLoader: false });
    }
  };

  onHandel = (e) => {
    let selectPickerObject = {};

    this.state.coachLibraryList.map((item) => {
      if (e.target.value == item.id) {
        selectPickerObject = item;
      }
    });

    this.setState({
      coachLibraryPickerId: e.target.value,
      selectPickerObject: selectPickerObject,
    });

    this.fetchAssignaedCoachLibraryForCoach(e.target.value);
  };

  handelAssignCoachLibraryToTeam = async (item, roll) => {
    roll === "player" && this.setState({ activePlayerId: item.id });

    const data =
      roll === "player"
        ? {
            access_token: await localStorage.getItem("access_token"),
            team_id: this.props.match.params.id,
            activity_id: this.state.coachLibraryPickerId,
            player_id: item.user_id,
          }
        : {
            access_token: await localStorage.getItem("access_token"),
            team_id: this.props.match.params.id,
            activity_id: this.state.coachLibraryPickerId,
          };

    roll === "player"
      ? this.setState({ sentPlayerLoader: true })
      : this.setState({ assignToTeamLoader: true });

    try {
      const res = await standardPostApi(
        "assign_coach_library",
        undefined,
        data,
        true
      );
      if (res.data.code === 200) {
        // console.log('Response of assign to coach', res.data.data);
        successToast(res.data.message);
        this.fetchAssignaedCoachLibraryForCoach(
          this.state.coachLibraryPickerId
        );
      }
    } catch (error) {
      console.error("error of assign coach library", error);
    } finally {
      roll === "player"
        ? this.setState({ sentPlayerLoader: false })
        : this.setState({ assignToTeamLoader: false });
    }
  };

  fetchAssignaedCoachLibraryForCoach = async (activityId) => {
    this.setState({ reseltSetLoader: true });
    try {
      const res = await standardPostApi(
        "get_assigned_coach_library_for_coach",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team_id: this.props?.match?.params?.id,
          activity_id: activityId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log('response of result set', res.data.data);
        this.setState({
          isAssignToAllTeam: res.data?.data?.is_assigned_to_all_team,
          playerList: res?.data?.data?.players,
          activityDetails: res?.data?.data?.coach_library_activity,
        });
      }
    } catch (error) {
      console.log("error of fetch result set", error);
    } finally {
      this.setState({ reseltSetLoader: false });
    }
  };

  toggleViewActivityModal = () => {
    this.setState({ viewActivityModal: !this.state.viewActivityModal });
  };

  handelEnableDiableCoachLibrary = async () => {
    this.setState({ enableDiableLoader: true });
    try {
      const res = await standardPostApi(
        "enable_disable_coach_library",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          coach_library_id: this.state.coachLibraryPickerId,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log('Response of enable disable Coach library', res.data);
        successToast(res.data.message);
        // this.setState({ selectPicker: "active" });
        this.fetchCoachLibrary();

        // await window.location.reload();
      }
    } catch (error) {
      console.error("error od enable disable coach library", error);
    } finally {
      this.setState({ enableDiableLoader: false });
    }
  };

  render() {
    const { coachLibraryList, selectPickerObject, selectPicker } = this.state;
    const coachRoles = localStorage.getItem("access_role");
    const playerList = this.state.playerList.sort((a, b) => {
      const nameA = a.user_first_name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.user_first_name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return (
      <div className="loader_sec">
        <CoachHeader />
        <div className="dashboard-wrapper">
          <section className="myteams_wrapper">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 side-buttons left-side-section">
                <Buttons
                  coachRoles={coachRoles}
                  props={this.props}
                  isActive="coachLibrary"
                />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 right-side-section">
                <div className="d-md-flex align-items-center ">
                  <CustomBreadcrumb
                    heading={"My Players"}
                    className={"mb-4"}
                    navList={[
                      {
                        name: "My Team",
                        link: `/myteamwrapper`,
                      },
                      {
                        name: this.props.match.params.teamname,
                        link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                      },
                      {
                        name: "Coach Library",
                      },
                    ]}
                  />
                </div>

                {this.state.pickerLoader ? (
                  <LoaderWrapper />
                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                      <CustomButton
                        style={{
                          marginRight: "12px",
                          borderRadius: 0,
                          backgroundColor:
                            selectPicker === "active"
                              ? "var(--safetyOrange)"
                              : "var(--valhalla)",
                          color: "white",
                        }}
                        onClick={() => this.onSelectChange("active")}
                      >
                        Active
                      </CustomButton>
                      <CustomButton
                        style={{
                          marginLeft: "12px",
                          borderRadius: 0,
                          backgroundColor:
                            selectPicker === "inactive"
                              ? "var(--safetyOrange)"
                              : "var(--valhalla)",
                          color: "white",
                        }}
                        onClick={() => this.onSelectChange("inactive")}
                      >
                        Inactive
                      </CustomButton>
                    </div>
                    <div className="row my-5">
                      <div
                        className={
                          !this.state.reseltSetLoader &&
                          coachLibraryList.length !== 0
                            ? "col-lg-10 col-md-10 col-sm-10"
                            : "col-lg-12 col-md-12 col-sm-12"
                        }
                      >
                        <select
                          className="select_class"
                          name="coachLibraryPickerId"
                          value={this.state.coachLibraryPickerId}
                          onChange={(e) => this.onHandel(e)}
                        >
                          {!this.state.pickerLoader &&
                            this.state.coachLibraryList.length === 0 && (
                              <option value="">
                                Sorry! no coach libraay available
                              </option>
                            )}

                          {coachLibraryList.length !== 0 &&
                            coachLibraryList
                              .filter(
                                (x) => x.is_enabled === this.state.selectPicker
                              )
                              .map((item) => {
                                return (
                                  <option
                                    className="dropdown-item dropdown-menu react_select_menu"
                                    key={item.id}
                                    value={item.id}
                                  >
                                    {item?.activity_name}
                                  </option>
                                );
                              })}
                        </select>

                        <span style={{ position: "relative" }}>
                          <span
                            style={{
                              marginTop: "20px",
                              position: "absolute",
                              right: "162px",
                              top: "-22px",
                              color:
                                selectPickerObject?.is_enabled === "active"
                                  ? "white"
                                  : "rgb(239, 173, 77)",
                            }}
                          >
                            Active
                          </span>
                          <FormGroup>
                            <FormControlLabel
                              className="switch-button"
                              control={
                                <Switch
                                  size="small"
                                  checked={
                                    selectPickerObject?.is_enabled === "active"
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    this.handelEnableDiableCoachLibrary()
                                  }
                                />
                              }
                            />
                          </FormGroup>
                          <span
                            style={{
                              marginTop: "20px",
                              position: "absolute",
                              right: "50px",
                              top: "-22px",
                              color:
                                selectPickerObject?.is_enabled === "active"
                                  ? "rgb(239, 173, 77)"
                                  : "white",
                            }}
                          >
                            Inactive
                          </span>
                        </span>
                      </div>
                      <div className="d-flex col-lg-2 col-md-2 col-sm-2">
                        {coachRoles === "Assistant Coach" ? null : (
                          <>
                            {!this.state.reseltSetLoader &&
                              coachLibraryList.length !== 0 && (
                                <>
                                  {this.state.isAssignToAllTeam ? (
                                    <CustomButton
                                      flex={1}
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Assign To Team
                                    </CustomButton>
                                  ) : (
                                    <CustomButton
                                      flex={1}
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                      onClick={() =>
                                        this.handelAssignCoachLibraryToTeam()
                                      }
                                      disabled={this.state.assignToTeamLoader}
                                      isLoading={this.state.assignToTeamLoader}
                                    >
                                      Assign To Team
                                    </CustomButton>
                                  )}
                                </>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      {coachRoles === "S&C Coach" ? null : coachRoles ===
                        "Assistant Coach" ? null : (
                        <ActionButton onClick={this.toggleCreateModal} />
                      )}
                    </div>
                  </>
                )}
                {!this.state.pickerLoader &&
                  !this.state.reseltSetLoader &&
                  playerList.length === 0 && (
                    <NoDataFound
                      height={250}
                      width={250}
                      text="No coach library available yet"
                    />
                  )}

                {this.state.reseltSetLoader ? (
                  <LoaderWrapper />
                ) : (
                  <div className="table-responsive mt-5 table_react_cursor">
                    <table className="table ">
                      <thead>
                        <tr className="react_Testing_Table">
                          {playerList.length !== 0 && (
                            <>
                              <th>Player Name</th>
                              <th style={{ width: "320px" }}>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {this.state?.activityDetails?.activity_name}
                                </span>
                              </th>
                            </>
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {playerList.length !== 0 &&
                          playerList.map((item) => {
                            return (
                              <tr key={item.id} className="react_Testing_Table">
                                <td>
                                  <img
                                    src={PlayerImg}
                                    alt="No Img..."
                                    className="table-usr-img"
                                  />
                                  {item?.user_first_name} {item?.user_last_name}{" "}
                                  ({item?.email})
                                </td>

                                <td>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <button
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                        color: "rgb(239, 173, 77)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.toggleViewActivityModal()
                                      }
                                    >
                                      View
                                    </button>
                                    {coachRoles !== "Assistant Coach" && (
                                      <Fragment>
                                        {item.is_coach_lib_assigned ===
                                        false ? (
                                          <button
                                            style={
                                              this.state.sentPlayerLoader &&
                                              item.id ===
                                                this.state.activePlayerId
                                                ? {
                                                    background: "transparent",
                                                    border: "none",
                                                    color: "#fff",
                                                    cursor: "wait",
                                                    opacity: "0.7",
                                                  }
                                                : {
                                                    background: "transparent",
                                                    border: "none",
                                                    color:
                                                      "var(--safetyOrange)",
                                                    cursor: "pointer",

                                                    // color: "#fff",
                                                  }
                                            }
                                            onClick={() =>
                                              this.handelAssignCoachLibraryToTeam(
                                                item,
                                                "player"
                                              )
                                            }
                                            disabled={
                                              this.state.sentPlayerLoader &&
                                              item.id ===
                                                this.state.activePlayerId
                                            }
                                          >
                                            Assign{" "}
                                            {this.state.sentPlayerLoader &&
                                              item.id ===
                                                this.state.activePlayerId && (
                                                <i
                                                  className="fa fa-spinner fa-spin fa-3x fa-fw"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "15px",
                                                    marginLeft: "5px",
                                                  }}
                                                />
                                              )}
                                          </button>
                                        ) : (
                                          <button
                                            style={{
                                              background: "transparent",
                                              border: "none",
                                              color: "#fff",
                                              opacity: "0.7",
                                              cursor: "not-allowed",
                                            }}
                                          >
                                            Assigned
                                          </button>
                                        )}
                                      </Fragment>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />

        <CreateCoachLibrary
          onHide={this.toggleCreateModal}
          show={this.state.createModal}
          parentState={this.state}
          onChange={this.onChange}
          onChangeExerciseMedia={this.onChangeExerciseMedia}
          addNewTips={this.addNewTips}
          hendelAddCoachLibrary={this.hendelAddCoachLibrary}
          currentTeamName={this.props.match.params.teamname}
          deleteEvent={this.deleteEvent}
        />

        <ViewActivityModal
          onHide={this.toggleViewActivityModal}
          show={this.state.viewActivityModal}
          activityDetails={this.state.activityDetails}
          currentTeamName={this.props.match.params.teamname}
        />
      </div>
    );
  }
}

export default CoachLibrary;
