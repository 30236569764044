import CustomButton from "component/customButton/CustomButton";
import CustomInput from "component/customInput/CustomInput";
import React from "react";
import { Modal } from "react-bootstrap";

function AddNewTeamModal(props) {
  const { teamName, sportList } = props.value;
  return (
    <Modal
      backdropClassName="modal_backdrop"
      show={props.showModal}
      onHide={props.onHidePress}
      centered
      contentClassName="modal_container"
    >
      <Modal.Body>
        <div className="modal-header">
          <h5 className="modal-title" id="teamcreate">
            Add New Team
          </h5>
          <button
            type="button"
            className="modal_close"
            data-dismiss="modal"
            aria-label="Close"
            // onClick={(e) => {
            //   this.hideModal();
            // }}
            onClick={props.onHidePress}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <Form action="#" method=""> */}
          <div className="form-group">
            <CustomInput
              type="text"
              //  className="form-control"
              placeholder="Team Name"
              name="teamName"
              value={teamName}
              onChange={props.onChange}
              error={props.teamNameError}
            />

            {/* <p className="react_validation">{props.teamNameError}</p> */}
          </div>
          <div className="form-group">
            <select
              name="sportList"
              value={sportList}
              onChange={props.onChange}
              className="select_class"
              placeholder="Athlete"
            >
              <option value=""> Select Sport</option>
              {props.sports &&
                props.sports.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
            <p className="react_validation">{props.selectSportError}</p>
          </div>
          <div className="d-flex">
            <CustomButton
              flex={1}
              onClick={props.addNewTeam}
              isLoading={props.isLoading}
              disabled={props.isLoading}
            >
              Save
            </CustomButton>
          </div>
          {/* <button
              type="button"
              className="Model_btn "
              data-dismiss="modal"
              onClick={props.addNewTeam}
            >
              Save
            </button> */}
          {/* </Form> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddNewTeamModal;
