import React, { useState } from "react";
import SvgIcon from "component/SvgIcon";
import { Modal } from "react-bootstrap";
import CustomButton from "component/customButton/CustomButton";

function DeleteModal({
  text,
  actionElement,
  show,
  onHide,
  onSubmit,
  isLoading,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div>{actionElement}</div>
      <Modal
        show={show ?? isOpen}
        onHide={isLoading ? () => null : onHide ?? handleClose}
        animation={true}
        backdropClassName="modal_backdrop"
        contentClassName="modal_container"
        centered
      >
        <Modal.Body>
          <div className="modal-header" style={{ border: "none" }}>
            {/* <button
            type="button"
            className="modal_close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide??handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
          </div>
          <div className="delete_modal_react d-flex align-items-center justify-content-center flex-column">
            <SvgIcon
              name="modal-delete"
              style={{
                height: 120,
                width: 120,
              }}
            />
            <span
              className="mt-3 mb-3"
              style={{ fontSize: 20, fontWeight: "bold", color: "white" }}
            >
              Are you sure?
            </span>
            <span style={{ color: "white", textAlign: "center" }}>
              {text ??
                "Are you sure you want to delete, this change cannot be undone?"}
            </span>
            <div className="d-flex mt-4">
              <CustomButton
                className={"mr-2"}
                data-dismiss="modal"
                onClick={onHide ?? handleClose}
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid white",
                }}
                disabled={isLoading}
              >
                Cancel
              </CustomButton>
              <CustomButton
                className={"ml-2"}
                data-dismiss="modal"
                onClick={onSubmit}
                style={{ backgroundColor: "#E81E16" }}
                isLoading={isLoading}
              >
                Delete
              </CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteModal;
