import { Tooltip } from "@material-ui/core";
import { standardPostApi } from "container/API/ApiWrapper";
import CoachHeader from "container/PublicLayout/CoachHeader";
import Footer from "container/PublicLayout/Footer";
import React, { Component } from "react";
import { errorToast, successToast } from "utils/toastMessage";
import CreateCoachAwairdsTable from "./CreateCoachAwardTable";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "component/customBreadcrumb/CustomBreadcrumb";
import Buttons from "container/Coach After Login/Buttons";
import CustomButton from "component/customButton/CustomButton";
import CustomModal from "component/customModal/CustomModal";
import AwardListBoardTable from "../All/AwardListBoardTable";

export class CoachAward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAward: [],
      viewModal: false,
      isLoading: false,
      assignTo: "",
      awardName: "",
      awardNameError: "",
      assignToError: "",
      createAwardLoader: false,
      updateModal: false,
      updateModalId: "",
      updateAwardName: "",
      updateAssignTo: "",
      updateAwardNameError: "",
      updateAssgnToError: "",
      updateLoader: false,
      assignModal: false,
      awardType: "",
      athleteList: [],
      AssignPlayerId: "",
      assignTeamId: this.props.match.params.id,
      awardIds: "",
      assignLoader: false,
    };
  }

  componentDidMount() {
    this.getListAwards();
    this.fetchListPlayer();
  }

  toggleModal = () => {
    this.setState({ viewModal: !this.state.viewModal });
  };

  onChange = (e) => {
    this.setState({ awardName: e.target.value });
  };

  getListAwards = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await standardPostApi(
        "awards",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team_id: this.props.match.params.id,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("response", res.data?.data?.awards);
        this.setState({ listAward: res.data?.data?.awards });
      }
    } catch (error) {
      console.log("get award error", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleAssignTo = (type) => {
    this.setState({ assignTo: type });
  };

  handelCreateAward = async () => {
    const isValid = this.validationCreateAward();

    if (isValid) {
      this.setState({ createAwardLoader: true });
      try {
        const res = await standardPostApi(
          "create_award",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            name: this.state.awardName,
            award_type: this.state.assignTo,
            team_id: this.props.match.params.id,
          },
          true
        );
        if (res.data.code === 200) {
          // console.log("Response of Create Awards", res.data);
          successToast(res.data.message);
          this.setState({
            viewModal: false,
            awardNameError: "",
            assignToError: "",
            awardName: "",
            assignTo: "",
          });
          this.getListAwards();
        }
      } catch (error) {
        console.error("create award", error);
      } finally {
        this.setState({ createAwardLoader: false });
      }
    }
  };

  validationCreateAward = () => {
    let awardNameError = "";
    let assignToError = "";

    if (!this.state.awardName) {
      awardNameError = "Award name field is required.";
    }

    if (!this.state.assignTo) {
      assignToError = "Choose assign award field.";
    }

    if (awardNameError || assignToError) {
      this.setState({ awardNameError, assignToError });
      return false;
    } else {
      return true;
    }
  };

  toggleUpdateAwardModal = (item) => {
    // console.log('itemmmmm', item);
    this.setState({
      updateModal: !this.state.updateModal,
      updateModalId: item?.id,
      updateAwardName: item?.name,
      updateAssignTo: item?.award_type,
    });
  };

  updateChange = (e) => {
    this.setState({ updateAwardName: e.target.value });
  };

  toggleUpdateAssignTo = (type) => {
    this.setState({ updateAssignTo: type });
  };

  handelUpdateAwards = async () => {
    const isValid = this.validationUpdateAwards();

    if (isValid) {
      this.setState({ updateLoader: true });
      try {
        const res = await standardPostApi(
          "create_award",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            name: this.state.updateAwardName,
            award_type: this.state.updateAssignTo,
            team_id: this.props.match.params.id,
            id: this.state.updateModalId,
          },
          true
        );
        if (res.data.code === 200) {
          // console.log('response of update awards');
          successToast(res.data.message);
          this.setState({
            updateModal: false,
            updateAwardNameError: "",
            updateAssgnToError: "",
            updateAwardName: "",
            updateAssignTo: "",
          });
          this.getListAwards();
        }
      } catch (error) {
        console.error("update award error", error);
      } finally {
        this.setState({ updateLoader: false });
      }
    }
  };

  validationUpdateAwards = () => {
    let updateAwardNameError = "";
    let updateAssgnToError = "";

    if (!this.state.updateAwardName) {
      updateAwardNameError = "Award name field is required.";
    }
    if (!this.state.updateAssignTo) {
      updateAssgnToError = "Choose assign award field.";
    }

    if (updateAwardNameError || updateAssgnToError) {
      this.setState({ updateAwardNameError, updateAssgnToError });
      return false;
    } else {
      return true;
    }
  };

  toggleAssignModal = (item) => {
    this.setState({
      assignModal: true,
      awardType: item?.award_type,
      awardIds: item?.id,
    });
  };

  closeAssignModal = () => {
    this.setState({
      assignModal: false,
    });
  };

  fetchListPlayer = async () => {
    try {
      const res = await standardPostApi(
        "list_team_players",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          team: this.props.match.params.id,
        },
        true
      );
      if (res.data.code === 200) {
        // console.log("Response of List Player", res.data.data.Athelete);
        this.setState({ athleteList: res.data?.data?.Athelete });
      }
    } catch (error) {
      console.error("list player error", error);
    }
  };

  handlePlayerRadio = (e) => {
    this.setState({ AssignPlayerId: e.target.value });
  };

  handelCoachRadio = (e) => {
    this.setState({ assignTeamId: e.target.value });
  };

  handelAsssignAwards = async () => {
    this.setState({ assignLoader: true });
    const isValid = this.validationAssignAward();

    if (isValid) {
      try {
        const res = await standardPostApi(
          "assign_award",
          undefined,
          {
            access_token: await localStorage.getItem("access_token"),
            player_id: this.state.AssignPlayerId,
            team_id: this.state.assignTeamId,
            award_id: this.state.awardIds,
          },
          true
        );
        if (res.data.code === 200) {
          // console.log('Response of assign award', res.data.data);
          this.setState({ AssignPlayerId: "" });
          this.closeAssignModal();
          this.getListAwards();
          successToast(res.data.message);
        }
      } catch (error) {
        console.error("assign Awards", error);
      } finally {
        this.setState({ assignLoader: false });
      }
    }
  };

  validationAssignAward = () => {
    if (this.state.awardType === "individual") {
      if (!this.state.AssignPlayerId) {
        errorToast("Please Choose Player");
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  render() {
    const coachRoles = localStorage.getItem("access_role");
    const { listAward } = this.state;

    return (
      <div>
        <CoachHeader />
        <div className="loader_sec">
          <div className="dashboard-wrapper">
            <section className="myteams_wrapper">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 side-buttons left-side-section">
                  <Buttons
                    coachRoles={coachRoles}
                    props={this.props}
                    isActive="award"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 right-side-section">
                  <div className="d-md-flex align-items-center ">
                    <CustomBreadcrumb
                      heading="My Player"
                      className={"mb-4"}
                      navList={[
                        {
                          name: "My Team",
                          link: `/myteamwrapper`,
                        },
                        {
                          name: this.props.match.params.teamname,
                          link: `/myplayers/${this.props.match.params.id}/${this.props.match.params.teamname}`,
                        },
                        {
                          name: "Awards",
                        },
                      ]}
                    />
                  </div>
                  {/* <Buttons coachRoles={coachRoles} props={this.props} isActive="award" /> */}
                  <div className="row my-5">
                    <div
                      className="col-lg-3 col-md-3 col-sm-3 heading"
                      style={{
                        fontWeight: 500,
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Awards
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 d-flex justify-content-end">
                      <div className="d-flex justify-content-end">
                        <div className="mr-2">
                          <CustomModal
                            modalSize={"lg"}
                            actionElement={
                              <CustomButton
                                style={{
                                  backgroundColor: "#EFAD4D",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                Award's Board
                              </CustomButton>
                            }
                            headingTitle={"Awards Board"}
                          >
                            <AwardListBoardTable
                              teamId={this.props.match.params.id}
                            />
                          </CustomModal>
                          {/* <Link
                            to={`/awardsBoard/${this.props.match.params.id}/${this.props.match.params.teamname}`}
                            style={{ textDecoration: "none" }}
                          >
                            <CustomButton
                              style={{
                                backgroundColor: "#EFAD4D",
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              Award's Board
                            </CustomButton>
                          </Link> */}
                        </div>

                        <div>
                          <CustomButton
                            onClick={() => this.toggleModal()}
                            style={{ fontSize: "14px", fontWeight: 500 }}
                          >
                            Add New Award
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <CreateCoachAwairdsTable
                        listAward={listAward}
                        toggleModal={this.toggleModal}
                        parentState={this.state}
                        toggleAssignTo={this.toggleAssignTo}
                        onChange={(e) => this.onChange(e)}
                        handelCreateAward={this.handelCreateAward}
                        toggleUpdateAwardModal={this.toggleUpdateAwardModal}
                        updateChange={this.updateChange}
                        toggleUpdateAssignTo={this.toggleUpdateAssignTo}
                        handelUpdateAwards={this.handelUpdateAwards}
                        toggleAssignModal={this.toggleAssignModal}
                        teamInfo={this.props.match.params.teamname}
                        handlePlayerRadio={this.handlePlayerRadio}
                        handelCoachRadio={this.handelCoachRadio}
                        closeAssignModal={this.closeAssignModal}
                        handelAsssignAwards={this.handelAsssignAwards}
                        isLoading={this.state.assignLoader}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CoachAward;
