import React from "react";
import ManageRecipientsModal from "../SelfScreen/ManageRecipientsModal";
import { standardPostApi } from "../../container/API/ApiWrapper";
import InputDateTimePicker from "component/customInput/InputDateTimePicker";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import moment from "moment";
class WeeklyReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manageRecipientsModal: false,
      listUserActivity: [],
      startDate: "",
      endDate: "",
      otherActivity: [],
      otherActivityValue: "",
    };
  }

  componentDidMount() {
    this.list_user_activity();
  }

  showManageRecipientsModal = async () => {
    await this.setState({
      manageRecipientsModal: !this.state.manageRecipientsModal,
    });
  };

  hideManageRecipientsModal = async () => {
    await this.setState({ manageRecipientsModal: false });
  };
  handleDateChange = (startDate) => {
    this.setState({ startDate: startDate });
  };
  handleEndDateChange = (endDate) => {
    this.setState({ endDate: endDate });
  };
  list_user_activity = async () => {
    const weekId = this.props.startWorkoutArray.weeks.find((data) => data);
    try {
      const res = await standardPostApi(
        "list_user_activity",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: this.props.startWorkoutArray.id,
          annual_training_program_week_id: weekId.id,
        },
        true
      );
      if (res.data.code === 200) {
        await this.setState({
          listUserActivity: res.data.data.activities,
          otherActivity: [
            ...res.data.data?.other_activites_duration,
            ...res.data.data?.other_activities_complete,
          ],
        });
        // console.log("RESPONSE OF LIST USER ACTIVITY", res.data.data.activities);
      }
    } catch (error) {
      console.log(error);
    }
  };
  list_user_activity_select_date = async () => {
    const start_date = moment(this.state.startDate).format("YYYY-MM-DD");

    const end_date = moment(this.state.endDate).format("YYYY-MM-DD");
    const weekId = this.props.startWorkoutArray.weeks.find((data) => data);
    try {
      const res = await standardPostApi(
        "list_user_activity",
        undefined,
        {
          access_token: await localStorage.getItem("access_token"),
          annual_training_program_id: this.props.startWorkoutArray.id,
          annual_training_program_week_id: weekId.id,
          start_date: start_date,
          end_date: end_date,
        },
        true
      );
      if (res.data.code === 200) {
        await this.setState({
          listUserActivity: res.data.data.activities,
          otherActivity: [
            ...res.data.data?.other_activites_duration,
            ...res.data.data?.other_activities_complete,
          ],
        });
        // console.log("RESPONSE OF LIST USER ACTIVITY", res.data.data.activities);
      }
    } catch (error) {
      console.log(error);
    }
  };
  onOtherActivitySelect = (e) => {
    this.setState({ otherActivityValue: e.target.value });
  };
  render() {
    console.log("activity", this.state.otherActivity);
    // console.log("start", this.state.startDate, "end", this.state.endDate);
    return (
      <div className="week_section mt-3">
        <h6 className="mb-3">Weekly Report</h6>
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-6 col-md-6">
            <InputDateTimePicker
              style={{ width: "100%" }}
              value={this.state.startDate}
              onChange={this.handleDateChange}
              placeholder="Start Date"
            />
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <div className="d-flex">
              <InputDateTimePicker
                style={{ width: "100%" }}
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
                placeholder="End Date"
              />
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  marginLeft: "15px",
                }}
                onClick={() => this.list_user_activity_select_date()}
              >
                <CheckCircleRoundedIcon
                  style={{
                    color: "var(--safetyOrange)",
                    fontSize: "40px",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="">
                      <button
                        className="manage_btn_react"
                        // onClick={(e) => {
                        //   this.showManageRecipientsModal();
                        // }}
                        onClick={() => alert("Coming soon!")}
                      >
                        Manage Recipients
                      </button>

                      <button
                        className="manage_btn_react"
                        // onClick={(e) => {
                        //   this.showManageRecipientsModal();
                        // }}
                        onClick={() => alert("Coming soon!")}
                      >
                        Send Report
                      </button>
                    </div> */}

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr
                    className="react_Testing_Table"
                    style={{ backgroundColor: "var(--blackRussian)" }}
                  >
                    {this.state.listUserActivity.map((data, index) => {
                      return <th key={index}>{data.key}</th>;
                    })}
                    {this.state.otherActivity.length > 0 && (
                      <th>
                        <select
                          style={{
                            backgroundColor: "var(--blackRussian)",
                            color: "white",
                            border: "none",
                            outline: "none",
                          }}
                          onChange={this.onOtherActivitySelect}
                        >
                          <option>Select</option>
                          {this.state.otherActivity?.map((item) => (
                            <option key={item.key} value={item.value}>
                              {item.key}
                            </option>
                          ))}
                        </select>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr className="react_Testing_Table_1">
                    {this.state.listUserActivity.map((item, index) => {
                      return <td key={index}>{item.value}</td>;
                    })}
                    {this.state.otherActivity.length > 0 && (
                      <td>{this.state.otherActivityValue}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ManageRecipientsModal
          show={this.state.manageRecipientsModal}
          onHide={this.hideManageRecipientsModal}
        />
      </div>
    );
  }
}

export default WeeklyReport;
