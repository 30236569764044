import React, { Component } from "react";
import Checkbox from "../../../component/Checkbox/Checkbox";
import { Modal } from "react-bootstrap";
import CustomInput from "component/customInput/CustomInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SvgIcon from "component/SvgIcon";
import CustomButton from "component/customButton/CustomButton";

class EditTestingProtocolModal extends Component {
  render() {
    const {
      show,
      onHide,
      state,
      onChange,
      handelUpdateTestingProtocol,
      handelUpdateTestingProtocolCheckbox,
      toggleEditCb,
      addToUpdateTestingProtocolExerciseNew,
      updateTestingProtocolApi,
      toggleConfirmModal,
      editNewDetailsObj,
      isLoading,
    } = this.props;

    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          animation={true}
          backdropClassName="modal_backdrop"
          contentClassName="modal_container"
        >
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title" id="edit-protocol">
                Edit Testing Protocol
              </h5>
              <button
                type="button"
                className="modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <CustomInput
                  type="text"
                  placeholder="Name"
                  name="editNewdefName"
                  value={(state.editNewdefName, state?.editNewDetailsName)}
                  onChange={(e) => onChange(e)}
                />
                {/* <label htmlFor="">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="editNewdefName"
                  defaultValue={
                    (state.editNewdefName, state?.editNewDetailsName)
                  }
                  onChange={(e) => onChange(e)}
                /> */}
              </div>
              <div className="d-flex align-item-center">
                <div className="form-group mr-2">
                  <CustomInput
                    type="text"
                    placeholder="Exercise"
                    name="editExerciseNew"
                    value={state.editExerciseNew}
                    onChange={(e) => onChange(e)}
                    error={state.editNewTestingExerciseError}
                  />
                </div>
                <div className="form-group ml-2">
                  <CustomInput
                    type="text"
                    placeholder="Unit"
                    name="editUnitNew"
                    value={state.editUnitNew}
                    onChange={(e) => onChange(e)}
                    error={state.editNewTestingUnitsError}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  <Checkbox
                    style={{ marginTop: "7px" }}
                    name="smaller_better"
                    checked={state.editTestingProtocolCheckbox}
                    toggleCb={() => toggleEditCb()}
                  />
                  <span>Smaller is Better?</span>
                </div>
                <div>
                  <AddCircleIcon
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#F75F03",
                      cursor: "pointer",
                    }}
                    onClick={() => addToUpdateTestingProtocolExerciseNew()}
                  />
                </div>
              </div>
              {editNewDetailsObj &&
                editNewDetailsObj.lenght !== 0 &&
                editNewDetailsObj.map((item, ind) => {
                  return (
                    <>
                      <div className="d-flex align-item-center">
                        <CustomInput
                          className={"mr-2"}
                          type="text"
                          value={item?.exercise}
                          onChange={(text) =>
                            handelUpdateTestingProtocol(ind, "exercise", text)
                          }
                        />
                        <CustomInput
                          className={"ml-2"}
                          type="text"
                          value={item?.units}
                          onChange={(text) =>
                            handelUpdateTestingProtocol(ind, "units", text)
                          }
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className="d-flex align-items-center"
                          style={{ fontSize: "14px", color: "white" }}
                        >
                          <Checkbox
                            style={{ marginTop: "7px" }}
                            checked={item.smaller_better === "1" ? true : false}
                            toggleCb={() =>
                              handelUpdateTestingProtocolCheckbox(item, ind)
                            }
                          />
                          <span>Smaller is Better?</span>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => toggleConfirmModal(ind)}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {state.deleteExerciseLoader &&
                            state.deleteExeIndex === ind ? (
                              <i
                                className="fa fa-spinner fa-spin fa-3x fa-fw"
                                style={{
                                  color: "#fff",
                                  fontSize: "20px",
                                }}
                              />
                            ) : (
                              <SvgIcon
                                name="trash"
                                style={{
                                  width: "25px",
                                  height: "50px",
                                }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}

              <div className="d-flex">
                <CustomButton
                  flex={1}
                  // isLoading={state.editLoader}
                  dataDismiss="modal"
                  onClick={() => updateTestingProtocolApi()}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save
                </CustomButton>
              </div>
              {/* <button
                type="button"
                className="Model_btn"
                data-dismiss="modal"
                onClick={() => updateTestingProtocolApi()}
              >
                Save
                {state.editLoader && (
                  <i
                    className="fa fa-spinner fa-spin fa-3x fa-fw"
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                    }}
                  ></i>
                )}
              </button> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EditTestingProtocolModal;
