import React from "react";
import { Link } from "react-router-dom";
import "./CustomBreadcrumb.scss";

// navList data should be like this
// navListExample = [
//     {
//         name : "",
//         link : "/",
//     }
// ]

function CustomBreadcrumb({ heading, navList, className }) {
  return (
    <div className={`breadcrumb_container ${className}`}>
      <p className="heading-p">{heading}</p>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {navList?.filter(Boolean)?.map((item, index) =>
            !item?.link ? (
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
                key={index}
              >
                {item?.name}
              </li>
            ) : (
              <li className={`breadcrumb-item text-capitalize`} key={index}>
                <Link to={item?.link}>{item?.name}</Link>
              </li>
            )
          )}
        </ol>
      </nav>
    </div>
  );
}

export default CustomBreadcrumb;
