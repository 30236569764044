import CustomButton from "component/customButton/CustomButton";
import SvgIcon from "component/SvgIcon";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export class DeleteScreeningPtotocol extends Component {
  render() {
    const { state, isLoading } = this.props;
    return (
      <div>
        <div>
          <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            centered
            animation={true}
            backdropClassName="modal_backdrop"
            contentClassName="modal_container"
          >
            <Modal.Body>
              <div className="modal-header" style={{ border: "none" }}>
                <button
                  type="button"
                  className="modal_close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.onHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="delete_modal_react d-flex align-items-center justify-content-center flex-column">
                <SvgIcon name="modal-delete" />
                <span
                  className="mt-3 mb-3"
                  style={{ fontSize: 20, fontWeight: "bold", color: "white" }}
                >
                  Are you sure?
                </span>
                <span style={{ color: "white", textAlign: "center" }}>
                  you want to delete the protocol {state.screenHeadPreName} ?
                  This change cannot be undone!
                </span>
                <div className="d-flex mt-4">
                  <CustomButton
                    className={"mr-2"}
                    data-dismiss="modal"
                    onClick={this.props.onHide}
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      border: "1px solid white",
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    className={"ml-2"}
                    dataDismiss="modal"
                    onClick={this.props.deleteScreeningProtocolApi}
                    style={{ backgroundColor: "#E81E16" }}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Delete
                  </CustomButton>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default DeleteScreeningPtotocol;
